import { IToken } from '../features/auth/store/models.types'
import { setToken } from './axios'
import { StorageFactory, StorageType } from './storage/StorageFactory'
import Cookie from './storage/Cookie'

const setTokenToAxiosAndCookie = (tokenResponse: IToken) => {
	const storage: Cookie<IToken> = StorageFactory.buildStorage(StorageType.Cookie)
	storage.set('token', tokenResponse)
	setToken(tokenResponse.accessToken)
}

const removeTokenFromAxiosAndCookie = () => {
	const storage: Cookie<IToken> = StorageFactory.buildStorage(StorageType.Cookie)
	storage.remove('token')
	setToken('')
}

const getTokenInfo = (token: string) => {
	return JSON.parse(atob(token.split('.')[1]))
}

export { setTokenToAxiosAndCookie, getTokenInfo, removeTokenFromAxiosAndCookie }
