import { IUser, IAuth } from './store/models.types'

export const defaultUser: IUser = {
	id: 0,
	firstName: '',
	lastName: '',
	fullName: '',
	email: ''
}

export const loginCredentials = {
	email: '',
	password: ''
}

export const defaultAuth: IAuth = {}
