import * as React from 'react'
import { Input, Form } from 'antd'
import { IFieldProps } from './types'

export const InputField: React.FC<IFieldProps> = props => {
	const { handleChange, value, name, label, placeholder } = props

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value
		if (handleChange) {
			handleChange(newValue)
		}
	}

	return (
		<Form.Item name={name} label={label}>
			<Input
				onChange={handleChange ? onChange : undefined}
				value={handleChange ? value : undefined}
				placeholder={placeholder}
			/>
		</Form.Item>
	)
}
