import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { getLoadersState, isLoading as getIsLoading } from '../../../store/common/loaders/selector'
import { fetchGroupMembersBatchRequest } from '../store/actions'
import { getGroupMembersById } from '../store/selectors'
import { TagsActionTypes } from '../store/action.types'
import { ITagsByTagId } from '../store/types'

interface IUseGroupMembersByIdResult {
	groupMembersById: ITagsByTagId
	isLoading: boolean
}

export const useGroupMembersById = (groupIds: number[]): IUseGroupMembersByIdResult => {
	const dispatch = useDispatch()

	const [isGroupMembersBatchFetchDispatched, setIsGroupMembersBatchFetchDispatched] = useState(
		false
	)

	const loaders = useSelector(getLoadersState)
	const isLoading = getIsLoading(loaders, [TagsActionTypes.FetchGroupMembersBatchRequest])

	useEffect(() => {
		if (groupIds.length && !isGroupMembersBatchFetchDispatched) {
			dispatch(fetchGroupMembersBatchRequest(groupIds))
			setIsGroupMembersBatchFetchDispatched(true)
		}
	}, [dispatch, groupIds, isGroupMembersBatchFetchDispatched])

	const groupMembersById = useSelector(getGroupMembersById)

	return {
		groupMembersById,
		isLoading
	}
}
