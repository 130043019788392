import * as React from 'react'
import { Table, Button } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { IStaffMember } from 'brainstorming-types'
import { printDate } from '../../../../utils/dateAndTimeFormatters'
import TableWrapper from '../../../../components/TableWrapper'
import { formatMoney, getAmountToPay, getTotalAmountToPay } from '../../utils/helpers'
import { TableInfo } from './TableInfo'
import { getZeroMoney } from '../../utils/constants'

interface IProps {
	staff: IStaffMember[]
	isLoading: boolean
	onOpenDetails(staffMemberId: number): void
}

export const ActiveStaffTable: React.FC<IProps> = ({ staff, isLoading, onOpenDetails }) => {
	const totalToPay = React.useMemo(() => getTotalAmountToPay(staff), [staff])

	const columns: ColumnProps<IStaffMember>[] = [
		{
			key: 'user.fullName',
			title: 'Full name',
			dataIndex: ['user', 'fullName']
		},
		{
			key: 'unpaidWordCount',
			title: 'Number of words',
			dataIndex: 'unpaidWordCount'
		},
		{
			key: 'pricePerWord',
			title: 'Price per word',
			dataIndex: 'pricePerWord',
			render: pricePerWord => formatMoney(pricePerWord)
		},
		{
			key: 'value',
			title: 'Earned',
			render: (text, { pricePerWord, unpaidWordCount }) =>
				formatMoney({
					...pricePerWord,
					amount: pricePerWord.amount * (unpaidWordCount || 0)
				})
		},
		{
			key: 'previouslyUnpaid',
			title: 'Remaining from previous payment',
			render: (text, { previouslyUnpaid, pricePerWord }) =>
				formatMoney(
					previouslyUnpaid?.currency ? previouslyUnpaid : getZeroMoney(pricePerWord.currency)
				)
		},
		{
			key: 'toPay',
			title: 'To pay',
			render: (text, staffMember) => formatMoney(getAmountToPay(staffMember))
		},
		{
			key: 'lastActivatedAt',
			title: 'Created at',
			dataIndex: 'lastActivatedAt',
			render: lastActivatedAt => printDate(lastActivatedAt)
		},
		{
			title: '',
			key: 'actions',
			render: (staffMember: IStaffMember) => (
				<div className="actions-wrapper">
					<Button type="primary" size="small" onClick={() => onOpenDetails(staffMember.id)}>
						see details
					</Button>
				</div>
			)
		}
	]

	return (
		<TableWrapper>
			<Table
				columns={columns}
				dataSource={staff}
				loading={isLoading}
				rowKey="id"
				pagination={false}
			/>
			<TableInfo>
				Total to pay: <b>${formatMoney(totalToPay)}</b>
			</TableInfo>
		</TableWrapper>
	)
}
