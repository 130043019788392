import * as React from 'react'
import { Table, Button, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { IStaffMember } from 'brainstorming-types'
import { printDate } from '../../../../utils/dateAndTimeFormatters'
import TableWrapper from '../../../../components/TableWrapper'
import { formatMoney } from '../../utils/helpers'

interface IProps {
	staff: IStaffMember[]
	isLoading: boolean
	onOpenDetails(staffMemberId: number): void
}

export const InactiveStaffTable: React.FC<IProps> = ({ staff, isLoading, onOpenDetails }) => {
	const columns: ColumnProps<IStaffMember>[] = [
		{
			key: 'user.fullName',
			title: 'Full name',
			dataIndex: ['user', 'fullName'],
			align: 'left'
		},
		{
			key: 'totalPaid',
			title: 'Total paid out',
			dataIndex: 'totalPaid',
			render: totalPaid => formatMoney(totalPaid),
			align: 'right'
		},
		{
			key: 'lastActivatedAt',
			title: 'Created at',
			dataIndex: 'lastActivatedAt',
			render: lastActivatedAt => printDate(lastActivatedAt),
			align: 'left'
		},
		{
			key: 'inactiveSince',
			title: 'Deactivated on',
			dataIndex: 'inactiveSince',
			render: text => (text ? <Tag color="red">{printDate(text)}</Tag> : '-'),
			align: 'left'
		},
		{
			title: '',
			key: 'actions',
			render: (staffMember: IStaffMember) => (
				<div className="actions-wrapper">
					<Button type="primary" size="small" onClick={() => onOpenDetails(staffMember.id)}>
						see history
					</Button>
				</div>
			),
			align: 'right'
		}
	]

	return (
		<TableWrapper>
			<Table
				columns={columns}
				dataSource={staff}
				loading={isLoading}
				rowKey="id"
				pagination={false}
			/>
		</TableWrapper>
	)
}
