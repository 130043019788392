import { get } from 'lodash'

export const errorHandler = (error: any) => {
	const errorData = get(error, 'response.data')
	if (!errorData)
		return {
			error: { statusCode: 500, message: 'Ups, something went wrong!' }
		}
	return { error: errorData }
}
