import * as React from 'react'
import styled from 'styled-components'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Table, Input, InputNumber } from 'antd'
import { Mode } from '../../constants'
import { IGroup, IPermission, GroupType } from 'brainstorming-types'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import AddPermissionFormModal from '../common/AddPermissionModal'
import HeaderButtons from '../common/HeaderButtons'
import usePrevious from '../../../../hooks/usePrevious'
import { SPACES } from '../../../../styles/spaces'

interface ILocalProps {
	mode: Mode
	active: IGroup
	permissions: IPermission[]
	onDelete(): void
	onEdit(): void
	onCancel(): void
	onSave(data: IUserRankForm): void
}

interface IFormProps extends FormComponentProps<IUserRankFormInternal> {}

interface IProps extends ILocalProps, IFormProps {}

export interface IUserRankForm extends IUserRankFormInternal {
	type?: GroupType
}

export interface IUserRankFormInternal {
	permissions: number[]
	name: string
	minPoints: number
	maxPoints: number
}

const columns = [
	{
		title: 'Permission',
		dataIndex: 'name'
	},
	{
		title: 'Description',
		dataIndex: 'description'
	}
]

const MinMaxInputs = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 35px;
`

const UserRankGroupNameInput = styled(Form.Item)`
	width: 70%;
	margin: 0;
	margin-bottom: ${SPACES.EXTRA_SMALL};
	p {
		margin: 0;
		padding: 0;
	}
`

const UserRankGroupPointsInput = styled(Form.Item)`
	margin: 0;
	padding: 0;
	display: flex;
	&:after {
		content: '-';
		margin-top: 8px;
		padding: 0 10px;
	}
`

const UserRankPermissionsTable = styled(Table)`
	background-color: white;
	thead {
		tr {
			th {
				background: white;
			}
		}
	}
	tbody {
		tr {
			td {
				background: white;
			}
		}
	}
`

const UserRankingGroupForm: React.FC<IProps> = ({
	mode,
	active,
	permissions,
	form,
	onDelete,
	onEdit,
	onCancel,
	onSave
}) => {
	const [permissionList, setPermissionList] = React.useState(active.permissions)
	const { getFieldDecorator } = form
	const prevActive = usePrevious(active)

	const isEditMode = mode === Mode.Edit

	React.useEffect(() => {
		if (prevActive !== active) {
			form.resetFields(['name', 'permissions', 'minPoints', 'maxPoints'])
			setPermissionList(active.permissions)
		}
	}, [active, form, prevActive])

	const handleSelectChange = (selected: any) => {
		form.setFieldsValue({ permissions: selected })
	}

	const handleAddPermissions = (checkedPermissions: number[]) => {
		const newPermissionIds = checkedPermissions.filter(
			permission => !permissionList!.find(p => p.id === permission)
		)
		const newPermissions = permissions.filter(permission =>
			newPermissionIds.includes(permission.id)
		)
		setPermissionList([...permissionList!, ...newPermissions])
		form.setFieldsValue({
			permissions: checkedPermissions
		})
	}

	const handleOnSave = () => {
		form.validateFields(['name', 'permissions', 'minPoints', 'maxPoints'], (errors, values) => {
			if (!errors) {
				onSave(values)
			}
		})
	}

	const handleOnCancel = () => {
		form.resetFields(['name', 'permissions', 'minPoints', 'maxPoints'])
		setPermissionList(active.permissions)
		onCancel()
	}

	return (
		<Form onSubmit={handleOnSave}>
			<HeaderButtons
				mode={mode}
				active={active}
				onCancel={handleOnCancel}
				onSave={handleOnSave}
				onDelete={onDelete}
				onEdit={onEdit}
			/>
			<div>
				<UserRankGroupNameInput>
					{getFieldDecorator('name', {
						initialValue: active.name,
						rules: [{ required: true, message: 'Name is required!' }]
					})(isEditMode ? <Input placeholder="Group name" /> : <p>{active.name}</p>)}
				</UserRankGroupNameInput>
				<MinMaxInputs>
					<UserRankGroupPointsInput style={{ marginBottom: 0 }}>
						{getFieldDecorator('minPoints', {
							initialValue: active.minPoints,
							rules: [
								{
									required: true,
									message: 'Min points is required!'
								},
								{
									validator: (_, value) => value < form.getFieldValue('maxPoints'),
									message: 'Should be less then max!'
								}
							]
						})(
							isEditMode ? (
								<InputNumber onBlur={() => form.validateFields(['maxPoints'])} />
							) : (
								<span>{active.minPoints}</span>
							)
						)}
					</UserRankGroupPointsInput>
					<Form.Item style={{ marginBottom: 0 }}>
						{getFieldDecorator('maxPoints', {
							initialValue: active.maxPoints,
							rules: [
								{ required: true, message: 'MaxPoints is required!' },
								{
									validator: (_, value) => value > form.getFieldValue('minPoints'),
									message: 'Should be more then min!'
								}
							]
						})(
							isEditMode ? (
								<InputNumber onBlur={() => form.validateFields(['minPoints'])} />
							) : (
								<span>{active.maxPoints}</span>
							)
						)}
					</Form.Item>
				</MinMaxInputs>
				{getFieldDecorator('permissions', {
					initialValue: active.permissions!.map(permission => permission.id),
					trigger: 'none'
				})(
					<UserRankPermissionsTable
						rowSelection={
							isEditMode
								? {
										selectedRowKeys: form.getFieldValue('permissions'),
										onChange: handleSelectChange
								  }
								: undefined
						}
						columns={columns}
						dataSource={permissionList!.map((permission: IPermission) => ({
							...permission,
							key: permission.id
						}))}
						pagination={{ hideOnSinglePage: true }}
						locale={{ emptyText: 'No permissions added' }}
					/>
				)}
				{isEditMode && (
					<AddPermissionFormModal
						allPermissions={permissions}
						activePermissions={form.getFieldValue('permissions')}
						onAddPermission={handleAddPermissions}
					/>
				)}
			</div>
		</Form>
	)
}

export default Form.create<IProps>({
	name: 'userRankingGroups'
})(UserRankingGroupForm)
