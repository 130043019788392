import * as React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { Mode } from '../../constants'
import { IGroup } from 'brainstorming-types'

interface IProps {
	mode: Mode
	active?: IGroup
	onSave(): void
	onDelete(): void
	onCancel(): void
	onEdit(): void
}

const HeaderButtons: React.FC<IProps> = ({ mode, onSave, onCancel, onDelete, onEdit, active }) => {
	if (mode === Mode.View) {
		return (
			<StyledWrapper>
				{active && active.id && (
					<Button onClick={onDelete} type="link">
						DELETE
					</Button>
				)}
				<Button onClick={onEdit} type="primary">
					EDIT
				</Button>
			</StyledWrapper>
		)
	}

	return (
		<StyledWrapper>
			<Button onClick={onCancel} type="link">
				CANCEL
			</Button>
			<Button onClick={onSave} type="primary">
				SAVE
			</Button>
		</StyledWrapper>
	)
}

const StyledWrapper = styled.div`
	text-align: right;
	float: right;
`

export default HeaderButtons
