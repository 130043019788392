import { ACCOUNTING, USER_MANAGEMENT } from '../../../config/urls'
import axios from 'axios'
import { IAddStaff, ICreateStaffPayment, IEditStaffMember } from './types'

export const fetchStaff = async () => {
	return axios
		.get(`${ACCOUNTING}/admin/staff`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchStaffMember = async (staffMemberId: number) => {
	return axios
		.get(`${ACCOUNTING}/admin/staff/${staffMemberId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const addStaff = async (data: IAddStaff) => {
	return axios
		.post(`${ACCOUNTING}/admin/staff`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const editStaffMember = async (staffMemberId: number, data: IEditStaffMember) => {
	return axios
		.patch(`${ACCOUNTING}/admin/staff/${staffMemberId}`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchPaymentsForStaffMember = async (staffMemberId: number) => {
	return axios
		.get(`${ACCOUNTING}/admin/staff/${staffMemberId}/payments`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const createPaymentForStaffMember = async (
	staffMemberId: number,
	data: ICreateStaffPayment
) => {
	return axios
		.post(`${ACCOUNTING}/admin/staff/${staffMemberId}/payments`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const quickSearchUsers = async (query: string, limit: number = 10) => {
	return axios
		.get(`${USER_MANAGEMENT}/admin/users`, { params: { s: { fullName: { $cont: query } }, limit } })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}
