import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { fetchPagesRequest } from '../store/actions'
import { getPages } from '../store/selectors'
import { IPages, PagesActionType } from '../store/types'

interface IUsePagesResult {
	pages: IPages
	isLoading: boolean
}

export const usePages = (): IUsePagesResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchPagesRequest())
	}, [dispatch])

	const pages = useSelector(getPages())
	const loaders = useSelector(getLoadersState)
	const isPagesLoading = isLoading(loaders, [PagesActionType.FetchPagesRequest])

	return {
		pages,
		isLoading: isPagesLoading
	}
}
