import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ITag } from 'brainstorming-types'

import { getLoadersState, isLoading as getIsLoading } from '../../../store/common/loaders/selector'
import { fetchRegularTagsRequest } from '../store/actions'
import { getRegularTags } from '../store/selectors'
import { TagsActionTypes } from '../store/action.types'

interface IUseRegularTagsResult {
	regularTags: ITag[]
	isLoading: boolean
}

export const useRegularTags = (): IUseRegularTagsResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchRegularTagsRequest())
	}, [dispatch])

	const regularTags = useSelector(getRegularTags)
	const loaders = useSelector(getLoadersState)
	const isLoading = getIsLoading(loaders, [TagsActionTypes.FetchRegularTagsRequest])

	return {
		regularTags,
		isLoading
	}
}
