import * as React from 'react'
import { Modal, Button, Select, Row, Col } from 'antd'
import { IUser } from 'brainstorming-types'
import { SelectValue } from 'antd/lib/select'

interface IProps {
	allUsers: IUser[]
	activeUsers: number[]
	onAddUser(user: IUser[]): void
}

const AddUserModal: React.FC<IProps> = ({ allUsers, activeUsers, onAddUser }) => {
	const [open, setOpen] = React.useState<boolean>(false)
	const [userValues, setUserValues] = React.useState<number[]>([])
	const [searchedUsers, setSearchedUsers] = React.useState(allUsers)

	const handleAddUsers = () => {
		onAddUser(allUsers.filter(u => userValues.includes(u.id)))
		setOpen(false)
	}

	const handleOpen = () => {
		setUserValues(activeUsers)
		setOpen(true)
		setSearchedUsers(allUsers)
	}

	const handleChange = (selectedValue: SelectValue[]) => {
		setUserValues(selectedValue as number[])
	}

	const handleSearch = (value: string) => {
		if (value) {
			setSearchedUsers(
				allUsers.filter(u => `${u.fullName}`.toLowerCase().includes(value.toLowerCase()))
			)
		} else {
			setSearchedUsers(allUsers)
		}
	}

	const options = searchedUsers.map(user => (
		<Select.Option key={`${user.fullName}`} value={user.id}>
			{user.fullName} - {user.email}
		</Select.Option>
	))

	return (
		<div>
			<Button type="link" onClick={handleOpen}>
				+ Add new users
			</Button>
			<Modal
				title="Add new User"
				visible={open}
				onOk={handleAddUsers}
				onCancel={() => setOpen(false)}
				okText="Add User"
				cancelText="Cancel"
				width={860}
				bodyStyle={{ minHeight: 200 }}
			>
				<Row>
					<Col span={24}>
						<Select
							showSearch={true}
							mode="multiple"
							value={userValues}
							style={{ width: '100%' }}
							autoClearSearchValue={false}
							defaultActiveFirstOption={false}
							showArrow={true}
							filterOption={false}
							onSearch={(value: any) => handleSearch(value)}
							onChange={handleChange}
							notFoundContent={null}
						>
							{options}
						</Select>
					</Col>
				</Row>
			</Modal>
		</div>
	)
}

export default AddUserModal
