import * as React from 'react'
import { Table, Divider, Button, Tag } from 'antd'
import { ISession, AuditableContentType } from 'brainstorming-types'
import { ColumnProps } from 'antd/lib/table'
import { TablePaginationConfig, SorterResult, SortOrder } from 'antd/lib/table/interface'
import { ISessions } from '../../store/types'
import { SESSIONS_PER_PAGE } from '../../containers/SessionDashboardContainer'
import { ISortUrl } from '../../../../utils/sorting'
import { printDateAndTime } from '../../../../utils/dateAndTimeFormatters'
import TableWrapper from '../../../../components/TableWrapper'

interface IProps {
	sessions: ISession[]
	isLoading: boolean
	sessionsState: ISessions
	page: number
	column?: string
	direction?: SortOrder
	onDelete(sessionId: number): void
	onRestore(sessionId: number): void
	openSessionHistory(historyLogId: number, historyLogType: string): void
	fetchSessions(page: number, sort: ISortUrl): void
}

const SessionsTable: React.FC<IProps> = ({
	sessions,
	isLoading,
	sessionsState,
	page,
	fetchSessions,
	column,
	direction,
	onDelete,
	onRestore,
	openSessionHistory
}) => {
	const columns: ColumnProps<ISession>[] = [
		{
			title: 'Id',
			dataIndex: 'id',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'id' ? direction : undefined
		},
		{
			title: 'Title',
			dataIndex: 'title'
		},
		{
			title: 'User',
			dataIndex: 'user',
			render: (text, record, index) => {
				return <div key={`user-session-${index}`}>{text.fullName}</div>
			}
		},
		{
			title: 'Number of up votes',
			dataIndex: 'numUpVotes',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'numUpVotes' ? direction : undefined,
			align: 'right'
		},
		{
			title: 'Status',
			dataIndex: 'status',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'status' ? direction : undefined,
			render: (text, record) => {
				return <span key={`${record.status}`}>{record.status ? record.status : '-'}</span>
			}
		},
		{
			title: 'Content Type',
			dataIndex: 'contentType',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'contentType' ? direction : undefined
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'createdAt' || column === undefined ? direction : undefined,
			render: text => printDateAndTime(text)
		},
		{
			key: 'deletedAt',
			title: 'Deleted at',
			dataIndex: 'deletedAt',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'deletedAt' ? direction : undefined,
			render: text => (!!text ? <Tag color="red">{printDateAndTime(text)}</Tag> : '-')
		},
		{
			title: 'Actions',
			key: 'action',
			render: text =>
				!text.deletedAt ? (
					<div className="actions-wrapper">
						<Button
							size="small"
							href={`${process.env.REACT_APP_WEB_URL}/r/s${text.id}`}
							disabled={!!text.deletedAt}
							target="_blank"
						>
							View
						</Button>
						<Divider type="vertical" />
						<Button
							type="primary"
							danger={true}
							size="small"
							onClick={() => onDelete(text.id)}
							disabled={!!text.deletedAt}
						>
							Delete
						</Button>
						<Divider type="vertical" />
						<Button
							type="primary"
							size="small"
							onClick={() => openSessionHistory(text.id, AuditableContentType.Session)}
						>
							See history
						</Button>
					</div>
				) : (
					<div>
						<Button
							type="primary"
							size="small"
							onClick={() => onRestore(text.id)}
							style={{ marginBottom: '2px' }}
						>
							Restore
						</Button>
						<Divider type="vertical" />
						<Button
							type="primary"
							size="small"
							onClick={() => openSessionHistory(text.id, AuditableContentType.Session)}
						>
							See history
						</Button>
					</div>
				)
		}
	]

	const onChange = (pagination: TablePaginationConfig, _: any, sorter: SorterResult<ISession>) => {
		fetchSessions(pagination.current as number, {
			column: sorter.field as string,
			order: sorter.order
		})
	}

	return (
		<TableWrapper>
			<Table
				columns={columns}
				dataSource={sessions}
				onChange={onChange as any}
				loading={isLoading}
				pagination={{
					total: sessionsState.total,
					current: page,
					pageSize: SESSIONS_PER_PAGE,
					showSizeChanger: false
				}}
				rowKey="id"
			/>
		</TableWrapper>
	)
}

export default SessionsTable
