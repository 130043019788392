import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { editPageModuleOrderRequest } from '../store/actions'
import { PagesActionType } from '../store/types'

interface IUseEditPageModuleOrderResult {
	onEditPageModuleOrder: (data: number[]) => any
	isLoading: boolean
}

export const useEditPageModuleOrder = (pageId: number): IUseEditPageModuleOrderResult => {
	const dispatch = useDispatch()

	const onEditPageModuleOrder = useCallback(
		(data: number[]) => dispatch(editPageModuleOrderRequest(pageId, { moduleOrder: data })),
		[dispatch, pageId]
	)

	const loaders = useSelector(getLoadersState)
	const isEditPageModuleOrder = isLoading(loaders, [PagesActionType.EditPageRequest], {
		default: false
	})

	return {
		onEditPageModuleOrder,
		isLoading: isEditPageModuleOrder
	}
}
