import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Modal } from 'antd'
import * as React from 'react'
import { useState } from 'react'
import { IMoney, IStaffMember } from 'brainstorming-types'
import { MoneyInput } from './MoneyInput'

interface IEditPricePerWordModalProps {
	isVisible: boolean
	onEdit: (newPrice: IMoney) => unknown
	onCancel: () => unknown
	isLoading: boolean
	staffMember: IStaffMember
}

export const EditPricePerWordModal: React.FC<IEditPricePerWordModalProps> = ({
	staffMember,
	isVisible,
	onEdit,
	onCancel,
	isLoading
}) => {
	const { pricePerWord } = staffMember

	const [newPricePerWord, setNewPricePerWord] = useState<IMoney>(pricePerWord)

	const handleChange = (val: IMoney) => setNewPricePerWord(val)

	const onOk = () => onEdit(newPricePerWord)

	return (
		<Modal
			title="Edit price per word"
			visible={isVisible}
			onCancel={onCancel}
			okText="Confirm"
			confirmLoading={isLoading}
			onOk={onOk}
		>
			<Form>
				<Form.Item label="Price per word:">
					<MoneyInput
						onChange={handleChange}
						value={newPricePerWord}
						placeholder={'Price per word'}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}
