import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
	fetchRegularTags,
	fetchGroupTags,
	addTagsToGroup,
	removeTagsFromGroup,
	updateTagSynonyms,
	getTagSynonyms,
	getTagGroups,
	getGroupMembers,
	updateTag,
	updateTagGroups,
	fetchSingleTag,
	deleteTag,
	createTag,
	getTagSynonymsBatch,
	getTagGroupsBatch,
	getGroupMembersBatch
} from './api'
import { TagsActionTypes } from './action.types'
import { AnyAction } from 'redux'
import {
	fetchRegularTagsSuccess,
	fetchRegularTagsFailure,
	fetchGroupTagsSuccess,
	fetchGroupTagsFailure,
	fetchSynonymsFailure,
	fetchSynonymsSuccess,
	updateTagFailure,
	updateTagSuccess,
	addTagsToGroupSuccess,
	addTagsToGroupFailure,
	removeTagsFromGroupSuccess,
	removeTagsFromGroupFailure,
	fetchGroupMembersFailure,
	fetchGroupMembersSuccess,
	fetchGroupsFailure,
	fetchGroupsSuccess,
	updateSynonymsSuccess,
	updateSynonymsFailure,
	updateGroupsSuccess,
	updateGroupsFailure,
	fetchSingleTagSuccess,
	fetchSingleTagFailure,
	deleteTagSuccess,
	deleteTagFailure,
	createTagSuccess,
	createTagFailure,
	fetchSynonymsBatchSuccess,
	fetchGroupsBatchSuccess,
	fetchGroupMembersBatchSuccess,
	fetchGroupMembersBatchFailure,
	fetchSynonymsBatchFailure,
	fetchGroupsBatchFailure
} from './actions'
import { notification } from 'antd'

const SUCCESS_MSG = 'Saved'
const ERROR_MSG = 'Something went wrong, please try again'

const showSuccessMsg = (msg: string = SUCCESS_MSG) => {
	notification.success({
		message: msg
	})
}

const showErrorMsg = (msg: string = ERROR_MSG) => {
	notification.error({
		message: msg
	})
}

// Handlers
function* handleFetchRegularTags(action: AnyAction) {
	const { response, error } = yield call(fetchRegularTags, action.payload)
	if (response) {
		yield put(fetchRegularTagsSuccess(response))
	} else {
		yield put(fetchRegularTagsFailure(error))
	}
}

function* handleFetchGroupTags(action: AnyAction) {
	const { response, error } = yield call(fetchGroupTags, action.payload)
	if (response) {
		yield put(fetchGroupTagsSuccess(response))
	} else {
		yield put(fetchGroupTagsFailure(error))
	}
}

function* handleCreateTag(action: AnyAction) {
	const { response, error } = yield call(createTag, action.payload)
	if (response) {
		yield put(createTagSuccess(response))
		showSuccessMsg()
	} else {
		yield put(createTagFailure(error))
		showErrorMsg()
	}
}

function* handleUpdateTag(action: AnyAction) {
	const { response, error } = yield call(updateTag, action.payload)
	if (response) {
		yield put(updateTagSuccess(response))
		showSuccessMsg()
	} else {
		yield put(updateTagFailure(error))
		showErrorMsg()
	}
}

function* handleFetchSynonyms(action: AnyAction) {
	const { tagId } = action.payload
	const { response, error } = yield call(getTagSynonyms, tagId)
	if (response) {
		yield put(
			fetchSynonymsSuccess({
				tagId,
				synonyms: response
			})
		)
	} else {
		yield put(fetchSynonymsFailure(error))
	}
}

function* handleFetchSynonymsBatch(action: AnyAction) {
	const { tagIds } = action.payload
	const { response, error } = yield call(getTagSynonymsBatch, { tagIds })
	if (response) {
		yield put(fetchSynonymsBatchSuccess(response))
	} else {
		yield put(fetchSynonymsBatchFailure(error))
	}
}

function* handleUpdateSynonyms(action: AnyAction) {
	const { tagId, synonymIds } = action.payload
	const { response, error } = yield call(updateTagSynonyms, tagId, {
		synonymIds
	})
	if (response) {
		yield put(
			updateSynonymsSuccess({
				tagId: action.payload.tagId,
				synonyms: response
			})
		)
		showSuccessMsg()
	} else {
		yield put(updateSynonymsFailure(error))
		showErrorMsg()
	}
}

function* handleAddTagsToGroup(action: AnyAction) {
	const { groupId, tagIds } = action.payload
	const { response, error } = yield call(addTagsToGroup, groupId, {
		tagIds
	})
	if (response) {
		yield put(
			addTagsToGroupSuccess({
				tagId: action.payload.groupId,
				groupMembers: response
			})
		)
		showSuccessMsg()
	} else {
		yield put(addTagsToGroupFailure(error))
		showErrorMsg()
	}
}

function* handleRemoveTagsFromGroup(action: AnyAction) {
	const { groupId, tagIds } = action.payload
	const { response, error } = yield call(removeTagsFromGroup, groupId, {
		tagIds
	})
	if (response) {
		yield put(
			removeTagsFromGroupSuccess({
				tagId: action.payload.groupId,
				groupMembers: response
			})
		)
		showSuccessMsg()
	} else {
		yield put(removeTagsFromGroupFailure(error))
		showErrorMsg()
	}
}

function* handleFetchGroupMembers(action: AnyAction) {
	const { groupId } = action.payload
	const { response, error } = yield call(getGroupMembers, groupId)
	if (response) {
		yield put(
			fetchGroupMembersSuccess({
				groupId,
				groupMembers: response
			})
		)
	} else {
		yield put(fetchGroupMembersFailure(error))
	}
}

function* handleFetchGroupMembersBatch(action: AnyAction) {
	const { tagIds } = action.payload
	const { response, error } = yield call(getGroupMembersBatch, { tagIds })
	if (response) {
		yield put(fetchGroupMembersBatchSuccess(response))
	} else {
		yield put(fetchGroupMembersBatchFailure(error))
	}
}

function* handleFetchGroups(action: AnyAction) {
	const { tagId } = action.payload
	const { response, error } = yield call(getTagGroups, tagId)
	if (response) {
		yield put(
			fetchGroupsSuccess({
				tagId,
				tagGroups: response
			})
		)
	} else {
		yield put(fetchGroupsFailure(error))
	}
}

function* handleFetchGroupsBatch(action: AnyAction) {
	const { tagIds } = action.payload
	const { response, error } = yield call(getTagGroupsBatch, { tagIds })
	if (response) {
		yield put(fetchGroupsBatchSuccess(response))
	} else {
		yield put(fetchGroupsBatchFailure(error))
	}
}

function* handleUpdateGroups(action: AnyAction) {
	const { tagId, groupIds } = action.payload
	const { response, error } = yield call(updateTagGroups, tagId, {
		groupIds
	})
	if (response) {
		yield put(
			updateGroupsSuccess({
				tagId: action.payload.tagId,
				tagGroups: response
			})
		)
		showSuccessMsg()
	} else {
		yield put(updateGroupsFailure(error))
		showErrorMsg()
	}
}

function* handleFetchSingleTag(action: AnyAction) {
	const { response, error } = yield call(fetchSingleTag, action.payload.tagId)
	if (response) {
		yield put(
			fetchSingleTagSuccess({
				tag: response
			})
		)
	} else {
		yield put(fetchSingleTagFailure(error))
	}
}

function* handleDeleteTag(action: AnyAction) {
	const tagId = action.payload.tagId
	const { response, error } = yield call(deleteTag, tagId)
	if (response) {
		yield put(
			deleteTagSuccess({
				tagId,
				tag: response
			})
		)
	} else {
		yield put(deleteTagFailure(error))
	}
}

// Watchers
function* watchFetchRegularTags() {
	yield takeEvery(TagsActionTypes.FetchRegularTagsRequest, handleFetchRegularTags)
}
function* watchFetchGroupTags() {
	yield takeEvery(TagsActionTypes.FetchGroupTagsRequest, handleFetchGroupTags)
}
function* watchCreateTag() {
	yield takeEvery(TagsActionTypes.CreateTagRequest, handleCreateTag)
}
function* watchUpdateTag() {
	yield takeEvery(TagsActionTypes.UpdateTagRequest, handleUpdateTag)
}
function* watchFetchSynonyms() {
	yield takeEvery(TagsActionTypes.FetchSynonymsRequest, handleFetchSynonyms)
}
function* watchFetchSynonymsBatch() {
	yield takeEvery(TagsActionTypes.FetchSynonymsBatchRequest, handleFetchSynonymsBatch)
}
function* watchUpdateSynonyms() {
	yield takeEvery(TagsActionTypes.UpdateSynonymsRequest, handleUpdateSynonyms)
}
function* watchAddTagsToGroup() {
	yield takeEvery(TagsActionTypes.AddTagsToGroupRequest, handleAddTagsToGroup)
}
function* watchRemoveTagsFromGroup() {
	yield takeEvery(TagsActionTypes.RemoveTagsFromGroupRequest, handleRemoveTagsFromGroup)
}
function* watchFetchGroupMembers() {
	yield takeEvery(TagsActionTypes.FetchGroupMembersRequest, handleFetchGroupMembers)
}
function* watchFetchGroupMembersBatch() {
	yield takeEvery(TagsActionTypes.FetchGroupMembersBatchRequest, handleFetchGroupMembersBatch)
}
function* watchFetchGroups() {
	yield takeEvery(TagsActionTypes.FetchGroupsRequest, handleFetchGroups)
}
function* watchFetchGroupsBatch() {
	yield takeEvery(TagsActionTypes.FetchGroupsBatchRequest, handleFetchGroupsBatch)
}
function* watchUpdateGroups() {
	yield takeEvery(TagsActionTypes.UpdateGroupsRequest, handleUpdateGroups)
}
function* watchFetchSingleTag() {
	yield takeEvery(TagsActionTypes.FetchSingleTagRequest, handleFetchSingleTag)
}
function* watchDeleteTag() {
	yield takeEvery(TagsActionTypes.DeleteTagRequest, handleDeleteTag)
}

export default function* rootSaga() {
	yield all([
		watchFetchRegularTags(),
		watchFetchGroupTags(),
		watchCreateTag(),
		watchUpdateTag(),
		watchFetchSynonyms(),
		watchFetchSynonymsBatch(),
		watchUpdateSynonyms(),
		watchAddTagsToGroup(),
		watchRemoveTagsFromGroup(),
		watchFetchGroupMembers(),
		watchFetchGroupMembersBatch(),
		watchFetchGroups(),
		watchFetchGroupsBatch(),
		watchUpdateGroups(),
		watchFetchSingleTag(),
		watchDeleteTag()
	])
}
