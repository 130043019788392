import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { restoreSessionRequest } from '../store/actions'
import { isLoading, getLoadersState } from '../../../store/common/loaders/selector'
import { SessionsActionType } from '../store/types'
import usePrevious from '../../../hooks/usePrevious'

const useRestoreSession = () => {
	const [restoreSessionId, setRestoreSessionId] = React.useState<number | null>(null)
	const dispatch = useDispatch()

	const loaders = useSelector(getLoadersState)
	const isRestoringSession = isLoading(loaders, [SessionsActionType.RestoreSessionRequest], {
		default: false
	})
	const prevIsRestoringSession = usePrevious(isRestoringSession)

	React.useEffect(() => {
		if (prevIsRestoringSession && !isRestoringSession) {
			setRestoreSessionId(null)
		}
	}, [prevIsRestoringSession, isRestoringSession])

	const handleRestoreSession = (restoreSessionId: number) => {
		setRestoreSessionId(restoreSessionId)
	}

	const handleConfirmRestoreSession = () => {
		dispatch(restoreSessionRequest(restoreSessionId as number))
	}

	const handleCancelRestoreSession = () => {
		setRestoreSessionId(null)
	}

	return {
		restoreSessionId,
		isRestoringSession,
		handleRestoreSession,
		handleConfirmRestoreSession,
		handleCancelRestoreSession
	}
}

export default useRestoreSession
