import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoading, getLoadersState } from '../../../store/common/loaders/selector'
import usePrevious from '../../../hooks/usePrevious'
import { ISolution } from 'brainstorming-types'
import { SolutionsActionType } from '../store/types'
import { editSolutionRequest } from '../store/actions'

const useEditSolution = () => {
	const [solution, setSolution] = React.useState<ISolution | null>(null)
	const dispatch = useDispatch()

	const loaders = useSelector(getLoadersState)
	const isEditingSolution = isLoading(loaders, [SolutionsActionType.EditSolutionRequest], {
		default: false
	})
	const prevIsEditingSolution = usePrevious(isEditingSolution)

	React.useEffect(() => {
		if (prevIsEditingSolution && !isEditingSolution) {
			setSolution(null)
		}
	}, [isEditingSolution, prevIsEditingSolution])

	const handleOpenEdit = (solution: ISolution) => {
		setSolution(solution)
	}

	const handleConfirmEditSolution = (data: any) => {
		dispatch(editSolutionRequest(solution!.id, data))
	}

	const handleCancelEditSolution = () => {
		setSolution(null)
	}

	return {
		solution,
		isEditingSolution,
		handleOpenEdit,
		handleConfirmEditSolution,
		handleCancelEditSolution
	}
}

export default useEditSolution
