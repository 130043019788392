import { IComment } from 'brainstorming-types'

export enum CommentsActionType {
	FetchCommentsRequest = '@comments/FETCH_COMMENTS_REQUEST',
	FetchCommentsSuccess = '@comments/FETCH_COMMENTS_SUCCESS',
	FetchCommentsFailure = '@comments/FETCH_COMMENTS_FAILURE',
	DeleteCommentRequest = '@comments/DELETE_COMMENT_REQUEST',
	DeleteCommentSuccess = '@comments/DELETE_COMMENT_SUCCESS',
	DeleteCommentFailure = '@comments/DELETE_COMMENT_FAILURE',
	EditCommentRequest = '@comments/EDIT_COMMENT_REQUEST',
	EditCommentSuccess = '@comments/EDIT_COMMENT_SUCCESS',
	EditCommentFailure = '@comments/EDIT_COMMENT_FAILURE',
	RestoreCommentRequest = '@comments/RESTORE_COMMENT_REQUEST',
	RestoreCommentSuccess = '@comments/RESTORE_COMMENT_SUCCESS',
	RestoreCommentFailure = '@comments/RESTORE_COMMENT_FAILURE'
}

export interface ICommentsById {
	[id: number]: IComment
}

export interface IComments {
	byId: ICommentsById
	ids: number[]
	total: number
	skip: number
}

export interface IEditComment {
	content: string
}
