import * as React from 'react'
import { IPage } from 'brainstorming-types'
import styled from 'styled-components'

import { BooleanField } from './fields/BooleanField'
import { useEditPage } from '../../hooks'
import { SPACES } from '../../../../styles/spaces'

const StyledDiv = styled.div`
	display: inline-flex;
	margin-top: ${SPACES.EXTRA_SMALL};

	.ant-form-item {
		margin-bottom: 0;
	}
`

const StyledSpan = styled.span`
	margin: auto;
	margin-left: ${SPACES.EXTRA_SMALL};
`

interface IProps {
	page: IPage
}

export const TogglePageLayoutRepeat: React.FC<IProps> = props => {
	const { page } = props

	const [repeatLayout, setRepeatLayout] = React.useState(page.repeatLayout)

	const { onEditPage } = useEditPage(page.id)

	const handleChange = (newRepeat: boolean) => {
		setRepeatLayout(newRepeat)

		onEditPage({
			repeatLayout: newRepeat
		})
	}

	return (
		<StyledDiv>
			<BooleanField name="repeatLayout" label="" value={repeatLayout} handleChange={handleChange} />
			<StyledSpan>Repeat this layout sequence</StyledSpan>
		</StyledDiv>
	)
}
