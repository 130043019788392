import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchSessionsRequest } from '../store/actions'
import { getSessions } from '../store/selectors'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { SessionsActionType } from '../store/types'
import { SESSIONS_PER_PAGE } from '../containers/SessionDashboardContainer'
import { mapTableToQuery } from '../../../utils/sorting'
import { SortOrder } from 'antd/lib/table/interface'

interface IUseSessions {
	page?: number | null
	column?: string | null
	direction?: string | null
}

const useSessions = ({ page = 1, column, direction }: IUseSessions) => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(
			fetchSessionsRequest(
				mapTableToQuery(page as number, SESSIONS_PER_PAGE, {
					column,
					order: direction as SortOrder
				})
			)
		)
	}, [page, column, direction, dispatch])

	const sessionsState = useSelector(getSessions)
	const loaders = useSelector(getLoadersState)
	const isLoadingSessions = isLoading(loaders, [SessionsActionType.FetchSessionsRequest])
	const sessions = sessionsState.ids.map(id => sessionsState.byId[id])

	return { sessions, isLoading: isLoadingSessions, sessionsState }
}

export default useSessions
