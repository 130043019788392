import { PageHeader, Divider } from 'antd'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import Page from '../../../components/layout/Page'
import { Routes } from '../../../config/routes'
import { RegularTagGroups } from '../components/dashboard/regular/RegularTagGroups'
import { RegularTagSynonyms } from '../components/dashboard/regular/RegularTagSynonyms'
import { TagDetails } from '../components/dashboard/shared/TagDetails'
import {
	useDeleteTag,
	useGroupTags,
	useRegularTags,
	useSynonyms,
	useTagGroups,
	useUpdateGroups,
	useUpdateSynonyms,
	useUpdateTag
} from '../hooks'
import { useSingleTag } from '../hooks/useSingleTag'

interface ISingleTagParams {
	id: string
}

export const SingleRegularTagContainer: React.FC<{}> = props => {
	const params = useParams<ISingleTagParams>()
	const tagId = parseInt(params.id as string, 10)

	const { tag, isLoading: isTagLoading } = useSingleTag(tagId)

	const { regularTags, isLoading: isRegularTagsLoading } = useRegularTags()
	const { groupTags } = useGroupTags()

	// update tag
	const { onUpdateTag, isLoading: isUpdateTagLoading } = useUpdateTag()
	const { onDeleteTag, isLoading: isDeleteTagLoading } = useDeleteTag()

	const { synonyms, isLoading: isSynonymsLoading } = useSynonyms(tagId)

	const { tagGroups, isLoading: isTagGroupsLoading } = useTagGroups(tagId)

	const { onUpdateSynonyms, isLoading: isUpdateSynonymsLoading } = useUpdateSynonyms()

	const { onUpdateGroups, isLoading: isUpdateGroupsLoading } = useUpdateGroups()

	return (
		<Page
			isLoading={isTagLoading}
			loadingText={'Loading Regular tag details...'}
			breadcrumbItems={[
				{ name: 'Regular tags', path: Routes.RegularTags.route },
				{
					name: tag?.name ? tag?.name : '',
					path: Routes.RegularTags.singleTagRoute.replace(':id', params.id)
				}
			]}
		>
			{tag && (
				<>
					<PageHeader title={tag?.name} />
					<TagDetails
						tag={tag}
						onUpdateTag={onUpdateTag}
						isUpdateTagLoading={isUpdateTagLoading}
						onDeleteTag={onDeleteTag}
						isDeleteTagLoading={isDeleteTagLoading}
					/>
					<Divider />
					<PageHeader title="Groups" />
					<RegularTagGroups
						tag={tag}
						groupTags={groupTags}
						tagGroups={tagGroups}
						isTagGroupsLoading={isTagGroupsLoading}
						onUpdateGroups={onUpdateGroups}
						isUpdateGroupsLoading={isUpdateGroupsLoading}
					/>
					<Divider />
					<PageHeader title="Synonyms" />
					<RegularTagSynonyms
						tag={tag}
						regularTags={regularTags}
						isRegularTagsLoading={isRegularTagsLoading}
						synonyms={synonyms}
						isSynonymsLoading={isSynonymsLoading}
						onUpdateSynonyms={onUpdateSynonyms}
						isUpdateSynonymsLoading={isUpdateSynonymsLoading}
					/>
				</>
			)}
			<></>
		</Page>
	)
}
