import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
	UsersActionTypes,
	IFetchUserRequest,
	IFetchUserActionsRequest,
	IUpdateUserActionRequest
} from './actions.types'
import { fetchUsers, fetchUsersAction, updateUserRankSystem } from './api'
import {
	fetchUsersSuccess,
	fetchUsersFailure,
	fetchUserActionsSuccess,
	fetchUserActionsFailure,
	updateUsersActionSuccess,
	updateUsersActionFailure
} from './actions'
import { notification } from 'antd'

function* handleFetchUsers(action: IFetchUserRequest) {
	const { response, error } = yield call(fetchUsers, action.payload)
	if (response) {
		yield put(fetchUsersSuccess(response))
	} else {
		yield put(fetchUsersFailure(error))
	}
}

function* handleFetchUsersAction(action: IFetchUserActionsRequest) {
	const { response, error } = yield call(fetchUsersAction, action.payload)
	if (response) {
		yield put(fetchUserActionsSuccess(response))
	} else {
		yield put(fetchUserActionsFailure(error))
	}
}

function* handleUpdateUsersAction(action: IUpdateUserActionRequest) {
	const { response, error } = yield call(
		updateUserRankSystem,
		action.payload.id,
		action.payload.points
	)
	if (response) {
		yield put(updateUsersActionSuccess(response))
		notification.success({ message: `User action "${response.name}" successfully updated!` })
	} else {
		yield put(updateUsersActionFailure(error))
		notification.error({ message: 'Error happened. User action is not updated!' })
	}
}

function* watchFetchUsers() {
	yield takeEvery(UsersActionTypes.FetchUserRequest, handleFetchUsers)
}

function* watchFetchUserActions() {
	yield takeEvery(UsersActionTypes.FetchUserActionsRequest, handleFetchUsersAction)
}

function* watchUpdateUsersAction() {
	yield takeEvery(UsersActionTypes.UpdateUserActionRequest, handleUpdateUsersAction)
}

export default function* rootSaga() {
	yield all([watchFetchUsers(), watchFetchUserActions(), watchUpdateUsersAction()])
}
