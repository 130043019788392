import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSolutionRequest } from '../store/actions'
import { isLoading, getLoadersState } from '../../../store/common/loaders/selector'
import { SolutionsActionType } from '../store/types'
import usePrevious from '../../../hooks/usePrevious'

const useDeleteSolution = () => {
	const [solutionId, setSolutionId] = React.useState<number | null>(null)
	const dispatch = useDispatch()

	const loaders = useSelector(getLoadersState)
	const isDeletingSolution = isLoading(loaders, [SolutionsActionType.DeleteSolutionRequest], {
		default: false
	})
	const prevIsDeleingSolution = usePrevious(isDeletingSolution)

	React.useEffect(() => {
		if (prevIsDeleingSolution && !isDeletingSolution) {
			setSolutionId(null)
		}
	}, [prevIsDeleingSolution, isDeletingSolution])

	const handleDeleteSolution = (solutionId: number) => {
		setSolutionId(solutionId)
	}

	const handleConfirmDeleteSolution = () => {
		dispatch(deleteSolutionRequest(solutionId as number))
	}

	const handleCancelDeleteSolution = () => {
		setSolutionId(null)
	}

	return {
		solutionId,
		isDeletingSolution,
		handleDeleteSolution,
		handleConfirmDeleteSolution,
		handleCancelDeleteSolution
	}
}

export default useDeleteSolution
