import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { IStaffMember } from 'brainstorming-types'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { fetchStaffRequest } from '../store/actions'
import { getActiveStaff, getInactiveStaff } from '../store/selectors'
import { StaffActionType } from '../store/types'

interface IUseStaffResult {
	activeStaff: IStaffMember[]
	inactiveStaff: IStaffMember[]
	isLoading: boolean
}

export const useStaff = (): IUseStaffResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchStaffRequest())
	}, [dispatch])

	const activeStaff = useSelector(getActiveStaff)
	const inactiveStaff = useSelector(getInactiveStaff)
	const loaders = useSelector(getLoadersState)
	const isLoadingStaff = isLoading(loaders, [StaffActionType.FetchStaffRequest])

	return {
		activeStaff,
		inactiveStaff,
		isLoading: isLoadingStaff
	}
}
