import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoading, getLoadersState } from '../../../store/common/loaders/selector'
import { CommentsActionType, IEditComment } from '../store/types'
import usePrevious from '../../../hooks/usePrevious'
import { IComment } from 'brainstorming-types'
import { editCommentRequest } from '../store/actions'

const useEditComment = () => {
	const [comment, setComment] = React.useState<IComment | null>(null)
	const dispatch = useDispatch()

	const loaders = useSelector(getLoadersState)
	const isEditingComment = isLoading(loaders, [CommentsActionType.EditCommentRequest], {
		default: false
	})
	const prevIsEditingComment = usePrevious(isEditingComment)

	React.useEffect(() => {
		if (prevIsEditingComment && !isEditingComment) {
			setComment(null)
		}
	}, [isEditingComment, prevIsEditingComment])

	const handleOpenEdit = (comment: IComment) => {
		setComment(comment)
	}

	const handleConfirmEditComment = (data: IEditComment) => {
		dispatch(editCommentRequest(comment!.id, data))
	}

	const handleCancelEditComment = () => {
		setComment(null)
	}

	return {
		comment,
		isEditingComment,
		handleOpenEdit,
		handleConfirmEditComment,
		handleCancelEditComment
	}
}

export default useEditComment
