import * as React from 'react'
import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ProtectedRoute from '../../../components/routes/ProtectedRoute'
import { getToken } from '../../auth/store/selectors'
import { Routes as RouteConfig } from '../../../config/routes'
import { StaffDashboardContainer } from '../containers/StaffDashboardContainer'
import { StaffMemberDetailsPageContainer } from '../containers/StaffMemberDetailsPageContainer'

export const StaffAccountingRoutes = () => {
	const token = useSelector(getToken)
	return (
		<Switch>
			<ProtectedRoute
				path={RouteConfig.StaffAccounting.route}
				exact={true}
				component={StaffDashboardContainer}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.StaffAccounting.singleUserRoute}
				exact={true}
				component={StaffMemberDetailsPageContainer}
				token={token}
			/>
		</Switch>
	)
}
