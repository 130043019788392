export const replaceIdsIfExist = (
	existingIds: number[],
	newIds: number[],
	atStart: boolean = false
) => {
	const filteredNewIds = newIds.reduce<number[]>((acc: number[], currId: number) => {
		if (!existingIds.find(id => currId === id)) {
			acc.push(currId)
		}
		return acc
	}, [])

	return atStart ? [...filteredNewIds, ...existingIds] : [...existingIds, ...filteredNewIds]
}
