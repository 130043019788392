import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Layout as AntLayout, Spin, Alert } from 'antd'

import Sidebar from './Sidebar'
import Header from './Header'
import { isSidebarOpen } from '../../store/common/ui/selectors'
import { getToken, getUser } from '../../features/auth/store/selectors'
import { getLoadersState, isLoading } from '../../store/common/loaders/selector'
import { AuthActionType } from '../../features/auth/store/actions.types'
import { getErrorsState, getErrors } from '../../store/common/errors/selector'
import { SPACES } from '../../styles/spaces'

interface IProps {
	children: React.ReactElement
}

const Layout: React.FC<IProps> = ({ children }) => {
	const token = useSelector(getToken)
	const sidebarOpen = useSelector(isSidebarOpen)
	const loaders = useSelector(getLoadersState)
	const errorsState = useSelector(getErrorsState)
	const user = useSelector(getUser)

	const actions = [AuthActionType.GetProfileRequest, AuthActionType.LoginRequest]

	const isLoadingProfile = isLoading(loaders, actions)
	const errors = getErrors(errorsState, actions)

	if (!token) {
		return <div>{children}</div>
	}

	if (isLoadingProfile || !user) {
		return (
			<Spin
				size="large"
				tip="Loading..."
				style={{ position: 'absolute', left: '50%', top: '200px' }}
			/>
		)
	}

	if (errors.length) {
		return (
			<Alert message="Ups, something went wrong." type="error" style={{ margin: SPACES.DEFAULT }} />
		)
	}

	return (
		<AntLayout>
			<Header />
			<AntLayout>
				<Sidebar />
				<AntLayout>
					<AntLayout.Content>
						<CustomLayout sidebarOpen={sidebarOpen}>{children}</CustomLayout>
					</AntLayout.Content>
				</AntLayout>
			</AntLayout>
		</AntLayout>
	)
}

const CustomLayout = styled(({ sidebarOpen, ...rest }) => <AntLayout {...rest} />)`
	&& {
		margin-left: ${(props: ISidebarOpen) => adoptMarginToTheSidebar(props.sidebarOpen)};
		transition: margin 0.2s ease-out;
		margin-top: 64px;
	}
`

export interface ISidebarOpen {
	sidebarOpen: boolean
}

export const adoptMarginToTheSidebar = (sidebarOpen: boolean) => (sidebarOpen ? '200px' : '80px')

export default Layout
