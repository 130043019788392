import * as React from 'react'
import styled from 'styled-components'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Table, Input } from 'antd'
import { Mode } from '../../constants'
import { IGroup, IPermission, GroupType, IUser } from 'brainstorming-types'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { SPACES } from '../../../../styles/spaces'
import AddPermissionFormModal from '../common/AddPermissionModal'
import HeaderButtons from '../common/HeaderButtons'
import usePrevious from '../../../../hooks/usePrevious'
import AddUserModal from './AddUserModal'

interface ILocalProps {
	mode: Mode
	active: IGroup
	permissions: IPermission[]
	users: IUser[]
	onDelete(): void
	onEdit(): void
	onCancel(): void
	onSave(data: IFormInternal): void
}

interface IFormProps extends FormComponentProps<IFormInternal> {}

interface IProps extends ILocalProps, IFormProps {}

export interface IBlackWhiteListForm extends IFormInternal {
	type?: GroupType
}

export interface IFormInternal {
	users: number[]
	permissions: number[]
	name: string
}

const columns = [
	{
		title: 'Permission',
		dataIndex: 'name'
	},
	{
		title: 'Description',
		dataIndex: 'description'
	}
]

const userColumns = [
	{
		title: 'Name',
		dataIndex: 'name'
	},
	{
		title: 'Email',
		dataIndex: 'email'
	}
]

const WBPermissionTable = styled(Table)`
	margin-bottom: 10px;
	thead {
		tr {
			th {
				background: white;
			}
		}
	}
	tbody {
		tr {
			td {
				background: white;
			}
		}
	}
`

const WBUserTable = styled(Table)`
	margin-top: 35px;
	margin-bottom: 10px;
	thead {
		tr {
			th {
				background: white;
			}
		}
	}
	tbody {
		tr {
			td {
				background: white;
			}
		}
	}
`

const GroupNameInput = styled(Form.Item)`
	width: 70%;
	margin: 0;
	margin-bottom: ${SPACES.SMALL};
	p {
		margin: 0;
		padding: 0;
	}
`

const WhitelistBlacklistForm: React.FC<IProps> = ({
	mode,
	active,
	permissions,
	form,
	onDelete,
	onEdit,
	onCancel,
	onSave,
	users
}) => {
	const [permissionList, setPermissionList] = React.useState(active.permissions)
	const { getFieldDecorator } = form
	const [userList, setUserList] = React.useState(active.users)
	const prevActive = usePrevious(active)

	const isEditMode = mode === Mode.Edit

	React.useEffect(() => {
		if (prevActive !== active) {
			form.resetFields(['name', 'permissions', 'users'])
			setPermissionList(active.permissions)
			setUserList(active.users)
		}
	}, [active, form, prevActive])

	const handlePermissionSelectChange = (selected: any) => {
		form.setFieldsValue({ permissions: selected })
	}

	const handleUserSelectChange = (selected: any) => {
		form.setFieldsValue({ users: selected })
	}

	const handleAddPermissions = (checkedPermissions: number[]) => {
		const newPermissionIds = checkedPermissions.filter(
			permission => !permissionList!.find(p => p.id === permission)
		)
		const newPermissions = permissions.filter(permission =>
			newPermissionIds.includes(permission.id)
		)
		setPermissionList([...permissionList!, ...newPermissions])
		form.setFieldsValue({
			permissions: checkedPermissions
		})
	}

	const handleAddUsers = (checkedUsers: IUser[]) => {
		const newUsers = checkedUsers.filter(user => !userList!.find(u => u.id === user.id))
		setUserList([...userList!, ...newUsers])
		form.setFieldsValue({
			users: checkedUsers.map(u => u.id)
		})
	}

	const handleOnSave = () => {
		form.validateFields()
		form.validateFields(['name', 'permissions', 'users'], (errors, values) => {
			if (!errors) {
				onSave(values)
			}
		})
	}

	const handleOnCancel = () => {
		form.resetFields(['name', 'permissions', 'users'])
		setPermissionList(active.permissions)
		setUserList(active.users)
		onCancel()
	}

	return (
		<Form onSubmit={handleOnSave}>
			<HeaderButtons
				mode={mode}
				active={active}
				onCancel={handleOnCancel}
				onSave={handleOnSave}
				onDelete={onDelete}
				onEdit={onEdit}
			/>
			<div>
				<GroupNameInput>
					{getFieldDecorator('name', {
						initialValue: active.name,
						rules: [{ required: true, message: 'Name is required!' }]
					})(isEditMode ? <Input placeholder="Group name" /> : <p>{active.name}</p>)}
				</GroupNameInput>
				{getFieldDecorator('permissions', {
					initialValue: active.permissions!.map(permission => permission.id),
					trigger: 'none'
				})(
					<WBPermissionTable
						rowSelection={
							isEditMode
								? {
										selectedRowKeys: form.getFieldValue('permissions'),
										onChange: handlePermissionSelectChange
								  }
								: undefined
						}
						columns={columns}
						dataSource={permissionList!.map((permission: IPermission) => ({
							...permission,
							key: permission.id
						}))}
						pagination={{ hideOnSinglePage: true }}
						locale={{ emptyText: 'No permissions added' }}
					/>
				)}

				{isEditMode && (
					<AddPermissionFormModal
						allPermissions={permissions}
						activePermissions={form.getFieldValue('permissions')}
						onAddPermission={handleAddPermissions}
					/>
				)}

				{getFieldDecorator('users', {
					initialValue: active.users ? active.users.map(u => u.id) : [],
					trigger: 'none'
				})(
					<WBUserTable
						rowSelection={
							isEditMode
								? {
										selectedRowKeys: form.getFieldValue('users'),
										onChange: handleUserSelectChange
								  }
								: undefined
						}
						columns={userColumns}
						dataSource={
							userList
								? userList!.map((user: IUser) => ({
										...user,
										name: `${user.fullName}`,
										key: user.id
								  }))
								: []
						}
						pagination={{ hideOnSinglePage: true }}
						locale={{ emptyText: 'No users added' }}
					/>
				)}
				{isEditMode && (
					<AddUserModal
						allUsers={users}
						activeUsers={form.getFieldValue('users')}
						onAddUser={handleAddUsers}
					/>
				)}
			</div>
		</Form>
	)
}

export default Form.create<IProps>({
	name: 'whitelistBlacklist'
})(WhitelistBlacklistForm)
