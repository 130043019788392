import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { fetchPageRequest } from '../store/actions'
import { getPage } from '../store/selectors'
import { PagesActionType } from '../store/types'
import { IPage } from 'brainstorming-types'

interface IUsePageResult {
	page: IPage
	isLoading: boolean
}

export const usePage = (pageId: number): IUsePageResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchPageRequest(pageId))
	}, [dispatch, pageId])

	const page = useSelector(getPage(pageId))
	const loaders = useSelector(getLoadersState)
	const isLoadingPage = isLoading(loaders, [PagesActionType.FetchPageRequest])

	return {
		page,
		isLoading: isLoadingPage
	}
}
