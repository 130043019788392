import Cookies from 'js-cookie'
import { IStorage } from './storage.interface'

class Cookie<T> implements IStorage<T> {
	get(key: string): T | null {
		const value = Cookies.get(key)
		if (value) {
			return JSON.parse(value)
		}

		return null
	}

	set(key: string, value: any, options?: any): void {
		Cookies.set(key, JSON.stringify(value), options)
	}

	remove(key: string): boolean {
		if (this.get(key)) {
			Cookies.remove(key)
			return true
		}
		return false
	}
}

export default Cookie
