import * as React from 'react'
import useComments from '../hooks/useComments'
import { Routes } from '../../../config/routes'
import Page from '../../../components/layout/Page'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { ISortUrl } from '../../../utils/sorting'
import { SortOrder } from 'antd/lib/table/interface'
import CommentsTable from '../components/dashboard/CommentsTable'
import useDeleteComment from '../hooks/useDeleteComment'
import useRestoreComments from '../hooks/useRestoreComment'
import { Modal, Button } from 'antd'
import useEditComment from '../hooks/useEditComment'
import EditComment from '../components/dashboard/EditComment'
import useHistoryLog from '../../../hooks/useHistoryLog'
import HistoryLogModal from '../../../components/layout/HistoryLogModal'

export const COMMENTS_PER_PAGE = 10

const CommentsDashboardContainer = () => {
	const [page, setPage] = useQueryParam('page', NumberParam)
	const [direction, setDirection] = useQueryParam('direction', StringParam)
	const [column, setColumn] = useQueryParam('column', StringParam)

	const { comments, isLoading, commentsState } = useComments({
		page,
		direction,
		column
	})

	const {
		commentId,
		handleCancelDeleteComment,
		handleConfirmDeleteComment,
		handleDeleteComment,
		isDeletingComment
	} = useDeleteComment()

	const {
		restoreCommentId,
		handleCancelRestoreComment,
		handleConfirmRestoreComment,
		handleRestoreComment,
		isRestoringComment
	} = useRestoreComments()

	const {
		comment,
		isEditingComment,
		handleOpenEdit,
		handleConfirmEditComment,
		handleCancelEditComment
	} = useEditComment()

	const {
		historyLog,
		handleOpenHistoryLog,
		handleCloseHistoryLog,
		historyLogModalState
	} = useHistoryLog()

	const handleFetchComments = (page: number, sort: ISortUrl) => {
		setPage(page)
		setDirection(sort.order)
		setColumn(sort.column)
	}

	return (
		<Page
			isLoading={false}
			loadingText="Loading Comments data"
			breadcrumbItems={[{ name: 'Comment management', path: Routes.Comments.route }]}
		>
			<React.Fragment>
				<CommentsTable
					comments={comments}
					isLoading={isLoading}
					commentsState={commentsState}
					onDelete={handleDeleteComment}
					onRestore={handleRestoreComment}
					onEdit={handleOpenEdit}
					page={page || 1}
					fetchComments={handleFetchComments}
					column={column as string}
					direction={direction as SortOrder}
					openCommentHistory={handleOpenHistoryLog}
				/>
				<Modal
					title="Delete comment"
					visible={!!commentId}
					onOk={handleConfirmDeleteComment}
					onCancel={handleCancelDeleteComment}
					footer={[
						<Button key="back" onClick={handleCancelDeleteComment}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							loading={isDeletingComment}
							onClick={handleConfirmDeleteComment}
						>
							Confirm
						</Button>
					]}
				>
					<p>Are you sure you want to delete comment?</p>
				</Modal>
				<EditComment
					comment={comment}
					isEditingComment={isEditingComment}
					onConfirm={handleConfirmEditComment}
					onCancel={handleCancelEditComment}
				/>
				<Modal
					title="Restore comment"
					visible={!!restoreCommentId}
					onOk={handleConfirmRestoreComment}
					onCancel={handleCancelRestoreComment}
					footer={[
						<Button key="back" onClick={handleCancelRestoreComment}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							loading={isRestoringComment}
							onClick={handleConfirmRestoreComment}
						>
							Confirm
						</Button>
					]}
				>
					<p>Are you sure you want to restore comment?</p>
				</Modal>
				<HistoryLogModal
					historyLog={historyLog}
					handleCloseHistoryLog={handleCloseHistoryLog}
					historyLogModalState={historyLogModalState}
				/>
			</React.Fragment>
		</Page>
	)
}

export default CommentsDashboardContainer
