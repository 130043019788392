import * as React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

const StyledWrapper = styled.div`
	text-align: right;
	margin-bottom: 20px;
`

interface IProps {
	onSave(): void
	onCancel(): void
}

const UserRankingSystemHeaderButtons: React.FC<IProps> = (props: IProps) => {
	return (
		<StyledWrapper>
			<Button onClick={props.onCancel} type="link">
				CANCEL
			</Button>
			<Button onClick={props.onSave} type="primary">
				SAVE
			</Button>
		</StyledWrapper>
	)
}

export default UserRankingSystemHeaderButtons
