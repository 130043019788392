import { IAcl } from './store/models.types'

export const defaultAcl: IAcl = {
	groups: [],
	permissions: []
}

export enum Mode {
	View = 'view',
	Edit = 'edit'
}
