import { call, put, takeEvery, all } from 'redux-saga/effects'
import { SessionsActionType } from './types'
import { AnyAction } from 'redux'
import {
	fetchSessions,
	fetchSessionDetails,
	deleteSession,
	uploadSessionImage,
	editSession,
	restoreSession
} from './api'
import {
	fetchSessionDetailsSuccess,
	fetchSessionDetailsFailure,
	fetchSessionsSuccess,
	fetchSessionsFailure,
	deleteSessionSuccess,
	deleteSessionFailure,
	editSessionFailure,
	editSessionSuccess,
	restoreSessionSuccess,
	restoreSessionFailure
} from './actions'
import { notification } from 'antd'

// Handlers
function* handleFetchSessionDetails(action: AnyAction) {
	const { response, error } = yield call(fetchSessionDetails, action.payload)
	if (response) {
		yield put(fetchSessionDetailsSuccess(response))
	} else {
		yield put(fetchSessionDetailsFailure(error))
	}
}

function* handleFetchSessions(action: AnyAction) {
	const { response, error } = yield call(fetchSessions, action.payload)
	if (response) {
		yield put(fetchSessionsSuccess(response))
	} else {
		yield put(fetchSessionsFailure(error))
	}
}

function* handleDeleteSession(action: AnyAction) {
	const { response, error } = yield call(deleteSession, action.payload)
	if (response) {
		yield put(deleteSessionSuccess(action.payload))
		notification.success({ message: `Session successfully deleted!` })
	} else {
		yield put(deleteSessionFailure(error))
		notification.error({ message: `Error happened. Session is not deleted!` })
	}
}

function* handleRestoreSession(action: AnyAction) {
	const { response, error } = yield call(restoreSession, action.payload)
	if (response) {
		yield put(restoreSessionSuccess(response))
		notification.success({ message: `Session successfully restored!` })
	} else {
		yield put(restoreSessionFailure(error))
		notification.error({ message: `Error happened. Session is not restored!` })
	}
}

function* handleEditSession(action: AnyAction): Generator<any, any, any> {
	const { data, sessionId } = action.payload
	let imageResponse

	if (typeof data.image !== 'string') {
		imageResponse = yield call(uploadSessionImage, data.image as File)
		if (imageResponse.error) {
			yield put(editSessionFailure(imageResponse.error))
			notification.error({ message: `Error happened. Session is not edited!` })
			return
		}
	}

	const session = yield call(
		editSession,
		{
			...data,
			image: imageResponse ? imageResponse.response.image : data.image
		},
		sessionId
	)
	if (session.error) {
		yield put(editSessionFailure(session.error))
		notification.error({ message: `Error happened. Session is not edited!` })
		return
	}
	yield put(editSessionSuccess(session.response))
	notification.success({ message: `Session successfully edited!` })
}

// Watcher
function* watchFetchSessionDetails() {
	yield takeEvery(SessionsActionType.FetchSessionDetailsRequest, handleFetchSessionDetails)
}

function* watchFetchSessionsDetails() {
	yield takeEvery(SessionsActionType.FetchSessionsRequest, handleFetchSessions)
}

function* watchDeleteSession() {
	yield takeEvery(SessionsActionType.DeleteSessionRequest, handleDeleteSession)
}

function* watchRestoreSession() {
	yield takeEvery(SessionsActionType.RestoreSessionRequest, handleRestoreSession)
}

function* watchEditSession() {
	yield takeEvery(SessionsActionType.EditSessionRequest, handleEditSession)
}

export default function* rootSaga() {
	yield all([
		watchFetchSessionDetails(),
		watchFetchSessionsDetails(),
		watchDeleteSession(),
		watchEditSession(),
		watchRestoreSession()
	])
}
