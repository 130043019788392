import axios from 'axios'
import { USER_MANAGEMENT } from '../../../config/urls'

export const fetchUsers = async (params?: any) => {
	return axios
		.get(`${USER_MANAGEMENT}/admin/users`, { params })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchUsersAction = async (params?: any) => {
	return axios
		.get(`${USER_MANAGEMENT}/ranking-system/user-actions`, { params })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const updateUserRankSystem = async (id: number, points: number) => {
	return axios
		.patch(`${USER_MANAGEMENT}/ranking-system/user-actions/${id}`, { points })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}
