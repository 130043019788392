import { FAILURE, REQUEST, SUCCESS } from '../constants'
import { IErrors } from './types'
import { IApplicationState } from '../../types'
import { IApiError } from 'brainstorming-types'

export const getErrorsState = (state: IApplicationState) => state.errors

export const getErrors = (state: IErrors, actions: string[]) => {
	const formattedActions = actions.map(action =>
		action
			.replace(REQUEST, '')
			.replace(SUCCESS, '')
			.replace(FAILURE, '')
	)

	const errors: IApiError[] = []

	formattedActions.forEach((error: string) => {
		if (state[error]) {
			errors.push(state[error])
		}
	})

	return errors
}
