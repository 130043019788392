import { IToken, IUser } from './models.types'
import {
	ILoginRequest,
	AuthActionType,
	ILoginSuccess,
	ILoginFailure,
	IGetProfileRequest,
	IGetProfileSuccess,
	IGetProfileFailure,
	IRefreshTokenFailure,
	IRefreshTokenRequest,
	IRefreshTokenSuccess,
	ILogoutSuccess
} from './actions.types'
import { ILoginRequest as ILoginRequestType } from 'brainstorming-types'
import { removeTokenFromAxiosAndCookie } from '../../../utils/token'
import { IApiError } from 'brainstorming-types'

export const loginRequest = (payload: ILoginRequestType): ILoginRequest => ({
	type: AuthActionType.LoginRequest,
	payload
})

export const loginSuccess = (payload: IToken): ILoginSuccess => ({
	type: AuthActionType.LoginSuccess,
	payload
})

export const loginFailure = (payload: IApiError): ILoginFailure => ({
	type: AuthActionType.LoginFailure,
	payload
})

export const getProfileRequest = (): IGetProfileRequest => ({
	type: AuthActionType.GetProfileRequest
})

export const getProfileSuccess = (payload: IUser): IGetProfileSuccess => ({
	type: AuthActionType.GetProfileSuccess,
	payload
})

export const getProfileFailure = (payload: IApiError): IGetProfileFailure => ({
	type: AuthActionType.GetProfileFailure,
	payload
})

export const refreshTokenRequest = (): IRefreshTokenRequest => ({
	type: AuthActionType.RefreshTokenRequest
})

export const refreshTokenSuccess = (payload: IToken): IRefreshTokenSuccess => ({
	type: AuthActionType.RefreshTokenSuccess,
	payload
})

export const refreshTokenFailure = (payload: IApiError): IRefreshTokenFailure => ({
	type: AuthActionType.RefreshTokenFailure,
	payload
})

export const logoutSuccess = (): ILogoutSuccess => ({
	type: AuthActionType.LogoutSuccess
})

export const logout = (): ILogoutSuccess => {
	removeTokenFromAxiosAndCookie()
	return logoutSuccess()
}
