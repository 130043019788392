import * as React from 'react'
import useSessions from '../hooks/useSessions'
import { Routes } from '../../../config/routes'
import Page from '../../../components/layout/Page'
import SessionsTable from '../components/dashboard/SessionsTable'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { ISortUrl } from '../../../utils/sorting'
import { SortOrder } from 'antd/lib/table/interface'
import { Modal, Button } from 'antd'
import useDeleteSession from '../hooks/useDeleteSession'
import useRestoreSession from '../hooks/useRestoreSession'
import useHistoryLog from '../../../hooks/useHistoryLog'
import HistoryLogModal from '../../../components/layout/HistoryLogModal'

export const SESSIONS_PER_PAGE = 10

const SessionDashboardContainer = () => {
	const [page, setPage] = useQueryParam('page', NumberParam)
	const [direction, setDirection] = useQueryParam('direction', StringParam)
	const [column, setColumn] = useQueryParam('column', StringParam)
	const { sessions, isLoading, sessionsState } = useSessions({
		page,
		direction,
		column
	})

	const {
		sessionId,
		isDeletingSession,
		handleDeleteSession,
		handleConfirmDeleteSession,
		handleCancelDeleteSession
	} = useDeleteSession()

	const {
		restoreSessionId,
		isRestoringSession,
		handleRestoreSession,
		handleConfirmRestoreSession,
		handleCancelRestoreSession
	} = useRestoreSession()

	const {
		historyLog,
		handleOpenHistoryLog,
		handleCloseHistoryLog,
		historyLogModalState
	} = useHistoryLog()

	const handleFetchSessions = (page: number, sort: ISortUrl) => {
		setPage(page)
		setDirection(sort.order)
		setColumn(sort.column)
	}

	return (
		<Page
			isLoading={false}
			loadingText="Loading Sessions data"
			breadcrumbItems={[{ name: 'Sessions management', path: Routes.Sessions.route }]}
		>
			<SessionsTable
				sessions={sessions}
				isLoading={isLoading}
				sessionsState={sessionsState}
				page={page || 1}
				fetchSessions={handleFetchSessions}
				column={column as string}
				direction={direction as SortOrder}
				onDelete={handleDeleteSession}
				onRestore={handleRestoreSession}
				openSessionHistory={handleOpenHistoryLog}
			/>
			<Modal
				title="Delete session"
				visible={!!sessionId}
				onOk={handleConfirmDeleteSession}
				onCancel={handleCancelDeleteSession}
				footer={[
					<Button key="back" onClick={handleCancelDeleteSession}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={isDeletingSession}
						onClick={handleConfirmDeleteSession}
					>
						Confirm
					</Button>
				]}
			>
				<p>Are you sure you want to delete session?</p>
			</Modal>
			<Modal
				title="Restore"
				visible={!!restoreSessionId}
				onOk={handleConfirmRestoreSession}
				onCancel={handleCancelRestoreSession}
				footer={[
					<Button key="back" onClick={handleCancelRestoreSession}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={isRestoringSession}
						onClick={handleConfirmRestoreSession}
					>
						Confirm
					</Button>
				]}
			>
				<p>Are you sure you want to restore session?</p>
			</Modal>
			<HistoryLogModal
				historyLog={historyLog}
				handleCloseHistoryLog={handleCloseHistoryLog}
				historyLogModalState={historyLogModalState}
			/>
		</Page>
	)
}

export default SessionDashboardContainer
