import { ILogoutSuccess } from '../../../features/auth/store/actions.types'

export enum LoaderActionType {
	TurnOnLoader = '@loader/TURN_ON',
	TurnOffLoader = '@loader/TURN_OFF'
}

export interface ITurnOnLoader {
	type: LoaderActionType.TurnOnLoader
	payload: { action: string }
}

export interface ITurnOffLoader {
	type: LoaderActionType.TurnOffLoader
	payload: { action: string }
}

export type LoaderActions = ITurnOnLoader | ITurnOffLoader | ILogoutSuccess

export interface ILoaders {
	[action: string]: boolean
}

export interface ILoaderOptions {
	default: boolean
}
