import styled from 'styled-components'
import { SPACES } from '../../../../styles/spaces'

export const GroupPageWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

export const GroupListWrapper = styled.div`
	margin-right: ${SPACES.EXTRA_LARGE};
`

export const GroupInfoWrapper = styled.div`
	min-width: calc(100% - 190px);
`
