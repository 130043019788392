import * as React from 'react'
import { useHistory } from 'react-router'

import Page from '../../../components/layout/Page'
import { Routes } from '../../../config/routes'
import { useCreatePage, useDeletePage, useEditPages, usePages } from '../hooks'
import { Mode } from '../utils/types'
import { PagesTable } from '../components/dashboard/PagesTable'
import { PagesActions } from '../components/dashboard/PagesActions'
import { redirectToSinglePage } from '../utils/helpers'
import { IEditPage } from '../store/types'

export interface IEditPagesFormState {
	[pageId: number | string]: IEditPage
}

export const PagesContainer: React.FC = () => {
	const [mode, setMode] = React.useState(Mode.View)
	const { pages, isLoading: isPagesLoading } = usePages()
	const pagesArray = React.useMemo(() => {
		return Object.values(pages.byId)
	}, [pages])

	const history = useHistory()

	const onOpenSinglePage = React.useCallback(
		(pageId: number) => redirectToSinglePage(history, pageId),
		[history]
	)

	const { onCreatePage, isLoading: isCreatePageLoading } = useCreatePage()
	const { onEditPages, isLoading: isEditPagesLoading } = useEditPages()
	const { onDeletePage } = useDeletePage()

	const [editFormState, setEditFormState] = React.useState<IEditPagesFormState>({})

	const onEditSubmit = () => {
		const pageIds = Object.keys(editFormState)
		if (!pageIds.length) {
			return
		}

		const valuesToSubmit = {
			data: pageIds.map(pageId => {
				return {
					id: parseInt(pageId, 10),
					...editFormState[(pageId as unknown) as number]
				}
			})
		}
		return onEditPages(valuesToSubmit)
	}

	const handleDeletePage = (pageId: number) => {
		onDeletePage(pageId)
		// make sure to remove the page from form state
		const formStateCopy = {
			...editFormState
		}
		delete formStateCopy[pageId]
		setEditFormState(formStateCopy)
	}

	const onUpdatePageDetails = (pageId: number | string, pageData: IEditPage) => {
		setEditFormState(curr => ({
			...curr,
			[pageId]: pageData
		}))
	}

	return (
		<Page
			isLoading={isPagesLoading}
			loadingText="Loading Pages data"
			breadcrumbItems={[{ name: 'Pages', path: Routes.Pages.route }]}
		>
			<PagesActions
				mode={mode}
				setMode={setMode}
				onEditSubmit={onEditSubmit}
				onCreatePage={onCreatePage}
				isCreatePageLoading={isCreatePageLoading}
				isEditPageLoading={isEditPagesLoading}
			/>
			<PagesTable
				mode={mode}
				pages={pagesArray}
				onDeletePage={handleDeletePage}
				onOpenSinglePage={onOpenSinglePage}
				onUpdatePageDetails={onUpdatePageDetails}
				editFormState={editFormState}
			/>
		</Page>
	)
}
