import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { createTagRequest } from '../store/actions'
import { TagsActionTypes } from '../store/action.types'
import { ITagForm } from '../components/dashboard/shared/CreateEditTagModal'

interface IUseCreateTagResult {
	onCreateTag: (payload: ITagForm) => any
	isLoading: boolean
}

export const useCreateTag = (): IUseCreateTagResult => {
	const dispatch = useDispatch()

	const onCreateTag = useCallback((payload: ITagForm) => dispatch(createTagRequest(payload)), [
		dispatch
	])

	const loaders = useSelector(getLoadersState)
	const isLoadingCreateTag = isLoading(loaders, [TagsActionTypes.CreateTagRequest], {
		default: false
	})

	return {
		onCreateTag,
		isLoading: isLoadingCreateTag
	}
}
