import {
	PagesActionType,
	ICreatePage,
	IEditPage,
	ICreatePageModule,
	IEditPageModule,
	IEditPageModuleOrder,
	IEditPagesBatch
} from './types'
import { IPage, IPageModule, IApiError } from 'brainstorming-types'

// fetch pages
export const fetchPagesRequest = () => ({
	type: PagesActionType.FetchPagesRequest
})

export const fetchPagesSuccess = (data: IPage[]) => ({
	type: PagesActionType.FetchPagesSuccess,
	payload: data
})

export const fetchPagesFailure = (error: IApiError) => ({
	type: PagesActionType.FetchPagesFailure,
	payload: error
})

// fetch page
export const fetchPageRequest = (pageId: number) => ({
	type: PagesActionType.FetchPageRequest,
	payload: { pageId }
})

export const fetchPageSuccess = (data: IPage) => ({
	type: PagesActionType.FetchPageSuccess,
	payload: data
})

export const fetchPageFailure = (error: IApiError) => ({
	type: PagesActionType.FetchPageFailure,
	payload: error
})

// create page
export const createPageRequest = (data: ICreatePage) => ({
	type: PagesActionType.CreatePageRequest,
	payload: data
})

export const createPageSuccess = (data: IPage) => ({
	type: PagesActionType.CreatePageSuccess,
	payload: data
})

export const createPageFailure = (error: IApiError) => ({
	type: PagesActionType.CreatePageFailure,
	payload: error
})

// edit page
export const editPageRequest = (pageId: number, data: IEditPage) => ({
	type: PagesActionType.EditPageRequest,
	payload: { pageId, data }
})

export const editPageSuccess = (data: IPage) => ({
	type: PagesActionType.EditPageSuccess,
	payload: data
})

export const editPageFailure = (error: IApiError) => ({
	type: PagesActionType.EditPageFailure,
	payload: error
})

// edit pages
export const editPagesRequest = (data: IEditPagesBatch) => ({
	type: PagesActionType.EditPagesRequest,
	payload: { data }
})

export const editPagesSuccess = (data: IPage[]) => ({
	type: PagesActionType.EditPagesSuccess,
	payload: data
})

export const editPagesFailure = (error: IApiError) => ({
	type: PagesActionType.EditPagesFailure,
	payload: error
})

// edit page module order
export const editPageModuleOrderRequest = (pageId: number, data: IEditPageModuleOrder) => ({
	type: PagesActionType.EditPageModuleOrderRequest,
	payload: { pageId, data }
})

export const editPageModuleOrderSuccess = (data: IPage) => ({
	type: PagesActionType.EditPageModuleOrderSuccess,
	payload: data
})

export const editPageModuleOrderFailure = (error: IApiError) => ({
	type: PagesActionType.EditPageModuleOrderFailure,
	payload: error
})

// delete page
export const deletePageRequest = (pageId: number) => ({
	type: PagesActionType.DeletePageRequest,
	payload: {
		pageId
	}
})

export const deletePageSuccess = (data: IPage) => ({
	type: PagesActionType.DeletePageSuccess,
	payload: data
})

export const deletePageFailure = (error: IApiError) => ({
	type: PagesActionType.DeletePageFailure,
	payload: error
})

// create page module
export const createModuleForPageRequest = (pageId: number, data: ICreatePageModule) => ({
	type: PagesActionType.CreateModuleForPageRequest,
	payload: { pageId, data }
})

export const createModuleForPageSuccess = (data: IPageModule) => ({
	type: PagesActionType.CreateModuleForPageSuccess,
	payload: data
})

export const createModuleForPageFailure = (error: IApiError) => ({
	type: PagesActionType.CreateModuleForPageFailure,
	payload: error
})

// edit page module
export const editPageModuleRequest = (pageModuleId: number, data: IEditPageModule) => ({
	type: PagesActionType.EditPageModuleRequest,
	payload: { pageModuleId, data }
})

export const editPageModuleSuccess = (data: IPageModule) => ({
	type: PagesActionType.EditPageModuleSuccess,
	payload: data
})

export const editPageModuleFailure = (error: IApiError) => ({
	type: PagesActionType.EditPageModuleFailure,
	payload: error
})

// delete page module
export const deletePageModuleRequest = (pageModuleId: number) => ({
	type: PagesActionType.DeletePageModuleRequest,
	payload: { pageModuleId }
})

export const deletePageModuleSuccess = (data: IPageModule) => ({
	type: PagesActionType.DeletePageModuleSuccess,
	payload: data
})

export const deletePageModuleFailure = (error: IApiError) => ({
	type: PagesActionType.DeletePageModuleFailure,
	payload: error
})
