import produce from 'immer'
import { ErrorActions, IErrors, ErrorsActionType } from './types'
import { AuthActionType } from '../../../features/auth/store/actions.types'

export const errorsReducer = produce(
	(state: IErrors = {}, action: ErrorActions): IErrors => {
		switch (action.type) {
			case ErrorsActionType.SetError:
				state[action.payload.action] = action.payload.error
				return state
			case ErrorsActionType.ClearError:
				delete state[action.payload.action]
				return state
			case AuthActionType.LogoutSuccess:
				return {}
			default:
				return state
		}
	}
)
