import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { restoreSolutionRequest } from '../store/actions'
import { isLoading, getLoadersState } from '../../../store/common/loaders/selector'
import { SolutionsActionType } from '../store/types'
import usePrevious from '../../../hooks/usePrevious'

const useRestoreSolution = () => {
	const [restoreSolutionId, setRestoreSolutionId] = React.useState<number | null>(null)
	const dispatch = useDispatch()

	const loaders = useSelector(getLoadersState)
	const isRestoringSolution = isLoading(loaders, [SolutionsActionType.RestoreSolutionRequest], {
		default: false
	})
	const prevIsRestoringSolution = usePrevious(isRestoringSolution)

	React.useEffect(() => {
		if (prevIsRestoringSolution && !isRestoringSolution) {
			setRestoreSolutionId(null)
		}
	}, [prevIsRestoringSolution, isRestoringSolution])

	const handleRestoreSolution = (restoreSolutionId: number) => {
		setRestoreSolutionId(restoreSolutionId)
	}

	const handleConfirmRestoreSolution = () => {
		dispatch(restoreSolutionRequest(restoreSolutionId as number))
	}

	const handleCancelRestoreSolution = () => {
		setRestoreSolutionId(null)
	}

	return {
		restoreSolutionId,
		isRestoringSolution,
		handleRestoreSolution,
		handleConfirmRestoreSolution,
		handleCancelRestoreSolution
	}
}

export default useRestoreSolution
