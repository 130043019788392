import { ITag } from 'brainstorming-types'

export const buildTagExamples = (tags: ITag[], numberOfExamples: number = 5): string => {
	let tagExamples = ''
	tags.forEach((tag, index) => {
		const maxIndex = numberOfExamples - 1
		if (index === 0) {
			tagExamples += tag.name
		} else if (index < maxIndex) {
			tagExamples += ', ' + tag.name
		} else if (index === maxIndex) {
			tagExamples += ', ...'
		} else if (index > maxIndex) {
			// skip, we don't want to add more examples
		}
	})

	return tagExamples
}
