import * as React from 'react'
import { Table, Divider, Button, Tag } from 'antd'
import { ISolution, AuditableContentType } from 'brainstorming-types'
import { ColumnProps } from 'antd/lib/table'
import { TablePaginationConfig, SorterResult, SortOrder } from 'antd/lib/table/interface'
import { ISolutions } from '../../store/types'
import { SOLUTIONS_PER_PAGE } from '../../containers/SolutionDashboardContainer'
import { ISortUrl } from '../../../../utils/sorting'
import { printDateAndTime } from '../../../../utils/dateAndTimeFormatters'
import TableWrapper from '../../../../components/TableWrapper'
import { redirectToView } from '../../utils/redirect-to-view'

interface IProps {
	solutions: ISolution[]
	isLoading: boolean
	solutionsState: ISolutions
	page: number
	column?: string
	direction?: SortOrder
	onDelete(solutionId: number): void
	onRestore(solutionId: number): void
	fetchSolutions(page: number, sort: ISortUrl): void
	openSolutionHistory(historyLogId: number, historyLogType: string): void
	onEdit(solution: ISolution): void
}

const SolutionsTable: React.FC<IProps> = ({
	solutions,
	isLoading,
	solutionsState,
	page,
	fetchSolutions,
	column,
	direction,
	onDelete,
	onEdit,
	onRestore,
	openSolutionHistory
}) => {
	const columns: ColumnProps<ISolution>[] = [
		{
			title: 'Id',
			dataIndex: 'id',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'id' ? direction : undefined
		},
		{
			title: 'Title',
			dataIndex: 'title'
		},
		{
			title: 'Content',
			dataIndex: 'content'
		},
		{
			title: 'User',
			dataIndex: 'user',
			render: (text, record, index) => {
				return <div key={`user-ideas-${index}`}>{text.fullName}</div>
			}
		},
		{
			title: 'Number of up votes',
			dataIndex: 'numUpVotes',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'numUpVotes' ? direction : undefined,
			align: 'right'
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'createdAt' ? direction : undefined,
			render: text => printDateAndTime(text)
		},
		{
			key: 'deletedAt',
			title: 'Deleted at',
			dataIndex: 'deletedAt',
			sorter: true,
			defaultSortOrder: 'descend',
			sortOrder: column === 'deletedAt' ? direction : undefined,
			render: text =>
				!!text ? (
					<div>
						<Tag color="red">{printDateAndTime(text)}</Tag>
					</div>
				) : (
					'-'
				)
		},
		{
			title: 'Actions',
			key: 'action',
			render: text =>
				!text.deletedAt ? (
					<div className="actions-wrapper">
						<Button
							size="small"
							onClick={() => redirectToView(text)}
							disabled={!!text.deletedAt}
							target="_blank"
						>
							View
						</Button>
						<Divider type="vertical" />
						<Button
							type="primary"
							danger={true}
							size="small"
							onClick={() => onDelete(text.id)}
							disabled={!!text.deletedAt}
						>
							Delete
						</Button>
						<Divider type="vertical" />
						<Button
							type="primary"
							size="small"
							onClick={() => openSolutionHistory(text.id, AuditableContentType.Solution)}
						>
							See history
						</Button>
					</div>
				) : (
					<div>
						<Button
							type="primary"
							size="small"
							onClick={() => onRestore(text.id)}
							style={{ marginBottom: '2px' }}
						>
							Restore
						</Button>
						<Divider type="vertical" />
						<Button
							type="primary"
							size="small"
							onClick={() => openSolutionHistory(text.id, AuditableContentType.Solution)}
						>
							See history
						</Button>
					</div>
				)
		}
	]

	const onChange = (pagination: TablePaginationConfig, _: any, sorter: SorterResult<ISolution>) => {
		fetchSolutions(pagination.current as number, {
			column: sorter.field as string,
			order: sorter.order
		})
	}

	return (
		<TableWrapper>
			<Table
				columns={columns}
				dataSource={solutions}
				onChange={onChange as any}
				loading={isLoading}
				rowKey="id"
				pagination={{
					total: solutionsState.total,
					current: page,
					pageSize: SOLUTIONS_PER_PAGE,
					showSizeChanger: false
				}}
			/>
		</TableWrapper>
	)
}

export default SolutionsTable
