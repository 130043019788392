import axios from 'axios'
import { ITagForm } from '../components/dashboard/shared/CreateEditTagModal'
import { SESSION_MANAGEMENT } from '../../../config/urls'
import {
	IAddTagsToGroup,
	IGetBatchPayload,
	IRemoveTagsFromGroup,
	IUpdateGroups,
	IUpdateSynonyms
} from './types'

export const getTagByName = async (name: string) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/tags/name/${name}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const searchTags = async (name: string, isGroup: boolean) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/tags/search`, {
			params: {
				name,
				isGroup
			}
		})
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchRegularTags = async (params: any) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/tags/regulars`, { params })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchGroupTags = async (params: any) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/tags/groups`, { params })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const createTag = async (data: ITagForm) => {
	const formData = new FormData()
	formData.set('name', data.name)
	formData.append('image', data.image as any)
	formData.set('isGroup', data.isGroup ? 'true' : 'false')
	return axios
		.post(`${SESSION_MANAGEMENT}/tags`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const addTagsToGroup = async (groupId: number, data: IAddTagsToGroup) => {
	return axios
		.post(`${SESSION_MANAGEMENT}/tags/groups/${groupId}/add-tags`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const removeTagsFromGroup = async (groupId: number, data: IRemoveTagsFromGroup) => {
	return axios
		.post(`${SESSION_MANAGEMENT}/tags/groups/${groupId}/remove-tags`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const updateTagSynonyms = async (tagId: number, data: IUpdateSynonyms) => {
	return axios
		.put(`${SESSION_MANAGEMENT}/tags/regulars/${tagId}/synonyms`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const getTagSynonyms = async (tagId: number) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/tags/regulars/${tagId}/synonyms`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const getTagSynonymsBatch = async (data: IGetBatchPayload) => {
	return axios
		.post(`${SESSION_MANAGEMENT}/tags/regulars/get-synonyms-batch`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const getTagGroups = async (tagId: number) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/tags/regulars/${tagId}/groups`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const getTagGroupsBatch = async (data: IGetBatchPayload) => {
	return axios
		.post(`${SESSION_MANAGEMENT}/tags/regulars/get-groups-batch`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const updateTagGroups = async (tagId: number, data: IUpdateGroups) => {
	return axios
		.put(`${SESSION_MANAGEMENT}/tags/regulars/${tagId}/groups`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const getGroupMembers = async (groupId: number) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/tags/groups/${groupId}/members`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const getGroupMembersBatch = async (data: IGetBatchPayload) => {
	return axios
		.post(`${SESSION_MANAGEMENT}/tags/groups/get-group-members-batch`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const updateTag = async (payload: { tagId: number; data: ITagForm }) => {
	const { tagId, data } = payload

	const formData = new FormData()
	formData.set('name', data.name)
	formData.set('isGroup', data.isGroup ? 'true' : 'false')
	if (data.image) {
		formData.append('image', data.image)
	}
	return axios
		.put(`${SESSION_MANAGEMENT}/tags/${tagId}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchSingleTag = async (tagId: number) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/tags/${tagId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const deleteTag = async (tagId: number) => {
	return axios
		.delete(`${SESSION_MANAGEMENT}/tags/${tagId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}
