import produce from 'immer'
import { AnyAction } from 'redux'
import { IStaffMember, IStaffPayment } from 'brainstorming-types'

import { IStaff, IStaffById, IStaffPayments, StaffActionType } from './types'
import { defaultState } from './constants'
import { arrayToObjectById, replaceIdsIfExist } from '../../../utils'

export const staffReducer = produce(
	(state: IStaff = defaultState as IStaff, action: AnyAction): IStaff => {
		const { type, payload } = action

		switch (type) {
			case StaffActionType.FetchStaffSuccess: {
				state.byId = { ...state.byId, ...arrayToObjectById(payload) }
				state.ids = payload.map((item: IStaffMember) => item.id)
				return state
			}
			case StaffActionType.FetchStaffMemberSuccess: {
				state.byId = { ...state.byId, [payload.id]: payload }
				state.ids = replaceIdsIfExist(state.ids, [payload.id])
				return state
			}
			case StaffActionType.AddStaffSuccess: {
				state.byId = {
					...state.byId,
					...(payload as IStaffMember[]).reduce<IStaffById>((acc, curr) => {
						return { ...acc, [curr.id]: curr }
					}, {})
				}
				state.ids = [...state.ids, ...(payload as IStaffMember[]).map(s => s.id)]
				return state
			}
			case StaffActionType.EditStaffMemberSuccess: {
				state.byId = { ...state.byId, [payload.id]: payload }
				return state
			}
			case StaffActionType.CreatePaymentForStaffMemberSuccess: {
				const staffMemberId = payload.staffMemberId
				const staffMember = state.byId[staffMemberId]
				const oldTotalPaid = staffMember.totalPaid
				state.byId = {
					...state.byId,
					[staffMemberId]: {
						...staffMember,
						previouslyUnpaid: payload.unpaid,
						unpaidWordCount: 0,
						totalPaid: {
							...(oldTotalPaid ? oldTotalPaid : payload.paid),
							amount: payload.paid.amount + (oldTotalPaid ? oldTotalPaid.amount : 0)
						}
					}
				}
				state.ids = [...state.ids, payload.staffMemberId]
				return state
			}
			default:
				return state
		}
	}
)

export const staffPaymentsReducer = produce(
	(state: IStaffPayments = defaultState as IStaffPayments, action: AnyAction): IStaffPayments => {
		const { type, payload } = action

		switch (type) {
			case StaffActionType.FetchPaymentsForStaffMemberSuccess: {
				state.byId = { ...state.byId, ...arrayToObjectById(payload) }
				state.ids = replaceIdsIfExist(
					state.ids,
					payload.map((item: IStaffPayment) => item.id)
				)
				return state
			}
			case StaffActionType.CreatePaymentForStaffMemberSuccess: {
				state.byId = { ...state.byId, [payload.id]: payload }
				state.ids = [...state.ids, payload.id]
				return state
			}
			default:
				return state
		}
	}
)
