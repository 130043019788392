import produce from 'immer'

import { defaultAuth } from '../constants'
import { IAuth } from './models.types'
import { AuthAction, AuthActionType } from './actions.types'

export const authReducer = produce((state: IAuth = defaultAuth, action: AuthAction) => {
	switch (action.type) {
		case AuthActionType.LoginSuccess:
		case AuthActionType.RefreshTokenSuccess:
			state.token = action.payload
			return state
		case AuthActionType.GetProfileSuccess:
			state.user = action.payload
			return state
		case AuthActionType.LogoutSuccess:
			state = defaultAuth
			return state
		default:
			return state
	}
})
