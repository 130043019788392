import { PageHeader, Input } from 'antd'
import * as React from 'react'

import { ITag } from 'brainstorming-types'
import Page from '../../../components/layout/Page'
import { Routes } from '../../../config/routes'
import { AddTagButton } from '../components/dashboard/shared/AddTagButton'
import { RegularTagsTable } from '../components/dashboard/regular/RegularTagsTable'
import { useCreateTag, useDeleteTag, useRegularTags } from '../hooks'
import { StyledButtonDiv } from '../components/dashboard/shared/StyledButtonDiv'
import { useGroupsByIdAndSynonymsById } from '../hooks/useGroupsByIdAndSynonymsById'

export const RegularTagsContainer = () => {
	const { regularTags, isLoading: isRegularTagsLoading } = useRegularTags()

	const regularTagIds = regularTags.map(({ id }) => id)
	const { groupsById, synonymsById } = useGroupsByIdAndSynonymsById(regularTagIds)

	const { onCreateTag, isLoading: isCreateTagLoading } = useCreateTag()

	const { onDeleteTag, isLoading: isDeleteTagLoading } = useDeleteTag()

	const [query, setQuery] = React.useState('')

	const [filteredTags, setFilteredTags] = React.useState<ITag[]>([])

	const filterTags = () => {
		const filteredTags = query
			? regularTags.filter(({ name }) => {
					return name.toLowerCase().includes(query.toLowerCase())
			  })
			: regularTags

		setFilteredTags(filteredTags)
	}

	React.useEffect(filterTags, [regularTags, query])

	const onSearchChange = (event: any) => {
		setQuery(event.target.value || '')
	}

	return (
		<Page
			isLoading={isRegularTagsLoading}
			loadingText={'Loading Regular tags'}
			breadcrumbItems={[{ name: 'Regular tags', path: Routes.RegularTags.route }]}
		>
			<PageHeader title="Regular tags" />

			<StyledButtonDiv>
				<AddTagButton
					isGroup={false}
					onCreateTag={onCreateTag}
					isCreateTagLoading={isCreateTagLoading}
				/>
				<Input value={query} onChange={onSearchChange} placeholder="Filter tags" />
			</StyledButtonDiv>

			<RegularTagsTable
				regularTags={filteredTags}
				groupsById={groupsById}
				synonymsById={synonymsById}
				isRegularTagsLoading={isRegularTagsLoading}
				onDeleteTag={onDeleteTag}
				isDeleteTagLoading={isDeleteTagLoading}
			/>
		</Page>
	)
}
