import * as React from 'react'
import { Form, Select } from 'antd'
import { ISelectOptions } from 'brainstorming-types'
import { IFieldProps } from './types'

export const SelectField: React.FC<IFieldProps> = props => {
	const { handleChange, value, name, label, options } = props

	const selectOptions = (options as ISelectOptions).options

	const onChange = (newValue: string) => {
		handleChange && handleChange(newValue)
	}

	return (
		<Form.Item name={name} label={label}>
			<Select
				onChange={handleChange ? onChange : undefined}
				value={handleChange ? value : undefined}
				options={selectOptions}
			/>
		</Form.Item>
	)
}
