import * as React from 'react'

export const ContentLayout6Illustration = (props: any) => (
	<svg viewBox="0 0 346 152" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g transform="translate(-13512 5084)" data-name="Group 2818">
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2670">
				<path stroke="none" d="M13525-5032h155v86h-155z" />
				<path fill="none" d="M13525.5-5031.5h154v85h-154z" />
			</g>
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2689">
				<path stroke="none" d="M13690-5032h155v86h-155z" />
				<path fill="none" d="M13690.5-5031.5h154v85h-154z" />
			</g>
			<g fill="#a4a4a4" stroke="#9195be" data-name="Rectangle 2672">
				<path stroke="none" d="M13525-5069h155v72h-155z" />
				<path fill="none" d="M13525.5-5068.5h154v71h-154z" />
			</g>
			<g fill="#e1e1f0" stroke="#9195be" data-name="Rectangle 2694">
				<path stroke="none" d="M13690-5069h155v72h-155z" />
				<path fill="none" d="M13690.5-5068.5h154v71h-154z" />
			</g>
			<g fill="none" stroke="#707070" data-name="Rectangle 2666">
				<path stroke="none" d="M13512-5084h346v152h-346z" />
				<path d="M13512.5-5083.5h345v151h-345z" />
			</g>
			<path fill="#e1e1f0" data-name="Rectangle 2673" d="M13529-4975h146v5h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2690" d="M13694-4975h146v5h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2674" d="M13529-4968h146v6h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2691" d="M13694-4968h146v6h-146z" />
			<rect
				transform="translate(13631 -4957)"
				width={20}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2675"
			/>
			<rect
				transform="translate(13796 -4957)"
				width={20}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2692"
			/>
			<rect
				transform="translate(13529 -4986)"
				width={20}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2688"
			/>
			<rect
				transform="translate(13694 -4986)"
				width={20}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2695"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2676" d="M13551-4986h20v6h-20z" />
			<path fill="#e1e1f0" data-name="Rectangle 2696" d="M13716-4986h20v6h-20z" />
			<rect
				transform="translate(13654 -4957)"
				width={21}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2677"
			/>
			<rect
				transform="translate(13819 -4957)"
				width={21}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2693"
			/>
			<text
				transform="translate(13664 -4981)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={1}
			>
				<tspan x={0} y={0}>
					{'1'}
				</tspan>
			</text>
			<text
				transform="translate(13832 -4981)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={2}
			>
				<tspan x={0} y={0}>
					{'2'}
				</tspan>
			</text>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 627"
				d="m13526-4997.775 154-70.925"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 629"
				d="m13691-4997.775 154-70.925"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 628"
				d="m13680-4997.775-154-70.925"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 630"
				d="m13845-4997.775-154-70.925"
			/>
		</g>
	</svg>
)
