import * as React from 'react'
import Table, { ColumnProps } from 'antd/lib/table'

import { IStaffMember, IStaffPayment, PaymentMethod } from 'brainstorming-types'
import TableWrapper from '../../../../components/TableWrapper'
import { printDate } from '../../../../utils/dateAndTimeFormatters'
import { formatMoney } from '../../utils/helpers'
import { PaymentMethodLabels } from '../../utils/constants'
import { TableInfo } from './TableInfo'

export interface IStaffMemberPaymentHistoryProps {
	staffMember: IStaffMember
	payments: IStaffPayment[]
	isLoading: boolean
}

export const StaffMemberPaymentHistory: React.FC<IStaffMemberPaymentHistoryProps> = ({
	staffMember,
	payments,
	isLoading
}) => {
	const columns: ColumnProps<IStaffPayment>[] = [
		{
			key: 'createdAt',
			title: 'Date of payment',
			dataIndex: 'createdAt',
			render: val => printDate(val)
		},
		{
			key: 'pricePerWord',
			title: 'Price per word',
			dataIndex: 'pricePerWord',
			render: val => formatMoney(val)
		},
		{
			key: 'paidWordCount',
			title: 'Number of words',
			dataIndex: 'paidWordCount'
		},
		{
			key: 'value',
			title: 'Earned',
			render: (text, { pricePerWord, paidWordCount }) =>
				formatMoney({
					...pricePerWord,
					amount: pricePerWord.amount * paidWordCount
				})
		},
		{
			key: 'previouslyUnpaid',
			title: 'Remaining from previous payment',
			dataIndex: 'previouslyUnpaid',
			render: val => formatMoney(val)
		},
		{
			key: 'paid',
			title: 'Paid out',
			dataIndex: 'paid',
			render: val => formatMoney(val)
		},
		{
			key: 'paymentMethod',
			title: 'Method',
			dataIndex: 'paymentMethod',
			render: (val: PaymentMethod) => PaymentMethodLabels[val]
		},
		{
			key: 'unpaid',
			title: 'Remaining',
			dataIndex: 'unpaid',
			render: val => formatMoney(val)
		}
	]

	return (
		<TableWrapper>
			<TableInfo>
				Paid account since: <b>{printDate(staffMember.lastActivatedAt)}</b>
				{!staffMember.isActive ? (
					<>
						{' '}
						- Deactivated at: <b>{printDate(staffMember.inactiveSince)}</b>
					</>
				) : null}
			</TableInfo>
			<Table
				columns={columns}
				dataSource={payments}
				rowKey="id"
				pagination={false}
				loading={isLoading}
			/>
			<TableInfo>
				Total paid out: <b>{formatMoney(staffMember.totalPaid)}</b>
			</TableInfo>
		</TableWrapper>
	)
}
