import { ISolution } from 'brainstorming-types'
import { notification } from 'antd'
import { fetchSessionDetails } from '../../sessions/store/api'

export const redirectToView = async (solution: ISolution) => {
	const response: any = await fetchSessionDetails(solution.sessionId)
	if (response.error) {
		notification.error({
			message: 'Something wrong happened!',
			description: response.error.statusCode === 404 ? 'Session is already deleted.' : undefined
		})
	} else {
		window.open(`${process.env.REACT_APP_WEB_URL}/r/s${solution.sessionId}`, '_blank')
	}
}
