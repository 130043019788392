import styled from 'styled-components'

const TableWrapper = styled.div`
	.ant-table-tbody > tr > td {
		background-color: white;
	}
	.ant-table-thead > tr > th {
		background-color: white;
	}
`

export default TableWrapper
