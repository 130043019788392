import {
	IFetchUserRequest,
	UsersActionTypes,
	IFetchUserSuccess,
	IFetchUserFailure,
	IFetchUserActionsRequest,
	IFetchUserActionsSuccess,
	IFetchUserActionsFailure,
	IUpdateUserActionFailure,
	IUpdateUserActionSuccess,
	IUpdateUserActionRequest
} from './actions.types'
import { IUser, IApiError, IUserAction } from 'brainstorming-types'

export const fetchUserRequest = (payload?: any): IFetchUserRequest => ({
	type: UsersActionTypes.FetchUserRequest,
	payload
})

export const fetchUsersSuccess = (payload: IUser[]): IFetchUserSuccess => ({
	type: UsersActionTypes.FetchUserSuccess,
	payload
})

export const fetchUsersFailure = (payload: IApiError): IFetchUserFailure => ({
	type: UsersActionTypes.FetchUserFailure,
	payload
})

export const fetchUserActionsRequest = (payload?: any): IFetchUserActionsRequest => ({
	type: UsersActionTypes.FetchUserActionsRequest,
	payload
})

export const fetchUserActionsSuccess = (payload: IUserAction[]): IFetchUserActionsSuccess => ({
	type: UsersActionTypes.FetchUserActionsSuccess,
	payload
})

export const fetchUserActionsFailure = (payload: IApiError): IFetchUserActionsFailure => ({
	type: UsersActionTypes.FetchUserActionsFailure,
	payload
})

export const updateUserActionRequest = (id: number, points: number): IUpdateUserActionRequest => ({
	type: UsersActionTypes.UpdateUserActionRequest,
	payload: { id, points }
})

export const updateUsersActionSuccess = (payload: IUserAction): IUpdateUserActionSuccess => ({
	type: UsersActionTypes.UpdateUserActionSuccess,
	payload
})

export const updateUsersActionFailure = (payload: IApiError): IUpdateUserActionFailure => ({
	type: UsersActionTypes.UpdateUserActionFailure,
	payload
})
