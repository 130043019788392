import * as React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'

const NotFound: React.FC<{}> = () => {
	return (
		<Result
			style={{ height: '100vh' }}
			status="404"
			title="404"
			subTitle="Sorry, the page you visited does not exist."
			extra={
				<Link className="ant-btn ant-btn-primary" to="/admin">
					Back Home
				</Link>
			}
		/>
	)
}

export default NotFound
