import { ITag, IApiError } from 'brainstorming-types'
import { TagsActionTypes } from './action.types'
import { ITagForm } from '../components/dashboard/shared/CreateEditTagModal'

// fetch regular tags

export const fetchRegularTagsRequest = (payload?: any) => ({
	type: TagsActionTypes.FetchRegularTagsRequest,
	payload
})

export const fetchRegularTagsSuccess = (payload: ITag[]) => ({
	type: TagsActionTypes.FetchRegularTagsSuccess,
	payload
})

export const fetchRegularTagsFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchRegularTagsFailure,
	payload
})

// fetch group tags

export const fetchGroupTagsRequest = (payload?: any) => ({
	type: TagsActionTypes.FetchGroupTagsRequest,
	payload
})

export const fetchGroupTagsSuccess = (payload: ITag[]) => ({
	type: TagsActionTypes.FetchGroupTagsSuccess,
	payload
})

export const fetchGroupTagsFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchGroupTagsFailure,
	payload
})

// create tag

export const createTagRequest = (payload: ITagForm) => ({
	type: TagsActionTypes.CreateTagRequest,
	payload
})

export const createTagSuccess = (payload: ITag) => ({
	type: TagsActionTypes.CreateTagSuccess,
	payload
})

export const createTagFailure = (payload: IApiError) => ({
	type: TagsActionTypes.CreateTagFailure,
	payload
})

// update tag

export const updateTagRequest = (tagId: number, data: ITagForm) => ({
	type: TagsActionTypes.UpdateTagRequest,
	payload: { tagId, data }
})

export const updateTagSuccess = (payload: ITag) => ({
	type: TagsActionTypes.UpdateTagSuccess,
	payload
})

export const updateTagFailure = (payload: IApiError) => ({
	type: TagsActionTypes.UpdateTagFailure,
	payload
})

// fetch synonyms

export const fetchSynonymsRequest = (tagId: number) => ({
	type: TagsActionTypes.FetchSynonymsRequest,
	payload: {
		tagId
	}
})

export const fetchSynonymsSuccess = (payload: { tagId: number; synonyms: ITag[] }) => ({
	type: TagsActionTypes.FetchSynonymsSuccess,
	payload
})

export const fetchSynonymsFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchSynonymsFailure,
	payload
})

// fetch synonyms batch

export const fetchSynonymsBatchRequest = (tagIds: number[]) => ({
	type: TagsActionTypes.FetchSynonymsBatchRequest,
	payload: {
		tagIds
	}
})

export const fetchSynonymsBatchSuccess = (payload: { [tagId: number]: ITag[] }) => ({
	type: TagsActionTypes.FetchSynonymsBatchSuccess,
	payload
})

export const fetchSynonymsBatchFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchSynonymsBatchFailure,
	payload
})

// update synonyms

export const updateSynonymsRequest = (tagId: number, synonymIds: number[]) => ({
	type: TagsActionTypes.UpdateSynonymsRequest,
	payload: {
		tagId,
		synonymIds
	}
})

export const updateSynonymsSuccess = (payload: { tagId: number; synonyms: ITag[] }) => ({
	type: TagsActionTypes.UpdateSynonymsSuccess,
	payload
})

export const updateSynonymsFailure = (payload: IApiError) => ({
	type: TagsActionTypes.UpdateSynonymsFailure,
	payload
})

// update tag groups

export const updateGroupsRequest = (tagId: number, groupIds: number[]) => ({
	type: TagsActionTypes.UpdateGroupsRequest,
	payload: {
		tagId,
		groupIds
	}
})

export const updateGroupsSuccess = (payload: { tagId: number; tagGroups: ITag[] }) => ({
	type: TagsActionTypes.UpdateGroupsSuccess,
	payload
})

export const updateGroupsFailure = (payload: IApiError) => ({
	type: TagsActionTypes.UpdateGroupsFailure,
	payload
})

// add tags to group

export const addTagsToGroupRequest = (groupId: number, tagIds: number[]) => ({
	type: TagsActionTypes.AddTagsToGroupRequest,
	payload: {
		groupId,
		tagIds
	}
})

export const addTagsToGroupSuccess = (payload: { tagId: number; groupMembers: ITag[] }) => ({
	type: TagsActionTypes.AddTagsToGroupSuccess,
	payload
})

export const addTagsToGroupFailure = (payload: IApiError) => ({
	type: TagsActionTypes.AddTagsToGroupFailure,
	payload
})

// remove tags from group

export const removeTagsFromGroupRequest = (groupId: number, tagIds: number[]) => ({
	type: TagsActionTypes.RemoveTagsFromGroupRequest,
	payload: {
		groupId,
		tagIds
	}
})

export const removeTagsFromGroupSuccess = (payload: { tagId: number; groupMembers: ITag[] }) => ({
	type: TagsActionTypes.RemoveTagsFromGroupSuccess,
	payload
})

export const removeTagsFromGroupFailure = (payload: IApiError) => ({
	type: TagsActionTypes.RemoveTagsFromGroupFailure,
	payload
})

// fetch group members

export const fetchGroupMembersRequest = (groupId: number) => ({
	type: TagsActionTypes.FetchGroupMembersRequest,
	payload: {
		groupId
	}
})

export const fetchGroupMembersSuccess = (payload: { groupId: number; groupMembers: ITag[] }) => ({
	type: TagsActionTypes.FetchGroupMembersSuccess,
	payload
})

export const fetchGroupMembersFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchGroupMembersFailure,
	payload
})

// fetch group members batch

export const fetchGroupMembersBatchRequest = (tagIds: number[]) => ({
	type: TagsActionTypes.FetchGroupMembersBatchRequest,
	payload: {
		tagIds
	}
})

export const fetchGroupMembersBatchSuccess = (payload: { [tagId: number]: ITag[] }) => ({
	type: TagsActionTypes.FetchGroupMembersBatchSuccess,
	payload
})

export const fetchGroupMembersBatchFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchGroupMembersBatchFailure,
	payload
})

// fetch groups

export const fetchGroupsRequest = (tagId: number) => ({
	type: TagsActionTypes.FetchGroupsRequest,
	payload: {
		tagId
	}
})

export const fetchGroupsSuccess = (payload: { tagId: number; tagGroups: ITag[] }) => ({
	type: TagsActionTypes.FetchGroupsSuccess,
	payload
})

export const fetchGroupsFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchGroupsFailure,
	payload
})

// fetch groups batch

export const fetchGroupsBatchRequest = (tagIds: number[]) => ({
	type: TagsActionTypes.FetchGroupsBatchRequest,
	payload: {
		tagIds
	}
})

export const fetchGroupsBatchSuccess = (payload: { [tagId: number]: ITag[] }) => ({
	type: TagsActionTypes.FetchGroupsBatchSuccess,
	payload
})

export const fetchGroupsBatchFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchGroupsBatchFailure,
	payload
})

// fetch single tag

export const fetchSingleTagRequest = (tagId: number) => ({
	type: TagsActionTypes.FetchSingleTagRequest,
	payload: {
		tagId
	}
})

export const fetchSingleTagSuccess = (payload: { tag: ITag }) => ({
	type: TagsActionTypes.FetchSingleTagSuccess,
	payload
})

export const fetchSingleTagFailure = (payload: IApiError) => ({
	type: TagsActionTypes.FetchSingleTagFailure,
	payload
})

// delete tag

export const deleteTagRequest = (tagId: number) => ({
	type: TagsActionTypes.DeleteTagRequest,
	payload: {
		tagId
	}
})

export const deleteTagSuccess = (payload: { tagId: number; tag: ITag }) => ({
	type: TagsActionTypes.DeleteTagSuccess,
	payload
})

export const deleteTagFailure = (payload: IApiError) => ({
	type: TagsActionTypes.DeleteTagFailure,
	payload
})
