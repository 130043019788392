import { IApplicationState } from '../../../store/types'
import { sortByDateField } from '../utils/helpers'

export const getActiveStaff = ({ staff }: IApplicationState) => {
	const val = Object.values(staff.byId).filter(member => member.isActive)
	return sortByDateField(val, 'createdAt', 'ASC')
}

export const getInactiveStaff = ({ staff }: IApplicationState) => {
	const val = Object.values(staff.byId).filter(member => !member.isActive)
	return sortByDateField(val, 'inactiveSince', 'DESC')
}

export const getStaffMember = (staffMemberId: number) => ({ staff }: IApplicationState) =>
	staff.byId[staffMemberId]

export const getStaffMemberPayments = (staffMemberId: number) => ({
	staffPayments
}: IApplicationState) => {
	const val = Object.values(staffPayments.byId).filter(
		payment => payment.staffMemberId === staffMemberId
	)
	return sortByDateField(val, 'createdAt', 'DESC')
}
