import { Reducer } from 'redux'
import { LoaderActions, ILoaders, LoaderActionType } from './types'
import { AuthActionType } from '../../../features/auth/store/actions.types'

export const loadersReducer: Reducer<ILoaders, LoaderActions> = (
	state: ILoaders = {},
	action: LoaderActions
): ILoaders => {
	switch (action.type) {
		case LoaderActionType.TurnOnLoader:
			return { ...state, [action.payload.action]: true }
		case LoaderActionType.TurnOffLoader:
			return { ...state, [action.payload.action]: false }
		case AuthActionType.LogoutSuccess:
			return {}
		default:
			return state
	}
}
