import * as React from 'react'
import { Input } from 'antd'
import { IMoney } from 'brainstorming-types'

import { getInputValueFromRealAmount, getRealAmountFromInputValue } from '../../utils/helpers'
import { CurrencyLabels } from '../../utils/constants'

const DECIMAL_SEPARATOR = '.'
const DIGITS_AFTER_SEPARATOR = 2

const getNewValue = (oldValue: IMoney, newAmount: number): IMoney => {
	return {
		...oldValue,
		amount: newAmount
	}
}

export interface IMoneyInputProps {
	value: IMoney
	onChange: (moneyValue: IMoney) => any
	placeholder?: string
}

const defaultProps: Partial<IMoneyInputProps> = {
	placeholder: 'Enter amount'
}

export const MoneyInput: React.FC<IMoneyInputProps> = ({
	value,
	onChange: onChangeProp,
	placeholder
}) => {
	const [amount, setAmount] = React.useState<string>(getInputValueFromRealAmount(value.amount))

	// This onChange handler won't work properly
	// if digits are input in the middle.
	// Only works if digits are appended.
	const onChange = (event: any) => {
		const newAmount: string = event.currentTarget.value.replace(',', DECIMAL_SEPARATOR)
		const lastChar = newAmount.length >= 1 ? newAmount[newAmount.length - 1] : ''
		const beforeLastChar = newAmount.length >= 2 ? newAmount[newAmount.length - 2] : ''
		const parsedLastChar = parseInt(lastChar, 10)
		const parsedBeforeLastChar = parseInt(beforeLastChar, 10)
		const indexOfDecimalSeparator = newAmount.indexOf(DECIMAL_SEPARATOR)
		const digitsAfterDecimalSeparator = newAmount.substr(indexOfDecimalSeparator + 1).length
		/**
		 * cases for last two chars:
		 * .
		 * 4
		 * .4
		 * 4.
		 * .. ----- unacceptable. Ignore input in this case.
		 */
		const isLastCharValid =
			!lastChar ||
			!isNaN(parsedLastChar) ||
			(lastChar === DECIMAL_SEPARATOR && indexOfDecimalSeparator === newAmount.length - 1)
		const isBeforeLastCharValid =
			!beforeLastChar ||
			!isNaN(parsedLastChar) ||
			(lastChar === DECIMAL_SEPARATOR && !isNaN(parsedBeforeLastChar))
		const areBothLastCharsValid =
			isLastCharValid &&
			isBeforeLastCharValid &&
			(indexOfDecimalSeparator === -1 || digitsAfterDecimalSeparator <= DIGITS_AFTER_SEPARATOR)

		const validAmount = areBothLastCharsValid
			? newAmount
			: newAmount.substr(0, newAmount.length - 1)
		const realAmount = getRealAmountFromInputValue(validAmount)

		setAmount(validAmount)
		onChangeProp(getNewValue(value, realAmount))
	}
	const onPaste = (event: any) => {
		event.preventDefault()
		const pastedText: string = event.clipboardData.getData('Text')
		const realAmount = Math.floor(parseFloat(pastedText.replace(',', DECIMAL_SEPARATOR)) * 100)
		if (isNaN(realAmount)) {
			return
		}
		setAmount((realAmount / 100).toFixed(2))
		onChangeProp(getNewValue(value, realAmount))
	}

	return (
		<Input
			value={amount}
			placeholder={placeholder}
			size="large"
			onChange={onChange}
			onPaste={onPaste}
			prefix={<React.Fragment>{CurrencyLabels[value.currency]}</React.Fragment>}
		/>
	)
}

MoneyInput.defaultProps = defaultProps
