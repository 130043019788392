import * as React from 'react'
import { Provider } from 'react-redux'
import { Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import 'antd/dist/antd.css'
import './styles/style.css'

import initStore from './store/initStore'
import routeHistory from './utils/history'
import Layout from './components/layout/Layout'
import Routes from './components/routes/Routes'
import { setBaseUrl, setToken } from './utils/axios'
import { BASE_URL } from './config/urls'
import Cookie from './utils/storage/Cookie'
import { loginSuccess } from './features/auth/store/actions'
import { StorageFactory, StorageType } from './utils/storage/StorageFactory'
import { IToken } from './features/auth/store/models.types'

const App: React.FC = () => {
	const store = initStore()
	setBaseUrl(BASE_URL)

	const storage: Cookie<IToken> = StorageFactory.buildStorage(StorageType.Cookie)
	const token = storage.get('token')

	if (token) {
		// TODO: handle refresh token
		setToken(token.accessToken)
		store.dispatch(loginSuccess(token))
	}

	return (
		<Provider store={store}>
			<Router history={routeHistory}>
				<QueryParamProvider ReactRouterRoute={Route}>
					<Layout>
						<Routes />
					</Layout>
				</QueryParamProvider>
			</Router>
		</Provider>
	)
}

export default App
