import { IMoney, IStaffMember } from 'brainstorming-types'
import { Routes } from '../../../config/routes'
import { CurrencyLabels } from './constants'

export const trimFloat = (value: number, decimalPlaces: number = 2) => value.toFixed(decimalPlaces)

export const formatMoneyAmount = (value: number) =>
	// divide by 100 because we store amount as number of cents
	trimFloat(value / 100).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

export const formatMoney = (value?: IMoney) =>
	value
		? // first add -, then add currency sign, then add amount
		  `${value.amount < 0 ? '-' : ''}${CurrencyLabels[value.currency] || ''}${formatMoneyAmount(
				Math.abs(value.amount)
		  )}`
		: // if value is undefined/null
		  '-'

export const redirectToSingleUserPage = (history: any, staffMemberId: number) =>
	history.push(
		Routes.StaffAccounting.singleUserRoute.replace(':staffMemberId', staffMemberId.toString())
	)

export const getRealAmountFromInputValue = (inputValue: string): number => {
	return inputValue ? Math.floor(parseFloat(inputValue) * 100) : 0
}

export const getInputValueFromRealAmount = (realAmount: number): string => {
	return (realAmount / 100).toFixed(2)
}

export const getValue = (staffMember: IStaffMember): IMoney => {
	const { pricePerWord, unpaidWordCount } = staffMember
	return {
		...pricePerWord,
		amount: pricePerWord.amount * (unpaidWordCount || 0)
	}
}

export const getAmountToPay = (staffMember: IStaffMember): IMoney => {
	const value = getValue(staffMember)
	// There's a possible currency mismatch here.
	return {
		...value,
		amount: value.amount + (staffMember.previouslyUnpaid?.amount || 0)
	}
}

export const getTotalAmountToPay = (staff: IStaffMember[]): IMoney => {
	return staff.map(getAmountToPay).reduce<IMoney>(
		// If some users have been overpaid, that shouldn't be calculated into the sum.
		(acc, curr) => ({ ...acc, amount: acc.amount + (curr.amount >= 0 ? curr.amount : 0) }),
		{
			amount: 0
		} as IMoney
	)
}

export function sortByDateField(array: any[], fieldKey: string, order: 'ASC' | 'DESC') {
	const sortedArray = [...array]
	sortedArray.sort((a, b) => {
		const dateA = new Date(a[fieldKey])
		const dateB = new Date(b[fieldKey])
		const areEqual = dateA === dateB
		const isBGreater = dateB > dateA

		return areEqual ? 0 : order === 'ASC' ? (isBGreater ? -1 : 1) : isBGreater ? 1 : -1
	})

	return sortedArray
}
