import produce from 'immer'
import { AnyAction } from 'redux'
import { ITag } from 'brainstorming-types'

import { ITags } from './types'
import { defaultTags } from './constants'
import { TagsActionTypes } from './action.types'
import { arrayToObjectById, replaceIdsIfExist } from '../../../utils'

interface TagsByTagId {
	[tagId: number]: ITag[]
}

export const tagsReducer = produce((state: ITags = defaultTags, action: AnyAction) => {
	let tagId: number

	switch (action.type) {
		case TagsActionTypes.FetchRegularTagsSuccess:
			const regularTags = action.payload as ITag[]
			const regularTagIds = regularTags.map(tag => tag.id)
			state.tagsById = {
				...state.tagsById,
				...arrayToObjectById(regularTags)
			}

			state.regularTagIds = replaceIdsIfExist(state.regularTagIds, regularTagIds)

			return state
		case TagsActionTypes.FetchGroupTagsSuccess:
			const groupTags = action.payload as ITag[]
			const groupTagIds = groupTags.map(tag => tag.id)
			state.tagsById = {
				...state.tagsById,
				...arrayToObjectById(groupTags)
			}

			state.groupTagIds = replaceIdsIfExist(state.groupTagIds, groupTagIds)

			return state
		case TagsActionTypes.CreateTagSuccess:
			const createdTag = {
				...action.payload,
				noOfContentDirect: 0,
				noOfContentTotal: 0
			} as ITag

			state.tagsById = {
				...state.tagsById,
				[createdTag.id]: createdTag
			}

			if (createdTag.isGroup) {
				state.groupTagIds = replaceIdsIfExist(state.groupTagIds, [createdTag.id], true)
				state.groupMembersById[createdTag.id] = []
			} else {
				state.regularTagIds = replaceIdsIfExist(state.regularTagIds, [createdTag.id], true)
				state.tagSynonymsById[createdTag.id] = []
				state.tagGroupsById[createdTag.id] = []
			}

			return state
		case TagsActionTypes.UpdateTagSuccess:
			const updatedTag = action.payload as ITag
			state.tagsById = {
				...state.tagsById,
				[updatedTag.id]: updatedTag
			}

			if (updatedTag.isGroup) {
				state.groupTagIds = replaceIdsIfExist(state.groupTagIds, [updatedTag.id], true)
				// clear old regular tag data if the tag.isGroup has changed
				state.regularTagIds = state.regularTagIds.filter(id => id !== updatedTag.id)
				delete state.tagGroupsById[updatedTag.id]
				delete state.tagSynonymsById[updatedTag.id]
			} else {
				state.regularTagIds = replaceIdsIfExist(state.regularTagIds, [updatedTag.id], true)
				// clear old group data if the tag.isGroup has changed
				state.groupTagIds = state.groupTagIds.filter(id => id !== updatedTag.id)
				delete state.groupMembersById[updatedTag.id]
			}

			return state
		case TagsActionTypes.FetchSingleTagSuccess:
			const singleTag = action.payload.tag as ITag

			state.tagsById = {
				...state.tagsById,
				[singleTag.id]: singleTag
			}

			if (singleTag.isGroup) {
				state.groupTagIds = replaceIdsIfExist(state.groupTagIds, [singleTag.id])
			} else {
				state.regularTagIds = replaceIdsIfExist(state.regularTagIds, [singleTag.id])
			}

			return state
		case TagsActionTypes.FetchSynonymsSuccess:
		case TagsActionTypes.UpdateSynonymsSuccess:
			const updatedSynonyms = action.payload.synonyms as ITag[]

			tagId = action.payload.tagId

			state.tagSynonymsById[tagId] = updatedSynonyms

			return state
		case TagsActionTypes.RemoveTagsFromGroupSuccess:
		case TagsActionTypes.AddTagsToGroupSuccess:
		case TagsActionTypes.FetchGroupMembersSuccess:
			tagId = action.payload.tagId || action.payload.groupId
			const groupMembers = action.payload.groupMembers as ITag[]
			state.groupMembersById[tagId] = groupMembers

			return state
		case TagsActionTypes.FetchGroupsSuccess:
		case TagsActionTypes.UpdateGroupsSuccess:
			tagId = action.payload.tagId
			const tagGroups = action.payload.tagGroups as ITag[]
			state.tagGroupsById[tagId] = tagGroups

			return state
		case TagsActionTypes.DeleteTagSuccess:
			tagId = action.payload.tagId
			const deletedTag = action.payload.tag as ITag

			if (deletedTag.isGroup) {
				state.groupTagIds = state.groupTagIds.filter((id: number) => {
					return id !== tagId
				})
			} else {
				state.regularTagIds = state.regularTagIds.filter((id: number) => {
					return id !== tagId
				})
			}

			delete state.tagsById[tagId]

			return state
		case TagsActionTypes.FetchGroupsBatchSuccess:
			const groupsById = action.payload as TagsByTagId

			Object.keys(groupsById).forEach(tagId => {
				const tagIdNumber = (tagId as unknown) as number
				const groups = groupsById[tagIdNumber]

				state.tagGroupsById[tagIdNumber] = groups
			})

			return state
		case TagsActionTypes.FetchSynonymsBatchSuccess:
			const synonymsById = action.payload as TagsByTagId

			Object.keys(synonymsById).forEach(tagId => {
				const tagIdNumber = (tagId as unknown) as number
				const synonyms = synonymsById[tagIdNumber]

				state.tagSynonymsById[tagIdNumber] = synonyms
			})

			return state
		case TagsActionTypes.FetchGroupMembersBatchSuccess:
			const groupMembersById = action.payload as TagsByTagId

			Object.keys(groupMembersById).forEach(tagId => {
				const tagIdNumber = (tagId as unknown) as number
				const groupMembers = groupMembersById[tagIdNumber]

				state.groupMembersById[tagIdNumber] = groupMembers
			})

			return state
		default:
			return state
	}
})
