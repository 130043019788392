import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { IPage } from 'brainstorming-types'
import * as React from 'react'
import TableWrapper from '../../../../components/TableWrapper'
import { EditPageDetails } from './EditPageDetails'

interface IProps {
	page: IPage
}

export const PageDetails: React.FC<IProps> = props => {
	const { page } = props

	const dataSource = React.useMemo(() => {
		return [page]
	}, [page])

	const columns: ColumnProps<IPage>[] = [
		{
			key: 'name',
			title: 'Page Name',
			dataIndex: 'name'
		},
		{
			key: 'slug',
			title: 'Slug',
			dataIndex: 'slug'
		},
		{
			key: 'type',
			title: 'Type',
			dataIndex: 'type'
		},
		{
			title: '',
			key: 'actions',
			render: (page: IPage) => {
				return (
					<div className="actions-wrapper">
						<EditPageDetails page={page} />
					</div>
				)
			}
		}
	]

	return (
		<TableWrapper>
			<Table
				columns={columns}
				dataSource={dataSource}
				loading={false}
				rowKey="id"
				pagination={false}
			/>
		</TableWrapper>
	)
}
