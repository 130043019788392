import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { createModuleForPageRequest } from '../store/actions'
import { ICreatePageModule, PagesActionType } from '../store/types'

interface IUseCreateModuleResult {
	onCreateModule: (data: ICreatePageModule) => unknown
	isLoading: boolean
}

export const useCreateModule = (pageId: number): IUseCreateModuleResult => {
	const dispatch = useDispatch()

	const onCreateModule = useCallback(
		(data: ICreatePageModule) => dispatch(createModuleForPageRequest(pageId, data)),
		[dispatch, pageId]
	)

	const loaders = useSelector(getLoadersState)
	const isCreateModuleLoading = isLoading(loaders, [PagesActionType.CreateModuleForPageRequest], {
		default: false
	})

	return {
		onCreateModule,
		isLoading: isCreateModuleLoading
	}
}
