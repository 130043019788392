import axios from 'axios'

export const httpClient = axios.create()

export const setBaseUrl = (url: string): void => {
	axios.defaults.baseURL = url
}

export const setToken = (token: string): void => {
	if (token) {
		axios.defaults.headers.common.Authorization = `Bearer ${token}`
	} else {
		delete axios.defaults.headers.common.Authorization
	}
}
