import { Button, Image, Popconfirm, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { ITag } from 'brainstorming-types'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import TableWrapper from '../../../../../components/TableWrapper'
import { Routes } from '../../../../../config/routes'
import { EditTagButton } from './EditTagButton'
import { ITagForm } from './CreateEditTagModal'

export interface ITagDetailsProps {
	tag: ITag
	onUpdateTag: (tagId: number, data: ITagForm) => any
	isUpdateTagLoading: boolean
	onDeleteTag: (tagId: number) => any
	isDeleteTagLoading: boolean
}

export const TagDetails: React.FC<ITagDetailsProps> = props => {
	const { tag, onUpdateTag, isUpdateTagLoading, onDeleteTag, isDeleteTagLoading } = props

	const history = useHistory()

	const onConfirmDelete = () => {
		onDeleteTag(tag.id)
		history.push(Routes[tag.isGroup ? 'GroupTags' : 'RegularTags'].route)
	}

	const dataSource = React.useMemo(() => [tag], [tag])

	const imageColumns: ColumnProps<ITag>[] = [
		{
			key: 'image',
			title: 'Image',
			render: (text, { image_url: imageUrl }: ITag) => {
				return imageUrl ? <Image width={'200px'} src={imageUrl} /> : <span>no image</span>
			}
		}
	]

	const detailsColumns: ColumnProps<ITag>[] = [
		{
			key: 'name',
			title: 'Tag name',
			dataIndex: 'name'
		},
		{
			key: 'noOfContentDirect',
			title: 'No of content direct',
			dataIndex: ['noOfContentDirect'],
			align: 'left'
		},
		{
			key: 'noOfContentTotal',
			title: 'No of content total',
			dataIndex: ['noOfContentTotal'],
			align: 'left'
		},
		{
			width: '20%',
			key: 'actions',
			title: '',
			render: (text, tag: ITag) => {
				return (
					<>
						<div className="actions-wrapper">
							<EditTagButton
								size="small"
								onUpdateTag={onUpdateTag}
								isUpdateTagLoading={isUpdateTagLoading}
								tag={tag}
							/>
							<Popconfirm
								onConfirm={onConfirmDelete}
								okText="Yes"
								cancelText="No"
								title="Are you sure you want to delete this tag?"
							>
								<Button danger={true} type="primary" size="small" loading={isDeleteTagLoading}>
									delete
								</Button>
							</Popconfirm>
						</div>
					</>
				)
			}
		}
	]

	return (
		<div>
			<TableWrapper>
				<Table
					columns={detailsColumns}
					dataSource={dataSource}
					loading={isUpdateTagLoading}
					rowKey="id"
					pagination={false}
				/>
			</TableWrapper>

			<TableWrapper>
				<Table
					columns={imageColumns}
					dataSource={dataSource}
					loading={isUpdateTagLoading}
					rowKey="id"
					pagination={false}
				/>
			</TableWrapper>
		</div>
	)
}
