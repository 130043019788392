import axios from 'axios'
import {
	IUpdatePermissionsGroupRequest,
	IUpdateUsersGroupRequest,
	ICreateGroupRequest,
	IGroup
} from 'brainstorming-types'
import { USER_MANAGEMENT } from '../../../config/urls'

export const fetchGroups = async (params: any) => {
	return axios
		.get(`${USER_MANAGEMENT}/acl/groups`, { params })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchPermissions = async (params?: any) => {
	return axios
		.get(`${USER_MANAGEMENT}/acl/permissions`, { params })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const updatePermissionsGroup = async (data: IUpdatePermissionsGroupRequest) => {
	return axios
		.post(`${USER_MANAGEMENT}/acl/groups/update-permissions`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const updateUsersGroup = async (data: IUpdateUsersGroupRequest) => {
	return axios
		.post(`${USER_MANAGEMENT}/acl/groups/update-users`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const createGroup = async (data: ICreateGroupRequest) => {
	return axios
		.post(`${USER_MANAGEMENT}/acl/groups/`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const updateGroup = async (id: number, data: Partial<IGroup>) => {
	return axios
		.put(`${USER_MANAGEMENT}/acl/groups/${id}`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const deleteGroup = async (groupId: number) => {
	return axios
		.delete(`${USER_MANAGEMENT}/acl/groups/${groupId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}
