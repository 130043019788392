import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { editPageRequest } from '../store/actions'
import { IEditPage, PagesActionType } from '../store/types'

interface IUseEditPageResult {
	onEditPage: (data: IEditPage) => any
	isLoading: boolean
}

export const useEditPage = (pageId: number): IUseEditPageResult => {
	const dispatch = useDispatch()

	const onEditPage = useCallback((data: IEditPage) => dispatch(editPageRequest(pageId, data)), [
		dispatch,
		pageId
	])

	const loaders = useSelector(getLoadersState)
	const isCreatePageLoading = isLoading(loaders, [PagesActionType.EditPageRequest], {
		default: false
	})

	return {
		onEditPage,
		isLoading: isCreatePageLoading
	}
}
