import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Modal, notification, Select, Alert } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { IMoney, IStaffMember, PaymentMethod } from 'brainstorming-types'

import { ICreateStaffPayment } from '../../store/types'
import { DEFAULT_PAYMENT_METHOD, PaymentMethodLabels } from '../../utils/constants'
import { MoneyInput } from './MoneyInput'
import { formatMoney, getAmountToPay } from '../../utils/helpers'
import { SPACES } from '../../../../styles/spaces'

const paymentMethodValues = Object.values(PaymentMethod)

const StyledDiv = styled.div`
	width: max-content;
	margin-left: auto;
	margin-top: ${SPACES.EXTRA_SMALL};
`

export interface ICreateStaffPaymentButtonProps {
	onCreateStaffPayment: (data: ICreateStaffPayment) => any
	isCreateStaffPaymentLoading: boolean
	staffMember: IStaffMember
}

export const CreateStaffPaymentButton: React.FC<ICreateStaffPaymentButtonProps> = ({
	onCreateStaffPayment,
	isCreateStaffPaymentLoading,
	staffMember
}) => {
	const toPay = React.useMemo(() => getAmountToPay(staffMember), [staffMember])

	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const [paid, setPaid] = React.useState<IMoney>(toPay)
	const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(DEFAULT_PAYMENT_METHOD)
	const [moneyValidationNotOk, setMoneyValidationNotOk] = React.useState(false)

	const onOk = async () => {
		if (!paid.amount) {
			setMoneyValidationNotOk(true)
			return
		}

		await onCreateStaffPayment({ paid, paymentMethod })
		setPaid({ ...paid, amount: 0 })
		setIsModalVisible(false)
	}
	const onCancel = () => setIsModalVisible(false)

	const onClick =
		toPay.amount > 0
			? () => {
					setPaid(toPay)
					setIsModalVisible(true)
			  }
			: () =>
					notification.error({
						message: 'To register payments, outstanding balance must be greater then 0.'
					})

	return (
		<StyledDiv>
			<Button type="primary" onClick={onClick}>
				Register payment
			</Button>
			<Modal
				title="Register payment"
				visible={isModalVisible}
				onOk={onOk}
				confirmLoading={isCreateStaffPaymentLoading}
				onCancel={onCancel}
				okText="Save"
			>
				<p>
					User name: {staffMember.user?.fullName}
					<br />
					To pay: <b>{formatMoney(toPay)}</b>
				</p>
				<Form>
					<Form.Item label="Payment method">
						<Select
							showSearch={true}
							value={paymentMethod}
							onChange={(val: PaymentMethod) => setPaymentMethod(val)}
							filterOption={(input, option) => {
								return (
									// unfortunately, this will have to do
									// (or we'll get errors because of Antd types)
									((option?.props?.children as unknown) as string)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								)
							}}
						>
							{paymentMethodValues.map(paymentMethod => {
								return (
									<Select.Option key={paymentMethod} value={paymentMethod}>
										{PaymentMethodLabels[paymentMethod]}
									</Select.Option>
								)
							})}
						</Select>
					</Form.Item>
					<Form.Item
						label="Amount paid"
						validateStatus={moneyValidationNotOk ? 'error' : undefined}
					>
						<MoneyInput value={paid} onChange={(val: IMoney) => setPaid(val)} />
						{moneyValidationNotOk && <Alert type="error" message="You must enter amount" />}
					</Form.Item>
					<p>Registering payments resets Word counter</p>
				</Form>
			</Modal>
		</StyledDiv>
	)
}
