import * as React from 'react'
import { connect } from 'react-redux'
import { IApplicationState } from '../../../store/types'
import { bindActionCreators, Dispatch } from 'redux'
import { isLoading } from '../../../store/common/loaders/selector'
import { AclActionTypes } from '../store/actions.types'
import {
	fetchGroupsRequest,
	fetchPermissionsRequest,
	createNewWhiteBlackListGroup,
	updateWhiteBlackListGroup,
	deleteGroupRequest
} from '../store/actions'
import { fetchUserRequest } from '../../users/store/actions'
import { IGroup, IPermission, GroupType, IUser } from 'brainstorming-types'
import { getGroupsState, getPermissionsState } from '../store/selectors'
import { getUsersState } from '../../users/store/selectors'
import WhitelistBlacklist from '../components/whitelist-blacklist/WhitelistBlacklist'
import {
	IBlackWhiteListForm,
	IFormInternal
} from '../components/whitelist-blacklist/WhitelistBlacklistForm'
import { UsersActionTypes } from '../../users/store/actions.types'
import Page from '../../../components/layout/Page'
import { Routes } from '../../../config/routes'

interface IProps {
	isLoading: boolean
	groups: IGroup[]
	permissions: IPermission[]
	users: IUser[]
	isDeleting: boolean
	isSaving: boolean
	groupType: GroupType
	fetchGroupsRequest(query: any): void
	fetchPermissionsRequest(): void
	fetchUserRequest(): void
	deleteGroupRequest(id: number): void
	createNewWhiteBlackListGroup(data: IBlackWhiteListForm): void
	updateWhiteBlackListGroup(id: number, data: IBlackWhiteListForm): void
}

class WhitelistBlacklistContainer extends React.Component<IProps> {
	componentDidMount() {
		this.props.fetchGroupsRequest({
			filter: `type||eq||${this.props.groupType}`,
			join: ['permissions', 'users']
		})
		this.props.fetchPermissionsRequest()
		this.props.fetchUserRequest()
	}

	componentDidUpdate(prevProps: IProps) {
		if (prevProps.groupType !== this.props.groupType) {
			this.props.fetchGroupsRequest({
				filter: `type||eq||${this.props.groupType}`,
				join: ['permissions', 'users']
			})
		}
	}

	handleDeleteGroup = (groupId: number) => {
		this.props.deleteGroupRequest(groupId)
	}

	handleSaveGroup = (formData: IFormInternal, group: IGroup) => {
		if (!group.id) {
			this.props.createNewWhiteBlackListGroup({ ...formData, type: this.props.groupType })
		} else {
			this.props.updateWhiteBlackListGroup(group.id, { ...group, ...formData })
		}
	}

	getBreadcrumbRoute = () => {
		if (this.props.groupType === GroupType.Blacklist) {
			return [{ name: 'Blacklist groups', path: Routes.Blacklist.route }]
		}

		return [{ name: 'Whitelist groups', path: Routes.Whitelist.route }]
	}

	render() {
		const { isLoading, groups, permissions, users } = this.props
		return (
			<Page
				isLoading={isLoading}
				loadingText="Loading ACL data"
				breadcrumbItems={this.getBreadcrumbRoute()}
			>
				<WhitelistBlacklist
					users={users}
					groups={groups}
					permissions={permissions}
					saveGroup={this.handleSaveGroup}
					deleteGroup={this.handleDeleteGroup}
					isDeleting={this.props.isDeleting}
					isSaving={this.props.isSaving}
					groupType={this.props.groupType}
				/>
			</Page>
		)
	}
}

const mapStateToProps = (state: IApplicationState) => ({
	isLoading: isLoading(state.loaders, [
		AclActionTypes.FetchGroupRequest,
		AclActionTypes.FetchPermissionRequest,
		UsersActionTypes.FetchUserRequest
	]),
	isDeleting: isLoading(state.loaders, [AclActionTypes.DeleteGroupRequest], { default: false }),
	isSaving: isLoading(
		state.loaders,
		[
			AclActionTypes.CreateGroupRequest,
			AclActionTypes.EditGroupRequest,
			AclActionTypes.UpdatePermissionsGroupRequest,
			AclActionTypes.UpdateUsersGroupRequest
		],
		{ default: false }
	),
	groups: getGroupsState(state),
	permissions: getPermissionsState(state),
	users: getUsersState(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			fetchGroupsRequest,
			fetchPermissionsRequest,
			fetchUserRequest,
			deleteGroupRequest,
			createNewWhiteBlackListGroup,
			updateWhiteBlackListGroup
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistBlacklistContainer)
