import { Button, Modal, Select } from 'antd'
import { ITag } from 'brainstorming-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { debounce } from 'lodash'
import * as React from 'react'

// fix for bad antd types
const TagSelect = (Select as unknown) as React.FC<any>

const DEBOUNCE_INTERVAL = 800

interface ITagOption {
	label: string
	key: number | string
	value: number | string
}

interface IEditTagSynonymsButtonProps {
	tag: ITag
	regularTags: ITag[]

	synonyms: ITag[]
	onUpdateSynonyms: (tagId: number, synonymIds: number[]) => any
	isUpdateSynonymsLoading: boolean
}

export const EditTagSynonymsButton: React.FC<IEditTagSynonymsButtonProps> = props => {
	const { tag, regularTags, synonyms, onUpdateSynonyms, isUpdateSynonymsLoading } = props

	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const [isConfirmationModalVisible, setIsConfirmationModalVisible] = React.useState(false)

	const [selectedOptions, setSelectedOptions] = React.useState<ITagOption[]>([])
	const [options, setOptions] = React.useState<ITagOption[]>([])

	const onOk = () => setIsConfirmationModalVisible(true)
	const onCancel = () => {
		setIsModalVisible(false)
		setSelectedOptions([])
	}

	const onConfirmOk = async () => {
		await onUpdateSynonyms(
			tag.id,
			selectedOptions.map(option => parseInt(option.key as string, 10))
		)
		setOptions([])
		setIsConfirmationModalVisible(false)
		setIsModalVisible(false)
		setSelectedOptions([])
	}
	const onConfirmCancel = () => setIsConfirmationModalVisible(false)

	const resetOptions = React.useCallback(
		(search?: string) => {
			let filteredTags = regularTags.filter(({ id }) => {
				return tag.id !== id
			})
			if (search) {
				filteredTags = filteredTags.filter(({ name }) => {
					return name.toLowerCase().includes(search.toLowerCase())
				})
			}
			setOptions(
				filteredTags.map(({ name, id }) => {
					return {
						label: name,
						key: id,
						value: id
					}
				})
			)
		},
		[regularTags, setOptions, tag]
	)

	const resetSelectedOptions = React.useCallback(() => {
		if (!isUpdateSynonymsLoading) {
			setSelectedOptions(
				synonyms.map(({ name, id }) => {
					return {
						label: name,
						key: id,
						value: id
					}
				})
			)
		}
	}, [isUpdateSynonymsLoading, synonyms])

	React.useEffect(() => resetOptions(), [regularTags, resetOptions])

	React.useEffect(resetSelectedOptions, [synonyms, isUpdateSynonymsLoading, resetSelectedOptions])

	const debouncedSearch = React.useMemo(() => {
		return debounce(resetOptions, DEBOUNCE_INTERVAL)
	}, [resetOptions])

	return (
		<>
			<Modal
				title="Update tag synonyms"
				visible={isModalVisible}
				onOk={onOk}
				confirmLoading={isUpdateSynonymsLoading}
				onCancel={onCancel}
				okText="Update synonyms"
			>
				<Form>
					<Form.Item>
						<TagSelect
							mode="multiple"
							value={selectedOptions}
							placeholder="Type to search synonyms"
							onChange={setSelectedOptions}
							style={{ width: '100%' }}
							labelInValue={true}
							filterOption={false}
							onSearch={debouncedSearch}
						>
							{options.map(option => (
								<Select.Option value={option.value} key={option.key}>
									{option.label}
								</Select.Option>
							))}
						</TagSelect>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				title="Update tag synonyms"
				visible={isConfirmationModalVisible}
				onOk={onConfirmOk}
				confirmLoading={isUpdateSynonymsLoading}
				onCancel={onConfirmCancel}
				okText="Update synonyms"
			>
				<p>Are you sure you want to update tag synonyms?</p>
			</Modal>
			<Button type="primary" size="small" onClick={() => setIsModalVisible(true)}>
				Edit synonyms
			</Button>
		</>
	)
}
