import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ITag } from 'brainstorming-types'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { fetchGroupsRequest } from '../store/actions'
import { getTagGroups } from '../store/selectors'
import { TagsActionTypes } from '../store/action.types'

interface IUseTagGroupsResult {
	tagGroups: ITag[]
	isLoading: boolean
}

export const useTagGroups = (tagId: number): IUseTagGroupsResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchGroupsRequest(tagId))
	}, [dispatch, tagId])

	const tagGroups = useSelector(getTagGroups(tagId))
	const loaders = useSelector(getLoadersState)
	const isLoadingGroups = isLoading(loaders, [TagsActionTypes.FetchGroupsRequest])

	return {
		tagGroups,
		isLoading: isLoadingGroups
	}
}
