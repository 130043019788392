import * as React from 'react'
import { IPageModule } from 'brainstorming-types'
import styled from 'styled-components'

import { BooleanField } from './fields/BooleanField'
import { useEditPageModule } from '../../hooks'

const StyledDiv = styled.div`
	.ant-form-item {
		margin-bottom: 0;
	}
`

interface IProps {
	module: IPageModule
}

export const TogglePageModuleRepeat: React.FC<IProps> = props => {
	const { module } = props

	const [repeat, setRepeat] = React.useState(module.fieldValues.repeat)

	React.useEffect(() => {
		const newRepeat = module.fieldValues.repeat
		if (newRepeat !== repeat) {
			setRepeat(newRepeat)
		}
	}, [module, repeat])

	const { onEditPageModule } = useEditPageModule(module.id)

	const handleChange = (newRepeat: boolean) => {
		setRepeat(newRepeat)

		onEditPageModule({
			layoutName: module.config.layoutName,
			fieldValues: {
				...module.fieldValues,
				repeat: newRepeat
			}
		})
	}

	return (
		<StyledDiv>
			<BooleanField name="repeat" label="Repeat" value={repeat} handleChange={handleChange} />
		</StyledDiv>
	)
}
