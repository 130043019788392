import * as React from 'react'
import styled from 'styled-components'
import { RightOutlined } from '@ant-design/icons'
import { List } from 'antd'
import { IGroup, GroupType } from 'brainstorming-types'
import { Mode } from '../../constants'

interface IProps {
	active?: IGroup
	groups: IGroup[]
	mode: Mode
	onChangeGroup(group: IGroup): void
}

const GroupList: React.FC<IProps> = ({ active, groups, onChangeGroup, mode }) => {
	const renderGroupName = (group: IGroup) => {
		if (group.type === GroupType.UserRank) {
			return (
				<div>
					<p>
						{group.minPoints} - {group.maxPoints}
					</p>
					<small>{group.name}</small>
				</div>
			)
		}
		return <p>{group.name}</p>
	}

	if (groups.length === 0) {
		return null
	}

	const handleClick = (group: IGroup) => {
		if (mode === Mode.Edit) {
			const result = window.confirm('You are in edit mode, are you sure you want to leave?')
			if (result) {
				onChangeGroup(group)
			}
		} else {
			onChangeGroup(group)
		}
	}

	return (
		<List
			dataSource={groups}
			renderItem={group => (
				<StyledListItem
					onClick={() => handleClick(group)}
					active={active && active.id === group.id}
				>
					{renderGroupName(group)}
					<RightOutlined style={{ fontSize: '8px' }} />
				</StyledListItem>
			)}
		/>
	)
}

const StyledListItem = styled(({ active, ...rest }) => <List.Item {...rest} />)`
	&&& {
		color: ${props => (props.active ? 'var(--antd-wave-shadow-color)' : undefined)};
		border-bottom: unset;
		max-width: 200px;
		padding: 16px 0 16px 0;
		text-transform: uppercase;
		&:hover {
			color: var(--antd-wave-shadow-color);
			cursor: pointer;
		}
		p {
			margin: 0;
		}
	}
`

export default GroupList
