import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { deletePageModuleRequest } from '../store/actions'
import { PagesActionType } from '../store/types'

interface IUseDeleteModuleResult {
	onDeleteModule: (moduleId: number) => unknown
	isLoading: boolean
}

export const useDeleteModule = (): IUseDeleteModuleResult => {
	const dispatch = useDispatch()

	const onDeleteModule = useCallback(
		(pageModuleId: number) => dispatch(deletePageModuleRequest(pageModuleId)),
		[dispatch]
	)

	const loaders = useSelector(getLoadersState)
	const isDeleteModuleLoading = isLoading(loaders, [PagesActionType.DeletePageModuleRequest], {
		default: false
	})

	return {
		onDeleteModule,
		isLoading: isDeleteModuleLoading
	}
}
