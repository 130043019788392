import { call, put, takeEvery, all } from 'redux-saga/effects'
import { message } from 'antd'
import { sendUserCredentials, getProfile, refreshToken } from './api'
import { ILoginRequest, AuthActionType } from './actions.types'
import {
	loginSuccess,
	loginFailure,
	getProfileRequest,
	getProfileSuccess,
	getProfileFailure,
	refreshTokenSuccess,
	refreshTokenFailure
} from './actions'
import { setTokenToAxiosAndCookie } from '../../../utils/token'

// Handlers
function* handleLogin(action: ILoginRequest) {
	const { response, error } = yield call(sendUserCredentials, action.payload)
	if (response) {
		setTokenToAxiosAndCookie(response)
		yield put(loginSuccess(response))
	} else {
		yield put(loginFailure(error))
		if (error.statusCode === 401) {
			message.error('Bad credentials!')
		} else {
			message.error(error.message)
		}
	}
}

function* handleGetProfile() {
	yield put(getProfileRequest())
	const { response, error } = yield call(getProfile)
	if (response) {
		yield put(getProfileSuccess(response))
	} else {
		yield put(getProfileFailure(error))
	}
}

function* handleTokenRefresh() {
	const { response, error } = yield call(refreshToken)
	if (response) {
		setTokenToAxiosAndCookie(response)
		yield put(refreshTokenSuccess(response))
	} else {
		yield put(refreshTokenFailure(error))
	}
}

// Watchers
function* watchLogin() {
	yield takeEvery(AuthActionType.LoginRequest, handleLogin)
}

function* watchLoginSuccess() {
	yield takeEvery(AuthActionType.LoginSuccess, handleGetProfile)
}

function* watchRefreshToken() {
	yield takeEvery(AuthActionType.RefreshTokenRequest, handleTokenRefresh)
}

export default function* rootSaga() {
	yield all([watchLogin(), watchLoginSuccess(), watchRefreshToken()])
}
