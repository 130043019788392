import * as React from 'react'
import { connect } from 'react-redux'
import { IApplicationState } from '../../../store/types'
import { bindActionCreators, Dispatch } from 'redux'
import {
	fetchGroupsRequest,
	fetchPermissionsRequest,
	createNewUserRankGroup,
	updateUserRankGroup,
	deleteGroupRequest
} from '../store/actions'
import { isLoading } from '../../../store/common/loaders/selector'
import { AclActionTypes } from '../store/actions.types'
import { IGroup, IPermission, GroupType } from 'brainstorming-types'
import { getGroupsState, getPermissionsState } from '../store/selectors'
import UserRanking from '../components/user-ranking/UserRanking'
import { IUserRankForm } from '../components/user-ranking/UserRankingGroupForm'
import Page from '../../../components/layout/Page'
import { Routes } from '../../../config/routes'

interface IProps {
	isLoading: boolean
	groups: IGroup[]
	permissions: IPermission[]
	isDeleting: boolean
	isSaving: boolean
	fetchGroupsRequest(query: any): void
	fetchPermissionsRequest(): void
	createNewUserRankGroup(data: IUserRankForm): void
	deleteGroupRequest(id: number): void
	updateUserRankGroup(id: number, data: IUserRankForm): void
}

class UserRankingGroupsContainer extends React.Component<IProps> {
	componentDidMount() {
		this.props.fetchGroupsRequest({
			filter: 'type||eq||userRank',
			join: ['permissions', 'users'],
			sort: 'maxPoints,ASC'
		})
		this.props.fetchPermissionsRequest()
	}

	handleDeleteGroup = (groupId: number) => {
		this.props.deleteGroupRequest(groupId)
	}

	handleSaveGroup = (formData: IUserRankForm, group: IGroup) => {
		if (!group.id) {
			this.props.createNewUserRankGroup({ ...formData, type: GroupType.UserRank })
		} else {
			this.props.updateUserRankGroup(group.id, { ...group, ...formData })
		}
	}

	render() {
		const { isLoading, groups, permissions, isDeleting, isSaving } = this.props

		return (
			<Page
				isLoading={isLoading}
				loadingText="Loading ACL data"
				breadcrumbItems={[{ name: 'User ranking groups', path: Routes.UserRankingGroups.route }]}
			>
				<UserRanking
					groups={groups}
					permissions={permissions}
					deleteGroup={this.handleDeleteGroup}
					saveGroup={this.handleSaveGroup}
					isDeleting={isDeleting}
					isSaving={isSaving}
				/>
			</Page>
		)
	}
}

const mapStateToProps = (state: IApplicationState) => ({
	isLoading: isLoading(state.loaders, [
		AclActionTypes.FetchGroupRequest,
		AclActionTypes.FetchPermissionRequest
	]),
	isDeleting: isLoading(state.loaders, [AclActionTypes.DeleteGroupRequest], { default: false }),
	isSaving: isLoading(
		state.loaders,
		[
			AclActionTypes.CreateGroupRequest,
			AclActionTypes.EditGroupRequest,
			AclActionTypes.UpdatePermissionsGroupRequest
		],
		{ default: false }
	),
	groups: getGroupsState(state),
	permissions: getPermissionsState(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			fetchGroupsRequest,
			fetchPermissionsRequest,
			createNewUserRankGroup,
			updateUserRankGroup,
			deleteGroupRequest
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(UserRankingGroupsContainer)
