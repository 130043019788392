import * as React from 'react'
import { Button } from 'antd'
import { ICreatePageModule } from '../../store/types'
import { ContentFillIn, PageModuleLayout } from 'brainstorming-types'
import styled from 'styled-components'
import { SPACES } from '../../../../styles/spaces'

const StyledDiv = styled.div`
	margin-top: ${SPACES.SMALL};
`

interface IProps {
	onCreateModule: (data: ICreatePageModule) => any
	isCreateModuleLoading: boolean
}

export const AddModule: React.FC<IProps> = props => {
	const { onCreateModule, isCreateModuleLoading } = props

	const onAddModule = () =>
		onCreateModule({
			layoutName: PageModuleLayout.ContentLayout1,
			fieldValues: {
				title: 'New Module',
				contentFillIn: ContentFillIn.AutoFillIn,
				repeat: true
			}
		})

	return (
		<StyledDiv>
			<Button type="primary" onClick={onAddModule} loading={isCreateModuleLoading}>
				+ Add Module
			</Button>
		</StyledDiv>
	)
}
