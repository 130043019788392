import { Button, Modal, Select } from 'antd'
import { ITag } from 'brainstorming-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import * as React from 'react'
import { debounce } from 'lodash'

// fix for bad antd types
const TagSelect = (Select as unknown) as React.FC<any>

const DEBOUNCE_INTERVAL = 800

interface ITagOption {
	label: string
	key: number | string
	value: number | string
}

interface IEditTagGroupsButtonProps {
	tag: ITag
	groupTags: ITag[]

	tagGroups: ITag[]
	onUpdateGroups: (tagId: number, groupIds: number[]) => any
	isUpdateGroupsLoading: boolean
}

export const EditTagGroupsButton: React.FC<IEditTagGroupsButtonProps> = props => {
	const { tag, groupTags, tagGroups, onUpdateGroups, isUpdateGroupsLoading } = props

	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const [isConfirmationModalVisible, setIsConfirmationModalVisible] = React.useState(false)

	const [selectedOptions, setSelectedOptions] = React.useState<ITagOption[]>([])
	const [options, setOptions] = React.useState<ITagOption[]>([])

	const onOk = () => setIsConfirmationModalVisible(true)
	const onCancel = () => {
		setIsModalVisible(false)
		setSelectedOptions([])
	}

	const onConfirmOk = async () => {
		await onUpdateGroups(
			tag.id,
			selectedOptions.map(option => parseInt(option.key as string, 10))
		)
		setOptions([])
		setIsConfirmationModalVisible(false)
		setIsModalVisible(false)
		setSelectedOptions([])
	}
	const onConfirmCancel = () => setIsConfirmationModalVisible(false)

	const resetOptions = React.useCallback(
		(search?: string) => {
			let filteredTags = groupTags.filter(({ id }) => {
				return tag.id !== id
			})
			if (search) {
				filteredTags = filteredTags.filter(({ name }) => {
					return name.toLowerCase().includes(search.toLowerCase())
				})
			}
			setOptions(
				filteredTags.map(({ name, id }) => {
					return {
						label: name,
						key: id,
						value: id
					}
				})
			)
		},
		[groupTags, tag]
	)

	const resetSelectedOptions = React.useCallback(() => {
		if (!isUpdateGroupsLoading) {
			setSelectedOptions(
				tagGroups.map(({ name, id }) => {
					return {
						label: name,
						key: id,
						value: id
					}
				})
			)
		}
	}, [isUpdateGroupsLoading, tagGroups])

	React.useEffect(() => resetOptions(), [groupTags, resetOptions])

	React.useEffect(resetSelectedOptions, [groupTags, isUpdateGroupsLoading])

	const debouncedSearch = React.useMemo(() => {
		return debounce(resetOptions, DEBOUNCE_INTERVAL)
	}, [resetOptions])

	return (
		<>
			<Modal
				title="Update tag groups"
				visible={isModalVisible}
				onOk={onOk}
				confirmLoading={isUpdateGroupsLoading}
				onCancel={onCancel}
				okText="Update groups"
			>
				<Form>
					<Form.Item>
						<TagSelect
							mode="multiple"
							value={selectedOptions}
							placeholder="Type to search groups"
							onChange={setSelectedOptions}
							style={{ width: '100%' }}
							labelInValue={true}
							filterOption={false}
							onSearch={debouncedSearch}
						>
							{options.map(option => (
								<Select.Option value={option.value} key={option.key}>
									{option.label}
								</Select.Option>
							))}
						</TagSelect>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				title="Update tag groups"
				visible={isConfirmationModalVisible}
				onOk={onConfirmOk}
				confirmLoading={isUpdateGroupsLoading}
				onCancel={onConfirmCancel}
				okText="Update groups"
			>
				<p>Are you sure you want to update tag groups?</p>
			</Modal>
			<Button type="primary" size="small" onClick={() => setIsModalVisible(true)}>
				Edit groups
			</Button>
		</>
	)
}
