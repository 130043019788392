import { call, put, takeEvery, all } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { notification } from 'antd'

import { StaffActionType } from './types'
import {
	addStaff,
	createPaymentForStaffMember,
	editStaffMember,
	fetchPaymentsForStaffMember,
	fetchStaff,
	fetchStaffMember
} from './api'
import {
	fetchStaffSuccess,
	fetchStaffFailure,
	fetchStaffMemberSuccess,
	fetchStaffMemberFailure,
	addStaffSuccess,
	addStaffFailure,
	editStaffMemberSuccess,
	editStaffMemberFailure,
	fetchPaymentsForStaffMemberSuccess,
	fetchPaymentsForStaffMemberFailure,
	createPaymentForStaffMemberSuccess,
	createPaymentForStaffMemberFailure
} from './actions'

function* handleFetchStaff(action: AnyAction) {
	const { response, error } = yield call(fetchStaff)
	if (response) {
		yield put(fetchStaffSuccess(response))
	} else {
		yield put(fetchStaffFailure(error))
	}
}

function* handleFetchStaffMember(action: AnyAction) {
	const { response, error } = yield call(fetchStaffMember, action.payload)
	if (response) {
		yield put(fetchStaffMemberSuccess(response))
	} else {
		yield put(fetchStaffMemberFailure(error))
	}
}

function* handleAddStaff(action: AnyAction) {
	const { response, error } = yield call(addStaff, action.payload)
	if (response) {
		yield put(addStaffSuccess(response))
		notification.success({ message: 'Saved!' })
	} else {
		yield put(addStaffFailure(error))
		notification.error({ message: 'Error occurred, please try again' })
	}
}

function* handleEditStaffMember({ payload: { staffMemberId, data } }: AnyAction) {
	const { response, error } = yield call(editStaffMember, staffMemberId, data)
	const successMessage = data.pricePerWord ? 'Price per word changed successfully!' : 'Saved!'
	if (response) {
		yield put(editStaffMemberSuccess(response))
		notification.success({ message: successMessage })
	} else {
		yield put(editStaffMemberFailure(error))
		notification.error({ message: 'Error occurred, please try again' })
	}
}

function* handleFetchPaymentsForStaffMember({ payload: { staffMemberId } }: AnyAction) {
	const { response, error } = yield call(fetchPaymentsForStaffMember, staffMemberId)
	if (response) {
		yield put(fetchPaymentsForStaffMemberSuccess(response))
	} else {
		yield put(fetchPaymentsForStaffMemberFailure(error))
	}
}

function* handleCreatePaymentForStaffMember({ payload: { staffMemberId, data } }: AnyAction) {
	const { response, error } = yield call(createPaymentForStaffMember, staffMemberId, data)
	if (response) {
		yield put(createPaymentForStaffMemberSuccess(response))
		notification.success({ message: 'Payment registered successfully!' })
	} else {
		yield put(createPaymentForStaffMemberFailure(error))
		notification.error({ message: 'Error occurred, please try again' })
	}
}

// Watchers
function* watchFetchStaff() {
	yield takeEvery(StaffActionType.FetchStaffRequest, handleFetchStaff)
}
function* watchFetchStaffMember() {
	yield takeEvery(StaffActionType.FetchStaffMemberRequest, handleFetchStaffMember)
}
function* watchAddStaff() {
	yield takeEvery(StaffActionType.AddStaffRequest, handleAddStaff)
}
function* watchEditStaffMember() {
	yield takeEvery(StaffActionType.EditStaffMemberRequest, handleEditStaffMember)
}
function* watchFetchPaymentsForStaffMember() {
	yield takeEvery(
		StaffActionType.FetchPaymentsForStaffMemberRequest,
		handleFetchPaymentsForStaffMember
	)
}
function* watchCreatePaymentForStaffMember() {
	yield takeEvery(
		StaffActionType.CreatePaymentForStaffMemberRequest,
		handleCreatePaymentForStaffMember
	)
}

export default function* rootSaga() {
	yield all([
		watchFetchStaff(),
		watchFetchStaffMember(),
		watchAddStaff(),
		watchEditStaffMember(),
		watchFetchPaymentsForStaffMember(),
		watchCreatePaymentForStaffMember()
	])
}
