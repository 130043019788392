import produce from 'immer'
import { AnyAction } from 'redux'
import { IPage, IPageModule } from 'brainstorming-types'

import { IPages, IPagesById, PagesActionType } from './types'
import { defaultState } from './constants'
import { arrayToObjectById, replaceIdsIfExist } from '../../../utils'

export const pagesReducer = produce(
	(state: IPages = defaultState, action: AnyAction): IPages => {
		const { type, payload } = action

		switch (type) {
			case PagesActionType.FetchPagesSuccess: {
				const pages = payload as IPage[]
				state.byId = { ...state.byId, ...arrayToObjectById(pages) }
				state.ids = replaceIdsIfExist(
					state.ids,
					pages.map(item => item.id)
				)
				return state
			}
			case PagesActionType.FetchPageSuccess:
			case PagesActionType.CreatePageSuccess:
			case PagesActionType.EditPageSuccess:
			case PagesActionType.EditPageModuleOrderSuccess: {
				const page = payload as IPage
				state.byId = { ...state.byId, [page.id]: page }
				state.ids = replaceIdsIfExist(state.ids, [page.id])
				return state
			}
			case PagesActionType.EditPagesSuccess: {
				const pages = payload as IPage[]
				const newPages = pages.reduce<IPagesById>((acc, currPage) => {
					return {
						...acc,
						[currPage.id]: currPage
					}
				}, {})
				state.byId = { ...state.byId, ...newPages }
				state.ids = replaceIdsIfExist(
					state.ids,
					pages.map(({ id }) => id)
				)
				return state
			}
			case PagesActionType.DeletePageSuccess: {
				const page = payload as IPage
				delete state.byId[page.id]
				state.ids = state.ids.filter(id => id !== page.id)
				return state
			}
			case PagesActionType.CreateModuleForPageSuccess: {
				const pageModule = payload as IPageModule
				const { pageId } = pageModule
				;(state.byId[pageId].modules as IPageModule[]).push(pageModule)
				state.byId[pageId].moduleOrder.push(pageModule.id)
				return state
			}
			case PagesActionType.EditPageModuleSuccess: {
				const pageModule = payload as IPageModule
				const { pageId } = pageModule
				const page = state.byId[pageId]
				const moduleIndex = page.modules?.findIndex(module => {
					return module.id === pageModule.id
				})
				if (moduleIndex !== -1) {
					const updatedModules = [...(state.byId[pageId].modules as IPageModule[])]
					updatedModules[moduleIndex as number] = pageModule
					state.byId[pageId].modules = updatedModules
				} else {
					const updatedModules = [...(state.byId[pageId].modules as IPageModule[]), pageModule]
					const updatedModuleOrder = [
						...(state.byId[pageId].moduleOrder as number[]),
						pageModule.id
					]
					state.byId[pageId].modules = updatedModules
					state.byId[pageId].moduleOrder = updatedModuleOrder
				}
				return state
			}
			case PagesActionType.DeletePageModuleSuccess: {
				const pageModule = payload as IPageModule
				const { pageId } = pageModule
				const page = state.byId[pageId]
				const moduleIndex = page.modules?.findIndex(module => {
					return module.id === pageModule.id
				}) as number
				const moduleOrderIndex = page.moduleOrder.findIndex(moduleId => {
					return moduleId === pageModule.id
				}) as number
				if (moduleIndex !== -1) {
					page.modules?.splice(moduleIndex)
				}
				if (moduleOrderIndex !== -1) {
					page.moduleOrder.splice(moduleOrderIndex)
				}
				return state
			}
			default:
				return state
		}
	}
)
