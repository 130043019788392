import * as React from 'react'
import '@ant-design/compatible/assets/index.css'
import { Button } from 'antd'
import { PageDetailsModal } from './PageDetailsModal'
import { ICreatePage } from '../../store/types'

interface IProps {
	onCreatePage: (data: ICreatePage) => any
	isCreatePageLoading: boolean
}

export const AddPage: React.FC<IProps> = props => {
	const { onCreatePage, isCreatePageLoading } = props

	const [isModalVisible, setIsModalVisible] = React.useState(false)

	const onCancel = () => {
		setIsModalVisible(false)
	}

	const onSubmit = (data: ICreatePage) => {
		onCreatePage(data)
		setIsModalVisible(false)
	}

	return (
		<>
			<Button type="primary" onClick={() => setIsModalVisible(true)} loading={isCreatePageLoading}>
				+ Add new Page
			</Button>
			<PageDetailsModal
				isLoading={isCreatePageLoading}
				onSubmit={onSubmit}
				onCancel={onCancel}
				isVisible={isModalVisible}
			/>
		</>
	)
}
