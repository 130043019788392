import * as React from 'react'
import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProtectedRoute from '../../../components/routes/ProtectedRoute'
import { getToken } from '../../auth/store/selectors'
import { Routes as RouteConfig } from '../../../config/routes'
import { PagesContainer } from '../containers/PagesContainer'
import { SinglePageContainer } from '../containers/SinglePageContainer'
import { SingleModuleContainer } from '../containers/SingleModuleContainer'

export const PagesRoutes = () => {
	const token = useSelector(getToken)

	return (
		<Switch>
			<ProtectedRoute
				path={RouteConfig.Pages.route}
				exact={true}
				component={PagesContainer}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.Pages.singlePageRoute}
				exact={true}
				component={SinglePageContainer}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.Pages.singleModuleRoute}
				exact={true}
				component={SingleModuleContainer}
				token={token}
			/>
		</Switch>
	)
}
