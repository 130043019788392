import { Form, Input } from 'antd'
import * as React from 'react'
import { ContentFillIn } from 'brainstorming-types'

import { getContentInputValueFromUrl } from '../../../utils/helpers'
import { IFieldProps } from './types'

export const ContentInputField: React.FC<IFieldProps> = props => {
	const { handleChange, value, name, label, placeholder } = props

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newUrl = event.target.value

		const contentInputValue = getContentInputValueFromUrl(newUrl)

		if (!contentInputValue) {
			return
		}

		if (handleChange) {
			handleChange(newUrl)
		}
	}

	return (
		<Form.Item
			noStyle={true}
			shouldUpdate={(prev, curr) => {
				return prev.contentFillIn !== curr.contentFillIn
			}}
		>
			{({ getFieldValue }) => {
				const contentFillIn = getFieldValue('contentFillIn')

				return contentFillIn === ContentFillIn.Manual ? (
					<Form.Item name={name} label={label}>
						<Input
							onChange={handleChange ? onChange : undefined}
							value={handleChange ? value : undefined}
							placeholder={placeholder}
						/>
					</Form.Item>
				) : null
			}}
		</Form.Item>
	)
}

ContentInputField.defaultProps = {
	placeholder: 'Session/idea link'
}
