import produce from 'immer'
import { AnyAction } from 'redux'
import { CommentsActionType, IComments } from './types'
import { defaultState } from './constants'
import { arrayToObjectById } from '../../../utils/arrayToObjectById'
import { ISolution } from 'brainstorming-types'
import moment from 'moment'

export const commentsReducer = produce(
	(state: IComments = defaultState, action: AnyAction): IComments => {
		switch (action.type) {
			case CommentsActionType.FetchCommentsSuccess: {
				state.byId = { ...state.byId, ...arrayToObjectById(action.payload.items) }
				state.ids = action.payload.items.map((item: ISolution) => item.id)
				state.total = action.payload.total
				state.skip = action.payload.skip
				return state
			}
			case CommentsActionType.EditCommentSuccess: {
				state.byId[action.payload.id] = { ...state.byId[action.payload.id], ...action.payload }
				return state
			}

			case CommentsActionType.RestoreCommentSuccess: {
				state.byId[action.payload.id].deletedAt = action.payload.deletedAt
				return state
			}

			case CommentsActionType.DeleteCommentSuccess: {
				state.byId[action.payload].deletedAt = moment().toString()
				return state
			}
			default:
				return state
		}
	}
)
