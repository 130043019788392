import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { useSelector } from 'react-redux'

import ProtectedRoute from './ProtectedRoute'
import NotFound from '../layout/NotFound'
import LoginContainer from '../../features/auth/containers/LoginContainer'
import DashboardContainer from '../../features/dashboard/containers/DashboardContainer'
import { getToken } from '../../features/auth/store/selectors'
import { Routes as RouteConfig } from '../../config/routes'
import UserRankingGroupsContainer from '../../features/acl/containers/UserRankingGroupsContainer'
import UserRankingSystemContainer from '../../features/users/containers/UserRankingSystemContainer'
import WhitelistBlacklistContainer from '../../features/acl/containers/WhitelistBlacklistContainer'
import { GroupType } from 'brainstorming-types'
import SessionRoutes from '../../features/sessions/components/SessionRoutes'
import SolutionRoutes from '../../features/solutions/components/SolutionRoutes'
import CommentRoutes from '../../features/comments/components/CommentRoutes'
import { StaffAccountingRoutes } from '../../features/staff-accounting/components/StaffAccountingRoutes'
import { PagesRoutes } from '../../features/pages/components/PagesRoutes'
import { GroupTagsRoutes } from '../../features/tags/components/dashboard/group/GroupTagsRoutes'
import { RegularTagsRoutes } from '../../features/tags/components/dashboard/regular/RegularTagsRoutes'

const Routes: React.FC = () => {
	const token = useSelector(getToken)
	return (
		<Switch>
			<Redirect from="/" exact={true} to={RouteConfig.Dashboard.route} />
			<ProtectedRoute
				path={RouteConfig.Login.route}
				component={LoginContainer}
				exact={true}
				isGuestRoute={true}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.Dashboard.route}
				exact={true}
				component={DashboardContainer}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.UserRankingGroups.route}
				exact={true}
				component={UserRankingGroupsContainer}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.Whitelist.route}
				exact={true}
				render={() => <WhitelistBlacklistContainer groupType={GroupType.Whitelist} />}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.Blacklist.route}
				exact={true}
				render={() => <WhitelistBlacklistContainer groupType={GroupType.Blacklist} />}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.UserRankingSystem.route}
				exact={true}
				component={UserRankingSystemContainer}
				token={token}
			/>
			<Route path={RouteConfig.Sessions.route} component={SessionRoutes} />
			<Route path={RouteConfig.Solutions.route} component={SolutionRoutes} />
			<Route path={RouteConfig.Comments.route} component={CommentRoutes} />
			<Route path={RouteConfig.Pages.route} component={PagesRoutes} />
			<Route path={RouteConfig.StaffAccounting.route} component={StaffAccountingRoutes} />
			<Route path={RouteConfig.GroupTags.route} component={GroupTagsRoutes} />
			<Route path={RouteConfig.RegularTags.route} component={RegularTagsRoutes} />
			<Route component={NotFound} />
		</Switch>
	)
}

export default Routes
