import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router'

import { Routes } from '../../config/routes'
import { IToken } from '../../features/auth/store/models.types'

interface IProps extends RouteProps {
	token?: IToken
	isGuestRoute?: boolean
}

const ProtectedRoute: React.FC<IProps> = ({ token, isGuestRoute, ...props }: IProps) => {
	const redirectRoute = isGuestRoute ? Routes.Dashboard : Routes.Login

	if ((token && !isGuestRoute) || (!token && isGuestRoute)) {
		return <Route {...props} />
	}

	return (
		<Redirect
			to={{
				pathname: redirectRoute.route,
				state: { from: props.location }
			}}
		/>
	)
}

export default ProtectedRoute
