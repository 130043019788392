import produce from 'immer'
import { AnyAction } from 'redux'
import { ISessions, SessionsActionType } from './types'
import { defaultState } from './constants'
import { ISession } from 'brainstorming-types'
import merge from 'lodash/merge'
import { arrayToObjectById } from '../../../utils/arrayToObjectById'
import moment from 'moment'

export const sessionsReducer = produce(
	(state: ISessions = defaultState, action: AnyAction): ISessions => {
		switch (action.type) {
			case SessionsActionType.FetchSessionsSuccess: {
				state.byId = merge(state.byId, arrayToObjectById(action.payload.items))
				state.ids = action.payload.items.map((item: ISession) => item.id)
				state.total = action.payload.total
				state.skip = action.payload.skip

				return state
			}

			case SessionsActionType.FetchSessionDetailsSuccess: {
				state.byId[action.payload.id] = action.payload
				return state
			}

			case SessionsActionType.RestoreSessionSuccess: {
				state.byId[action.payload.id].deletedAt = action.payload.deletedAt
				return state
			}

			case SessionsActionType.DeleteSessionSuccess: {
				state.byId[action.payload].deletedAt = moment().toString()
				return state
			}

			default:
				return state
		}
	}
)
