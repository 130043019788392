import * as React from 'react'
import { FormInstance } from 'antd'
import {
	ContentType,
	IPageModuleFieldConfig,
	ISelectOptions,
	PageModuleFieldType,
	PageModuleLayout,
	PredefinedModuleConfigs
} from 'brainstorming-types'
import { Routes } from '../../../config/routes'
import { BooleanField } from '../components/dashboard/fields/BooleanField'
import { ContentInputField } from '../components/dashboard/fields/ContentInputField'
import { InputField } from '../components/dashboard/fields/InputField'
import { SelectField } from '../components/dashboard/fields/SelectField'
import {
	ContentLayout1Illustration,
	ContentLayout2Illustration,
	ContentLayout3Illustration,
	ContentLayout4Illustration,
	ContentLayout5Illustration,
	ContentLayout6Illustration,
	BannerLightIllustration
} from '../components/dashboard/layoutIllustrations'
import { ContentInputValue } from '../components/dashboard/fields/types'

export const redirectToSinglePage = (history: any, pageId: number) =>
	history.push(Routes.Pages.singlePageRoute.replace(':pageId', pageId.toString()))

export const redirectToSingleModule = (history: any, pageId: number, moduleId: number) =>
	history.push(
		Routes.Pages.singleModuleRoute
			.replace(':pageId', pageId.toString())
			.replace(':moduleId', moduleId.toString())
	)

export const getPredefinedLayoutFieldConfigs = (layoutName: PageModuleLayout) => {
	const predefinedLayout = PredefinedModuleConfigs[layoutName]
	return predefinedLayout.fields
}

export const getRegularAndContentFieldConfigs = (fieldConfigs: IPageModuleFieldConfig[]) => {
	const regularFieldConfigs: IPageModuleFieldConfig[] = []
	const contentFieldConfigs: IPageModuleFieldConfig[] = []

	for (const fieldConfig of fieldConfigs) {
		if (fieldConfig.id.startsWith('content') && fieldConfig.id !== 'contentFillIn') {
			contentFieldConfigs.push(fieldConfig)
		} else {
			regularFieldConfigs.push(fieldConfig)
		}
	}

	return {
		regularFieldConfigs,
		contentFieldConfigs
	}
}

export const getFieldComponent = (type: PageModuleFieldType) => {
	return {
		[PageModuleFieldType.Boolean]: BooleanField,
		[PageModuleFieldType.Input]: InputField,
		[PageModuleFieldType.ContentInput]: ContentInputField,
		[PageModuleFieldType.Select]: SelectField
	}[type]
}

export const getDefaultFieldValue = (fieldConfig: IPageModuleFieldConfig) => {
	if (fieldConfig.type === PageModuleFieldType.Select) {
		const selectOptions = fieldConfig.options as ISelectOptions

		return selectOptions.options[0].value
	}

	return {
		[PageModuleFieldType.Boolean]: true,
		[PageModuleFieldType.Input]: '',
		[PageModuleFieldType.ContentInput]: undefined
	}[fieldConfig.type]
}

export const mapFieldConfigsToFields = (fieldConfigs: IPageModuleFieldConfig[]) => {
	return fieldConfigs.map(fieldConfig => {
		const Field = getFieldComponent(fieldConfig.type)

		return (
			<Field
				key={fieldConfig.id}
				name={fieldConfig.id}
				label={fieldConfig.name}
				options={fieldConfig.options}
			/>
		)
	})
}

export const mapContentFieldConfigsToFields = (
	fieldConfigs: IPageModuleFieldConfig[],
	form: FormInstance
) => {
	const { contentFieldConfigs } = getRegularAndContentFieldConfigs(fieldConfigs)

	return contentFieldConfigs.length
		? contentFieldConfigs.map(fieldConfig => {
				const handleChange = (newValue: ContentInputValue) => {
					form.setFieldsValue({
						[fieldConfig.id]: newValue
					})
				}

				const value = form.getFieldValue(fieldConfig.id)

				return (
					<ContentInputField
						key={fieldConfig.id}
						name={fieldConfig.id}
						label={fieldConfig.name}
						handleChange={handleChange}
						value={value}
					/>
				)
		  })
		: null
}

export const getContentInputValueFromUrl = (url: string): ContentInputValue | undefined => {
	const urlParts = url.split('/')
	const stringId = urlParts[urlParts.length - 1]

	const id = parseInt(stringId, 10)

	if (isNaN(id)) {
		return undefined
	}

	return {
		url,
		id,
		type: ContentType.Session
	}
}

export const isContentInput = (fieldId: string) => {
	return fieldId.startsWith('content') && fieldId !== 'contentFillIn'
}

export const getModuleLayoutIllustration = (layoutName: PageModuleLayout) => {
	return {
		[PageModuleLayout.ContentLayout1]: ContentLayout1Illustration,
		[PageModuleLayout.ContentLayout2]: ContentLayout2Illustration,
		[PageModuleLayout.ContentLayout3]: ContentLayout3Illustration,
		[PageModuleLayout.ContentLayout4]: ContentLayout4Illustration,
		[PageModuleLayout.ContentLayout5]: ContentLayout5Illustration,
		[PageModuleLayout.ContentLayout6]: ContentLayout6Illustration,
		[PageModuleLayout.BannerLayout]: BannerLightIllustration
	}[layoutName]
}
