import { IGroup, GroupType, IPermission, IUser } from 'brainstorming-types'

export const buildNewGroup = ({
	type,
	permissions,
	users
}: {
	type: GroupType
	permissions?: IPermission[]
	users?: IUser[]
}): Partial<IGroup> => {
	return {
		name: '',
		minPoints: type === GroupType.UserRank ? 10 : undefined,
		maxPoints: type === GroupType.UserRank ? 100 : undefined,
		type,
		permissions: permissions || [],
		users: users || []
	}
}
