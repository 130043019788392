import * as React from 'react'
import { Table, Button, Select, Popconfirm, Form } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { IPage, PageType } from 'brainstorming-types'
import TableWrapper from '../../../../components/TableWrapper'
import { Mode } from '../../utils/types'
import { PageTypeLabels } from '../../utils/constants'
import { IEditPage } from '../../store/types'
import { IEditPagesFormState } from '../../containers/PagesContainer'

interface IProps {
	pages: IPage[]
	onOpenSinglePage: (pageId: number) => void
	onDeletePage: (pageId: number) => void
	onUpdatePageDetails: (pageId: number, pageData: IEditPage) => void
	mode: Mode
	editFormState: IEditPagesFormState
}

export const PagesTable: React.FC<IProps> = props => {
	const { pages, onOpenSinglePage, onDeletePage, mode, onUpdatePageDetails, editFormState } = props

	const isEdit = mode === Mode.Edit

	const typeOptions = React.useMemo(() => {
		return Object.values(PageType).map(type => {
			return {
				key: type,
				value: type,
				label: PageTypeLabels[type]
			}
		})
	}, [])

	const onTypeSelectChange = (page: IPage, type: PageType) => {
		if (type === PageType.Default) {
			const pageIds = Object.keys(editFormState)
			let previousDefaultPageId =
				pageIds.find(pageId => {
					return editFormState[pageId]?.type === PageType.Default
				}) ||
				(pages.find(page => {
					return page.type === PageType.Default
				}) as IPage).id

			onUpdatePageDetails(previousDefaultPageId as number, {
				type: PageType.Inactive
			})
		}

		onUpdatePageDetails(page.id, {
			type
		})
	}

	const columns: ColumnProps<IPage>[] = [
		{
			key: 'name',
			title: 'Page Name',
			dataIndex: 'name'
		},
		{
			key: 'slug',
			title: 'Slug',
			dataIndex: 'slug'
		},
		{
			key: 'type',
			title: 'Type',
			render: (page: IPage) => {
				const formValue = editFormState[page.id]?.type

				return isEdit ? (
					<Select
						options={typeOptions}
						onChange={value => onTypeSelectChange(page, value as PageType)}
						value={formValue ? formValue : page.type}
					/>
				) : (
					<>{page.type}</>
				)
			}
		},
		{
			title: '',
			key: 'actions',
			render: (page: IPage) => (
				<div className="actions-wrapper">
					{isEdit ? (
						page.type !== PageType.Default && (
							<Popconfirm
								onConfirm={() => onDeletePage(page.id)}
								okText="Yes"
								cancelText="No"
								title="Are you sure you want to delete this page?"
							>
								<Button danger={true} type="primary" size="small">
									delete page
								</Button>
							</Popconfirm>
						)
					) : (
						<Button type="primary" size="small" onClick={() => onOpenSinglePage(page.id)}>
							see details
						</Button>
					)}
				</div>
			)
		}
	]

	return (
		<TableWrapper>
			<Form>
				<Table
					columns={columns}
					dataSource={pages}
					loading={false}
					rowKey="id"
					pagination={false}
				/>
			</Form>
		</TableWrapper>
	)
}
