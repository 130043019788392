import * as React from 'react'

export const ContentLayout2Illustration = (props: any) => (
	<svg viewBox="0 0 346 152" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g transform="translate(-13512 5676)" data-name="Group 2814">
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2647">
				<path stroke="none" d="M13690-5624h72v86h-72z" />
				<path fill="none" d="M13690.5-5623.5h71v85h-71z" />
			</g>
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2649">
				<path stroke="none" d="M13525-5624h155v86h-155z" />
				<path fill="none" d="M13525.5-5623.5h154v85h-154z" />
			</g>
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2648">
				<path stroke="none" d="M13773-5624h72v86h-72z" />
				<path fill="none" d="M13773.5-5623.5h71v85h-71z" />
			</g>
			<g fill="#e1e1f0" stroke="#9195be" data-name="Rectangle 2629">
				<path stroke="none" d="M13525-5661h155v72h-155z" />
				<path fill="none" d="M13525.5-5660.5h154v71h-154z" />
			</g>
			<g fill="#e1e1f0" stroke="#9195be" data-name="Rectangle 2624">
				<path stroke="none" d="M13690-5661h72v38h-72z" />
				<path fill="none" d="M13690.5-5660.5h71v37h-71z" />
			</g>
			<g fill="#e1e1f0" stroke="#9195be" data-name="Rectangle 2622">
				<path stroke="none" d="M13773-5661h72v38h-72z" />
				<path fill="none" d="M13773.5-5660.5h71v37h-71z" />
			</g>
			<g fill="none" stroke="#707070" data-name="Rectangle 2623">
				<path stroke="none" d="M13512-5676h346v152h-346z" />
				<path d="M13512.5-5675.5h345v151h-345z" />
			</g>
			<path fill="#e1e1f0" data-name="Rectangle 2630" d="M13529-5567h146v5h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2631" d="M13529-5560h146v6h-146z" />
			<rect
				transform="translate(13631 -5549)"
				width={20}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2632"
			/>
			<rect
				transform="translate(13529 -5578)"
				width={20}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2644"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2645" d="M13551-5578h20v6h-20z" />
			<rect
				transform="translate(13654 -5549)"
				width={21}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2633"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2625" d="M13694-5607h64v5h-64z" />
			<path fill="#e1e1f0" data-name="Rectangle 2634" d="M13694-5599h64v5h-64z" />
			<rect
				transform="translate(13694 -5549)"
				width={21}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2635"
			/>
			<rect
				transform="translate(13694 -5617)"
				width={21}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2636"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2637" d="M13717-5617h20v5h-20z" />
			<rect
				transform="translate(13718 -5549)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2638"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2639" d="M13694-5592h64v5h-64z" />
			<path fill="#e1e1f0" data-name="Rectangle 2626" d="M13777-5607h64v5h-64z" />
			<path fill="#e1e1f0" data-name="Rectangle 2640" d="M13777-5599h64v5h-64z" />
			<rect
				transform="translate(13777 -5549)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2641"
			/>
			<rect
				transform="translate(13777 -5617)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2627"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2628" d="M13800-5617h20v5h-20z" />
			<rect
				transform="translate(13801 -5549)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2642"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2643" d="M13777-5592h64v5h-64z" />
			<text
				transform="translate(13664 -5573)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={1}
			>
				<tspan x={0} y={0}>
					{'1'}
				</tspan>
			</text>
			<text
				transform="translate(13749 -5545)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={2}
			>
				<tspan x={0} y={0}>
					{'2'}
				</tspan>
			</text>
			<text
				transform="translate(13831 -5545)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={3}
			>
				<tspan x={0} y={0}>
					{'3'}
				</tspan>
			</text>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 613"
				d="m13774-5623.121 70.74-37.379"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 615"
				d="m13690-5623.121 71.169-37.379"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 617"
				d="m13526-5589.775 154-70.925"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 614"
				d="M13844.74-5623.121 13774-5660.5"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 616"
				d="M13761.169-5623.121 13690-5660.5"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 618"
				d="m13680-5589.775-154-70.925"
			/>
		</g>
	</svg>
)
