import { ColumnProps } from 'antd/lib/table'
import { Table } from 'antd'
import { ITag } from 'brainstorming-types'
import * as React from 'react'
import TableWrapper from '../../../../../components/TableWrapper'
import { EditTagGroupsButton } from './EditTagGroupsButton'
import { buildTagExamples } from '../../../utils/helpers'

export interface IRegularTagGroupsProps {
	tag: ITag
	groupTags: ITag[]

	tagGroups: ITag[]
	isTagGroupsLoading: boolean
	onUpdateGroups: (tagId: number, groupIds: number[]) => any
	isUpdateGroupsLoading: boolean
}

export const RegularTagGroups: React.FC<IRegularTagGroupsProps> = props => {
	const {
		tag,
		groupTags,
		tagGroups,
		isTagGroupsLoading,
		onUpdateGroups,
		isUpdateGroupsLoading
	} = props

	const dataSource = React.useMemo(() => [tagGroups], [tagGroups])

	const columns: ColumnProps<ITag[]>[] = [
		{
			width: '20%',
			key: 'noOfGroups',
			title: 'No of groups',
			render: () => {
				return tagGroups.length
			}
		},
		{
			key: 'groups',
			title: 'Groups',
			render: () => {
				return buildTagExamples(tagGroups)
			}
		},
		{
			width: '20%',
			key: 'actions',
			title: '',
			render: () => {
				return (
					<EditTagGroupsButton
						tag={tag}
						groupTags={groupTags}
						tagGroups={tagGroups}
						onUpdateGroups={onUpdateGroups}
						isUpdateGroupsLoading={isUpdateGroupsLoading}
					/>
				)
			}
		}
	]

	return (
		<div>
			<TableWrapper>
				<Table
					columns={columns}
					dataSource={dataSource}
					loading={isTagGroupsLoading || isUpdateGroupsLoading}
					rowKey="id"
					pagination={false}
				/>
			</TableWrapper>
		</div>
	)
}
