import { IComment, CommentableType } from 'brainstorming-types'
import { fetchSolution } from '../../solutions/store/api'
import { notification } from 'antd'

export const redirectToView = async (comment: IComment) => {
	if (comment.commentableType === CommentableType.Solution) {
		const response: any = await fetchSolution(comment.commentableId)
		if (response.error) {
			notification.error({
				message: 'Something wrong happened!',
				description: response.error.statusCode === 404 ? 'Solution is already deleted.' : undefined
			})
		} else {
			window.open(`${process.env.REACT_APP_WEB_URL}/r/s${response.response.sessionId}`, '_blank')
		}
	} else {
		window.open(
			`${process.env.REACT_APP_WEB_URL}/r/${
				comment.commentableType === CommentableType.Idea ? 'i' : 's'
			}${comment.commentableId}`,
			'_blank'
		)
	}
}
