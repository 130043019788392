import { Button } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { ITag } from 'brainstorming-types'
import * as React from 'react'
import { ITagForm, CreateEditTagModal } from './CreateEditTagModal'

export interface IEditTagButtonProps {
	onUpdateTag: (tagId: number, data: ITagForm) => any
	isUpdateTagLoading: boolean
	tag?: ITag

	size: SizeType
}

export const EditTagButton: React.FC<IEditTagButtonProps> = props => {
	const { onUpdateTag, isUpdateTagLoading, tag, ...restProps } = props

	const [isModalVisible, setIsModalVisible] = React.useState(false)

	const onSave = (data: ITagForm) => {
		onUpdateTag((tag?.id as unknown) as number, data)
		setIsModalVisible(false)
	}

	const onCancel = () => {
		setIsModalVisible(false)
	}

	return (
		<>
			<CreateEditTagModal
				onSave={onSave}
				isSaving={isUpdateTagLoading}
				open={isModalVisible}
				onClose={onCancel}
				tag={tag}
			/>
			<Button type="primary" onClick={() => setIsModalVisible(true)} {...restProps}>
				{`Edit${tag?.isGroup ? ' group ' : ' '}tag`}
			</Button>
		</>
	)
}
