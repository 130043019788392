import { SolutionsActionType, IEditSolution } from './types'
import { ISolution, IApiError, IPaginate } from 'brainstorming-types'

export const fetchSolutionsFailure = (error: IApiError) => ({
	type: SolutionsActionType.FetchSolutionsFailure,
	payload: error
})

export const fetchSolutionsRequest = (query: any) => ({
	type: SolutionsActionType.FetchSolutionsRequest,
	payload: query
})

export const fetchSolutionsSuccess = (data: IPaginate<ISolution>) => ({
	type: SolutionsActionType.FetchSolutionsSuccess,
	payload: data
})

export const deleteSolutionFailure = (error: IApiError) => ({
	type: SolutionsActionType.DeleteSolutionFailure,
	payload: error
})

export const deleteSolutionRequest = (solutionId: number) => ({
	type: SolutionsActionType.DeleteSolutionRequest,
	payload: solutionId
})

export const deleteSolutionSuccess = (solution: Partial<ISolution>) => ({
	type: SolutionsActionType.DeleteSolutionSuccess,
	payload: solution
})

export const restoreSolutionFailure = (error: IApiError) => ({
	type: SolutionsActionType.RestoreSolutionFailure,
	payload: error
})

export const restoreSolutionRequest = (solutionId: number) => ({
	type: SolutionsActionType.RestoreSolutionRequest,
	payload: solutionId
})

export const restoreSolutionSuccess = (data: any) => ({
	type: SolutionsActionType.RestoreSolutionSuccess,
	payload: data
})

export const editSolutionRequest = (solutionId: number, data: IEditSolution) => ({
	type: SolutionsActionType.EditSolutionRequest,
	payload: { solutionId, data }
})

export const editSolutionSuccess = (solution: ISolution) => ({
	type: SolutionsActionType.EditSolutionSuccess,
	payload: solution
})

export const editSolutionFailure = (error: IApiError) => ({
	type: SolutionsActionType.EditSolutionFailure,
	payload: error
})
