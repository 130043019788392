import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { editPageModuleRequest } from '../store/actions'
import { IEditPageModule, PagesActionType } from '../store/types'

interface IUseEditPageModuleResult {
	onEditPageModule: (data: IEditPageModule) => any
	isLoading: boolean
}

export const useEditPageModule = (pageModuleId: number): IUseEditPageModuleResult => {
	const dispatch = useDispatch()

	const onEditPageModule = useCallback(
		(data: IEditPageModule) => dispatch(editPageModuleRequest(pageModuleId, data)),
		[dispatch, pageModuleId]
	)

	const loaders = useSelector(getLoadersState)
	const isEditPageModuleLoading = isLoading(loaders, [PagesActionType.EditPageModuleRequest], {
		default: false
	})

	return {
		onEditPageModule,
		isLoading: isEditPageModuleLoading
	}
}
