import { ISession } from 'brainstorming-types'

export enum SessionsActionType {
	FetchSessionsRequest = '@sessions/FETCH_SESSIONS_REQUEST',
	FetchSessionsSuccess = '@sessions/FETCH_SESSIONS_SUCCESS',
	FetchSessionsFailure = '@sessions/FETCH_SESSIONS_FAILURE',
	FetchSessionDetailsRequest = '@sessions/FETCH_SESSION_DETAILS_REQUEST',
	FetchSessionDetailsSuccess = '@sessions/FETCH_SESSION_DETAILS_SUCCESS',
	FetchSessionDetailsFailure = '@sessions/FETCH_SESSION_DETAILS_FAILURE',
	DeleteSessionRequest = '@sessions/DELETE_SESSION_REQUEST',
	DeleteSessionSuccess = '@sessions/DELETE_SESSION_SUCCESS',
	DeleteSessionFailure = '@sessions/DELETE_SESSION_FAILURE',
	EditSessionRequest = '@sessions/EDIT_SESSION_REQUEST',
	EditSessionSuccess = '@sessions/EDIT_SESSION_SUCCESS',
	EditSessionFailure = '@sessions/EDIT_SESSION_FAILURE',
	RestoreSessionRequest = '@sessions/RESTORE_SESSION_REQUEST',
	RestoreSessionSuccess = '@sessions/RESTORE_SESSION_SUCCESS',
	RestoreSessionFailure = '@sessions/RESTORE_SESSION_FAILURE'
}

export interface ISessionsById {
	[id: number]: ISession
}

export interface ISessions {
	byId: ISessionsById
	ids: number[]
	skip: number
	total: number
}
