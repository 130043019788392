import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { addStaffRequest } from '../store/actions'
import { IAddStaff, StaffActionType } from '../store/types'

interface IUseAddStaffResult {
	onAddStaff: (data: IAddStaff) => any
	isLoading: boolean
}

export const useAddStaff = (): IUseAddStaffResult => {
	const dispatch = useDispatch()

	const onAddStaff = useCallback((data: IAddStaff) => dispatch(addStaffRequest(data)), [dispatch])

	const loaders = useSelector(getLoadersState)
	const isEditStaffMemberLoading = isLoading(loaders, [StaffActionType.AddStaffRequest], {
		default: false
	})

	return {
		onAddStaff,
		isLoading: isEditStaffMemberLoading
	}
}
