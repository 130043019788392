export const Routes = {
	Dashboard: {
		route: '/dashboard',
		group: null
	},
	Users: { route: '/users', group: null },
	Login: { route: '/login', group: null },
	UserRankingGroups: { route: '/acl/user-ranking-groups', group: 'acl' },
	Whitelist: { route: '/acl/whitelist', group: 'acl' },
	Blacklist: { route: '/acl/blacklist', group: 'acl' },
	UserRankingSystem: { route: '/user-ranking-system', group: null },
	RegularTags: { route: '/tags/regular', singleTagRoute: '/tags/regular/:id', group: 'tags' },
	GroupTags: { route: '/tags/group', singleTagRoute: '/tags/group/:id', group: 'tags' },
	Sessions: { route: '/sessions', group: null },
	Solutions: { route: '/solutions', group: null },
	Comments: { route: '/comments', group: null },
	Pages: {
		route: '/pages',
		singlePageRoute: '/pages/:pageId',
		singleModuleRoute: '/pages/:pageId/modules/:moduleId',
		group: null
	},
	StaffAccounting: {
		route: '/staff-accounting',
		singleUserRoute: '/staff-accounting/staff/:staffMemberId',
		group: null
	}
}
