import * as React from 'react'

export const ContentLayout1Illustration = (props: any) => (
	<svg viewBox="0 0 345 90" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g data-name="Group 2813">
			<g data-name="Group 2798">
				<g fill="#fff" stroke="#9195be" data-name="Rectangle 2613">
					<path stroke="none" d="M13 15h72v60H13z" />
					<path fill="none" d="M13.5 15.5h71v59h-71z" />
				</g>
				<g fill="#fff" stroke="#9195be" data-name="Rectangle 2614">
					<path stroke="none" d="M95 15h72v60H95z" />
					<path fill="none" d="M95.5 15.5h71v59h-71z" />
				</g>
				<g fill="#fff" stroke="#9195be" data-name="Rectangle 2615">
					<path stroke="none" d="M178 15h72v60h-72z" />
					<path fill="none" d="M178.5 15.5h71v59h-71z" />
				</g>
				<g fill="#fff" stroke="#9195be" data-name="Rectangle 2616">
					<path stroke="none" d="M260 15h72v60h-72z" />
					<path fill="none" d="M260.5 15.5h71v59h-71z" />
				</g>
				<g fill="none" stroke="#707070" data-name="Rectangle 2612">
					<path stroke="none" d="M0 0h345v90H0z" />
					<path d="M.5.5h344v89H.5z" />
				</g>
				<g transform="translate(17 18.9)" fill="#e1e1f0" data-name="Group 2793">
					<path data-name="Rectangle 2601" d="M-.292 10.053h64v6h-64z" />
					<path data-name="Rectangle 2602" d="M-.292 18.053h64v5h-64z" />
					<rect
						transform="translate(-.292 44.053)"
						width={20}
						height={6}
						rx={3}
						data-name="Rectangle 2604"
					/>
					<rect
						transform="translate(-.292 .053)"
						width={20}
						height={5}
						rx={2.5}
						data-name="Rectangle 2606"
					/>
					<path data-name="Rectangle 2607" d="M22.708.053h20v5h-20z" />
					<rect
						transform="translate(23.708 44.053)"
						width={20}
						height={6}
						rx={3}
						data-name="Rectangle 2605"
					/>
					<path data-name="Rectangle 2603" d="M-.292 25.053h64v5h-64z" />
				</g>
				<g transform="translate(100 18.9)" fill="#e1e1f0" data-name="Group 2794">
					<path data-name="Rectangle 2601" d="M.418 10.053h63v6h-63z" />
					<path data-name="Rectangle 2602" d="M.418 18.053h63v5h-63z" />
					<rect
						transform="translate(.418 44.053)"
						width={20}
						height={6}
						rx={3}
						data-name="Rectangle 2604"
					/>
					<rect
						transform="translate(.418 .053)"
						width={20}
						height={5}
						rx={2.5}
						data-name="Rectangle 2606"
					/>
					<path data-name="Rectangle 2607" d="M22.418.053h20v5h-20z" />
					<rect
						transform="translate(23.418 44.053)"
						width={20}
						height={6}
						rx={3}
						data-name="Rectangle 2605"
					/>
					<path data-name="Rectangle 2603" d="M.418 25.053h63v5h-63z" />
				</g>
				<g transform="translate(182 18.9)" fill="#e1e1f0" data-name="Group 2795">
					<path data-name="Rectangle 2601" d="M.127 10.053h63v6h-63z" />
					<path data-name="Rectangle 2602" d="M.127 18.053h63v5h-63z" />
					<rect
						transform="translate(.127 44.053)"
						width={20}
						height={6}
						rx={3}
						data-name="Rectangle 2604"
					/>
					<rect
						transform="translate(.127 .053)"
						width={20}
						height={5}
						rx={2.5}
						data-name="Rectangle 2606"
					/>
					<path data-name="Rectangle 2607" d="M22.127.053h20v5h-20z" />
					<rect
						transform="translate(23.127 44.053)"
						width={20}
						height={6}
						rx={3}
						data-name="Rectangle 2605"
					/>
					<path data-name="Rectangle 2603" d="M.127 25.053h63v5h-63z" />
				</g>
				<g transform="translate(264 18.9)" fill="#e1e1f0" data-name="Group 2796">
					<path data-name="Rectangle 2601" d="M-.163 10.053h64v6h-64z" />
					<path data-name="Rectangle 2602" d="M-.163 18.053h64v5h-64z" />
					<rect
						transform="translate(-.163 44.053)"
						width={20}
						height={6}
						rx={3}
						data-name="Rectangle 2604"
					/>
					<rect
						transform="translate(-.163 .053)"
						width={20}
						height={5}
						rx={2.5}
						data-name="Rectangle 2606"
					/>
					<path data-name="Rectangle 2607" d="M21.837.053h20v5h-20z" />
					<rect
						transform="translate(23.837 44.053)"
						width={20}
						height={6}
						rx={3}
						data-name="Rectangle 2605"
					/>
					<path data-name="Rectangle 2603" d="M-.163 25.053h64v5h-64z" />
				</g>
				<text
					transform="translate(68 67.5)"
					fill="#33385a"
					fontFamily="Helvetica"
					fontSize={15}
					letterSpacing=".1em"
					stroke="rgba(0,0,0,0)"
					data-name={1}
				>
					<tspan x={0} y={0}>
						{'1'}
					</tspan>
				</text>
				<text
					transform="translate(155 67)"
					fill="#33385a"
					fontFamily="Helvetica"
					fontSize={15}
					letterSpacing=".1em"
					stroke="rgba(0,0,0,0)"
					data-name={2}
				>
					<tspan x={0} y={0}>
						{'2'}
					</tspan>
				</text>
				<text
					transform="translate(236 67)"
					fill="#33385a"
					fontFamily="Helvetica"
					fontSize={15}
					letterSpacing=".1em"
					stroke="rgba(0,0,0,0)"
					data-name={3}
				>
					<tspan x={0} y={0}>
						{'3'}
					</tspan>
				</text>
				<text
					transform="translate(319 67)"
					fill="#33385a"
					fontFamily="Helvetica"
					fontSize={15}
					letterSpacing=".1em"
					stroke="rgba(0,0,0,0)"
					data-name={4}
				>
					<tspan x={0} y={0}>
						{'4'}
					</tspan>
				</text>
			</g>
		</g>
	</svg>
)
