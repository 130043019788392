import * as React from 'react'
import { ISolution } from 'brainstorming-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Modal, Button, Input } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import TextArea from 'antd/lib/input/TextArea'
interface ILocalProps {
	solution: ISolution | null
	isEditingSolution: boolean
	onConfirm(data: any): void
	onCancel(): void
}

interface IFormProps extends FormComponentProps<any> {}

interface IProps extends ILocalProps, IFormProps {}

const EditSolution: React.FC<IProps> = ({
	solution,
	onConfirm,
	onCancel,
	isEditingSolution,
	form
}) => {
	const handleSubmit = () => {
		onConfirm({ text: form.getFieldValue('text'), title: form.getFieldValue('title') })
	}
	if (!solution) {
		return null
	}
	return (
		<Modal
			title="Edit solution"
			visible={true}
			onOk={onConfirm}
			onCancel={onCancel}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					loading={isEditingSolution}
					onClick={handleSubmit}
					disabled={!!form.getFieldError('text')}
				>
					Confirm
				</Button>
			]}
		>
			<Form layout="vertical">
				<Form.Item label="Title">
					{form.getFieldDecorator('title', {
						rules: [{ required: true, message: 'Please input the title of solution!' }],
						initialValue: solution.title
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Content">
					{form.getFieldDecorator('text', {
						rules: [{ required: true, message: 'Please input the content of solution!' }],
						initialValue: solution.text // quick fix
					})(<TextArea />)}
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default Form.create<IProps>({
	name: 'editSolution'
})(EditSolution)
