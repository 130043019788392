import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { editStaffMemberRequest } from '../store/actions'
import { IEditStaffMember, StaffActionType } from '../store/types'

interface IUseEditStaffMemberResult {
	onEditStaffMember: (data: IEditStaffMember) => any
	isLoading: boolean
}

export const useEditStaffMember = (staffMemberId: number): IUseEditStaffMemberResult => {
	const dispatch = useDispatch()

	const onEditStaffMember = useCallback(
		(data: IEditStaffMember) => dispatch(editStaffMemberRequest(staffMemberId, data)),
		[dispatch, staffMemberId]
	)

	const loaders = useSelector(getLoadersState)
	const isEditStaffMemberLoading = isLoading(loaders, [StaffActionType.EditStaffMemberRequest], {
		default: false
	})

	return {
		onEditStaffMember,
		isLoading: isEditStaffMemberLoading
	}
}
