import * as React from 'react'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Typography, Input, Button } from 'antd'
import styled from 'styled-components'

import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ILoginRequest } from 'brainstorming-types'

const LoginWrapper = styled.div`
	text-align: center;
`

interface ILoginProps extends ILoginRequest, FormComponentProps<ILoginRequest> {}

interface ILocalProps {
	isSubmitting: boolean
	login(data: ILoginRequest): void
}

interface IProps extends ILocalProps, ILoginProps {}

const { Title } = Typography

const LoginForm: React.FC<IProps> = (props: IProps) => {
	const { login, isSubmitting, form } = props
	const { getFieldDecorator } = form

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		form.validateFields((err, values) => {
			if (!err) {
				login(values as ILoginRequest)
			}
		})
	}

	return (
		<LoginWrapper>
			<Title level={2}>Brainstorming Admin</Title>
			<Form onSubmit={handleSubmit} className="login-form" layout="vertical" name="login">
				<Form.Item>
					{getFieldDecorator('email', {
						rules: [
							{ required: true, message: 'Please input your email!' },
							{
								type: 'email',
								message: 'The input is not valid E-mail!'
							}
						],
						validateTrigger: 'onBlur'
					})(
						<Input
							prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
							placeholder="Email"
						/>
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator('password', {
						rules: [
							{ required: true, message: 'Please input your Password!' },
							{ min: 6, message: 'Require 6 characters at least!' }
						],
						validateTrigger: 'onBlur'
					})(
						<Input
							prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
							type="password"
							placeholder="Password"
						/>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="login-form-button"
						shape="round"
						block={true}
						loading={isSubmitting}
					>
						Log in
					</Button>
				</Form.Item>
			</Form>
		</LoginWrapper>
	)
}

const LoginFormWrapper = Form.create<any>({ name: 'login' })(LoginForm)

export default LoginFormWrapper
