import * as React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Modal, notification, Select, Spin } from 'antd'
// import { SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import { IStaffMember, IUser } from 'brainstorming-types'

import { IAddStaff } from '../../store/types'
import { quickSearchUsers } from '../../store/api'
import { SPACES } from '../../../../styles/spaces'

// fix for bad antd types
const UserSelect = (Select as unknown) as React.FC<any>

const DEBOUNCE_INTERVAL = 800

const StyledDiv = styled.div`
	width: max-content;
	margin-left: auto;
	margin-bottom: ${SPACES.EXTRA_SMALL};
`

export interface IAddStaffButtonProps {
	onAddStaff: (data: IAddStaff) => any
	isAddStaffLoading: boolean
	activeStaff: IStaffMember[]
}

interface IUserOption {
	label: string
	key: number | string
	value: number | string
}

const fetchUserOptions = async (query: string): Promise<IUserOption[]> => {
	const { response } = (await quickSearchUsers(query)) as any

	if (response) {
		return response.map(({ fullName, id, email }: IUser) => ({
			label: `${fullName}, ${email}`,
			key: id,
			value: id
		}))
	} else {
		notification.error({ message: `Error occurred, please try again` })
		return []
	}
}

export const AddStaffButton: React.FC<IAddStaffButtonProps> = ({
	onAddStaff,
	isAddStaffLoading,
	activeStaff
}) => {
	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const [isConfirmationModalVisible, setIsConfirmationModalVisible] = React.useState(false)
	const preselectedOptions = React.useMemo(
		() =>
			activeStaff.map(({ user }) => ({
				label: user?.fullName as string,
				key: user?.id as number,
				value: user?.id as number
			})),
		[activeStaff]
	)
	const [selectedOptions, setSelectedOptions] = React.useState<IUserOption[]>([])
	const [options, setOptions] = React.useState<IUserOption[]>([])

	const [fetching, setFetching] = React.useState(false)
	const fetchRef = React.useRef(0)

	const debounceFetcher = React.useMemo(() => {
		const loadOptions = (value: string) => {
			if (!value) {
				setOptions([])
				return
			}

			fetchRef.current += 1
			const fetchId = fetchRef.current
			setOptions([])
			setFetching(true)

			fetchUserOptions(value).then(newOptions => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return
				}

				setOptions(newOptions)
				setFetching(false)
			})
		}

		return debounce(loadOptions, DEBOUNCE_INTERVAL)
	}, [setOptions, setFetching])

	const onOk = () => setIsConfirmationModalVisible(true)
	const onCancel = () => {
		setIsModalVisible(false)
		setSelectedOptions([])
	}

	const onConfirmOk = async () => {
		await onAddStaff({ userIds: selectedOptions.map(option => parseInt(option.key as string, 10)) })
		setOptions([])
		setIsConfirmationModalVisible(false)
		setIsModalVisible(false)
		setSelectedOptions([])
	}
	const onConfirmCancel = () => setIsConfirmationModalVisible(false)

	const hasChanged = selectedOptions.length > 0

	return (
		<StyledDiv>
			<Button type="primary" onClick={() => setIsModalVisible(true)}>
				+ Add new accounts
			</Button>
			<Modal
				title="Add new accounts"
				visible={isModalVisible}
				onOk={onOk}
				confirmLoading={isAddStaffLoading}
				onCancel={onCancel}
				okText="Add users"
				okButtonProps={{
					disabled: !hasChanged
				}}
			>
				<Form>
					<Form.Item>
						<UserSelect
							mode="multiple"
							value={selectedOptions}
							placeholder="Type to search users"
							onChange={setSelectedOptions}
							style={{ width: '100%' }}
							labelInValue={true}
							filterOption={false}
							onSearch={debounceFetcher}
							notFoundContent={fetching ? <Spin size="small" /> : null}
						>
							{/* Remove users who are already paid from search results. */}
							{options
								.filter(
									option =>
										!preselectedOptions.find(
											preselectedOption => option.key === preselectedOption.key
										)
								)
								.map(option => (
									<Select.Option value={option.value} key={option.key}>
										{option.label}
									</Select.Option>
								))}
						</UserSelect>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				title="Add new paid account"
				visible={isConfirmationModalVisible}
				onOk={onConfirmOk}
				confirmLoading={isAddStaffLoading}
				onCancel={onConfirmCancel}
				okText="Add new accounts"
			>
				<p>Are you sure you want to add new paid accounts?</p>
			</Modal>
		</StyledDiv>
	)
}
