import axios from 'axios'
import { ILoginRequest } from 'brainstorming-types'
import { USER_MANAGEMENT } from '../../../config/urls'
import { errorHandler } from '../../../utils/apiErrorHandler'

export const sendUserCredentials = (payload: ILoginRequest) => {
	return axios
		.post(`${USER_MANAGEMENT}/auth/admin/login`, payload)
		.then(response => ({ response: response.data }))
		.catch(errorHandler)
}

export const getProfile = () => {
	return axios
		.get(`${USER_MANAGEMENT}/users/me`)
		.then(response => ({ response: response.data }))
		.catch(errorHandler)
}

export const refreshToken = () => {
	return axios
		.post(`${USER_MANAGEMENT}/auth/refresh`)
		.then(response => ({ response: response.data }))
		.catch(errorHandler)
}
