export enum UIActionType {
	TOGGLE_SIDEBAR_MENU = '@ui/TOGGLE_SIDEBAR_MENU'
}

export interface IUI {
	sidebarOpen: boolean
}

export interface IToggleSidebarMenu {
	type: UIActionType.TOGGLE_SIDEBAR_MENU
	payload: boolean
}

export type UIActions = IToggleSidebarMenu
