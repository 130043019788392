import { call, put, takeEvery, all } from 'redux-saga/effects'
import { SolutionsActionType } from './types'
import { AnyAction } from 'redux'
import { fetchSolutions, deleteSolution, editSolution, restoreSolution } from './api'
import {
	fetchSolutionsSuccess,
	fetchSolutionsFailure,
	deleteSolutionSuccess,
	deleteSolutionFailure,
	editSolutionSuccess,
	editSolutionFailure,
	restoreSolutionSuccess,
	restoreSolutionFailure
} from './actions'
import { notification } from 'antd'

// Handlers

function* handleFetchSolutions(action: AnyAction) {
	const { response, error } = yield call(fetchSolutions, action.payload)
	if (response) {
		yield put(fetchSolutionsSuccess(response))
	} else {
		yield put(fetchSolutionsFailure(error))
	}
}

function* handleDeleteSolution(action: AnyAction) {
	const { response, error } = yield call(deleteSolution, action.payload)
	if (response) {
		yield put(deleteSolutionSuccess(action.payload))
		notification.success({ message: `Solution successfully deleted!` })
	} else {
		yield put(deleteSolutionFailure(error))
		notification.error({ message: `Error happened. Solution is not edited!` })
	}
}

function* handleEditSolution(action: AnyAction) {
	const { response, error } = yield call(
		editSolution,
		action.payload.solutionId,
		action.payload.data
	)
	if (response) {
		yield put(editSolutionSuccess(response))
		notification.success({ message: `Solution successfully edited!` })
	} else {
		yield put(editSolutionFailure(error))
		notification.error({ message: `Error happened. Solution is not edited!` })
	}
}

function* handleRestoreSolution(action: AnyAction) {
	const { response, error } = yield call(restoreSolution, action.payload)
	if (response) {
		yield put(restoreSolutionSuccess(response))
		notification.success({ message: `Solution successfully restored!` })
	} else {
		yield put(restoreSolutionFailure(error))
		notification.error({ message: `Error happened. Solution is not restored!` })
	}
}

// Watchers
function* watchFetchSolutions() {
	yield takeEvery(SolutionsActionType.FetchSolutionsRequest, handleFetchSolutions)
}

function* watchDeleteSolution() {
	yield takeEvery(SolutionsActionType.DeleteSolutionRequest, handleDeleteSolution)
}

function* watchEditSolution() {
	yield takeEvery(SolutionsActionType.EditSolutionRequest, handleEditSolution)
}

function* watchRestoreSolution() {
	yield takeEvery(SolutionsActionType.RestoreSolutionRequest, handleRestoreSolution)
}

export default function* rootSaga() {
	yield all([
		watchFetchSolutions(),
		watchDeleteSolution(),
		watchEditSolution(),
		watchRestoreSolution()
	])
}
