import { call, put, takeEvery, all } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { notification } from 'antd'

import { PagesActionType } from './types'
import {
	fetchPages,
	fetchPage,
	createPage,
	editPage,
	editPages,
	editPageModuleOrder,
	deletePage,
	createModuleForPage,
	editPageModule,
	deletePageModule
} from './api'
import {
	fetchPagesSuccess,
	fetchPagesFailure,
	fetchPageSuccess,
	fetchPageFailure,
	createPageSuccess,
	createPageFailure,
	editPageSuccess,
	editPageFailure,
	createModuleForPageSuccess,
	createModuleForPageFailure,
	editPageModuleSuccess,
	editPageModuleFailure,
	editPageModuleOrderSuccess,
	editPageModuleOrderFailure,
	editPagesSuccess,
	editPagesFailure,
	deletePageSuccess,
	deletePageFailure,
	deletePageModuleSuccess,
	deletePageModuleFailure
} from './actions'

const SUCCESS_MSG = 'Saved!'
const ERROR_MSG = 'Error occurred, please try again'

function* handleFetchPages(action: AnyAction) {
	const { response, error } = yield call(fetchPages)
	if (response) {
		yield put(fetchPagesSuccess(response))
	} else {
		yield put(fetchPagesFailure(error))
	}
}

function* handleFetchPage({ payload: { pageId } }: AnyAction) {
	const { response, error } = yield call(fetchPage, pageId)
	if (response) {
		yield put(fetchPageSuccess(response))
	} else {
		yield put(fetchPageFailure(error))
	}
}

function* handleCreatePage(action: AnyAction) {
	const { response, error } = yield call(createPage, action.payload)
	if (response) {
		yield put(createPageSuccess(response))
		notification.success({ message: SUCCESS_MSG })
	} else {
		yield put(createPageFailure(error))
		notification.error({ message: ERROR_MSG })
	}
}

function* handleEditPage({ payload: { pageId, data } }: AnyAction) {
	const { response, error } = yield call(editPage, pageId, data)
	if (response) {
		yield put(editPageSuccess(response))
		notification.success({ message: SUCCESS_MSG })
	} else {
		yield put(editPageFailure(error))
		notification.error({ message: ERROR_MSG })
	}
}

function* handleEditPages({ payload: { data } }: AnyAction) {
	const { response, error } = yield call(editPages, data)
	if (response) {
		yield put(editPagesSuccess(response))
		notification.success({ message: SUCCESS_MSG })
	} else {
		yield put(editPagesFailure(error))
		notification.error({ message: ERROR_MSG })
	}
}

function* handleEditPageModuleOrder({ payload: { pageId, data } }: AnyAction) {
	const { response, error } = yield call(editPageModuleOrder, pageId, data)
	if (response) {
		yield put(editPageModuleOrderSuccess(response))
		notification.success({ message: SUCCESS_MSG })
	} else {
		yield put(editPageModuleOrderFailure(error))
		notification.error({ message: ERROR_MSG })
	}
}

function* handleDeletePage({ payload: { pageId } }: AnyAction) {
	const { response, error } = yield call(deletePage, pageId)
	if (response) {
		yield put(deletePageSuccess(response))
		notification.success({ message: 'Deleted' })
	} else {
		yield put(deletePageFailure(error))
		notification.error({ message: ERROR_MSG })
	}
}

function* handleCreateModuleForPage({ payload: { pageId, data } }: AnyAction) {
	const { response, error } = yield call(createModuleForPage, pageId, data)
	if (response) {
		yield put(createModuleForPageSuccess(response))
		notification.success({ message: SUCCESS_MSG })
	} else {
		yield put(createModuleForPageFailure(error))
		notification.error({ message: ERROR_MSG })
	}
}

function* handleEditPageModule({ payload: { pageModuleId, data } }: AnyAction) {
	const { response, error } = yield call(editPageModule, pageModuleId, data)
	if (response) {
		yield put(editPageModuleSuccess(response))
		notification.success({ message: SUCCESS_MSG })
	} else {
		yield put(editPageModuleFailure(error))
		notification.error({ message: ERROR_MSG })
	}
}

function* handleDeletePageModule({ payload: { pageModuleId } }: AnyAction) {
	const { response, error } = yield call(deletePageModule, pageModuleId)
	if (response) {
		yield put(deletePageModuleSuccess(response))
		notification.success({ message: SUCCESS_MSG })
	} else {
		yield put(deletePageModuleFailure(error))
		notification.error({ message: ERROR_MSG })
	}
}

// Watchers
function* watchFetchPages() {
	yield takeEvery(PagesActionType.FetchPagesRequest, handleFetchPages)
}

function* watchFetchPage() {
	yield takeEvery(PagesActionType.FetchPageRequest, handleFetchPage)
}

function* watchCreatePage() {
	yield takeEvery(PagesActionType.CreatePageRequest, handleCreatePage)
}

function* watchEditPage() {
	yield takeEvery(PagesActionType.EditPageRequest, handleEditPage)
}

function* watchEditPages() {
	yield takeEvery(PagesActionType.EditPagesRequest, handleEditPages)
}

function* watchEditPageModuleOrder() {
	yield takeEvery(PagesActionType.EditPageModuleOrderRequest, handleEditPageModuleOrder)
}

function* watchDeletePage() {
	yield takeEvery(PagesActionType.DeletePageRequest, handleDeletePage)
}

function* watchCreateModuleForPage() {
	yield takeEvery(PagesActionType.CreateModuleForPageRequest, handleCreateModuleForPage)
}

function* watchEditPageModule() {
	yield takeEvery(PagesActionType.EditPageModuleRequest, handleEditPageModule)
}

function* watchDeletePageModule() {
	yield takeEvery(PagesActionType.DeletePageModuleRequest, handleDeletePageModule)
}

export default function* rootSaga() {
	yield all([
		watchFetchPages(),
		watchFetchPage(),
		watchCreatePage(),
		watchEditPage(),
		watchEditPages(),
		watchDeletePage(),
		watchEditPageModuleOrder(),
		watchCreateModuleForPage(),
		watchEditPageModule(),
		watchDeletePageModule()
	])
}
