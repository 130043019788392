import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ITag } from 'brainstorming-types'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { fetchSynonymsRequest } from '../store/actions'
import { getTagSynonyms } from '../store/selectors'
import { TagsActionTypes } from '../store/action.types'

interface IUseSynonymsResult {
	synonyms: ITag[]
	isLoading: boolean
}

export const useSynonyms = (tagId: number): IUseSynonymsResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchSynonymsRequest(tagId))
	}, [dispatch, tagId])

	const synonyms = useSelector(getTagSynonyms(tagId))
	const loaders = useSelector(getLoadersState)
	const isLoadingSynonyms = isLoading(loaders, [TagsActionTypes.FetchSynonymsRequest])

	return {
		synonyms,
		isLoading: isLoadingSynonyms
	}
}
