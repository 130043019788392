import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
	fetchGroups,
	fetchPermissions,
	deleteGroup,
	createGroup,
	updatePermissionsGroup,
	updateGroup,
	updateUsersGroup
} from './api'
import {
	AclActionTypes,
	IFetchGroupRequest,
	IDeleteGroupRequest,
	ICreateNewUserRankGroup,
	IUpdatePermissionsGroupRequest as IUpdatePermissionsGroupRequestAction,
	IUpdateUsersGroupRequest as IUpdateUsersGroupRequestAction,
	IUpdateUserRankGroup,
	IEditGroupRequest,
	ICreateWhiteBlackListGroup,
	IUpdateWhiteBlackListGroup
} from './actions.types'
import {
	fetchGroupsFailure,
	fetchGroupsSuccess,
	fetchPermissionsSuccess,
	fetchPermissionsFailure,
	deleteGroupSuccess,
	deleteGroupFailure,
	createGroupSuccess,
	createGroupFailure,
	updatePermissionsGroupSuccess,
	updatePermissionsGroupFailure,
	updatePermissionsGroupRequest,
	editGroupRequest,
	editGroupSuccess,
	editGroupFailure,
	updateUsersGroupSuccess,
	updateUsersGroupFailure,
	updateUsersGroupRequest
} from './actions'
import { ICreateGroupRequest } from 'brainstorming-types'
import { notification } from 'antd'

// Handlers
function* handleFetchGroups(action: IFetchGroupRequest) {
	const { response, error } = yield call(fetchGroups, action.payload)
	if (response) {
		yield put(fetchGroupsSuccess(response))
	} else {
		yield put(fetchGroupsFailure(error))
	}
}

function* handleFetchPermissions(action: IFetchGroupRequest) {
	const { response, error } = yield call(fetchPermissions, action.payload)
	if (response) {
		yield put(fetchPermissionsSuccess(response))
	} else {
		yield put(fetchPermissionsFailure(error))
	}
}

function* handleDeleteGroup(action: IDeleteGroupRequest) {
	const { error } = yield call(deleteGroup, action.payload)
	if (error) {
		yield put(deleteGroupFailure(error))
		notification.error({ message: `Error happened. Group is not deleted!` })
	} else {
		yield put(deleteGroupSuccess(action.payload))
		notification.success({ message: 'Group successfully deleted!' })
	}
}

function* handleUpdatePermissionsGroup(action: IUpdatePermissionsGroupRequestAction) {
	const { response, error } = yield call(updatePermissionsGroup, action.payload)
	if (response) {
		yield put(updatePermissionsGroupSuccess(response))
	} else {
		yield put(updatePermissionsGroupFailure(error))
	}
}

function* handleCreateNewUserRankGroup(action: ICreateNewUserRankGroup) {
	const groupData: ICreateGroupRequest = {
		name: action.payload.name,
		minPoints: action.payload.minPoints,
		maxPoints: action.payload.maxPoints,
		type: action.payload.type!
	}
	const { response, error } = yield call(createGroup, groupData)
	if (response) {
		yield put(
			updatePermissionsGroupRequest({
				permissions: action.payload.permissions,
				groupId: response.id
			})
		)
		yield put(createGroupSuccess(response))
		notification.success({ message: `Group "${response.name}" is successfully created!` })
	} else {
		yield put(createGroupFailure(error))
		notification.error({ message: 'Error happened. Group is not created!' })
	}
}

function* handleUpdateGroup(action: IEditGroupRequest) {
	const { response, error } = yield call(updateGroup, action.payload.id, action.payload.data)
	if (response) {
		yield put(editGroupSuccess(response))
		notification.success({ message: `Group "${response.name}" successfully updated!` })
	} else {
		yield put(editGroupFailure(error))
		notification.error({ message: 'Error happened. Group is not updated!' })
	}
}

function* handleUpdateUserRankGroup(action: IUpdateUserRankGroup) {
	const groupData: ICreateGroupRequest = {
		name: action.payload.data.name,
		minPoints: action.payload.data.minPoints,
		maxPoints: action.payload.data.maxPoints,
		type: action.payload.data.type!
	}
	yield put(
		updatePermissionsGroupRequest({
			permissions: action.payload.data.permissions,
			groupId: action.payload.id
		})
	)
	yield put(
		editGroupRequest({
			data: groupData,
			id: action.payload.id
		})
	)
}

function* handleCreateWhiteBlackListGroup(action: ICreateWhiteBlackListGroup) {
	const groupData: ICreateGroupRequest = {
		name: action.payload.name,
		type: action.payload.type!
	}
	const { response, error } = yield call(createGroup, groupData)
	if (response) {
		yield put(
			updatePermissionsGroupRequest({
				permissions: action.payload.permissions,
				groupId: response.id
			})
		)
		yield put(
			updateUsersGroupRequest({
				users: action.payload.users,
				groupId: response.id
			})
		)
		yield put(createGroupSuccess(response))
		notification.success({ message: `Group "${response.name}" successfully created!` })
	} else {
		yield put(createGroupFailure(error))
		notification.error({ message: 'Error happened. Group is not updated!' })
	}
}

function* handleUpdateWhiteBlackListGroup(action: IUpdateWhiteBlackListGroup) {
	const groupData: ICreateGroupRequest = {
		name: action.payload.data.name,
		type: action.payload.data.type!
	}
	yield put(
		updatePermissionsGroupRequest({
			permissions: action.payload.data.permissions,
			groupId: action.payload.id
		})
	)
	yield put(
		updateUsersGroupRequest({
			users: action.payload.data.users,
			groupId: action.payload.id
		})
	)
	yield put(
		editGroupRequest({
			data: groupData,
			id: action.payload.id
		})
	)
}

function* handleUpdateUsersGroup(action: IUpdateUsersGroupRequestAction) {
	const { response, error } = yield call(updateUsersGroup, action.payload)
	if (response) {
		yield put(updateUsersGroupSuccess(response))
	} else {
		yield put(updateUsersGroupFailure(error))
	}
}

// Watchers
function* watchFetchGroups() {
	yield takeEvery(AclActionTypes.FetchGroupRequest, handleFetchGroups)
}

function* watchFetchPermissions() {
	yield takeEvery(AclActionTypes.FetchPermissionRequest, handleFetchPermissions)
}

function* watchDeleteGroup() {
	yield takeEvery(AclActionTypes.DeleteGroupRequest, handleDeleteGroup)
}

function* watchCreateUserRankGroup() {
	yield takeEvery(AclActionTypes.CreateNewUserRankGroup, handleCreateNewUserRankGroup)
}

function* watchUpdateUserRankGroup() {
	yield takeEvery(AclActionTypes.UpdateUserRankGroup, handleUpdateUserRankGroup)
}

function* watchUpdatePermissionsGroup() {
	yield takeEvery(AclActionTypes.UpdatePermissionsGroupRequest, handleUpdatePermissionsGroup)
}

function* watchUpdateGroup() {
	yield takeEvery(AclActionTypes.EditGroupRequest, handleUpdateGroup)
}

function* watchCreateWhiteBlackListGroup() {
	yield takeEvery(AclActionTypes.CreateWhiteBlackListGroup, handleCreateWhiteBlackListGroup)
}

function* watchUpdateWhiteBlackListGroup() {
	yield takeEvery(AclActionTypes.UpdateWhiteBlackListGroup, handleUpdateWhiteBlackListGroup)
}

function* watchUpdateUsersGroup() {
	yield takeEvery(AclActionTypes.UpdateUsersGroupRequest, handleUpdateUsersGroup)
}

export default function* rootSaga() {
	yield all([
		watchFetchGroups(),
		watchFetchPermissions(),
		watchDeleteGroup(),
		watchCreateUserRankGroup(),
		watchUpdatePermissionsGroup(),
		watchUpdateUserRankGroup(),
		watchUpdateGroup(),
		watchCreateWhiteBlackListGroup(),
		watchUpdateWhiteBlackListGroup(),
		watchUpdateUsersGroup()
	])
}
