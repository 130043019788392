import produce from 'immer'
import { AnyAction } from 'redux'
import { ISolutions, SolutionsActionType } from './types'
import { defaultState } from './constants'
import { arrayToObjectById } from '../../../utils/arrayToObjectById'
import { ISolution } from 'brainstorming-types'
import moment from 'moment'

export const solutionsReducer = produce(
	(state: ISolutions = defaultState, action: AnyAction): ISolutions => {
		switch (action.type) {
			case SolutionsActionType.FetchSolutionsSuccess: {
				state.byId = { ...state.byId, ...arrayToObjectById(action.payload.items) }
				state.ids = action.payload.items.map((item: ISolution) => item.id)
				state.total = action.payload.total
				state.skip = action.payload.skip
				return state
			}
			case SolutionsActionType.EditSolutionSuccess: {
				state.byId[action.payload.id] = { ...state.byId[action.payload.id], ...action.payload }
				return state
			}

			case SolutionsActionType.RestoreSolutionSuccess: {
				state.byId[action.payload.id].deletedAt = action.payload.deletedAt
				return state
			}

			case SolutionsActionType.DeleteSolutionSuccess: {
				state.byId[action.payload].deletedAt = moment().toString()
				return state
			}
			default:
				return state
		}
	}
)
