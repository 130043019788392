import produce from 'immer'
import { IUsers } from '../../users/store/models.types'
import { defaultUsers } from '../../users/constants'
import { UsersActions, UsersActionTypes } from '../../users/store/actions.types'

export const usersReducer = produce((state: IUsers = defaultUsers, action: UsersActions) => {
	switch (action.type) {
		case UsersActionTypes.FetchUserSuccess:
			state.users = action.payload
			return state
		case UsersActionTypes.FetchUserActionsSuccess:
			state.actions = action.payload
			return state
		case UsersActionTypes.UpdateUserActionSuccess:
			state.actions = state.actions.map(userAction => {
				if (userAction.id === action.payload.id) {
					return action.payload
				}
				return userAction
			})
			return state
		default:
			return state
	}
})
