import { combineReducers } from 'redux'

import { loadersReducer } from './common/loaders/reducer'
import { uiReducer } from './common/ui/reducer'
import { errorsReducer } from './common/errors/reducer'
import { IApplicationState } from './types'
import { authReducer } from '../features/auth/store/reducer'
import { aclReducer } from '../features/acl/store/reducer'
import { usersReducer } from '../features/users/store/reducer'
import { tagsReducer } from '../features/tags/store/reducer'
import { sessionsReducer } from '../features/sessions/store/reducer'
import { solutionsReducer } from '../features/solutions/store/reducer'
import { commentsReducer } from '../features/comments/store/reducer'
import { staffPaymentsReducer, staffReducer } from '../features/staff-accounting/store/reducer'
import { pagesReducer } from '../features/pages/store/reducer'

const rootReducer = combineReducers<IApplicationState>({
	auth: authReducer,
	ui: uiReducer,
	loaders: loadersReducer,
	errors: errorsReducer,
	acl: aclReducer,
	users: usersReducer,
	tags: tagsReducer,
	sessions: sessionsReducer,
	solutions: solutionsReducer,
	comments: commentsReducer,
	staff: staffReducer,
	staffPayments: staffPaymentsReducer,
	pages: pagesReducer
})

export default rootReducer
