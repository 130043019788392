import { IPage } from 'brainstorming-types'
import { Input, Modal, Checkbox, Form } from 'antd'
import * as React from 'react'
import { useState } from 'react'
import slugify from 'slugify'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { ICreatePage } from '../../store/types'

interface IPageDetailsModalProps {
	isVisible: boolean
	onSubmit: (data: ICreatePage) => unknown
	onCancel: () => void
	isLoading: boolean
	page?: IPage
}

export const PageDetailsModal: React.FC<IPageDetailsModalProps> = ({
	page,
	isVisible,
	onSubmit,
	onCancel,
	isLoading
}) => {
	const [form] = Form.useForm()

	const isCreate = !page

	// Preserve the slug if we're editing the page.
	// Otherwise, create it as the name is typed.
	const [isGeneratedSlug, setIsGeneratedSlug] = useState<boolean>(isCreate)

	const handleNameChange = (newName: string) => {
		form.setFieldsValue({
			name: newName
		})
		if (isGeneratedSlug) {
			const newSlug = slugify(newName, {
				lower: true
			})
			form.setFieldsValue({ slug: newSlug })
		}
	}

	const onSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		form.setFieldsValue({
			slug: event.target.value
		})
	}

	const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleNameChange(event.target.value)
	}

	const onIsGeneratedSlugChange = (event: CheckboxChangeEvent) => {
		setIsGeneratedSlug(event.target.checked)
	}

	const resetForm = React.useCallback(() => {
		form.setFieldsValue(isCreate ? { name: '', slug: '' } : { name: page.name, slug: page.slug })
	}, [form, isCreate, page])

	React.useEffect(() => {
		resetForm()
	}, [page, resetForm])

	return (
		<Modal
			title={isCreate ? 'Create new page' : 'Edit page details'}
			visible={isVisible}
			onCancel={() => {
				resetForm()
				onCancel()
			}}
			okText="Save"
			confirmLoading={isLoading}
			onOk={async event => {
				event.preventDefault()
				try {
					await form.validateFields()
				} catch (e) {
					return
				}
				form.submit()
			}}
		>
			<Form
				form={form}
				onFinish={values => {
					onSubmit(values)
					resetForm()
				}}
				layout="vertical"
			>
				<Form.Item
					label="Name:"
					name="name"
					required={true}
					rules={[{ required: true, message: 'Name is required' }]}
					shouldUpdate={(prev, curr) => prev.name !== curr.name}
				>
					<Input name="name" onChange={onNameChange} placeholder={'Name'} />
				</Form.Item>
				<Form.Item
					label="Slug:"
					name="slug"
					required={true}
					rules={[{ required: true, message: 'Slug is required' }]}
					shouldUpdate={(prev, curr) => prev.slug !== curr.slug}
				>
					<Input
						name="slug"
						onChange={onSlugChange}
						placeholder={'Slug'}
						disabled={isGeneratedSlug}
					/>
				</Form.Item>
				<Form.Item label="Auto generated slug:" required={false}>
					<Checkbox onChange={onIsGeneratedSlugChange} checked={isGeneratedSlug} />
				</Form.Item>
			</Form>
		</Modal>
	)
}
