import * as React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Table, InputNumber } from 'antd'
import UserRankingSystemHeaderButtons from './UserRankingSystemHeaderButtons'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { IUpdateUserActionRequest, IUserAction } from 'brainstorming-types'
import TableWrapper from '../../../components/TableWrapper'

interface ILocalProps {
	userActions: IUserAction[]
	onSave(data: IUserRankSystemFormInternal): void
	handleCancel(): void
}

interface IFormProps extends FormComponentProps<IUserRankSystemFormInternal> {}

interface IProps extends ILocalProps, IFormProps {}

export interface IUserRankSystemFormInternal {
	userActions: IUserAction[]
}

const UserRankingSystemForm: React.FC<IProps> = ({ form, userActions, onSave }) => {
	const { getFieldDecorator } = form

	const handleOnCancel = () => {
		form.resetFields(['userActions'])
	}

	const handleOnSave = () => {
		form.validateFields(['userActions'], (errors, values) => {
			if (!errors) {
				onSave(values)
			}
		})
	}

	const handleOnChange = (value: number, id: number) => {
		const newValues = form.getFieldValue('userActions').map((v: any) => {
			if (v.id === id) {
				return { ...v, points: value }
			}
			return v
		})
		form.setFieldsValue({
			userActions: newValues
		})
	}

	return (
		<Form onSubmit={handleOnSave}>
			<UserRankingSystemHeaderButtons onCancel={handleOnCancel} onSave={handleOnSave} />
			<Form.Item>
				{getFieldDecorator('userActions', {
					initialValue: userActions,
					trigger: 'none'
				})(
					<TableWrapper>
						<Table
							columns={[
								{
									title: 'Name',
									dataIndex: 'name',
									key: 'name'
								},
								{
									title: 'Points',
									dataIndex: 'points',
									key: 'id',
									render: (text, record: any) => {
										return (
											<InputNumber
												onChange={(value: any) => handleOnChange(value, record.id)}
												name={record.id}
												value={text}
											/>
										)
									}
								}
							]}
							dataSource={
								form.getFieldValue('userActions')
									? form.getFieldValue('userActions')!.map((action: IUpdateUserActionRequest) => ({
											...action,
											key: action.id
									  }))
									: []
							}
							pagination={{
								hideOnSinglePage: true
							}}
						/>
					</TableWrapper>
				)}
			</Form.Item>
		</Form>
	)
}

export default Form.create<IProps>({
	name: 'userRankingSystemForm'
})(UserRankingSystemForm)
