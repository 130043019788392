import * as React from 'react'
import styled from 'styled-components'
import { Checkbox, Modal, Button } from 'antd'
import { IPermission } from 'brainstorming-types'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

interface IProps {
	allPermissions: IPermission[]
	activePermissions: number[]
	onAddPermission(permissions: number[]): void
}

const CheckboxPermissions = styled(Checkbox.Group)`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	label {
		width: 33%;
		margin: 0 0 16px 0;
		padding: 0;
	}
`

const AddPermissionFormModal: React.FC<IProps> = ({
	allPermissions,
	activePermissions,
	onAddPermission
}) => {
	const [open, setOpen] = React.useState<boolean>(false)
	const [values, setValues] = React.useState<number[]>([])

	const handleAddPermissions = () => {
		onAddPermission(values)
		setOpen(false)
	}

	const handleOpen = () => {
		setValues(activePermissions)
		setOpen(true)
	}

	const handleChange = (checkedValue: CheckboxValueType[]) => {
		setValues(checkedValue as number[])
	}

	return (
		<div>
			<Button type="link" onClick={handleOpen}>
				+ Add new permissions
			</Button>
			<Modal
				title="Add new permission"
				visible={open}
				onOk={handleAddPermissions}
				onCancel={() => setOpen(false)}
				okText="Add permissions"
				cancelText="Cancel"
				width={920}
			>
				<CheckboxPermissions
					options={allPermissions.map(p => ({ label: p.name, value: p.id }))}
					value={values}
					onChange={handleChange}
				/>
			</Modal>
		</div>
	)
}

export default AddPermissionFormModal
