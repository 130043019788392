import * as React from 'react'
import axios from 'axios'
import { SESSION_MANAGEMENT } from '../config/urls'
import { message } from 'antd'

const useHistoryLog = () => {
	const [historyLog, setHistoryLog] = React.useState(null)
	const [historyLogModalState, setHistoryLogModalState] = React.useState(false)

	const handleOpenHistoryLog = async (historyLogId: number, historyLogType: string) => {
		await axios
			.get(`${SESSION_MANAGEMENT}/admin/audit-content/content/${historyLogType}/${historyLogId}`)
			.then(response => {
				setHistoryLog(response.data.items)
				setHistoryLogModalState(true)
			})
			.catch(error => message.error('Error occurred!'))
	}

	const handleCloseHistoryLog = () => {
		setHistoryLogModalState(false)
	}

	return {
		historyLog,
		historyLogModalState,
		handleOpenHistoryLog,
		handleCloseHistoryLog
	}
}

export default useHistoryLog
