import { ITag } from 'brainstorming-types'
import * as React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Table } from 'antd'
import { Button, Popconfirm } from 'antd'
import { AddGroupMembersButton } from './AddGroupMembersButton'
import { StyledButtonDiv } from '../shared/StyledButtonDiv'
import { StyledTableWrapper } from '../regular/RegularTagsTable'

export interface IGroupTagMemberProps {
	group: ITag

	regularTags: ITag[]

	groupMembers: ITag[]
	isGroupMembersLoading: boolean

	onAddGroupMembers: (groupId: number, idsToAdd: number[]) => any
	onRemoveGroupMembers: (groupId: number, idsToRemove: number[]) => any
	isAddGroupMembersLoading: boolean
	isRemoveGroupMembersLoading: boolean
}

export const GroupTagMembers: React.FC<IGroupTagMemberProps> = props => {
	const {
		group,
		regularTags,
		groupMembers,
		isGroupMembersLoading,
		onAddGroupMembers,
		onRemoveGroupMembers,
		isAddGroupMembersLoading,
		isRemoveGroupMembersLoading
	} = props

	const onRemoveGroupMember = (groupMemberId: number) => {
		onRemoveGroupMembers(group.id, [groupMemberId])
	}

	const columns: ColumnProps<ITag>[] = [
		{
			key: 'name',
			dataIndex: 'name',
			title: 'Tag name'
		},
		{
			width: '20%',
			key: 'actions',
			title: '',
			render: (text, groupMember: ITag) => {
				return (
					<Popconfirm
						onConfirm={() => onRemoveGroupMember(groupMember.id)}
						okText="Yes"
						cancelText="No"
						title="Are you sure you want to remove this tag from group?"
					>
						<Button danger={true} type="primary" size="small" loading={isRemoveGroupMembersLoading}>
							remove
						</Button>
					</Popconfirm>
				)
			}
		}
	]

	return (
		<div>
			<StyledButtonDiv>
				<AddGroupMembersButton
					group={group}
					regularTags={regularTags}
					groupMembers={groupMembers}
					onAddGroupMembers={onAddGroupMembers}
					isAddGroupMembersLoading={isAddGroupMembersLoading}
				/>
			</StyledButtonDiv>
			<StyledTableWrapper>
				<Table
					columns={columns}
					dataSource={groupMembers}
					loading={isGroupMembersLoading || isRemoveGroupMembersLoading || isAddGroupMembersLoading}
					rowKey="id"
					pagination={{
						position: ['bottomRight']
					}}
				/>
			</StyledTableWrapper>
		</div>
	)
}
