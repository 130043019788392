import Cookie from './Cookie'
import LocalStorage from './LocalStorage'

export enum StorageType {
	Cookie = 'cookie',
	Local = 'local'
}

let cookieInstance: any
let localInstance: any

export class StorageFactory {
	static buildStorage<T>(type: StorageType) {
		if (type === StorageType.Cookie) {
			// Build cookie
			if (cookieInstance) {
				return cookieInstance as Cookie<T>
			}
			cookieInstance = new Cookie<T>()
			return cookieInstance
		}

		// Build LocalStorage
		if (localInstance) {
			return localInstance as LocalStorage<T>
		}
		localInstance = new LocalStorage<T>()
		return localInstance
	}
}
