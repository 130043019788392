import { StaffActionType, IEditStaffMember, ICreateStaffPayment, IAddStaff } from './types'
import { IStaffMember, IStaffPayment, IApiError } from 'brainstorming-types'

// fetch staff
export const fetchStaffRequest = () => ({
	type: StaffActionType.FetchStaffRequest
})

export const fetchStaffSuccess = (data: IStaffMember[]) => ({
	type: StaffActionType.FetchStaffSuccess,
	payload: data
})

export const fetchStaffFailure = (error: IApiError) => ({
	type: StaffActionType.FetchStaffFailure,
	payload: error
})

export const fetchStaffMemberRequest = (data?: any) => ({
	type: StaffActionType.FetchStaffMemberRequest,
	payload: data
})

export const fetchStaffMemberSuccess = (data: IStaffMember) => ({
	type: StaffActionType.FetchStaffMemberSuccess,
	payload: data
})

export const fetchStaffMemberFailure = (error: IApiError) => ({
	type: StaffActionType.FetchStaffMemberFailure,
	payload: error
})

// add staff
export const addStaffRequest = (data: IAddStaff) => ({
	type: StaffActionType.AddStaffRequest,
	payload: data
})

export const addStaffSuccess = (data: IStaffMember) => ({
	type: StaffActionType.AddStaffSuccess,
	payload: data
})

export const addStaffFailure = (error: IApiError) => ({
	type: StaffActionType.AddStaffFailure,
	payload: error
})

// edit staff member
export const editStaffMemberRequest = (staffMemberId: number, data: IEditStaffMember) => ({
	type: StaffActionType.EditStaffMemberRequest,
	payload: { staffMemberId, data }
})

export const editStaffMemberSuccess = (data: IStaffMember) => ({
	type: StaffActionType.EditStaffMemberSuccess,
	payload: data
})

export const editStaffMemberFailure = (error: IApiError) => ({
	type: StaffActionType.EditStaffMemberFailure,
	payload: error
})

// fetch staff payments
export const fetchPaymentsForStaffMemberRequest = (staffMemberId: number) => ({
	type: StaffActionType.FetchPaymentsForStaffMemberRequest,
	payload: { staffMemberId }
})

export const fetchPaymentsForStaffMemberSuccess = (data: IStaffPayment[]) => ({
	type: StaffActionType.FetchPaymentsForStaffMemberSuccess,
	payload: data
})

export const fetchPaymentsForStaffMemberFailure = (error: IApiError) => ({
	type: StaffActionType.FetchPaymentsForStaffMemberFailure,
	payload: error
})

// add staff payment
export const createPaymentForStaffMemberRequest = (
	staffMemberId: number,
	data: ICreateStaffPayment
) => ({
	type: StaffActionType.CreatePaymentForStaffMemberRequest,
	payload: { staffMemberId, data }
})

export const createPaymentForStaffMemberSuccess = (data: IStaffPayment) => ({
	type: StaffActionType.CreatePaymentForStaffMemberSuccess,
	payload: data
})

export const createPaymentForStaffMemberFailure = (error: IApiError) => ({
	type: StaffActionType.CreatePaymentForStaffMemberFailure,
	payload: error
})
