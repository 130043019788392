import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { getLoadersState, isLoading as getIsLoading } from '../../../store/common/loaders/selector'
import {
	fetchGroupsBatchRequest,
	fetchRegularTagsRequest,
	fetchSynonymsBatchRequest
} from '../store/actions'
import { getTagGroupsById, getTagSynonymsById } from '../store/selectors'
import { TagsActionTypes } from '../store/action.types'
import { ITagsByTagId } from '../store/types'

interface IUseGroupsByIdAndSynonymsByIdResult {
	groupsById: ITagsByTagId
	synonymsById: ITagsByTagId
	isLoading: boolean
}

export const useGroupsByIdAndSynonymsById = (
	tagIds: number[]
): IUseGroupsByIdAndSynonymsByIdResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchRegularTagsRequest())
	}, [dispatch])

	const [isSynonymsBatchFetchDispatched, setIsSynonymsBatchFetchDispatched] = useState(false)
	const [isGroupsBatchFetchDispatched, setIsGroupsBatchFetchDispatched] = useState(false)

	const loaders = useSelector(getLoadersState)
	const isLoading = getIsLoading(loaders, [
		TagsActionTypes.FetchGroupsBatchRequest,
		TagsActionTypes.FetchSynonymsBatchRequest
	])

	useEffect(() => {
		if (tagIds.length && !isSynonymsBatchFetchDispatched) {
			dispatch(fetchSynonymsBatchRequest(tagIds))
			setIsSynonymsBatchFetchDispatched(true)
		}
	}, [dispatch, tagIds, isSynonymsBatchFetchDispatched])

	useEffect(() => {
		if (tagIds.length && !isGroupsBatchFetchDispatched) {
			dispatch(fetchGroupsBatchRequest(tagIds))
			setIsGroupsBatchFetchDispatched(true)
		}
	}, [dispatch, tagIds, isGroupsBatchFetchDispatched])

	const groupsById = useSelector(getTagGroupsById)
	const synonymsById = useSelector(getTagSynonymsById)

	return {
		groupsById,
		synonymsById,
		isLoading
	}
}
