import * as React from 'react'
import { useState, useCallback, useRef } from 'react'
import { Button, Popconfirm, Table } from 'antd'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import styled from 'styled-components'

import { IPageModule, ContentFillInLabels, ContentFillIn } from 'brainstorming-types'
import { TogglePageModuleRepeat } from './TogglePageModuleRepeat'
import { SPACES } from '../../../../styles/spaces'

const ModuleActionsWrapper = styled.div`
	> button:nth-of-type(1) {
		margin-right: ${SPACES.XXS};
	}
`

const type = 'PageModuleRow'

interface IDraggablePageModuleRowProps {
	index: number
	moveRow: (...args: any[]) => any
	style: object
}

interface IPageModuleOrderProps {
	pageModules: IPageModule[]
	pageModuleOrder: number[]
	onEditPageModuleOrder: (newOrder: number[]) => unknown
	onOpenSingleModule: (moduleId: number) => unknown
	onDeleteModule: (moduleId: number) => unknown
	isDeleteModuleLoading: boolean
}

// TODO: add row key
const DraggablePageModuleRow: React.FC<IDraggablePageModuleRowProps> = props => {
	const { index, moveRow, style, ...restProps } = props
	const ref = useRef()
	const [, drop] = useDrop({
		accept: type,
		collect: (monitor: { index: number; getItem: () => any; isOver: () => any }) => {
			const { index: dragIndex } = monitor.getItem() || {}
			if (dragIndex === index) {
				return {}
			}
			return {
				isOver: monitor.isOver()
			}
		},
		drop: (item: any) => {
			if (moveRow) {
				moveRow(item.index, index)
			}
		}
	} as any) // bad react-dnd types...
	const [, drag] = useDrag({
		type,
		item: { index },
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	})
	drop(drag(ref))

	return <tr ref={ref as any} style={{ cursor: 'move', ...style }} {...restProps} />
}

const getModulesData = (pageModules: IPageModule[], pageModuleOrder: number[]) => {
	return pageModuleOrder.map(
		id => pageModules.find(pageModule => pageModule.id === id) as IPageModule
	)
}

export const PageModuleOrder: React.FC<IPageModuleOrderProps> = props => {
	const {
		pageModules,
		pageModuleOrder,
		onEditPageModuleOrder,
		onOpenSingleModule,
		onDeleteModule,
		isDeleteModuleLoading
	} = props

	const columns = [
		{
			title: '',
			key: 'index',
			render: (pageModule: any, record: any, index: number) => {
				return index + 1
			}
		},
		{
			title: 'Module',
			dataIndex: ['config', 'name'],
			key: 'name'
		},
		{
			title: 'Repetition',
			key: 'repetition',
			render: (pageModule: IPageModule) => {
				return <TogglePageModuleRepeat module={pageModule} />
			}
		},
		{
			title: 'Title',
			dataIndex: ['fieldValues', 'title'],
			key: 'title'
		},
		{
			title: 'Content',
			render: (module: IPageModule) => {
				const {
					fieldValues: { contentFillIn }
				} = module
				return contentFillIn ? ContentFillInLabels[contentFillIn as ContentFillIn] : ''
			}
		},
		{
			title: '',
			render: (module: IPageModule) => {
				return (
					<ModuleActionsWrapper>
						<Button type="primary" size="small" onClick={() => onOpenSingleModule(module.id)}>
							see details
						</Button>

						<Popconfirm
							onConfirm={() => onDeleteModule(module.id)}
							okText="Yes"
							cancelText="No"
							title="Are you sure you want to remove this module?"
						>
							<Button danger={true} type="primary" size="small" loading={isDeleteModuleLoading}>
								remove module
							</Button>
						</Popconfirm>
					</ModuleActionsWrapper>
				)
			}
		}
	]

	const [data, setData] = useState(getModulesData(pageModules, pageModuleOrder))

	React.useEffect(() => {
		setData(getModulesData(pageModules, pageModuleOrder))
	}, [pageModuleOrder, pageModules])

	const updatePageModuleOrder = React.useCallback(
		async (newPageModules: IPageModule[]) => {
			const newPageModuleOrder = newPageModules.map(({ id }) => id)
			setData(newPageModules)
			await onEditPageModuleOrder(newPageModuleOrder)
		},
		[onEditPageModuleOrder]
	)

	const components = {
		body: {
			row: DraggablePageModuleRow
		}
	}

	const moveRow = useCallback(
		(dragIndex, hoverIndex) => {
			const dragRow = data[dragIndex]
			const dataCopy = [...data]

			dataCopy.splice(dragIndex, 1)
			dataCopy.splice(hoverIndex, 0, dragRow)
			updatePageModuleOrder(dataCopy)
		},
		[data, updatePageModuleOrder]
	)

	return (
		<DndProvider backend={HTML5Backend}>
			<Table
				columns={columns}
				dataSource={data}
				components={components}
				onRow={(record, index) =>
					({
						index,
						moveRow
					} as any)
				} // more Antd type definition woes...
				pagination={false}
			/>
		</DndProvider>
	)
}
