import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ITag } from 'brainstorming-types'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { fetchGroupMembersRequest } from '../store/actions'
import { getGroupMembers } from '../store/selectors'
import { TagsActionTypes } from '../store/action.types'

interface IUseGroupMembersResult {
	groupMembers: ITag[]
	isLoading: boolean
}

export const useGroupMembers = (groupTagId: number): IUseGroupMembersResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchGroupMembersRequest(groupTagId))
	}, [dispatch, groupTagId])

	const groupMembers = useSelector(getGroupMembers(groupTagId))
	const loaders = useSelector(getLoadersState)
	const isLoadingGroupMembers = isLoading(loaders, [TagsActionTypes.FetchGroupMembersRequest])

	return {
		groupMembers,
		isLoading: isLoadingGroupMembers
	}
}
