import { ISetError, ErrorsActionType, IClearError } from './types'
import { FAILURE, SUCCESS, REQUEST } from '../constants'
import { IApiError } from 'brainstorming-types'

export const setError = (action: string, error: IApiError): ISetError => ({
	type: ErrorsActionType.SetError,
	payload: { action: action.replace(FAILURE, ''), error }
})

export const clearError = (action: string): IClearError => ({
	type: ErrorsActionType.ClearError,
	payload: {
		action: action.replace(SUCCESS, '').replace(REQUEST, '')
	}
})
