import * as React from 'react'
import styled from 'styled-components'

import {
	CrownOutlined,
	DesktopOutlined,
	ExperimentOutlined,
	HomeOutlined,
	MailOutlined,
	SettingOutlined,
	TagsOutlined,
	TeamOutlined
} from '@ant-design/icons'

import { Layout, Menu } from 'antd'
import { NavLink, matchPath, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CollapseType } from 'antd/lib/layout/Sider'

import { Routes } from '../../config/routes'
import { isSidebarOpen } from '../../store/common/ui/selectors'
import { toggleSidebarMenu } from '../../store/common/ui/actions'

const LAYOUT_CSS_SETTINGS: React.CSSProperties = {
	overflow: 'auto',
	height: '100vh',
	position: 'fixed',
	left: 0,
	top: '64px'
}

const SidebarMenuNavbarLink = styled(Menu.Item)`
	&:hover {
		a {
			color: #1890ff !important;
		}
	}
	span {
		a {
			color: rgba(0, 0, 0, 0.65);
			display: inline-block;
		}
		a.active {
			color: #1890ff;
		}
	}
`

const SidebarMenuSubmenu = styled(Menu.SubMenu)`
	color: rgba(0, 0, 0, 0.65);
`

const Sidebar: React.FC = () => {
	const location = useLocation()
	const sidebarOpen = useSelector(isSidebarOpen)
	const dispatch = useDispatch()

	const handleCollapseMenu = (collapsed: boolean, _: CollapseType) => {
		dispatch(toggleSidebarMenu(!collapsed))
	}

	const currentRoute = Object.values(Routes).find(route => {
		const match = matchPath(route.route, location.pathname)
		if (match) {
			return match.isExact
		}
		return false
	})

	const defaultSelectedKeys = currentRoute ? [currentRoute.route as string] : []
	const defaultOpenKeys = currentRoute && currentRoute.group ? [currentRoute.group] : []

	return (
		<Layout>
			<Layout.Sider
				collapsible={true}
				breakpoint="sm"
				style={LAYOUT_CSS_SETTINGS}
				collapsed={!sidebarOpen}
				onCollapse={handleCollapseMenu}
				theme="light"
			>
				<Menu
					theme="light"
					mode="inline"
					defaultSelectedKeys={defaultSelectedKeys}
					defaultOpenKeys={defaultOpenKeys as any}
					selectedKeys={defaultSelectedKeys}
					style={{ borderRight: 0 }}
				>
					<SidebarMenuSubmenu
						key="acl"
						title={
							<span>
								<DesktopOutlined />
								<span>ACL</span>
							</span>
						}
					>
						<SidebarMenuNavbarLink key={Routes.UserRankingGroups.route}>
							<span>
								<NavLink className="nav-text" to={Routes.UserRankingGroups.route}>
									User Ranking Groups
								</NavLink>
							</span>
						</SidebarMenuNavbarLink>
						<SidebarMenuNavbarLink key={Routes.Whitelist.route}>
							<span>
								<NavLink className="nav-text" to={Routes.Whitelist.route}>
									Whitelist
								</NavLink>
							</span>
						</SidebarMenuNavbarLink>
						<SidebarMenuNavbarLink key={Routes.Blacklist.route}>
							<span>
								<NavLink className="nav-text" to={Routes.Blacklist.route}>
									Blacklisting
								</NavLink>
							</span>
						</SidebarMenuNavbarLink>
					</SidebarMenuSubmenu>
					<SidebarMenuNavbarLink key={Routes.UserRankingSystem.route}>
						<SettingOutlined />
						<span>
							<NavLink className="nav-text" to="/user-ranking-system">
								User Ranking
							</NavLink>
						</span>
					</SidebarMenuNavbarLink>
					<SidebarMenuSubmenu
						key="tags"
						title={
							<span>
								<TagsOutlined />
								<span>Tags</span>
							</span>
						}
					>
						<SidebarMenuNavbarLink key={Routes.RegularTags.route}>
							<span>
								<NavLink className="nav-text" to={Routes.RegularTags.route}>
									Regular Tags
								</NavLink>
							</span>
						</SidebarMenuNavbarLink>
						<SidebarMenuNavbarLink key={Routes.GroupTags.route}>
							<span>
								<NavLink className="nav-text" to={Routes.GroupTags.route}>
									Group Tags
								</NavLink>
							</span>
						</SidebarMenuNavbarLink>
					</SidebarMenuSubmenu>
					<SidebarMenuNavbarLink key={Routes.Sessions.route}>
						<CrownOutlined />
						<span>
							<NavLink className="nav-text" to={Routes.Sessions.route}>
								Sessions / Ideas
							</NavLink>
						</span>
					</SidebarMenuNavbarLink>
					<SidebarMenuNavbarLink key={Routes.Solutions.route}>
						<ExperimentOutlined />
						<span>
							<NavLink className="nav-text" to={Routes.Solutions.route}>
								Solutions
							</NavLink>
						</span>
					</SidebarMenuNavbarLink>
					<SidebarMenuNavbarLink key={Routes.Comments.route}>
						<MailOutlined />
						<span>
							<NavLink className="nav-text" to={Routes.Comments.route}>
								Comments
							</NavLink>
						</span>
					</SidebarMenuNavbarLink>
					<SidebarMenuNavbarLink key={Routes.Pages.route}>
						<HomeOutlined />
						<span>
							<NavLink className="nav-text" to={Routes.Pages.route}>
								Pages
							</NavLink>
						</span>
					</SidebarMenuNavbarLink>
					<SidebarMenuNavbarLink key={Routes.StaffAccounting.route}>
						<TeamOutlined />
						<span>
							<NavLink className="nav-text" to={Routes.StaffAccounting.route}>
								Staff Accounting
							</NavLink>
						</span>
					</SidebarMenuNavbarLink>
				</Menu>
			</Layout.Sider>
		</Layout>
	)
}

export default Sidebar
