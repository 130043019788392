import axios from 'axios'
import { stringify } from 'querystring'
import { SESSION_MANAGEMENT } from '../../../config/urls'

export const fetchSessions = async (params: any) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/admin/sessions`, {
			params,
			paramsSerializer: stringify
		})
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchSessionDetails = async (id: number) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/sessions/${id}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const uploadSessionImage = async (image: File) => {
	const formData = new FormData()
	formData.set('image', image)
	return axios
		.post(`${SESSION_MANAGEMENT}/sessions/upload`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const editSession = async (data: any, sessionId: number) => {
	return axios
		.put(`${SESSION_MANAGEMENT}/admin/sessions/${sessionId}`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const deleteSession = async (sessionId: number) => {
	return axios
		.delete(`${SESSION_MANAGEMENT}/admin/sessions/${sessionId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const restoreSession = async (sessionId: number) => {
	return axios
		.put(`${SESSION_MANAGEMENT}/admin/sessions/restore/${sessionId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}
