import * as React from 'react'
import { Button } from 'antd'
import { PageDetailsModal } from './PageDetailsModal'
import { useEditPage } from '../../hooks'
import { IPage } from 'brainstorming-types'
import { ICreatePage } from '../../store/types'

interface IProps {
	page: IPage
}

export const EditPageDetails: React.FC<IProps> = props => {
	const { page } = props

	const [isModalVisible, setIsModalVisible] = React.useState(false)

	const { onEditPage, isLoading: isEditPageLoading } = useEditPage(page.id)

	const onCancel = () => {
		setIsModalVisible(false)
	}

	const onSubmit = async (data: ICreatePage) => {
		onEditPage(data)
		setIsModalVisible(false)
	}

	return (
		<div>
			<Button
				type="primary"
				size="small"
				onClick={() => setIsModalVisible(true)}
				loading={isEditPageLoading}
			>
				Edit details
			</Button>
			<PageDetailsModal
				isLoading={isEditPageLoading}
				onSubmit={onSubmit}
				onCancel={onCancel}
				isVisible={isModalVisible}
				page={page}
			/>
		</div>
	)
}
