import * as React from 'react'
import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProtectedRoute from '../../../../../components/routes/ProtectedRoute'
import { getToken } from '../../../../auth/store/selectors'
import { Routes as RouteConfig } from '../../../../../config/routes'
import { GroupTagsContainer } from '../../../container/GroupTagsContainer'
import { SingleGroupTagContainer } from '../../../container/SingleGroupTagContainer'

export const GroupTagsRoutes = () => {
	const token = useSelector(getToken)

	return (
		<Switch>
			<ProtectedRoute
				path={RouteConfig.GroupTags.route}
				exact={true}
				component={GroupTagsContainer}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.GroupTags.singleTagRoute}
				exact={true}
				component={SingleGroupTagContainer}
				token={token}
			/>
		</Switch>
	)
}
