import * as React from 'react'
import { IComment } from 'brainstorming-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Modal, Button, Input } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { IEditComment } from '../../store/types'
interface ILocalProps {
	comment: IComment | null
	isEditingComment: boolean
	onConfirm(data: IEditComment): void
	onCancel(): void
}

interface IFormProps extends FormComponentProps<any> {}

interface IProps extends ILocalProps, IFormProps {}

const EditComment: React.FC<IProps> = ({
	comment,
	onConfirm,
	onCancel,
	isEditingComment,
	form
}) => {
	const handleSubmit = () => {
		onConfirm({ content: form.getFieldValue('content') })
	}
	if (!comment) {
		return null
	}
	return (
		<Modal
			title="Edit comment"
			visible={true}
			onOk={handleSubmit}
			onCancel={onCancel}
			okText="Confirm"
			okType="danger"
			cancelText="Cancel"
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					loading={isEditingComment}
					onClick={handleSubmit}
					disabled={!!form.getFieldError('content')}
				>
					Confirm
				</Button>
			]}
		>
			<Form layout="vertical">
				<Form.Item label="Content">
					{form.getFieldDecorator('content', {
						rules: [{ required: true, message: 'Please input the content of comment!' }],
						initialValue: comment.text
					})(<Input.TextArea rows={6} />)}
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default Form.create<IProps>({
	name: 'editComment'
})(EditComment)
