import { Button, Modal, notification } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { IStaffMember } from 'brainstorming-types'

import { IEditStaffMember } from '../../store/types'
import { getAmountToPay } from '../../utils/helpers'
import { SPACES } from '../../../../styles/spaces'

const StyledDiv = styled.div`
	width: max-content;
	margin-left: auto;
	margin-bottom: ${SPACES.EXTRA_SMALL};
`

export interface IDeactivateAccountButtonProps {
	staffMember: IStaffMember
	onEditStaffMember: (data: IEditStaffMember) => any
	isEditStaffMemberLoading: boolean
}

export const DeactivateAccountButton: React.FC<IDeactivateAccountButtonProps> = ({
	staffMember,
	onEditStaffMember,
	isEditStaffMemberLoading
}) => {
	const [isModalVisible, setIsModalVisible] = React.useState(false)

	const toPay = React.useMemo(() => getAmountToPay(staffMember), [staffMember])

	const onOk = async () => {
		await onEditStaffMember({ isActive: false })
		setIsModalVisible(false)
	}
	const onCancel = () => setIsModalVisible(false)

	const onClick =
		toPay.amount <= 0
			? () => setIsModalVisible(true)
			: () =>
					notification.error({
						message: 'To deactivate account, outstanding balance (to pay) must be cleared.'
					})

	return (
		<StyledDiv>
			<Button onClick={onClick}>Deactivate account</Button>
			<Modal
				title="Deactivate account"
				visible={isModalVisible}
				onOk={onOk}
				confirmLoading={isEditStaffMemberLoading}
				onCancel={onCancel}
				okText="Yes, deactivate account"
			>
				<p>Are you sure you want to deactivate this account?</p>
			</Modal>
		</StyledDiv>
	)
}
