import { all } from 'redux-saga/effects'
import authSaga from '../features/auth/store/sagas'
import aclSaga from '../features/acl/store/sagas'
import usersSaga from '../features/users/store/sagas'
import tagsSaga from '../features/tags/store/sagas'
import sessionsSaga from '../features/sessions/store/sagas'
import solutionsSaga from '../features/solutions/store/sagas'
import commentsSaga from '../features/comments/store/sagas'
import staffAccountingSaga from '../features/staff-accounting/store/sagas'
import pagesSaga from '../features/pages/store/sagas'

export function* rootSaga() {
	yield all([
		authSaga(),
		aclSaga(),
		usersSaga(),
		tagsSaga(),
		sessionsSaga(),
		solutionsSaga(),
		commentsSaga(),
		staffAccountingSaga(),
		pagesSaga()
	])
}
