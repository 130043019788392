import createSagaMiddleware from 'redux-saga'
import { Store, compose, applyMiddleware, createStore } from 'redux'

import rootReducer from './rootReducer'
import { IApplicationState } from './types'
import { rootSaga } from './rootSaga'
import loadersMiddleware from './common/loaders/middleware'
import errorsMiddleware from './common/errors/middleware'

const initStore = (): Store<IApplicationState> => {
	const __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'
	const windowIfDefined = typeof window === 'undefined' ? null : (window as any)

	const composeEnhancers =
		(process.env.NODE_ENV !== 'production' &&
			windowIfDefined &&
			windowIfDefined[__REDUX_DEVTOOLS_EXTENSION_COMPOSE__]) ||
		compose

	const sagaMiddleware = createSagaMiddleware()

	const middleware = applyMiddleware(sagaMiddleware, loadersMiddleware, errorsMiddleware)

	const store = createStore(rootReducer, composeEnhancers(middleware))

	sagaMiddleware.run(rootSaga)

	return store
}

export default initStore
