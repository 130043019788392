import { Path, LocationState, createBrowserHistory } from 'history'

const routeHistory = createBrowserHistory()

const push = (path: Path, state?: LocationState) => {
	routeHistory.push(path, state)
}

const goBack = () => routeHistory.goBack()

const replace = (path: Path, state?: LocationState) => routeHistory.replace(path, state)

const getLocation = () => routeHistory.location

export { push, goBack, replace, getLocation }

export default routeHistory
