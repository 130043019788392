import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { mapTableToQuery } from '../../../utils/sorting'
import { SortOrder } from 'antd/lib/table/interface'
import { COMMENTS_PER_PAGE } from '../containers/CommentsDashboardContainer'
import { fetchCommentsRequest } from '../store/actions'
import { getCommentsState, getComments } from '../store/selectors'
import { CommentsActionType } from '../store/types'

interface IUseSolutions {
	page?: number | null
	column?: string | null
	direction?: string | null
}

const useComments = ({ page = 1, column, direction }: IUseSolutions) => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			fetchCommentsRequest(
				mapTableToQuery(page as number, COMMENTS_PER_PAGE, {
					column,
					order: direction as SortOrder
				})
			)
		)
	}, [column, direction, dispatch, page])

	const commentsState = useSelector(getCommentsState)
	const loaders = useSelector(getLoadersState)
	const isLoadingSolutions = isLoading(loaders, [CommentsActionType.FetchCommentsRequest])
	const comments = useSelector(getComments)

	return { comments, isLoading: isLoadingSolutions, commentsState }
}

export default useComments
