import { ITurnOffLoader, ITurnOnLoader, LoaderActionType } from './types'
import { SUCCESS, FAILURE, REQUEST } from '../constants'

export const turnOnLoader = (actionType: string): ITurnOnLoader => ({
	type: LoaderActionType.TurnOnLoader,
	payload: { action: actionType.replace(REQUEST, '') }
})

export const turnOffLoader = (actionType: string): ITurnOffLoader => ({
	type: LoaderActionType.TurnOffLoader,
	payload: { action: actionType.replace(SUCCESS, '').replace(FAILURE, '') }
})
