import { IUser, IApiError, IUserAction } from 'brainstorming-types'

export enum UsersActionTypes {
	FetchUserRequest = '@users/FETCH_USER_REQUEST',
	FetchUserSuccess = '@users/FETCH_USER_SUCCESS',
	FetchUserFailure = '@users/FETCH_USER_FAILURE',
	FetchUserActionsRequest = '@users/FETCH_USER_ACTIONS_REQUEST',
	FetchUserActionsSuccess = '@users/FETCH_USER_ACTIONS_SUCCESS',
	FetchUserActionsFailure = '@users/FETCH_USER_ACTIONS_FAILURE',
	UpdateUserActionRequest = '@users/UPDATE_USER_ACTION_REQUEST',
	UpdateUserActionSuccess = '@users/UPDATE_USER_ACTION_SUCCESS',
	UpdateUserActionFailure = '@users/UPDATE_USER_ACTION_FAILURE'
}

export interface IFetchUserRequest {
	type: UsersActionTypes.FetchUserRequest
	payload: any
}

export interface IFetchUserSuccess {
	type: UsersActionTypes.FetchUserSuccess
	payload: IUser[]
}

export interface IFetchUserFailure {
	type: UsersActionTypes.FetchUserFailure
	payload: IApiError
}

export interface IUpdateUserActionRequest {
	type: UsersActionTypes.UpdateUserActionRequest
	payload: { id: number; points: number }
}

export interface IUpdateUserActionSuccess {
	type: UsersActionTypes.UpdateUserActionSuccess
	payload: IUserAction
}

export interface IUpdateUserActionFailure {
	type: UsersActionTypes.UpdateUserActionFailure
	payload: IApiError
}

export interface IFetchUserActionsRequest {
	type: UsersActionTypes.FetchUserActionsRequest
	payload: any
}

export interface IFetchUserActionsSuccess {
	type: UsersActionTypes.FetchUserActionsSuccess
	payload: IUserAction[]
}

export interface IFetchUserActionsFailure {
	type: UsersActionTypes.FetchUserActionsFailure
	payload: IApiError
}

export type UsersActions =
	| IFetchUserRequest
	| IFetchUserSuccess
	| IFetchUserFailure
	| IFetchUserActionsRequest
	| IFetchUserActionsSuccess
	| IFetchUserActionsFailure
	| IUpdateUserActionRequest
	| IUpdateUserActionSuccess
	| IUpdateUserActionFailure
