interface IWithId {
	id: number
}

interface IById<T> {
	[id: number]: T
}

export function arrayToObjectById<T extends IWithId>(items: T[]): IById<T> {
	return items.reduce((obj, item) => {
		obj[item.id] = item
		return obj
	}, {} as IById<T>)
}
