import * as React from 'react'
import { connect } from 'react-redux'
import Page from '../../../components/layout/Page'
import UserRankingSystem from '../components/UserRankingSystem'
import { fetchUserActionsRequest, updateUserActionRequest } from '../store/actions'
import { bindActionCreators, Dispatch } from 'redux'
import { IApplicationState } from '../../../store/types'
import { getUsersActionState } from '../store/selectors'
import { IUserAction } from 'brainstorming-types'
import { isLoading } from '../../../store/common/loaders/selector'
import { UsersActionTypes } from '../store/actions.types'
import { Routes } from '../../../config/routes'

interface IProps {
	isLoading: boolean
	userActions: IUserAction[]
	fetchUserActionsRequest(): void
	updateUserActionRequest(id: number, points: number): void
}

class UserRankingSystemContainer extends React.Component<IProps> {
	componentDidMount() {
		this.props.fetchUserActionsRequest()
	}

	handleSaveUserRankSystem = (changedUserActions: IUserAction[]) => {
		changedUserActions.forEach(action => {
			this.props.updateUserActionRequest(action.id, action.points)
		})
	}

	render() {
		return (
			<Page
				isLoading={this.props.isLoading}
				loadingText="Loading User Ranking System"
				breadcrumbItems={[{ name: 'User ranking', path: Routes.UserRankingSystem.route }]}
			>
				<UserRankingSystem
					saveGroup={this.handleSaveUserRankSystem}
					userActions={this.props.userActions}
				/>
			</Page>
		)
	}
}

const mapStateToProps = (state: IApplicationState) => ({
	userActions: getUsersActionState(state),
	isLoading: isLoading(state.loaders, [UsersActionTypes.FetchUserActionsRequest])
})

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			fetchUserActionsRequest,
			updateUserActionRequest
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(UserRankingSystemContainer)
