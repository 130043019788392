import { Middleware, AnyAction } from 'redux'
import { FAILURE, REQUEST, SUCCESS } from '../constants'
import { setError, clearError } from './actions'
import { logout } from '../../../features/auth/store/actions'

const errorsMiddleware: Middleware = ({ dispatch }) => (next: any) => (action: AnyAction) => {
	if (action.type.includes(FAILURE)) {
		if (action.payload.statusCode === 401) {
			dispatch(logout())
		}
		dispatch(setError(action.type, action.payload))
	} else if (action.type.includes(REQUEST) || action.type.includes(SUCCESS)) {
		dispatch(clearError(action.type))
	}

	next(action)
}

export default errorsMiddleware
