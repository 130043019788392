import { Divider, PageHeader } from 'antd'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import Page from '../../../components/layout/Page'
import { Routes } from '../../../config/routes'
import { GroupTagMembers } from '../components/dashboard/group/GroupTagMembers'
import { TagDetails } from '../components/dashboard/shared/TagDetails'
import {
	useAddAndRemoveGroupMembers,
	useDeleteTag,
	useGroupMembers,
	useRegularTags,
	useUpdateTag
} from '../hooks'
import { useSingleTag } from '../hooks/useSingleTag'

export interface ISingleGroupTagContainerProps {
	//
}

interface ISingleTagParams {
	id: string
}

export const SingleGroupTagContainer: React.FC<ISingleGroupTagContainerProps> = props => {
	const params = useParams<ISingleTagParams>()
	const tagId = parseInt(params.id as string, 10)

	const { tag, isLoading: isTagLoading } = useSingleTag(tagId)

	const { regularTags } = useRegularTags()

	// update tag
	const { onUpdateTag, isLoading: isUpdateTagLoading } = useUpdateTag()
	const { onDeleteTag, isLoading: isDeleteTagLoading } = useDeleteTag()

	const {
		onAddGroupMembers,
		isAddGroupMembersLoading,
		onRemoveGroupMembers,
		isRemoveGroupMembersLoading
	} = useAddAndRemoveGroupMembers()

	const { groupMembers, isLoading: isGroupMembersLoading } = useGroupMembers(tagId)

	return (
		<Page
			isLoading={isTagLoading}
			loadingText={'Loading Group tag details...'}
			breadcrumbItems={[
				{ name: 'Group tags', path: Routes.GroupTags.route },
				{
					name: tag?.name ? tag?.name : '',
					path: Routes.GroupTags.singleTagRoute.replace(':id', params.id)
				}
			]}
		>
			{tag && (
				<>
					<PageHeader title={tag?.name} />
					<TagDetails
						tag={tag}
						onUpdateTag={onUpdateTag}
						isUpdateTagLoading={isUpdateTagLoading}
						onDeleteTag={onDeleteTag}
						isDeleteTagLoading={isDeleteTagLoading}
					/>
					<Divider />
					<PageHeader title={'Group members'} />
					<GroupTagMembers
						group={tag}
						regularTags={regularTags}
						onAddGroupMembers={onAddGroupMembers}
						onRemoveGroupMembers={onRemoveGroupMembers}
						isAddGroupMembersLoading={isAddGroupMembersLoading}
						isRemoveGroupMembersLoading={isRemoveGroupMembersLoading}
						groupMembers={groupMembers}
						isGroupMembersLoading={isGroupMembersLoading}
					/>
				</>
			)}
		</Page>
	)
}
