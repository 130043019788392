import { IApplicationState } from '../../../store/types'

export const getPages = () => (state: IApplicationState) => {
	return state.pages
}

export const getPage = (pageId: number) => (state: IApplicationState) => {
	return state.pages.byId[pageId]
}

export const getPageModules = (pageId: number) => (state: IApplicationState) => {
	return state.pages.byId[pageId].modules
}
