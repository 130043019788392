import * as React from 'react'
import { Button } from 'antd'
import { Mode } from '../../constants'

interface IProps {
	onAddNew(): void
	mode: Mode
}

const AddNewGroupButton: React.FC<IProps> = ({ onAddNew, mode }) => {
	const handleAddNew = () => {
		if (mode === Mode.Edit) {
			const result = window.confirm('You are in edit mode, are you sure you want to leave?')
			if (result) {
				onAddNew()
			}
		} else {
			onAddNew()
		}
	}
	return (
		<div>
			<Button type="primary" ghost={true} onClick={handleAddNew}>
				+ Add new group
			</Button>
		</div>
	)
}

export default AddNewGroupButton
