import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCommentRequest } from '../store/actions'
import { isLoading, getLoadersState } from '../../../store/common/loaders/selector'
import { CommentsActionType } from '../store/types'
import usePrevious from '../../../hooks/usePrevious'

const useDeleteComment = () => {
	const [commentId, setCommentId] = React.useState<number | null>(null)
	const dispatch = useDispatch()

	const loaders = useSelector(getLoadersState)
	const isDeletingComment = isLoading(loaders, [CommentsActionType.DeleteCommentRequest], {
		default: false
	})
	const prevIsDeleingComment = usePrevious(isDeletingComment)

	React.useEffect(() => {
		if (prevIsDeleingComment && !isDeletingComment) {
			setCommentId(null)
		}
	}, [isDeletingComment, prevIsDeleingComment])

	const handleDeleteComment = (commentId: number) => {
		setCommentId(commentId)
	}

	const handleConfirmDeleteComment = () => {
		dispatch(deleteCommentRequest(commentId as number))
	}

	const handleCancelDeleteComment = () => {
		setCommentId(null)
	}

	return {
		commentId,
		isDeletingComment,
		handleDeleteComment,
		handleConfirmDeleteComment,
		handleCancelDeleteComment
	}
}

export default useDeleteComment
