import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ITag } from 'brainstorming-types'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { fetchSingleTagRequest } from '../store/actions'
import { getTagById } from '../store/selectors'
import { TagsActionTypes } from '../store/action.types'

interface IUseSingleTagResult {
	tag: ITag
	isLoading: boolean
}

export const useSingleTag = (tagId: number): IUseSingleTagResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchSingleTagRequest(tagId))
	}, [dispatch, tagId])

	const tag = useSelector(getTagById(tagId))
	const loaders = useSelector(getLoadersState)
	const isLoadingSingleTag = isLoading(loaders, [TagsActionTypes.FetchSingleTagRequest])

	return {
		tag,
		isLoading: isLoadingSingleTag
	}
}
