import * as React from 'react'

export const ContentLayout5Illustration = (props: any) => (
	<svg viewBox="0 0 345 80" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g transform="translate(-13512 5183)" data-name="Group 2817">
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2658">
				<path stroke="none" d="M13596-5169h83v55h-83z" />
				<path fill="none" d="M13596.5-5168.5h82v54h-82z" />
			</g>
			<g fill="none" stroke="#707070" data-name="Rectangle 2654">
				<path stroke="none" d="M13512-5183h345v80h-345z" />
				<path d="M13512.5-5182.5h344v79h-344z" />
			</g>
			<g transform="translate(13600 -5165.1)" fill="#e1e1f0" data-name="Group 2805">
				<path data-name="Rectangle 2601" d="M-.292 10.053h74v6h-74z" />
				<path data-name="Rectangle 2602" d="M-.292 18.053h74v5h-74z" />
				<rect
					transform="translate(-.292 40.568)"
					width={20}
					height={6}
					rx={3}
					data-name="Rectangle 2604"
				/>
				<rect
					transform="translate(-.292 .053)"
					width={20}
					height={5}
					rx={2.5}
					data-name="Rectangle 2606"
				/>
				<path data-name="Rectangle 2607" d="M22.708.053h20v5h-20z" />
				<rect
					transform="translate(23.708 40.568)"
					width={20}
					height={6}
					rx={3}
					data-name="Rectangle 2605"
				/>
			</g>
			<text
				transform="translate(13666 -5120)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={1}
			>
				<tspan x={0} y={0}>
					{'1'}
				</tspan>
			</text>
			<g stroke="#9195be" data-name="Group 2808">
				<g fill="#e1e1f0" data-name="Rectangle 2653">
					<path stroke="none" d="M13525-5169h72v38h-72z" />
					<path fill="none" d="M13525.5-5168.5h71v37h-71z" />
				</g>
				<path
					fill="none"
					strokeLinecap="round"
					data-name="Line 625"
					d="m13525-5131.121 71.169-37.379"
				/>
				<path
					fill="none"
					strokeLinecap="round"
					data-name="Line 626"
					d="M13596.169-5131.121 13525-5168.5"
				/>
			</g>
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2659">
				<path stroke="none" d="M13690-5169h155v57h-155z" />
				<path fill="none" d="M13690.5-5168.5h154v56h-154z" />
			</g>
			<path fill="#e1e1f0" data-name="Rectangle 2663" d="M13694-5153h146v5h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2660" d="M13694-5145h146v5h-146z" />
			<rect
				transform="translate(13777 -5124)"
				width={21}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2661"
			/>
			<rect
				transform="translate(13694 -5163)"
				width={21}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2665"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2664" d="M13717-5163h20v5h-20z" />
			<rect
				transform="translate(13801 -5124)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2662"
			/>
			<text
				transform="translate(13832 -5120)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={2}
			>
				<tspan x={0} y={0}>
					{'2'}
				</tspan>
			</text>
		</g>
	</svg>
)
