import * as React from 'react'
import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProtectedRoute from '../../../../../components/routes/ProtectedRoute'
import { getToken } from '../../../../auth/store/selectors'
import { Routes as RouteConfig } from '../../../../../config/routes'
import { RegularTagsContainer } from '../../../container/RegularTagsContainer'
import { SingleRegularTagContainer } from '../../../container/SingleRegularTagContainer'

export const RegularTagsRoutes = () => {
	const token = useSelector(getToken)

	return (
		<Switch>
			<ProtectedRoute
				path={RouteConfig.RegularTags.route}
				exact={true}
				component={RegularTagsContainer}
				token={token}
			/>
			<ProtectedRoute
				path={RouteConfig.RegularTags.singleTagRoute}
				exact={true}
				component={SingleRegularTagContainer}
				token={token}
			/>
		</Switch>
	)
}
