import { Button, Form, Popconfirm, Select } from 'antd'
import {
	IPageModule,
	IPageModuleFieldConfig,
	IPageModuleFieldValues,
	PageModuleLayout,
	PredefinedModuleConfigs
} from 'brainstorming-types'
import * as React from 'react'
import styled from 'styled-components'

import { SPACES } from '../../../../styles/spaces'
import { IEditPageModule } from '../../store/types'
import {
	getDefaultFieldValue,
	getModuleLayoutIllustration,
	getPredefinedLayoutFieldConfigs,
	getRegularAndContentFieldConfigs,
	mapContentFieldConfigsToFields,
	mapFieldConfigsToFields,
	getContentInputValueFromUrl,
	isContentInput
} from '../../utils/helpers'
import { ContentInputValue } from '../dashboard/fields/types'

const FormGrid = styled.div`
	display: grid;
	grid-template-columns: 50%;
	grid-column-gap: ${SPACES.EXTRA_LARGE};
`

interface IFormGridColumnProps {
	column: number
}

const FormGridColumn = styled.div<IFormGridColumnProps>`
	grid-column: ${props => props.column};
`

const LayoutIllustrationLabel = styled.label`
	margin-bottom: ${SPACES.EXTRA_SMALL};
`

const FormButtons = styled.div`
	width: fit-content;
	margin-left: auto;
	margin-right: 0;

	> button:nth-of-type(1) {
		margin-right: ${SPACES.XXS};
	}
`

interface IModuleDetailsPageProps {
	module: IPageModule
	onEditPageModule: (data: IEditPageModule) => any
	isEditPageModuleLoading: boolean
	onRedirectToSinglePage: () => any
}

export const ModuleDetailsPage: React.FC<IModuleDetailsPageProps> = props => {
	const { module, isEditPageModuleLoading, onEditPageModule, onRedirectToSinglePage } = props

	const moduleOptions = Object.keys(PageModuleLayout).map(layoutKey => {
		const layout = (PageModuleLayout as any)[layoutKey]
		const predefinedLayout = PredefinedModuleConfigs[layout]
		const label = predefinedLayout.name
		const value = layout

		const option = { label, value }

		return option
	})

	const [form] = Form.useForm()

	const [nextLayoutName, setNextLayoutName] = React.useState<PageModuleLayout>(
		module.config.layoutName
	)
	const [fieldConfigs, setFieldConfigs] = React.useState<IPageModuleFieldConfig[]>([])

	const { regularFieldConfigs } = getRegularAndContentFieldConfigs(fieldConfigs)

	const resetForm = React.useCallback(
		(layoutName: PageModuleLayout) => {
			const predefinedFieldConfigs = getPredefinedLayoutFieldConfigs(layoutName)

			setFieldConfigs(predefinedFieldConfigs)

			const fieldValues = module.fieldValues

			const idsAndValues = predefinedFieldConfigs.map(fieldConfig => {
				const { id } = fieldConfig
				const moduleFieldValue = fieldValues[id]

				const value =
					moduleFieldValue !== undefined ? moduleFieldValue : getDefaultFieldValue(fieldConfig)

				return {
					id,
					value
				}
			})

			const formValues = {} as { [id: string]: any }

			for (const { id, value } of idsAndValues) {
				if (isContentInput(id) && value) {
					formValues[id] = (value as ContentInputValue).url
				} else {
					formValues[id] = value
				}
			}

			form.setFieldsValue({
				layoutName,
				...formValues
			})
		},
		[setFieldConfigs, module, form]
	)

	React.useEffect(() => {
		if (nextLayoutName) {
			resetForm(nextLayoutName)
		} else {
			resetForm(module.config.layoutName)
		}
	}, [nextLayoutName, resetForm, module.config.layoutName])

	const onLayoutNameChange = (value: string) => {
		setNextLayoutName(value as PageModuleLayout)
	}

	const LayoutIllustration = getModuleLayoutIllustration(nextLayoutName)

	return (
		<Form
			onFinish={values => {
				const { layoutName, ...fieldValues } = values

				// get ContentInputValue from urls
				for (const fieldId of Object.keys(fieldValues)) {
					if (isContentInput(fieldId)) {
						const value = getContentInputValueFromUrl(fieldValues[fieldId])
						fieldValues[fieldId] = value
					}
				}

				// filter out possible extra values that remained after changing layout
				const sanitizedFieldValues = {} as IPageModuleFieldValues
				for (const { id: fieldId } of fieldConfigs) {
					sanitizedFieldValues[fieldId] = fieldValues[fieldId]
				}

				onEditPageModule({
					layoutName,
					fieldValues: sanitizedFieldValues
				})

				onRedirectToSinglePage()
			}}
			form={form}
			layout="vertical"
		>
			<FormGrid>
				<FormGridColumn column={1}>
					<Form.Item label="Module" name="layoutName">
						<Select options={moduleOptions} onChange={onLayoutNameChange} />
					</Form.Item>
					{mapFieldConfigsToFields(regularFieldConfigs)}
				</FormGridColumn>

				<FormGridColumn column={2}>
					<LayoutIllustrationLabel>
						{PredefinedModuleConfigs[nextLayoutName].name}:
					</LayoutIllustrationLabel>
					<LayoutIllustration />
					{mapContentFieldConfigsToFields(fieldConfigs, form)}
				</FormGridColumn>
			</FormGrid>

			<FormButtons>
				<Popconfirm
					onConfirm={onRedirectToSinglePage}
					okText="Yes"
					cancelText="No"
					title="Are you sure you want to revert the changes?"
				>
					<Button type="primary" danger={true}>
						Cancel
					</Button>
				</Popconfirm>

				<Button type="primary" htmlType="submit" loading={isEditPageModuleLoading}>
					Save
				</Button>
			</FormButtons>
		</Form>
	)
}
