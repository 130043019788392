import * as React from 'react'
import styled from 'styled-components'
import UserRankingSystemForm, { IUserRankSystemFormInternal } from './UserRankingSystemForm'
import { Modal, message } from 'antd'
import { IUserAction } from 'brainstorming-types'

const URSTableWrapper = styled.div`
	width: 800px;
	margin: auto;
`

const UserRankSystemModalList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`

interface IProps {
	userActions: IUserAction[]
	saveGroup(data: IUserAction[]): void
}

interface IState {
	changedUserActions?: IUserAction[]
}

class UserRankingSystem extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)
		this.state = {}
	}

	handleOnSave = (formData: IUserRankSystemFormInternal) => {
		const newUserActions = formData.userActions.filter(
			action =>
				!this.props.userActions!.find(
					userAction => userAction.id === action.id && userAction.points === action.points
				)
		)
		this.setState({
			changedUserActions: newUserActions.length ? newUserActions : undefined
		})
		if (!newUserActions.length) {
			message.error('No changes to save.')
		}
	}

	handleOnCancel = () => {
		this.setState({ changedUserActions: undefined })
	}

	handleConfirmSave = () => {
		this.props.saveGroup(this.state.changedUserActions!)
		this.setState({
			changedUserActions: undefined
		})
	}

	render() {
		return (
			<URSTableWrapper>
				<UserRankingSystemForm
					handleCancel={this.handleOnCancel}
					userActions={this.props.userActions}
					onSave={this.handleOnSave}
				/>
				{this.state.changedUserActions && (
					<Modal
						title="Edit user ranking"
						visible={true}
						onOk={this.handleConfirmSave}
						onCancel={this.handleOnCancel}
						okText="Confirm"
						cancelText="Cancel"
					>
						<UserRankSystemModalList>
							<React.Fragment>
								<strong>Do you want to save this changes?</strong>
								{this.state.changedUserActions.map(action => (
									<li key={action.name}>
										<p>{action.name}</p>
										<span>{action.points}</span>
									</li>
								))}
							</React.Fragment>
						</UserRankSystemModalList>
					</Modal>
				)}
			</URSTableWrapper>
		)
	}
}

export default UserRankingSystem
