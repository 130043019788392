import { SESSION_MANAGEMENT } from '../../../config/urls'
import axios from 'axios'
import { stringify } from 'querystring'
import { IEditSolution } from './types'

export const fetchSolutions = async (params: any) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/admin/solutions`, {
			params,
			paramsSerializer: stringify
		})
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const deleteSolution = async (solutionId: number) => {
	return axios
		.delete(`${SESSION_MANAGEMENT}/admin/solutions/${solutionId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const editSolution = async (solutionId: number, data: IEditSolution) => {
	return axios
		.put(`${SESSION_MANAGEMENT}/admin/solutions/${solutionId}`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchSolution = async (id: number) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/solutions/${id}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const restoreSolution = async (solutionId: number) => {
	return axios
		.put(`${SESSION_MANAGEMENT}/admin/solutions/restore/${solutionId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}
