import { Button } from 'antd'
import * as React from 'react'
import { ITagForm, CreateEditTagModal } from './CreateEditTagModal'

export interface IAddTagButtonProps {
	isGroup: boolean
	onCreateTag: (data: ITagForm) => any
	isCreateTagLoading: boolean
}

export const AddTagButton: React.FC<IAddTagButtonProps> = props => {
	const { isGroup, onCreateTag, isCreateTagLoading } = props

	const [isModalVisible, setIsModalVisible] = React.useState(false)

	const onSave = (data: ITagForm) => {
		onCreateTag(data)
		setIsModalVisible(false)
	}

	const onCancel = () => {
		setIsModalVisible(false)
	}

	return (
		<>
			<CreateEditTagModal
				onSave={onSave}
				isSaving={isCreateTagLoading}
				open={isModalVisible}
				onClose={onCancel}
				isGroup={isGroup}
			/>
			<Button type="primary" onClick={() => setIsModalVisible(true)}>
				{`+ Add new${isGroup ? ' group ' : ' '}tag`}
			</Button>
		</>
	)
}
