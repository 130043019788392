import { IQuery } from 'brainstorming-types'
import { SortOrder } from 'antd/lib/table/interface'

export enum SortTypes {
	Latest = 'latest',
	TopVoted = 'topVoted'
}

export const mapSortTypesToQuery = (
	sortType: SortTypes,
	options: Partial<IQuery> = { skip: 0, limit: 10000 }
) => {
	const { limit, skip } = options
	const sortsPerSortType = {
		[SortTypes.Latest]: 'createdAt,DESC',
		[SortTypes.TopVoted]: 'numUpVotes,DESC'
	}
	const query: any = {}

	if (sortType) {
		query.sort = [sortsPerSortType[sortType]]
	}

	if (limit) {
		query.limit = limit
	}

	if (skip) {
		query.skip = skip
	}

	return query
}

export const mapTableToQuery = (page: number, pageSize: number, sortAnt: ISortUrl) => {
	const skip = (page - 1) * pageSize
	const limit = pageSize!
	let sort: any = []
	if (sortAnt.order) {
		const sortDirection = sortAnt.order === 'ascend' ? 'ASC' : 'DESC'
		sort = [`${sortAnt.column},${sortDirection}`]
	}
	return {
		skip,
		limit,
		sort
	}
}

export interface ISortUrl {
	column?: string | null
	order?: SortOrder | null
}
