import { Currency, IMoney, PaymentMethod } from 'brainstorming-types'

export const CurrencyLabels = Object.freeze({
	[Currency.USD]: '$',
	[Currency.BTC]: 'BTC',
	[Currency.SATOSHI]: 'SATOSHI'
})

export const PaymentMethodLabels = Object.freeze({
	[PaymentMethod.Bitcoin]: 'Bitcoin',
	[PaymentMethod.Other]: 'Other',
	[PaymentMethod.Paypal]: 'Paypal',
	[PaymentMethod.Wiretransfer]: 'Wiretransfer'
})

export const DEFAULT_CURRENCY = Currency.USD

export const getZeroMoney = (currency: Currency = DEFAULT_CURRENCY): IMoney => ({
	currency,
	amount: 0
})

export const DEFAULT_PAYMENT_METHOD = PaymentMethod.Paypal
