import { Middleware, AnyAction } from 'redux'
import { REQUEST, SUCCESS, FAILURE } from '../constants'
import { turnOnLoader, turnOffLoader } from './actions'

const loadersMiddleware: Middleware = ({ dispatch }) => (next: any) => (action: AnyAction) => {
	next(action)

	if (action.type.includes(REQUEST)) {
		dispatch(turnOnLoader(action.type))
	}

	if (action.type.includes(SUCCESS) || action.type.includes(FAILURE)) {
		dispatch(turnOffLoader(action.type))
	}
}

export default loadersMiddleware
