import { IGroup, IPermission } from 'brainstorming-types'
import {
	IApiError,
	IUpdatePermissionsGroupRequest as IUpdatePermissionsGroupRequestType,
	ICreateGroupRequest as ICreateGroupRequestType,
	IUpdateUsersGroupRequest as IUpdateUsersGroupRequestType
} from 'brainstorming-types'
import { IUserRankForm } from '../components/user-ranking/UserRankingGroupForm'
import { IBlackWhiteListForm } from '../components/whitelist-blacklist/WhitelistBlacklistForm'

export enum AclActionTypes {
	FetchGroupRequest = '@acl/FETCH_GROUP_REQUEST',
	FetchGroupSuccess = '@acl/FETCH_GROUP_SUCCESS',
	FetchGroupFailure = '@acl/FETCH_GROUP_FAILURE',
	FetchPermissionRequest = '@acl/FETCH_PERMISSION_REQUEST',
	FetchPermissionSuccess = '@acl/FETCH_PERMISSION_SUCCESS',
	FetchPermissionFailure = '@acl/FETCH_PERMISSION_FAILURE',
	UpdatePermissionsGroupRequest = '@acl/UPDATE_PERMISSIONS_GROUP_REQUEST',
	UpdatePermissionsGroupSuccess = '@acl/UPDATE_PERMISSIONS_GROUP_SUCCESS',
	UpdatePermissionsGroupFailure = '@acl/UPDATE_PERMISSIONS_GROUP_FAILURE',
	UpdateUsersGroupRequest = '@acl/UPDATE_USERS_GROUP_REQUEST',
	UpdateUsersGroupSuccess = '@acl/UPDATE_USERS_GROUP_SUCCESS',
	UpdateUsersGroupFailure = '@acl/UPDATE_USERS_GROUP_FAILURE',
	CreateGroupRequest = '@acl/CREATE_GROUP_REQUEST',
	CreateGroupSuccess = '@acl/CREATE_GROUP_SUCCESS',
	CreateGroupFailure = '@acl/CREATE_GROUP_FAILURE',
	EditGroupRequest = '@acl/EDIT_GROUP_REQUEST',
	EditGroupSuccess = '@acl/EDIT_GROUP_SUCCESS',
	EditGroupFailure = '@acl/EDIT_GROUP_FAILURE',
	DeleteGroupRequest = '@acl/DELETE_GROUP_REQUEST',
	DeleteGroupSuccess = '@acl/DELETE_GROUP_SUCCESS',
	DeleteGroupFailure = '@acl/DELETE_GROUP_FAILURE',
	CreateNewUserRankGroup = '@acl/CREATE_NEW_USER_RANK_GROUP',
	UpdateUserRankGroup = '@acl/UPDATE_USER_RANK_GROUP',
	CreateWhiteBlackListGroup = '@acl/CREATE_WHITE_BLACK_LIST_GROUP',
	UpdateWhiteBlackListGroup = '@acl/UPDATE_WHITE_BLACK_LIST_GROUP'
}

export interface IFetchGroupRequest {
	type: AclActionTypes.FetchGroupRequest
	payload: any
}

export interface IFetchGroupSuccess {
	type: AclActionTypes.FetchGroupSuccess
	payload: IGroup[]
}

export interface IFetchGroupFailure {
	type: AclActionTypes.FetchGroupFailure
	payload: IApiError
}

export interface IFetchPermissionRequest {
	type: AclActionTypes.FetchPermissionRequest
	payload: any
}

export interface IFetchPermissionSuccess {
	type: AclActionTypes.FetchPermissionSuccess
	payload: IPermission[]
}

export interface IFetchPermissionFailure {
	type: AclActionTypes.FetchPermissionFailure
	payload: IApiError
}

export interface IUpdatePermissionsGroupRequest {
	type: AclActionTypes.UpdatePermissionsGroupRequest
	payload: IUpdatePermissionsGroupRequestType
}

export interface IUpdatePermissionsGroupSuccess {
	type: AclActionTypes.UpdatePermissionsGroupSuccess
	payload: IGroup
}

export interface IUpdatePermissionsGroupFailure {
	type: AclActionTypes.UpdatePermissionsGroupFailure
	payload: IApiError
}

export interface IUpdateUsersGroupRequest {
	type: AclActionTypes.UpdateUsersGroupRequest
	payload: IUpdateUsersGroupRequestType
}

export interface IUpdateUsersGroupSuccess {
	type: AclActionTypes.UpdateUsersGroupSuccess
	payload: IGroup
}

export interface IUpdateUsersGroupFailure {
	type: AclActionTypes.UpdateUsersGroupFailure
	payload: IApiError
}

export interface ICreateGroupRequest {
	type: AclActionTypes.CreateGroupRequest
	payload: ICreateGroupRequestType
}

export interface ICreateGroupSuccess {
	type: AclActionTypes.CreateGroupSuccess
	payload: IGroup
}

export interface ICreateGroupFailure {
	type: AclActionTypes.CreateGroupFailure
	payload: IApiError
}

export interface IEditGroupRequest {
	type: AclActionTypes.EditGroupRequest
	payload: {
		id: number
		data: Partial<IGroup>
	}
}

export interface IEditGroupSuccess {
	type: AclActionTypes.EditGroupSuccess
	payload: IGroup
}

export interface IEditGroupFailure {
	type: AclActionTypes.EditGroupFailure
	payload: IApiError
}

export interface IDeleteGroupRequest {
	type: AclActionTypes.DeleteGroupRequest
	payload: number
}

export interface IDeleteGroupSuccess {
	type: AclActionTypes.DeleteGroupSuccess
	payload: number
}

export interface IDeleteGroupFailure {
	type: AclActionTypes.DeleteGroupFailure
	payload: IApiError
}

export interface ICreateNewUserRankGroup {
	type: AclActionTypes.CreateNewUserRankGroup
	payload: IUserRankForm
}

export interface IUpdateUserRankGroup {
	type: AclActionTypes.UpdateUserRankGroup
	payload: {
		id: number
		data: IUserRankForm
	}
}

export interface ICreateWhiteBlackListGroup {
	type: AclActionTypes.CreateWhiteBlackListGroup
	payload: IBlackWhiteListForm
}

export interface IUpdateWhiteBlackListGroup {
	type: AclActionTypes.UpdateWhiteBlackListGroup
	payload: {
		id: number
		data: IBlackWhiteListForm
	}
}

export type AclActions =
	| IFetchGroupSuccess
	| IFetchGroupRequest
	| IFetchGroupFailure
	| IFetchPermissionRequest
	| IFetchPermissionSuccess
	| IFetchPermissionFailure
	| IUpdatePermissionsGroupRequest
	| IUpdatePermissionsGroupSuccess
	| IUpdatePermissionsGroupFailure
	| ICreateGroupRequest
	| ICreateGroupSuccess
	| ICreateGroupFailure
	| IEditGroupRequest
	| IEditGroupSuccess
	| IEditGroupFailure
	| IDeleteGroupRequest
	| IDeleteGroupSuccess
	| IDeleteGroupFailure
	| ICreateNewUserRankGroup
	| IUpdateUserRankGroup
	| IUpdateUsersGroupSuccess
