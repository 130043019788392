import { IApplicationState } from '../../../store/types'
import { ITagsByTagId } from './types'

export const getRegularTags = (state: IApplicationState) => {
	const regularTagIds = state.tags.regularTagIds

	return regularTagIds.map(tagId => {
		return state.tags.tagsById[tagId]
	})
}

export const getTagGroupsById = (state: IApplicationState) => {
	const regularTagIds = state.tags.regularTagIds

	const tagGroupsById = {} as ITagsByTagId

	regularTagIds.forEach(tagId => {
		tagGroupsById[tagId] = state.tags.tagGroupsById[tagId]
	})

	return tagGroupsById
}

export const getGroupTags = (state: IApplicationState) => {
	const groupTagIds = state.tags.groupTagIds

	return groupTagIds.map(tagId => {
		return state.tags.tagsById[tagId]
	})
}

export const getGroupMembers = (groupId: number) => (state: IApplicationState) => {
	const members = state.tags.groupMembersById[groupId]

	return members || []
}

export const getGroupMembersById = (state: IApplicationState) => {
	const groupTagIds = state.tags.groupTagIds

	const groupMembersById = {} as ITagsByTagId

	groupTagIds.forEach(tagId => {
		groupMembersById[tagId] = state.tags.groupMembersById[tagId]
	})

	return groupMembersById
}

export const getTagGroups = (tagId: number) => (state: IApplicationState) => {
	const groups = state.tags.tagGroupsById[tagId]

	return groups || []
}

export const getTagSynonyms = (tagId: number) => (state: IApplicationState) => {
	const synonyms = state.tags.tagSynonymsById[tagId]

	return synonyms || []
}

export const getTagSynonymsById = (state: IApplicationState) => {
	const regularTagIds = state.tags.regularTagIds

	const tagSynonymsById = {} as ITagsByTagId

	regularTagIds.forEach(tagId => {
		tagSynonymsById[tagId] = state.tags.tagSynonymsById[tagId]
	})

	return tagSynonymsById
}

export const getTagById = (tagId: number) => (state: IApplicationState) => {
	return state.tags.tagsById[tagId]
}
