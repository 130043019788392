import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { updateSynonymsRequest } from '../store/actions'
import { TagsActionTypes } from '../store/action.types'

interface IUseUpdateSynonymsResult {
	onUpdateSynonyms: (tagId: number, synonymIds: number[]) => any
	isLoading: boolean
}

export const useUpdateSynonyms = (): IUseUpdateSynonymsResult => {
	const dispatch = useDispatch()

	const onUpdateSynonyms = useCallback(
		(groupId: number, synonymIds: number[]) => dispatch(updateSynonymsRequest(groupId, synonymIds)),
		[dispatch]
	)

	const loaders = useSelector(getLoadersState)
	const isUpdateSynonymsLoading = isLoading(loaders, [TagsActionTypes.UpdateSynonymsRequest], {
		default: false
	})

	return {
		onUpdateSynonyms,
		isLoading: isUpdateSynonymsLoading
	}
}
