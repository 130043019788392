import { ITags } from './types'

export const defaultTags: ITags = {
	tagsById: {},
	regularTagIds: [],
	groupTagIds: [],
	groupMembersById: {},
	tagGroupsById: {},
	tagSynonymsById: {}
}
