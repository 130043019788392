import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { mapTableToQuery } from '../../../utils/sorting'
import { SortOrder } from 'antd/lib/table/interface'
import { fetchSolutionsRequest } from '../store/actions'
import { SolutionsActionType } from '../store/types'
import { getSolutionsState, getSolutions } from '../store/selectors'
import { SOLUTIONS_PER_PAGE } from '../containers/SolutionDashboardContainer'

interface IUseSolutions {
	page?: number | null
	column?: string | null
	direction?: string | null
}

const useSolutions = ({ page = 1, column, direction }: IUseSolutions) => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(
			fetchSolutionsRequest(
				mapTableToQuery(page as number, SOLUTIONS_PER_PAGE, {
					column,
					order: direction as SortOrder
				})
			)
		)
	}, [column, direction, dispatch, page])

	const solutionsState = useSelector(getSolutionsState)
	const loaders = useSelector(getLoadersState)
	const isLoadingSolutions = isLoading(loaders, [SolutionsActionType.FetchSolutionsRequest])
	const solutions = useSelector(getSolutions)

	return { solutions, isLoading: isLoadingSolutions, solutionsState }
}

export default useSolutions
