import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { createPageRequest } from '../store/actions'
import { ICreatePage, PagesActionType } from '../store/types'

interface IUseCreatePageResult {
	onCreatePage: (data: ICreatePage) => unknown
	isLoading: boolean
}

export const useCreatePage = (): IUseCreatePageResult => {
	const dispatch = useDispatch()

	const onCreatePage = useCallback((data: ICreatePage) => dispatch(createPageRequest(data)), [
		dispatch
	])

	const loaders = useSelector(getLoadersState)
	const isCreatePageLoading = isLoading(loaders, [PagesActionType.CreatePageRequest], {
		default: false
	})

	return {
		onCreatePage,
		isLoading: isCreatePageLoading
	}
}
