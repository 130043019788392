import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Divider, PageHeader } from 'antd'
import styled from 'styled-components'

import { useEditPageModuleOrder, usePage, useDeleteModule } from '../hooks'
import { Routes } from '../../../config/routes'
import Page from '../../../components/layout/Page'
import { PageDetails } from '../components/dashboard/PageDetails'
import { PageModuleOrder } from '../components/dashboard/PageModuleOrder'
import { IPageModule } from 'brainstorming-types'
import { useCreateModule } from '../hooks/useCreateModule'
import { AddModule } from '../components/dashboard/AddModule'
import { redirectToSingleModule } from '../utils/helpers'
import { TogglePageLayoutRepeat } from '../components/dashboard/TogglePageLayoutRepeat'

const StyledDiv = styled.div`
	//
`

interface ISinglePageParams {
	pageId: string | undefined
}

export const SinglePageContainer = () => {
	const params = useParams<ISinglePageParams>()
	const pageId = parseInt(params.pageId as string, 10)

	const { page, isLoading: isPageLoading } = usePage(pageId)

	const { onEditPageModuleOrder } = useEditPageModuleOrder(pageId)

	const { onCreateModule, isLoading: isCreateModuleLoading } = useCreateModule(pageId)
	const { onDeleteModule, isLoading: isDeleteModuleLoading } = useDeleteModule()

	const history = useHistory()

	const onOpenSingleModule = React.useCallback(
		(moduleId: number) => redirectToSingleModule(history, pageId, moduleId),
		[history, pageId]
	)

	return (
		<Page
			isLoading={isPageLoading}
			loadingText="Loading Page data"
			breadcrumbItems={[
				{ name: 'Pages', path: Routes.Pages.route },
				{
					name: page?.name || '',
					path: Routes.Pages.singlePageRoute.replace(':pageId', pageId.toString())
				}
			]}
		>
			{page && (
				<StyledDiv>
					<PageHeader title="Details" />
					<PageDetails page={page} />
					<Divider />
					<PageHeader title="Layout" />
					<PageModuleOrder
						pageModules={page.modules as IPageModule[]}
						pageModuleOrder={page.moduleOrder}
						onEditPageModuleOrder={onEditPageModuleOrder}
						onOpenSingleModule={onOpenSingleModule}
						onDeleteModule={onDeleteModule}
						isDeleteModuleLoading={isDeleteModuleLoading}
					/>
					<AddModule
						onCreateModule={onCreateModule}
						isCreateModuleLoading={isCreateModuleLoading}
					/>
					<TogglePageLayoutRepeat page={page} />
				</StyledDiv>
			)}
		</Page>
	)
}
