import { SessionsActionType } from './types'
import { IApiError, IPaginate, ISession } from 'brainstorming-types'
import { IActionQuery } from '../../../store/types'

export const fetchSessionsRequest = (query: IActionQuery) => ({
	type: SessionsActionType.FetchSessionsRequest,
	payload: query
})

export const fetchSessionsSuccess = (data: IPaginate<ISession>) => ({
	type: SessionsActionType.FetchSessionsSuccess,
	payload: data
})

export const fetchSessionsFailure = (error: IApiError) => ({
	type: SessionsActionType.FetchSessionsFailure,
	payload: error
})

export const fetchSessionDetailsRequest = (id: number) => ({
	type: SessionsActionType.FetchSessionDetailsRequest,
	payload: id
})

export const fetchSessionDetailsSuccess = (data: ISession) => ({
	type: SessionsActionType.FetchSessionDetailsSuccess,
	payload: data
})

export const fetchSessionDetailsFailure = (error: IApiError) => ({
	type: SessionsActionType.FetchSessionDetailsFailure,
	payload: error
})

export const deleteSessionRequest = (sessionId: number) => ({
	type: SessionsActionType.DeleteSessionRequest,
	payload: sessionId
})

export const restoreSessionSuccess = (data: any) => ({
	type: SessionsActionType.RestoreSessionSuccess,
	payload: data
})

export const restoreSessionFailure = (error: IApiError) => ({
	type: SessionsActionType.RestoreSessionFailure,
	payload: error
})

export const restoreSessionRequest = (sessionId: number) => ({
	type: SessionsActionType.RestoreSessionRequest,
	payload: sessionId
})

export const deleteSessionSuccess = (sessionId: number) => ({
	type: SessionsActionType.DeleteSessionSuccess,
	payload: sessionId
})

export const deleteSessionFailure = (error: IApiError) => ({
	type: SessionsActionType.DeleteSessionFailure,
	payload: error
})

export const editSessionRequest = (data: any, sessionId: number) => ({
	type: SessionsActionType.EditSessionRequest,
	payload: { data, sessionId }
})

export const editSessionSuccess = (session: ISession) => ({
	type: SessionsActionType.EditSessionSuccess,
	payload: session
})

export const editSessionFailure = (error: IApiError) => ({
	type: SessionsActionType.EditSessionFailure,
	payload: error
})
