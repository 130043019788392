import produce from 'immer'

import { defaultAcl } from '../constants'
import { IAcl } from './models.types'
import { AclActions, AclActionTypes } from './actions.types'

export const aclReducer = produce((state: IAcl = defaultAcl, action: AclActions) => {
	switch (action.type) {
		case AclActionTypes.FetchGroupSuccess:
			state.groups = action.payload
			return state
		case AclActionTypes.CreateGroupSuccess:
			state.groups = [...state.groups, action.payload]
			return state
		case AclActionTypes.DeleteGroupRequest:
			state.groups = state.groups.filter(group => group.id !== action.payload)
			return state
		case AclActionTypes.UpdatePermissionsGroupSuccess: {
			state.groups = state.groups.map(group => {
				if (group.id === action.payload.id) {
					return { ...group, permissions: action.payload.permissions }
				}
				return group
			})
			return state
		}
		case AclActionTypes.UpdateUsersGroupSuccess: {
			state.groups = state.groups.map(group => {
				if (group.id === action.payload.id) {
					return { ...group, users: action.payload.users }
				}
				return group
			})
			return state
		}
		case AclActionTypes.EditGroupSuccess:
			state.groups = state.groups.map(group => {
				if (group.id === action.payload.id) {
					return { ...group, ...action.payload }
				}
				return group
			})
			return state
		case AclActionTypes.FetchPermissionSuccess:
			state.permissions = action.payload
			return state
		default:
			return state
	}
})
