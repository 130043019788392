import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { deletePageRequest } from '../store/actions'
import { PagesActionType } from '../store/types'

interface IUseDeletePageResult {
	onDeletePage: (pageId: number) => unknown
	isLoading: boolean
}

export const useDeletePage = (): IUseDeletePageResult => {
	const dispatch = useDispatch()

	const onDeletePage = useCallback((pageId: number) => dispatch(deletePageRequest(pageId)), [
		dispatch
	])

	const loaders = useSelector(getLoadersState)
	const isDeletePageLoading = isLoading(loaders, [PagesActionType.DeletePageRequest], {
		default: false
	})

	return {
		onDeletePage,
		isLoading: isDeletePageLoading
	}
}
