import * as React from 'react'

export const ContentLayout3Illustration = (props: any) => (
	<svg viewBox="0 0 346 152" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g transform="translate(-13512 5509)" data-name="Group 2815">
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2647">
				<path stroke="none" d="M13690-5494h155v57h-155z" />
				<path fill="none" d="M13690.5-5493.5h154v56h-154z" />
			</g>
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2649">
				<path stroke="none" d="M13525-5457h155v86h-155z" />
				<path fill="none" d="M13525.5-5456.5h154v85h-154z" />
			</g>
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2648">
				<path stroke="none" d="M13690-5427h155v56h-155z" />
				<path fill="none" d="M13690.5-5426.5h154v55h-154z" />
			</g>
			<g fill="#e1e1f0" stroke="#9195be" data-name="Rectangle 2629">
				<path stroke="none" d="M13525-5494h155v72h-155z" />
				<path fill="none" d="M13525.5-5493.5h154v71h-154z" />
			</g>
			<g fill="none" stroke="#707070" data-name="Rectangle 2623">
				<path stroke="none" d="M13512-5509h346v152h-346z" />
				<path d="M13512.5-5508.5h345v151h-345z" />
			</g>
			<path fill="#e1e1f0" data-name="Rectangle 2630" d="M13529-5400h146v5h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2631" d="M13529-5393h146v6h-146z" />
			<rect
				transform="translate(13631 -5382)"
				width={20}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2632"
			/>
			<rect
				transform="translate(13529 -5411)"
				width={20}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2644"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2645" d="M13551-5411h20v6h-20z" />
			<rect
				transform="translate(13654 -5382)"
				width={21}
				height={6}
				rx={3}
				fill="#e1e1f0"
				data-name="Rectangle 2633"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2625" d="M13694-5478h146v5h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2634" d="M13694-5470h146v5h-146z" />
			<rect
				transform="translate(13777 -5449)"
				width={21}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2635"
			/>
			<rect
				transform="translate(13694 -5488)"
				width={21}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2636"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2637" d="M13717-5488h20v5h-20z" />
			<rect
				transform="translate(13801 -5449)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2638"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2626" d="M13694-5412h146v5h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2640" d="M13694-5404h146v5h-146z" />
			<rect
				transform="translate(13777 -5382)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2641"
			/>
			<rect
				transform="translate(13694 -5422)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2627"
			/>
			<path fill="#e1e1f0" data-name="Rectangle 2628" d="M13717-5422h20v5h-20z" />
			<rect
				transform="translate(13801 -5382)"
				width={20}
				height={5}
				rx={2.5}
				fill="#e1e1f0"
				data-name="Rectangle 2642"
			/>
			<text
				transform="translate(13664 -5406)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={1}
			>
				<tspan x={0} y={0}>
					{'1'}
				</tspan>
			</text>
			<text
				transform="translate(13832 -5445)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={2}
			>
				<tspan x={0} y={0}>
					{'2'}
				</tspan>
			</text>
			<text
				transform="translate(13831 -5378)"
				fill="#33385a"
				fontFamily="Helvetica"
				fontSize={15}
				letterSpacing=".1em"
				stroke="rgba(0,0,0,0)"
				data-name={3}
			>
				<tspan x={0} y={0}>
					{'3'}
				</tspan>
			</text>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 617"
				d="m13526-5422.775 154-70.925"
			/>
			<path
				fill="none"
				stroke="#9195be"
				strokeLinecap="round"
				data-name="Line 618"
				d="m13680-5422.775-154-70.925"
			/>
		</g>
	</svg>
)
