import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSessionRequest } from '../store/actions'
import { isLoading, getLoadersState } from '../../../store/common/loaders/selector'
import { SessionsActionType } from '../store/types'
import usePrevious from '../../../hooks/usePrevious'

const useDeleteSession = () => {
	const [sessionId, setSessionId] = React.useState<number | null>(null)
	const dispatch = useDispatch()

	const loaders = useSelector(getLoadersState)
	const isDeletingSession = isLoading(loaders, [SessionsActionType.DeleteSessionRequest], {
		default: false
	})
	const prevIsDeleingSession = usePrevious(isDeletingSession)

	React.useEffect(() => {
		if (prevIsDeleingSession && !isDeletingSession) {
			setSessionId(null)
		}
	}, [prevIsDeleingSession, isDeletingSession])

	const handleDeleteSession = (sessionId: number) => {
		setSessionId(sessionId)
	}

	const handleConfirmDeleteSession = () => {
		dispatch(deleteSessionRequest(sessionId as number))
	}

	const handleCancelDeleteSession = () => {
		setSessionId(null)
	}

	return {
		sessionId,
		isDeletingSession,
		handleDeleteSession,
		handleConfirmDeleteSession,
		handleCancelDeleteSession
	}
}

export default useDeleteSession
