import * as React from 'react'
import { Modal, Button, Timeline } from 'antd'
import { printDateAndTime } from '../../utils/dateAndTimeFormatters'
import { IContentAuditLog } from 'brainstorming-types'

interface IProps {
	historyLogModalState: boolean
	handleCloseHistoryLog(): void
	historyLog: IContentAuditLog[] | null
}

const HistoryLogModal: React.FC<IProps> = ({
	historyLogModalState,
	handleCloseHistoryLog,
	historyLog
}) => {
	return (
		<Modal
			title="History"
			visible={historyLogModalState}
			onCancel={handleCloseHistoryLog}
			footer={[
				<Button key="back" onClick={handleCloseHistoryLog}>
					Close
				</Button>
			]}
		>
			<Timeline>
				{historyLog && historyLog.length > 0 ? (
					historyLog.map(log => (
						<Timeline.Item>
							{printDateAndTime(log.updatedAt)} , {log.actionUser.fullName},{' '}
							{log.auditableContentAction}
						</Timeline.Item>
					))
				) : (
					<Timeline.Item>No history log</Timeline.Item>
				)}
			</Timeline>
		</Modal>
	)
}

export default HistoryLogModal
