import * as React from 'react'
import { useCallback } from 'react'
import { Divider, PageHeader } from 'antd'
import { useHistory } from 'react-router-dom'

import { useAddStaff, useStaff } from '../hooks'
import { Routes } from '../../../config/routes'
import Page from '../../../components/layout/Page'
import { ActiveStaffTable } from '../components/dashboard/ActiveStaffTable'
import { InactiveStaffTable } from '../components/dashboard/InactiveStaffTable'
import { redirectToSingleUserPage } from '../utils/helpers'
import { AddStaffButton } from '../components/dashboard/AddStaffButton'

export const StaffDashboardContainer = () => {
	const { activeStaff, inactiveStaff, isLoading } = useStaff()
	const history = useHistory()

	const onOpenDetails = useCallback(
		(staffMemberId: number) => redirectToSingleUserPage(history, staffMemberId),
		[history]
	)

	const { onAddStaff, isLoading: isAddStaffLoading } = useAddStaff()

	return (
		<Page
			isLoading={isLoading}
			loadingText="Loading Staff data"
			breadcrumbItems={[{ name: 'Staff accounting', path: Routes.StaffAccounting.route }]}
		>
			<PageHeader title="Active users" />
			<AddStaffButton
				onAddStaff={onAddStaff}
				isAddStaffLoading={isAddStaffLoading}
				activeStaff={activeStaff}
			/>
			<ActiveStaffTable staff={activeStaff} isLoading={isLoading} onOpenDetails={onOpenDetails} />

			<Divider />

			<PageHeader title="Inactive users" />
			<InactiveStaffTable
				staff={inactiveStaff}
				isLoading={isLoading}
				onOpenDetails={onOpenDetails}
			/>
		</Page>
	)
}
