import { IApiError } from 'brainstorming-types'
import { ILogoutSuccess } from '../../../features/auth/store/actions.types'

export enum ErrorsActionType {
	SetError = '@error/SET_ERROR',
	ClearError = '@error/CLEAR_ERROR'
}

export interface ISetError {
	type: ErrorsActionType.SetError
	payload: {
		action: string
		error: IApiError
	}
}

export interface IClearError {
	type: ErrorsActionType.ClearError
	payload: any
}

export type ErrorActions = ISetError | IClearError | ILogoutSuccess

export interface IErrors {
	[action: string]: IApiError
}
