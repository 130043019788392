import * as React from 'react'
import Breadcrumb, { IBreadcrumbItem } from './Breadcrumb'
import styled from 'styled-components'
import { Spin } from 'antd'
import { SPACES } from '../../styles/spaces'

interface IProps {
	isLoading: boolean
	loadingText?: string
	breadcrumbItems: IBreadcrumbItem[]
	children: React.ReactElement | React.ReactElement[]
}

const Wrapper = styled.div`
	margin: 0 ${SPACES.LARGE};
`

const Page: React.FC<IProps> = ({ isLoading, loadingText, breadcrumbItems, children }) => {
	return (
		<React.Fragment>
			<Breadcrumb items={breadcrumbItems} />
			{isLoading ? (
				<Spin
					size="large"
					tip={loadingText}
					style={{ position: 'absolute', left: '50%', top: '200px' }}
				/>
			) : (
				<Wrapper>{children}</Wrapper>
			)}
		</React.Fragment>
	)
}

export default Page
