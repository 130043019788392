import * as React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Layout, Button, Menu, Avatar, Typography, Dropdown } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Routes } from '../../config/routes'
import { SPACES } from '../../styles/spaces'
import { getUser } from '../../features/auth/store/selectors'
import { logout } from '../../features/auth/store/actions'

const margin = `0 ${SPACES.EXTRA_SMALL} 0 ${SPACES.EXTRA_SMALL}`

// const HeaderModeChange = styled(Menu.Item)`
// 	margin-top: -5px !important;
// 	top: 0 !important;
// 	border-bottom: 0 !important;
// `

// const HeaderModeMenu = styled(Menu)`
// 	border-bottom: 0;
// 	line-height: 64px;
// `

const Header: React.FC = () => {
	const user = useSelector(getUser)

	const dispatch = useDispatch()

	const handleLogout = () => {
		dispatch(logout())
	}

	const menu = (
		<Menu>
			{/* <Menu.Item key="0">
				<Link to="/profile">See profile</Link>
			</Menu.Item> */}
			<Menu.Item onClick={handleLogout} key="1">
				<span>Logout</span>
			</Menu.Item>
		</Menu>
	)

	return (
		<StyledHeader className="header">
			<LeftHeader>
				<Link to={Routes.Dashboard.route}>
					<img src="/assets/logo.png" alt="brainstorming" />
				</Link>
			</LeftHeader>
			<RightHeader>
				{/* <HeaderModeMenu theme="light" mode="horizontal" defaultSelectedKeys={['admin']}>
					<HeaderModeChange key="admin">ADMIN</HeaderModeChange>
					<HeaderModeChange key="moderator">MODERATOR</HeaderModeChange>
				</HeaderModeMenu>
				<Button shape="circle" style={{ margin, border: 0 }} icon="bell" /> */}
				<Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
					<Button style={{ margin, border: 0 }}>
						<Avatar icon={<UserOutlined />} />
						<Typography.Text style={{ margin, border: 0 }}>{user!.fullName}</Typography.Text>
					</Button>
				</Dropdown>
			</RightHeader>
		</StyledHeader>
	)
}

const LeftHeader = styled.div`
	float: left;
`

const RightHeader = styled.div`
	height: 100%;
	float: right;
	display: flex;
	align-items: center;
`

const StyledHeader = styled(Layout.Header)`
	&&& {
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
		position: fixed;
		z-index: 2;
		width: 100%;
		background: #fff;
		padding: 0 ${SPACES.DEFAULT};
	}
`

export default Header
