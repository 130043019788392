import * as React from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { IApplicationState } from '../../../store/types'
import { loginRequest } from '../store/actions'
import LoginForm from '../components/LoginForm'
import { SPACES } from '../../../styles/spaces'
import { isLoading } from '../../../store/common/loaders/selector'
import { AuthActionType } from '../store/actions.types'
import { ILoginRequest } from 'brainstorming-types'

interface IProps {
	isSubmitting: boolean
	login(data: ILoginRequest): void
}

const LoginPage = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`

const LoginWrapper = styled.div`
	background-color: white;
	padding: ${SPACES.DEFAULT};
	border-radius: 8px;
	.ant-form-item {
		.ant-form-explain {
			text-align: left;
		}
	}
`
// TODO: move all redux to hooks
const LoginContainer: React.FC<IProps> = (props: IProps) => {
	return (
		<LoginPage>
			<LoginWrapper>
				<LoginForm login={props.login} isSubmitting={props.isSubmitting} />
			</LoginWrapper>
		</LoginPage>
	)
}

const mapStateToProps = (state: IApplicationState) => ({
	isSubmitting: isLoading(state.loaders, [AuthActionType.LoginRequest], { default: false })
})

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ login: loginRequest }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
