import styled from 'styled-components'
import { SPACES } from '../../../../../styles/spaces'

export const StyledButtonDiv = styled.div`
	display: inline-flex;
	flex-direction: row-reverse;
	padding: ${SPACES.EXTRA_SMALL};
	padding-right: 0;
	width: 100%;

	> input {
		width: 250px;
		margin-right: ${SPACES.EXTRA_SMALL};
	}
`
