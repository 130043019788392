import { CommentsActionType, IEditComment } from './types'
import { IComment, IApiError, IPaginate } from 'brainstorming-types'

export const fetchCommentsFailure = (error: IApiError) => ({
	type: CommentsActionType.FetchCommentsFailure,
	payload: error
})

export const fetchCommentsRequest = (query: any) => ({
	type: CommentsActionType.FetchCommentsRequest,
	payload: query
})

export const fetchCommentsSuccess = (data: IPaginate<IComment>) => ({
	type: CommentsActionType.FetchCommentsSuccess,
	payload: data
})

export const deleteCommentFailure = (error: IApiError) => ({
	type: CommentsActionType.DeleteCommentFailure,
	payload: error
})

export const deleteCommentRequest = (commentId: number) => ({
	type: CommentsActionType.DeleteCommentRequest,
	payload: commentId
})

export const deleteCommentSuccess = (commentId: number) => ({
	type: CommentsActionType.DeleteCommentSuccess,
	payload: commentId
})

export const editCommentFailure = (error: IApiError) => ({
	type: CommentsActionType.EditCommentFailure,
	payload: error
})

export const editCommentRequest = (commentId: number, data: IEditComment) => ({
	type: CommentsActionType.EditCommentRequest,
	payload: { commentId, data }
})

export const editCommentSuccess = (comment: IComment) => ({
	type: CommentsActionType.EditCommentSuccess,
	payload: comment
})

export const restoreCommentSuccess = (data: any) => ({
	type: CommentsActionType.RestoreCommentSuccess,
	payload: data
})

export const restoreCommentFailure = (error: IApiError) => ({
	type: CommentsActionType.RestoreCommentFailure,
	payload: error
})

export const restoreCommentRequest = (commentId: number) => ({
	type: CommentsActionType.RestoreCommentRequest,
	payload: commentId
})
