import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { createPaymentForStaffMemberRequest } from '../store/actions'
import { ICreateStaffPayment, StaffActionType } from '../store/types'

interface IUseCreateStaffPaymentResult {
	onCreateStaffPayment: (data: ICreateStaffPayment) => any
	isLoading: boolean
}

export const useCreateStaffPayment = (staffMemberId: number): IUseCreateStaffPaymentResult => {
	const dispatch = useDispatch()

	const onCreateStaffPayment = useCallback(
		(data: ICreateStaffPayment) =>
			dispatch(createPaymentForStaffMemberRequest(staffMemberId, data)),
		[dispatch, staffMemberId]
	)

	const loaders = useSelector(getLoadersState)
	const isEditStaffMemberLoading = isLoading(
		loaders,
		[StaffActionType.CreatePaymentForStaffMemberRequest],
		{ default: false }
	)

	return {
		onCreateStaffPayment,
		isLoading: isEditStaffMemberLoading
	}
}
