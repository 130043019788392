import * as React from 'react'
import Page from '../../../components/layout/Page'

const DashboardContainer: React.FC = () => {
	return (
		<Page isLoading={false} loadingText="Loading" breadcrumbItems={[]}>
			<h2 style={{ marginLeft: '24px' }}>Brainstorming Dashboard</h2>
		</Page>
	)
}

export default DashboardContainer
