import * as React from 'react'
import { ITag } from 'brainstorming-types'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { Form, Icon as LegacyIcon } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Modal, Button, Input, Upload, Checkbox } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import styled from 'styled-components'
import { RcFile } from 'antd/lib/upload'
import { SPACES } from '../../../../../styles/spaces'
import usePrevious from '../../../../../hooks/usePrevious'
import { getTagByName } from '../../../store/api'

interface ILocalProps {
	tag?: ITag
	open: boolean
	isSaving: boolean
	onClose(): void
	onSave(values: ITagForm, tag?: ITag): void

	isGroup?: boolean
}

export interface ITagForm {
	image?: RcFile | string
	name: string
	isGroup: boolean
}

interface IFormProps extends FormComponentProps<ITagForm> {}

interface IProps extends ILocalProps, IFormProps {}

const StyledUpload = styled(Form.Item)`
	.ant-upload {
		float: none;
		margin-top: 16px;
	}
	img {
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
	.has-error {
		.ant-upload {
			border-color: #f5222d;
			background-color: #ffe7e7;
		}
		.ant-form-explain {
			bottom: -20px;
		}
	}
	.ant-form-item-control {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: ${SPACES.SMALL};
	}
	.ant-form-item-children {
		display: inline-flex;
	}
	.ant-legacy-form-item-children {
		margin: 0;
		padding: 0;
	}
`

const CurrentTagImage = styled.div`
	margin: 0;
	img {
		width: 100px;
		height: 100px;
	}
`

const NoImage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 90px;
`

const TagModal: React.FC<IProps> = ({ tag, open, onClose, form, onSave, isSaving, isGroup }) => {
	const [file, setFile] = React.useState('')
	const [validationSuccess, setValidationSuccess] = React.useState(false)

	const prevIsSaving = usePrevious(isSaving)

	React.useEffect(() => {
		if (prevIsSaving !== isSaving && !isSaving) {
			setFile('')
			form.resetFields(['name', 'image', 'isGroup'])
			setValidationSuccess(false)
		}
	}, [prevIsSaving, isSaving, form])

	const handleOnConfirm = async () => {
		await validateTagName()
		form.validateFields(['image'], (errors, values) => {
			if (!errors && !form.getFieldError('name')) {
				const data = form.getFieldsValue(['name', 'image', 'isGroup']) as ITagForm

				onSave(data, tag)
			}
		})
	}

	const handleOnCancel = () => {
		setFile('')
		onClose()
		setValidationSuccess(false)
		form.resetFields(['name', 'image'])
	}

	const onBeforeUpload = (file: RcFile, _: RcFile[]) => {
		form.setFieldsValue({ image: file })
		getBase64(file)
		return false
	}

	const getBase64 = (file: RcFile) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setFile(reader.result as string)
		}
	}

	const validateTagName = async () => {
		const nameFieldValue = form.getFieldValue('name')
		if (!nameFieldValue) {
			form.setFields({
				name: {
					value: nameFieldValue,
					errors: [new Error('Tag name is required!')]
				}
			})
		}

		const existingTagResponse = ((await getTagByName(nameFieldValue)) as unknown) as any
		const existingTag = (existingTagResponse.response as unknown) as ITag

		if ((existingTag && !tag) || (existingTag && tag && existingTag.id !== tag.id)) {
			form.setFields({
				name: {
					value: nameFieldValue,
					errors: [new Error('Tag name has already been taken')]
				}
			})
			setValidationSuccess(false)
		} else {
			setValidationSuccess(true)
		}
	}

	const { getFieldDecorator } = form

	const GroupWarning = styled.span`
		display: inline-block;
		margin-left: 8px;
	`

	// TODO: za Filipa
	const isGroupWarning: string = tag
		? 'Changing this will remove all group and synonym settings'
		: ''

	return (
		<Modal
			visible={open}
			title={tag ? 'Edit tag' : 'Add new tag'}
			onOk={handleOnConfirm}
			onCancel={handleOnCancel}
			width={580}
			footer={[
				<Button key="cancel" onClick={handleOnCancel} disabled={isSaving}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={handleOnConfirm} loading={isSaving}>
					Save
				</Button>
			]}
		>
			<Form onSubmit={handleOnConfirm}>
				<Form.Item label="Tag name">
					{getFieldDecorator('name', {
						initialValue: tag ? tag.name : '',
						rules: [{ required: true, message: 'Tag name is required!' }]
					})(
						<Input
							placeholder="Tag name"
							size="large"
							suffix={
								<React.Fragment>
									{validationSuccess && (
										<CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: 8 }} />
									)}
									<Button type="primary" style={{ marginRight: '-8px' }} onClick={validateTagName}>
										Check
									</Button>
								</React.Fragment>
							}
						/>
					)}
				</Form.Item>

				<Form.Item label="Is group tag">
					{getFieldDecorator('isGroup', {
						initialValue: tag ? tag.isGroup : isGroup ? true : false,
						valuePropName: 'checked'
					})(<Checkbox />)}
					{isGroupWarning && <GroupWarning>{isGroupWarning}</GroupWarning>}
				</Form.Item>

				<StyledUpload label="Upload new tag photo (png, jpg, jpeg)">
					{getFieldDecorator('image', {
						valuePropName: 'image',
						initialValue: tag ? tag.image_url : undefined
					})(
						<React.Fragment>
							{tag && (
								<CurrentTagImage>
									<p>Current tag photo</p>
									{tag.image_url ? (
										<img src={tag.image_url} alt={tag.name} />
									) : (
										<NoImage>No image</NoImage>
									)}
								</CurrentTagImage>
							)}
							<Upload
								name="image"
								listType="picture-card"
								beforeUpload={onBeforeUpload}
								showUploadList={false}
							>
								<React.Fragment>
									{file ? (
										<div>
											<img src={file} alt="new tag" />
										</div>
									) : (
										<div>
											<LegacyIcon type={'plus'} />
										</div>
									)}
								</React.Fragment>
							</Upload>
						</React.Fragment>
					)}
				</StyledUpload>
			</Form>
		</Modal>
	)
}

export const CreateEditTagModal = Form.create<IProps>({ name: 'tag_form' })(TagModal)
