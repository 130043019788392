import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { editPagesRequest } from '../store/actions'
import { IEditPagesBatch, PagesActionType } from '../store/types'

interface IUseEditPagesResult {
	onEditPages: (data: IEditPagesBatch) => any
	isLoading: boolean
}

export const useEditPages = (): IUseEditPagesResult => {
	const dispatch = useDispatch()

	const onEditPages = useCallback((data: IEditPagesBatch) => dispatch(editPagesRequest(data)), [
		dispatch
	])

	const loaders = useSelector(getLoadersState)
	const isEditPagesLoading = isLoading(loaders, [PagesActionType.EditPagesRequest], {
		default: false
	})

	return {
		onEditPages,
		isLoading: isEditPagesLoading
	}
}
