import { call, put, takeEvery, all } from 'redux-saga/effects'
import { CommentsActionType } from './types'
import { AnyAction } from 'redux'
import { fetchComments, deleteComment, editComment, restoreComment } from './api'
import {
	fetchCommentsFailure,
	fetchCommentsSuccess,
	deleteCommentSuccess,
	deleteCommentFailure,
	editCommentSuccess,
	editCommentFailure,
	restoreCommentSuccess,
	restoreCommentFailure
} from './actions'
import { notification } from 'antd'

// Handlers
function* handleFetchComments(action: AnyAction) {
	const { response, error } = yield call(fetchComments, action.payload)
	if (response) {
		yield put(fetchCommentsSuccess(response))
	} else {
		yield put(fetchCommentsFailure(error))
	}
}

function* handleDeleteComment(action: AnyAction) {
	const { response, error } = yield call(deleteComment, action.payload)
	if (response) {
		yield put(deleteCommentSuccess(action.payload))
		notification.success({ message: `Comment successfully deleted!` })
	} else {
		yield put(deleteCommentFailure(error))
		notification.error({ message: `Error happened. Comment is not deleted!` })
	}
}

function* handleEditComment(action: AnyAction) {
	const { response, error } = yield call(editComment, action.payload.commentId, action.payload.data)
	if (response) {
		yield put(editCommentSuccess(response))
		notification.success({ message: `Comment successfully edited!` })
	} else {
		yield put(editCommentFailure(error))
		notification.error({ message: `Error happened. Comment is not edited!` })
	}
}

function* handleRestoreComments(action: AnyAction) {
	const { response, error } = yield call(restoreComment, action.payload)
	if (response) {
		yield put(restoreCommentSuccess(response))
		notification.success({ message: `Comment successfully restored!` })
	} else {
		yield put(restoreCommentFailure(error))
		notification.error({ message: `Error happened. Comment is not restored!` })
	}
}

// Watchers
function* watchFetchComments() {
	yield takeEvery(CommentsActionType.FetchCommentsRequest, handleFetchComments)
}

function* watchDeleteComment() {
	yield takeEvery(CommentsActionType.DeleteCommentRequest, handleDeleteComment)
}

function* watchEditComment() {
	yield takeEvery(CommentsActionType.EditCommentRequest, handleEditComment)
}

function* watchRestoreComment() {
	yield takeEvery(CommentsActionType.RestoreCommentRequest, handleRestoreComments)
}

export default function* rootSaga() {
	yield all([watchFetchComments(), watchDeleteComment(), watchEditComment(), watchRestoreComment()])
}
