import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { restoreCommentRequest } from '../store/actions'
import { isLoading, getLoadersState } from '../../../store/common/loaders/selector'
import { CommentsActionType } from '../store/types'
import usePrevious from '../../../hooks/usePrevious'

const useRestoreComments = () => {
	const [restoreCommentId, setRestoreCommentId] = React.useState<number | null>(null)
	const dispatch = useDispatch()

	const loaders = useSelector(getLoadersState)
	const isRestoringComment = isLoading(loaders, [CommentsActionType.RestoreCommentRequest], {
		default: false
	})
	const prevIsRestoringComment = usePrevious(isRestoringComment)

	React.useEffect(() => {
		if (prevIsRestoringComment && !isRestoringComment) {
			setRestoreCommentId(null)
		}
	}, [prevIsRestoringComment, isRestoringComment])

	const handleRestoreComment = (restoreCommentId: number) => {
		setRestoreCommentId(restoreCommentId)
	}

	const handleConfirmRestoreComment = () => {
		dispatch(restoreCommentRequest(restoreCommentId as number))
	}

	const handleCancelRestoreComment = () => {
		setRestoreCommentId(null)
	}

	return {
		restoreCommentId,
		isRestoringComment,
		handleRestoreComment,
		handleConfirmRestoreComment,
		handleCancelRestoreComment
	}
}

export default useRestoreComments
