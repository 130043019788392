import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { updateGroupsRequest } from '../store/actions'
import { TagsActionTypes } from '../store/action.types'

interface IUseUpdateGroupsResult {
	onUpdateGroups: (tagId: number, groupIds: number[]) => any
	isLoading: boolean
}

export const useUpdateGroups = (): IUseUpdateGroupsResult => {
	const dispatch = useDispatch()

	const onUpdateGroups = useCallback(
		(groupId: number, groupIds: number[]) => dispatch(updateGroupsRequest(groupId, groupIds)),
		[dispatch]
	)

	const loaders = useSelector(getLoadersState)
	const isUpdateGroupsLoading = isLoading(loaders, [TagsActionTypes.UpdateGroupsRequest], {
		default: false
	})

	return {
		onUpdateGroups,
		isLoading: isUpdateGroupsLoading
	}
}
