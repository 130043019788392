import {
	AclActionTypes,
	IFetchGroupSuccess,
	IFetchGroupRequest,
	IFetchGroupFailure,
	IFetchPermissionRequest,
	IFetchPermissionSuccess,
	IFetchPermissionFailure,
	IUpdatePermissionsGroupRequest,
	IUpdatePermissionsGroupSuccess,
	IUpdatePermissionsGroupFailure,
	IUpdateUsersGroupRequest,
	IUpdateUsersGroupFailure,
	IUpdateUsersGroupSuccess,
	ICreateGroupRequest,
	ICreateGroupSuccess,
	ICreateGroupFailure,
	IEditGroupRequest,
	IEditGroupSuccess,
	IEditGroupFailure,
	IDeleteGroupRequest,
	IDeleteGroupSuccess,
	IDeleteGroupFailure,
	ICreateNewUserRankGroup,
	IUpdateUserRankGroup,
	ICreateWhiteBlackListGroup,
	IUpdateWhiteBlackListGroup
} from './actions.types'
import {
	IGroup,
	IPermission,
	IUpdatePermissionsGroupRequest as IUpdatePermissionsGroupRequestType,
	IUpdateUsersGroupRequest as IUpdateUsersGroupRequestType,
	ICreateGroupRequest as ICreateGroupRequestType
} from 'brainstorming-types'
import { IApiError } from 'brainstorming-types'
import { IUserRankForm } from '../components/user-ranking/UserRankingGroupForm'
import { IBlackWhiteListForm } from '../components/whitelist-blacklist/WhitelistBlacklistForm'

export const fetchGroupsRequest = (payload?: any): IFetchGroupRequest => ({
	type: AclActionTypes.FetchGroupRequest,
	payload
})

export const fetchGroupsSuccess = (payload: IGroup[]): IFetchGroupSuccess => ({
	type: AclActionTypes.FetchGroupSuccess,
	payload
})

export const fetchGroupsFailure = (payload: IApiError): IFetchGroupFailure => ({
	type: AclActionTypes.FetchGroupFailure,
	payload
})

export const fetchPermissionsRequest = (payload?: any): IFetchPermissionRequest => ({
	type: AclActionTypes.FetchPermissionRequest,
	payload
})

export const fetchPermissionsSuccess = (payload: IPermission[]): IFetchPermissionSuccess => ({
	type: AclActionTypes.FetchPermissionSuccess,
	payload
})

export const fetchPermissionsFailure = (payload: IApiError): IFetchPermissionFailure => ({
	type: AclActionTypes.FetchPermissionFailure,
	payload
})

export const updatePermissionsGroupRequest = (
	payload: IUpdatePermissionsGroupRequestType
): IUpdatePermissionsGroupRequest => ({
	type: AclActionTypes.UpdatePermissionsGroupRequest,
	payload
})

export const updatePermissionsGroupSuccess = (payload: IGroup): IUpdatePermissionsGroupSuccess => ({
	type: AclActionTypes.UpdatePermissionsGroupSuccess,
	payload
})

export const updatePermissionsGroupFailure = (
	payload: IApiError
): IUpdatePermissionsGroupFailure => ({
	type: AclActionTypes.UpdatePermissionsGroupFailure,
	payload
})

export const updateUsersGroupRequest = (
	payload: IUpdateUsersGroupRequestType
): IUpdateUsersGroupRequest => ({
	type: AclActionTypes.UpdateUsersGroupRequest,
	payload
})

export const updateUsersGroupSuccess = (payload: IGroup): IUpdateUsersGroupSuccess => ({
	type: AclActionTypes.UpdateUsersGroupSuccess,
	payload
})

export const updateUsersGroupFailure = (payload: IApiError): IUpdateUsersGroupFailure => ({
	type: AclActionTypes.UpdateUsersGroupFailure,
	payload
})

export const createGroupRequest = (payload: ICreateGroupRequestType): ICreateGroupRequest => ({
	type: AclActionTypes.CreateGroupRequest,
	payload
})

export const createGroupSuccess = (payload: IGroup): ICreateGroupSuccess => ({
	type: AclActionTypes.CreateGroupSuccess,
	payload
})

export const createGroupFailure = (payload: IApiError): ICreateGroupFailure => ({
	type: AclActionTypes.CreateGroupFailure,
	payload
})

export const editGroupRequest = (payload: {
	id: number
	data: Partial<IGroup>
}): IEditGroupRequest => ({
	type: AclActionTypes.EditGroupRequest,
	payload
})

export const editGroupSuccess = (payload: IGroup): IEditGroupSuccess => ({
	type: AclActionTypes.EditGroupSuccess,
	payload
})

export const editGroupFailure = (payload: IApiError): IEditGroupFailure => ({
	type: AclActionTypes.EditGroupFailure,
	payload
})

export const deleteGroupRequest = (payload: number): IDeleteGroupRequest => ({
	type: AclActionTypes.DeleteGroupRequest,
	payload
})

export const deleteGroupSuccess = (payload: number): IDeleteGroupSuccess => ({
	type: AclActionTypes.DeleteGroupSuccess,
	payload
})

export const deleteGroupFailure = (payload: IApiError): IDeleteGroupFailure => ({
	type: AclActionTypes.DeleteGroupFailure,
	payload
})

export const createNewUserRankGroup = (payload: IUserRankForm): ICreateNewUserRankGroup => ({
	type: AclActionTypes.CreateNewUserRankGroup,
	payload
})

export const updateUserRankGroup = (id: number, data: IUserRankForm): IUpdateUserRankGroup => ({
	type: AclActionTypes.UpdateUserRankGroup,
	payload: { id, data }
})

export const createNewWhiteBlackListGroup = (
	payload: IBlackWhiteListForm
): ICreateWhiteBlackListGroup => ({
	type: AclActionTypes.CreateWhiteBlackListGroup,
	payload
})

export const updateWhiteBlackListGroup = (
	id: number,
	data: IBlackWhiteListForm
): IUpdateWhiteBlackListGroup => ({
	type: AclActionTypes.UpdateWhiteBlackListGroup,
	payload: { id, data }
})
