import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { updateTagRequest } from '../store/actions'
import { TagsActionTypes } from '../store/action.types'
import { ITagForm } from '../components/dashboard/shared/CreateEditTagModal'

interface IUseCreateTagResult {
	onUpdateTag: (tagId: number, payload: ITagForm) => any
	isLoading: boolean
}

export const useUpdateTag = (): IUseCreateTagResult => {
	const dispatch = useDispatch()

	const onUpdateTag = useCallback(
		(tagId: number, payload: ITagForm) => dispatch(updateTagRequest(tagId, payload)),
		[dispatch]
	)

	const loaders = useSelector(getLoadersState)
	const isLoadingUpdateTag = isLoading(loaders, [TagsActionTypes.UpdateTagRequest], {
		default: false
	})

	return {
		onUpdateTag,
		isLoading: isLoadingUpdateTag
	}
}
