import * as React from 'react'
import { IPermission, IGroup, GroupType } from 'brainstorming-types'
import { Modal, Empty } from 'antd'
import GroupList from '../common/GroupsList'
import { Mode } from '../../constants'
import UserRankingGroupForm, { IUserRankForm } from './UserRankingGroupForm'
import { buildNewGroup } from '../../utils/builders'
import { GroupPageWrapper, GroupListWrapper, GroupInfoWrapper } from '../common/StyledComponents'
import { Prompt } from 'react-router-dom'
import AddNewGroupButton from '../common/AddNewGroupButton'

interface IProps {
	groups: IGroup[]
	permissions: IPermission[]
	isDeleting: boolean
	isSaving: boolean
	deleteGroup(groupId: number): void
	saveGroup(formData: IUserRankForm, group: IGroup): void
}

interface IState {
	activeGroup?: IGroup
	mode: Mode
	deleteGroupOpen: boolean
	formData?: IUserRankForm
}

class UserRanking extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)
		this.state = {
			activeGroup: props.groups.length ? props.groups[0] : undefined,
			mode: Mode.View,
			deleteGroupOpen: false
		}
	}

	componentDidUpdate(prevProps: IProps) {
		if (prevProps.isSaving && !this.props.isSaving) {
			this.onSaveFinished()
		}
		if (prevProps.isDeleting && !this.props.isDeleting) {
			this.onDeleteFinished()
		}
	}

	onSaveFinished = () => {
		const { formData } = this.state
		const { groups } = this.props
		const activeGroup = groups.find(
			g =>
				g.name === formData!.name &&
				g.minPoints === formData?.minPoints &&
				g.maxPoints === formData?.maxPoints
		)
		this.setState({
			mode: Mode.View,
			formData: undefined,
			activeGroup
		})
	}

	onDeleteFinished = () => {
		this.setState({
			activeGroup: this.props.groups[0],
			mode: Mode.View,
			deleteGroupOpen: false
		})
	}

	handleChangeActiveGroup = (activeGroup: IGroup) => {
		this.setState({ activeGroup })
	}

	handleAddNewGroup = () => {
		this.setState({
			activeGroup: buildNewGroup({ type: GroupType.UserRank }) as IGroup,
			mode: Mode.Edit
		})
	}

	handleOnCancel = () => {
		const { activeGroup } = this.state
		this.setState({
			mode: Mode.View,
			activeGroup: activeGroup!.id ? activeGroup : this.props.groups[0]
		})
	}

	handleOnSave = (formData: IUserRankForm) => {
		this.setState({ formData })
	}

	handleOnDelete = () => {
		this.setState({
			deleteGroupOpen: true
		})
	}

	handleOnEdit = () => {
		this.setState({
			mode: Mode.Edit
		})
	}

	handleDeleteConfirm = () => {
		this.props.deleteGroup(this.state.activeGroup!.id)
	}

	handleConfirmSave = () => {
		this.props.saveGroup(this.state.formData!, this.state.activeGroup!)
	}

	render() {
		const { activeGroup, deleteGroupOpen, mode, formData } = this.state
		return (
			<GroupPageWrapper>
				<GroupListWrapper>
					<GroupList
						groups={this.props.groups}
						active={activeGroup}
						onChangeGroup={this.handleChangeActiveGroup}
						mode={this.state.mode}
					/>
					<AddNewGroupButton mode={this.state.mode} onAddNew={this.handleAddNewGroup} />
				</GroupListWrapper>
				<GroupInfoWrapper>
					{activeGroup && (
						<UserRankingGroupForm
							mode={mode}
							active={activeGroup}
							permissions={this.props.permissions}
							onEdit={this.handleOnEdit}
							onDelete={this.handleOnDelete}
							onCancel={this.handleOnCancel}
							onSave={this.handleOnSave}
						/>
					)}
					{!activeGroup && (
						<Empty
							description={<p>Groups don't exist. Click on "Add new group" to start adding it.</p>}
						/>
					)}
					{activeGroup && (
						<Modal
							title="Delete group"
							visible={deleteGroupOpen}
							onOk={this.handleDeleteConfirm}
							onCancel={() => this.setState({ deleteGroupOpen: false })}
							okText="Confirm"
							okType="danger"
							cancelText="Cancel"
						>
							<p>
								Are you sure you want to delete <strong>{activeGroup.name}</strong>?
							</p>
						</Modal>
					)}
					{formData && activeGroup && (
						<Modal
							title={activeGroup.id ? 'Edit group' : 'Create group'}
							visible={!!formData}
							onOk={this.handleConfirmSave}
							onCancel={() => this.setState({ formData: undefined })}
							okText="Confirm"
							cancelText="Cancel"
						>
							<p>
								Are you sure you want to {activeGroup.id ? 'edit' : 'create'}{' '}
								<strong>{formData.name}</strong> group?
							</p>
						</Modal>
					)}
				</GroupInfoWrapper>
				<Prompt
					when={this.state.mode === Mode.Edit}
					message="You are in edit mode, are you sure you want to leave? UR"
				/>
			</GroupPageWrapper>
		)
	}
}

export default UserRanking
