import * as React from 'react'
import { Switch } from 'react-router-dom'
import ProtectedRoute from '../../../components/routes/ProtectedRoute'
import { useSelector } from 'react-redux'
import { getToken } from '../../auth/store/selectors'
import { Routes as RouteConfig } from '../../../config/routes'
import CommentsDashboardContainer from '../containers/CommentsDashboardContainer'

const CommentRoutes = () => {
	const token = useSelector(getToken)
	return (
		<Switch>
			<ProtectedRoute
				path={RouteConfig.Comments.route}
				exact={true}
				component={CommentsDashboardContainer}
				token={token}
			/>
		</Switch>
	)
}

export default CommentRoutes
