import * as React from 'react'
import { Button, Popconfirm } from 'antd'
import styled from 'styled-components'

import { SPACES } from '../../../../styles/spaces'
import { AddPage } from './AddPage'
import { Mode } from '../../utils/types'
import { ICreatePage } from '../../store/types'

interface IPagesActionsProps {
	mode: Mode
	setMode: (mode: Mode) => void
	onEditSubmit: () => any
	onCreatePage: (data: ICreatePage) => any
	isCreatePageLoading: boolean
	isEditPageLoading: boolean
}

const StyledDiv = styled.div`
	display: inline-flex;
	flex-direction: row-reverse;
	width: 100%;
	margin-left: auto;
	margin-right: 0;
	margin-bottom: ${SPACES.EXTRA_SMALL};

	> button {
		margin-left: ${SPACES.EXTRA_SMALL};
	}
`

export const PagesActions: React.FC<IPagesActionsProps> = props => {
	const {
		mode,
		setMode,
		onEditSubmit,
		onCreatePage,
		isCreatePageLoading,
		isEditPageLoading
	} = props

	const onSave = () => {
		onEditSubmit()
		setMode(Mode.View)
	}

	return (
		<StyledDiv>
			{mode === Mode.View && (
				<>
					<AddPage onCreatePage={onCreatePage} isCreatePageLoading={isCreatePageLoading} />
					<Button type="ghost" onClick={() => setMode(Mode.Edit)}>
						Edit
					</Button>
				</>
			)}
			{mode === Mode.Edit && (
				<>
					<Popconfirm
						onConfirm={() => onSave()}
						okText="Yes"
						cancelText="No"
						title="Are you sure you want to save changes?"
					>
						<Button type="primary" loading={isEditPageLoading}>
							Save
						</Button>
					</Popconfirm>
					<Button type="ghost" onClick={() => setMode(Mode.View)}>
						Cancel
					</Button>
				</>
			)}
		</StyledDiv>
	)
}
