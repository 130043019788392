import { IStorage } from './storage.interface'

class LocalStorage<T> implements IStorage<T> {
	get(key: string): T | null {
		const item = localStorage.getItem(key)
		if (item) {
			return JSON.parse(item)
		}

		return null
	}

	set(key: string, value: any): void {
		localStorage.setItem(key, JSON.stringify(value))
	}

	remove(key: string): boolean {
		if (this.get(key) !== undefined) {
			localStorage.removeItem(key)
			return true
		}

		return false
	}
}

export default LocalStorage
