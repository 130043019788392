import * as React from 'react'
import { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import Table, { ColumnProps } from 'antd/lib/table'
import styled from 'styled-components'
import { IMoney, IStaffMember } from 'brainstorming-types'

import { IEditStaffMember } from '../../store/types'
import { formatMoney, getAmountToPay, getValue } from '../../utils/helpers'
import TableWrapper from '../../../../components/TableWrapper'
import { EditPricePerWordModal } from './EditPricePerWordModal'
import { SPACES } from '../../../../styles/spaces'
import { getZeroMoney } from '../../utils/constants'

const PricePerWord = styled.div`
	cursor: pointer;
	display: flex;
	width: max-content;
`

export interface IStaffMemberBalanceProps {
	staffMember: IStaffMember
	isLoading: boolean
	isEditStaffMemberLoading: boolean
	onEditStaffMember: (data: IEditStaffMember) => any
}

export const StaffMemberBalance: React.FC<IStaffMemberBalanceProps> = ({
	staffMember,
	isLoading,
	onEditStaffMember,
	isEditStaffMemberLoading
}) => {
	const [isEditPriceVisible, setIsEditPriceVisible] = useState(false)
	const openEditPrice = () => setIsEditPriceVisible(true)
	const closeEditPrice = () => setIsEditPriceVisible(false)

	const submitPricePerWord = async (newPrice: IMoney) => {
		await onEditStaffMember({
			pricePerWord: newPrice
		})
		closeEditPrice()
	}

	const valueFormatted = React.useMemo(() => formatMoney(getValue(staffMember)), [staffMember])
	const toPayFormatted = React.useMemo(() => formatMoney(getAmountToPay(staffMember)), [
		staffMember
	])

	const dataSource = React.useMemo(() => [staffMember], [staffMember])

	const columns: ColumnProps<IStaffMember>[] = [
		{
			key: 'unpaidWordCount',
			title: 'Number of words',
			dataIndex: 'unpaidWordCount'
		},
		{
			key: 'pricePerWord',
			title: 'Price per word',
			dataIndex: 'pricePerWord',
			render: pricePerWord => (
				<PricePerWord onClick={openEditPrice}>
					{formatMoney(pricePerWord)}
					<EditOutlined
						style={{ marginLeft: SPACES.EXTRA_SMALL, color: '#40a9ff', fontSize: '24px' }}
					/>
				</PricePerWord>
			)
		},
		{
			key: 'value',
			title: 'Earned',
			render: () => valueFormatted
		},
		{
			key: 'previouslyUnpaid',
			title: 'Remaining from previous payments',
			dataIndex: 'previouslyUnpaid',
			render: (text, { previouslyUnpaid, pricePerWord }) =>
				formatMoney(
					previouslyUnpaid?.currency ? previouslyUnpaid : getZeroMoney(pricePerWord.currency)
				)
		},
		{
			key: 'toPay',
			title: 'To pay',
			render: () => toPayFormatted
		}
	]

	return (
		<TableWrapper>
			<EditPricePerWordModal
				isVisible={isEditPriceVisible}
				onCancel={closeEditPrice}
				onEdit={submitPricePerWord}
				isLoading={isEditStaffMemberLoading}
				staffMember={staffMember}
			/>
			<Table
				columns={columns}
				dataSource={dataSource}
				loading={isLoading}
				rowKey="id"
				pagination={false}
			/>
		</TableWrapper>
	)
}
