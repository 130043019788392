import { ILoaders, ILoaderOptions } from './types'
import { REQUEST, SUCCESS, FAILURE } from '../constants'
import { IApplicationState } from '../../types'

export const getLoadersState = (state: IApplicationState) => state.loaders

export const isLoading = (
	state: ILoaders,
	actions: string[],
	options: ILoaderOptions = { default: true }
) => {
	const formattedActions = actions.map(action =>
		action
			.replace(REQUEST, '')
			.replace(SUCCESS, '')
			.replace(FAILURE, '')
	)
	// let loading = options.default

	// formattedActions.forEach(action => {
	// 	if (state[action] !== undefined) {
	// 		loading = loading && state[action]
	// 	}
	// })

	const isLoading = formattedActions.reduce((acc, cur) => {
		return state[cur] === undefined ? acc || options.default : state[cur] || acc
	}, state[formattedActions[0]])

	return isLoading
}
