import { ISolution } from 'brainstorming-types'

export enum SolutionsActionType {
	FetchSolutionsRequest = '@solutions/FETCH_SOLUTIONS_REQUEST',
	FetchSolutionsSuccess = '@solutions/FETCH_SOLUTIONS_SUCCESS',
	FetchSolutionsFailure = '@solutions/FETCH_SOLUTIONS_FAILURE',
	DeleteSolutionRequest = '@solutions/DELETE_SOLUTION_REQUEST',
	DeleteSolutionSuccess = '@solutions/DELETE_SOLUTION_SUCCESS',
	DeleteSolutionFailure = '@solutions/DELETE_SOLUTION_FAILURE',
	RestoreSolutionRequest = '@solutions/RESTORE_SOLUTION_REQUEST',
	RestoreSolutionSuccess = '@solutions/RESTORE_SOLUTION_SUCCESS',
	RestoreSolutionFailure = '@solutions/RESTORE_SOLUTION_FAILURE',
	EditSolutionRequest = '@solutions/EDIT_SOLUTION_REQUEST',
	EditSolutionSuccess = '@solutions/EDIT_SOLUTION_SUCCESS',
	EditSolutionFailure = '@solutions/EDIT_SOLUTION_FAILURE'
}

export interface ISolutionsById {
	[id: number]: ISolution
}

export interface ISolutions {
	byId: ISolutionsById
	ids: number[]
	total: number
	skip: number
}

export interface IEditSolution {
	title: string
	text: string // quick fix
}
