import * as React from 'react'
import { ITag } from 'brainstorming-types'
import { ColumnProps } from 'antd/lib/table'
import TableWrapper from '../../../../../components/TableWrapper'
import { Button, Popconfirm, Table } from 'antd'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../../../config/routes'
import { ITagsByTagId } from '../../../store/types'
import { buildTagExamples } from '../../../utils/helpers'
import { SPACES } from '../../../../../styles/spaces'
import styled from 'styled-components'

export interface IRegularTagsTableProps {
	regularTags: ITag[]
	groupsById: ITagsByTagId
	synonymsById: ITagsByTagId
	isRegularTagsLoading: boolean
	onDeleteTag: (tagId: number) => any
	isDeleteTagLoading: boolean
}

export const RegularTagsTable: React.FC<IRegularTagsTableProps> = props => {
	const {
		regularTags,
		groupsById,
		synonymsById,
		isRegularTagsLoading,
		onDeleteTag,
		isDeleteTagLoading
	} = props

	const history = useHistory()

	const onOpenDetails = (id: number) => {
		history.push(Routes.RegularTags.singleTagRoute.replace(':id', id.toString()))
	}

	const columns: ColumnProps<ITag>[] = [
		{
			key: 'name',
			title: 'Tag name',
			dataIndex: ['name'],
			align: 'left'
		},
		{
			key: 'noOfContentDirect',
			title: 'No of co direct',
			dataIndex: ['noOfContentDirect'],
			align: 'left',
			sorter: (tag1: ITag, tag2: ITag) => {
				const value1 = tag1.noOfContentDirect as number
				const value2 = tag2.noOfContentDirect as number

				return value1 - value2
			}
		},
		{
			key: 'noOfContentTotal',
			title: 'No of co total',
			dataIndex: ['noOfContentTotal'],
			align: 'left',
			sorter: (tag1: ITag, tag2: ITag) => {
				const value1 = tag1.noOfContentTotal as number
				const value2 = tag2.noOfContentTotal as number

				return value1 - value2
			}
		},
		{
			key: 'noOfSynonyms',
			title: 'No of synonyms',
			align: 'left',
			render: (text: any, tag: ITag) => {
				const synonyms = synonymsById[tag.id]

				return synonyms ? synonyms.length.toString() : 'loading...'
			}
		},
		{
			key: 'synonymExamples',
			title: 'Synonyms',
			align: 'left',
			render: (text: any, tag: ITag) => {
				const synonyms = synonymsById[tag.id]

				return synonyms ? buildTagExamples(synonyms) : 'loading...'
			}
		},
		{
			key: 'noOfGroups',
			title: 'No of groups',
			align: 'left',
			render: (text: any, tag: ITag) => {
				const groups = groupsById[tag.id]

				return groups ? groups.length.toString() : 'loading...'
			}
		},
		{
			key: 'groupExamples',
			title: 'Groups',
			align: 'left',
			render: (text: any, tag: ITag) => {
				const groups = groupsById[tag.id]

				return groups ? buildTagExamples(groups) : 'loading...'
			}
		},
		{
			title: '',
			key: 'actions',
			render: (tag: ITag) => (
				<div className="actions-wrapper">
					<Button type="primary" size="small" onClick={() => onOpenDetails(tag.id)}>
						see details
					</Button>

					<Popconfirm
						onConfirm={() => onDeleteTag(tag.id)}
						okText="Yes"
						cancelText="No"
						title="Are you sure you want to delete this tag?"
					>
						<Button danger={true} type="primary" size="small" loading={isDeleteTagLoading}>
							delete
						</Button>
					</Popconfirm>
				</div>
			),
			align: 'right'
		}
	]

	return (
		<StyledTableWrapper>
			<Table
				columns={columns}
				dataSource={regularTags}
				loading={isRegularTagsLoading}
				rowKey="id"
				pagination={{
					position: ['bottomRight'],
					pageSize: 20,
					showSizeChanger: false
				}}
			/>
		</StyledTableWrapper>
	)
}

export const StyledTableWrapper = styled(TableWrapper as any)`
	padding-bottom: ${SPACES.EXTRA_LARGE};
`
