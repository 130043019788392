import * as React from 'react'
import { Switch, Form } from 'antd'
import { IFieldProps } from './types'

export const BooleanField: React.FC<IFieldProps> = props => {
	const { handleChange, value, name, label } = props

	const onChange = (checked: boolean) => {
		handleChange && handleChange(checked)
	}

	const optionalProps = {} as any

	// if the input is not controlled
	if (!handleChange) {
		optionalProps.valuePropName = 'checked'
	}

	return (
		<Form.Item name={name} label={label} {...optionalProps}>
			<Switch
				onChange={handleChange ? onChange : undefined}
				checked={handleChange ? value : undefined}
			/>
		</Form.Item>
	)
}
