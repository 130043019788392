import * as React from 'react'
import useSolutions from '../hooks/useSolutions'
import { Routes } from '../../../config/routes'
import Page from '../../../components/layout/Page'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { ISortUrl } from '../../../utils/sorting'
import { SortOrder } from 'antd/lib/table/interface'
import SolutionsTable from '../components/dashboard/SolutionsTable'
import useDeleteSolution from '../hooks/useDeleteSolution'
import { Button, Modal } from 'antd'
import useEditSolution from '../hooks/useEditSolution'
import EditSolution from '../components/dashboard/EditSolution'
import useRestoreSolution from '../hooks/useRestoreSolution'
import useHistoryLog from '../../../hooks/useHistoryLog'
import HistoryLogModal from '../../../components/layout/HistoryLogModal'

export const SOLUTIONS_PER_PAGE = 10

const SolutionDashboardContainer = () => {
	const [page, setPage] = useQueryParam('page', NumberParam)
	const [direction, setDirection] = useQueryParam('direction', StringParam)
	const [column, setColumn] = useQueryParam('column', StringParam)

	const { solutions, isLoading, solutionsState } = useSolutions({
		page,
		direction,
		column
	})

	const {
		solutionId,
		handleCancelDeleteSolution,
		handleConfirmDeleteSolution,
		handleDeleteSolution,
		isDeletingSolution
	} = useDeleteSolution()

	const {
		restoreSolutionId,
		handleCancelRestoreSolution,
		handleConfirmRestoreSolution,
		handleRestoreSolution,
		isRestoringSolution
	} = useRestoreSolution()

	const {
		historyLog,
		handleOpenHistoryLog,
		handleCloseHistoryLog,
		historyLogModalState
	} = useHistoryLog()

	const {
		solution,
		isEditingSolution,
		handleOpenEdit,
		handleCancelEditSolution,
		handleConfirmEditSolution
	} = useEditSolution()

	const handleFetchSolutions = (page: number, sort: ISortUrl) => {
		setPage(page)
		setDirection(sort.order)
		setColumn(sort.column)
	}

	return (
		<Page
			isLoading={false}
			loadingText="Loading Solutions data"
			breadcrumbItems={[{ name: 'Solutions management', path: Routes.Solutions.route }]}
		>
			<React.Fragment>
				<SolutionsTable
					solutions={solutions}
					isLoading={isLoading}
					solutionsState={solutionsState}
					page={page || 1}
					onRestore={handleRestoreSolution}
					fetchSolutions={handleFetchSolutions}
					column={column as string}
					direction={direction as SortOrder}
					onDelete={handleDeleteSolution}
					onEdit={handleOpenEdit}
					openSolutionHistory={handleOpenHistoryLog}
				/>
				<Modal
					title="Delete solution"
					visible={!!solutionId}
					onOk={handleConfirmDeleteSolution}
					onCancel={handleCancelDeleteSolution}
					footer={[
						<Button key="back" onClick={handleCancelDeleteSolution}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							loading={isDeletingSolution}
							onClick={handleConfirmDeleteSolution}
						>
							Confirm
						</Button>
					]}
				>
					<p>Are you sure you want to delete solution?</p>
				</Modal>
				<EditSolution
					solution={solution}
					isEditingSolution={isEditingSolution}
					onCancel={handleCancelEditSolution}
					onConfirm={handleConfirmEditSolution}
				/>
				<Modal
					title="Restore solution"
					visible={!!restoreSolutionId}
					onOk={handleConfirmRestoreSolution}
					onCancel={handleCancelRestoreSolution}
					footer={[
						<Button key="back" onClick={handleCancelRestoreSolution}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							loading={isRestoringSolution}
							onClick={handleConfirmRestoreSolution}
						>
							Confirm
						</Button>
					]}
				>
					<p>Are you sure you want to restore solution?</p>
				</Modal>
				<HistoryLogModal
					historyLog={historyLog}
					handleCloseHistoryLog={handleCloseHistoryLog}
					historyLogModalState={historyLogModalState}
				/>
			</React.Fragment>
		</Page>
	)
}

export default SolutionDashboardContainer
