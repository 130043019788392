import { ColumnProps } from 'antd/lib/table'
import { Table } from 'antd'
import { ITag } from 'brainstorming-types'
import * as React from 'react'
import { EditTagSynonymsButton } from './EditTagSynonymsButton'
import { buildTagExamples } from '../../../utils/helpers'
import { StyledTableWrapper } from './RegularTagsTable'

export interface IRegularTagSynonymsProps {
	tag: ITag
	regularTags: ITag[]
	isRegularTagsLoading: boolean

	synonyms: ITag[]
	isSynonymsLoading: boolean

	onUpdateSynonyms: (tagId: number, synonymIds: number[]) => any
	isUpdateSynonymsLoading: boolean
}

export const RegularTagSynonyms: React.FC<IRegularTagSynonymsProps> = props => {
	const {
		tag,
		regularTags,
		synonyms,
		isSynonymsLoading,
		onUpdateSynonyms,
		isUpdateSynonymsLoading
	} = props

	const dataSource = React.useMemo(() => [synonyms], [synonyms])

	const columns: ColumnProps<ITag[]>[] = [
		{
			width: '20%',
			key: 'noOfSynonyms',
			title: 'No of synonyms',
			render: () => {
				return synonyms.length
			}
		},
		{
			key: 'synonyms',
			title: 'Synonyms',
			render: () => {
				return buildTagExamples(synonyms)
			}
		},
		{
			width: '20%',
			key: 'actions',
			title: '',
			render: () => {
				return (
					<EditTagSynonymsButton
						tag={tag}
						regularTags={regularTags}
						synonyms={synonyms}
						onUpdateSynonyms={onUpdateSynonyms}
						isUpdateSynonymsLoading={isUpdateSynonymsLoading}
					/>
				)
			}
		}
	]

	return (
		<StyledTableWrapper>
			<Table
				columns={columns}
				dataSource={dataSource}
				loading={isSynonymsLoading || isUpdateSynonymsLoading}
				rowKey="id"
				pagination={false}
			/>
		</StyledTableWrapper>
	)
}
