import { ILoginRequest as ILoginRequestData } from 'brainstorming-types'
import { IToken, IUser } from './models.types'
import { IApiError } from 'brainstorming-types'

export enum AuthActionType {
	LoginRequest = '@auth/LOGIN_REQUEST',
	LoginSuccess = '@auth/LOGIN_SUCCESS',
	LoginFailure = '@auth/LOGIN_FAILURE',
	GetProfileRequest = '@auth/GET_PROFILE_REQUEST',
	GetProfileSuccess = '@auth/GET_PROFILE_SUCCESS',
	GetProfileFailure = '@auth/GET_PROFILE_FAILURE',
	RefreshTokenRequest = '@auth/REFRESH_TOKEN_REQUEST',
	RefreshTokenSuccess = '@auth/REFRESH_TOKEN_SUCCESS',
	RefreshTokenFailure = '@auth/REFRESH_TOKEN_FAILURE',
	LogoutSuccess = '@auth/LOGOUT_SUCCESS'
}

export interface ILoginSuccess {
	type: AuthActionType.LoginSuccess
	payload: IToken
}

export interface ILoginRequest {
	type: AuthActionType.LoginRequest
	payload: ILoginRequestData
}

export interface ILoginFailure {
	type: AuthActionType.LoginFailure
	payload: IApiError
}

export interface IGetProfileSuccess {
	type: AuthActionType.GetProfileSuccess
	payload: IUser
}

export interface IGetProfileRequest {
	type: AuthActionType.GetProfileRequest
}

export interface IGetProfileFailure {
	type: AuthActionType.GetProfileFailure
	payload: IApiError
}

export interface IRefreshTokenSuccess {
	type: AuthActionType.RefreshTokenSuccess
	payload: IToken
}

export interface IRefreshTokenRequest {
	type: AuthActionType.RefreshTokenRequest
}

export interface IRefreshTokenFailure {
	type: AuthActionType.RefreshTokenFailure
	payload: IApiError
}

export interface ILogoutSuccess {
	type: AuthActionType.LogoutSuccess
}

export type AuthAction =
	| ILoginSuccess
	| ILoginRequest
	| ILoginFailure
	| IGetProfileFailure
	| IGetProfileRequest
	| IGetProfileSuccess
	| IRefreshTokenFailure
	| IRefreshTokenRequest
	| IRefreshTokenSuccess
	| ILogoutSuccess
