import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { deleteTagRequest } from '../store/actions'
import { TagsActionTypes } from '../store/action.types'

interface IUseDeleteTag {
	onDeleteTag: (tagId: number) => any
	isLoading: boolean
}

export const useDeleteTag = (): IUseDeleteTag => {
	const dispatch = useDispatch()

	const onDeleteTag = useCallback((tagId: number) => dispatch(deleteTagRequest(tagId)), [dispatch])

	const loaders = useSelector(getLoadersState)
	const isLoadingUpdateTag = isLoading(loaders, [TagsActionTypes.DeleteTagRequest], {
		default: false
	})

	return {
		onDeleteTag,
		isLoading: isLoadingUpdateTag
	}
}
