import { Reducer } from 'redux'
import { IUI, UIActions, UIActionType } from './types'
import { defaultState } from './constants'

export const uiReducer: Reducer<IUI, UIActions> = (
	state: IUI = defaultState,
	action: UIActions
): IUI => {
	switch (action.type) {
		case UIActionType.TOGGLE_SIDEBAR_MENU:
			return { ...state, sidebarOpen: action.payload }
		default:
			return state
	}
}
