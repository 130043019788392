import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Divider, PageHeader } from 'antd'

import { useCreateStaffPayment, useEditStaffMember, useStaffMember } from '../hooks'
import { Routes } from '../../../config/routes'
import Page from '../../../components/layout/Page'
import { StaffMemberBalance } from '../components/dashboard/StaffMemberBalance'
import { StaffMemberPaymentHistory } from '../components/dashboard/StaffMemberPaymentHistory'
import { DeactivateAccountButton } from '../components/dashboard/DeactivateAccountButton'
import { CreateStaffPaymentButton } from '../components/dashboard/CreateStaffPaymentButton'

interface IStaffMemberDetailsPageParams {
	staffMemberId: string | undefined
}

export const StaffMemberDetailsPageContainer = () => {
	const params = useParams<IStaffMemberDetailsPageParams>()
	const staffMemberId = parseInt(params.staffMemberId as string, 10)

	const { staffMember, payments, isStaffMemberLoading, isPaymentsLoading } = useStaffMember(
		staffMemberId
	)

	const { onEditStaffMember, isLoading: isEditStaffMemberLoading } = useEditStaffMember(
		staffMemberId
	)
	const { onCreateStaffPayment, isLoading: isCreateStaffPaymentLoading } = useCreateStaffPayment(
		staffMemberId
	)

	return (
		<Page
			isLoading={isStaffMemberLoading}
			loadingText="Loading Staff member data"
			breadcrumbItems={[
				{ name: 'Staff accounting', path: Routes.StaffAccounting.route },
				{
					name: staffMember?.user?.fullName || '',
					path: Routes.StaffAccounting.singleUserRoute.replace(
						':staffMemberId',
						staffMemberId.toString()
					)
				}
			]}
		>
			{staffMember?.isActive ? (
				<>
					<PageHeader title="Balance (since last payment)" />

					<DeactivateAccountButton
						staffMember={staffMember}
						onEditStaffMember={onEditStaffMember}
						isEditStaffMemberLoading={isEditStaffMemberLoading}
					/>
					<StaffMemberBalance
						staffMember={staffMember}
						isLoading={isStaffMemberLoading}
						isEditStaffMemberLoading={isEditStaffMemberLoading}
						onEditStaffMember={onEditStaffMember}
					/>
					<CreateStaffPaymentButton
						staffMember={staffMember}
						onCreateStaffPayment={onCreateStaffPayment}
						isCreateStaffPaymentLoading={isCreateStaffPaymentLoading}
					/>

					<Divider />
				</>
			) : (
				<></> // null makes TS complain because of Antd types.
			)}
			<PageHeader title="History" />
			<StaffMemberPaymentHistory
				staffMember={staffMember}
				payments={payments}
				isLoading={isPaymentsLoading}
			/>
		</Page>
	)
}
