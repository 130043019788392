import * as React from 'react'

export const BannerLightIllustration = (props: any) => (
	<svg viewBox="0 0 319 46" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g transform="translate(-13525 4732)" data-name="Group 2821">
			<g fill="#fff" stroke="#9195be" data-name="Rectangle 2714">
				<path stroke="none" d="M13525-4732h319v46h-319z" />
				<path fill="none" d="M13525.5-4731.5h318v45h-318z" />
			</g>
			<path fill="#e1e1f0" data-name="Rectangle 2716" d="M13607-4716h146v5h-146z" />
			<path fill="#e1e1f0" data-name="Rectangle 2715" d="M13607-4709h146v6h-146z" />
			<rect
				transform="translate(13787 -4716)"
				width={45}
				height={13}
				rx={2}
				fill="#33385a"
				data-name="Rectangle 2717"
			/>
			<g data-name="Group 2810">
				<g stroke="#9195be" data-name="Group 2809">
					<g fill="#e1e1f0" data-name="Rectangle 2653">
						<path stroke="none" d="M13536-4725h60v32h-60z" />
						<path fill="none" d="M13536.5-4724.5h59v31h-59z" />
					</g>
					<path
						fill="none"
						strokeLinecap="round"
						data-name="Line 625"
						d="m13535.859-4693.077 60.039-31.534"
					/>
					<path
						fill="none"
						strokeLinecap="round"
						data-name="Line 626"
						d="m13595.898-4693.077-60.039-31.534"
					/>
				</g>
			</g>
		</g>
	</svg>
)
