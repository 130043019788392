import { IMoney, IStaffMember, IStaffPayment, PaymentMethod } from 'brainstorming-types'

export enum StaffActionType {
	/// staff
	// fetch all
	FetchStaffRequest = '@staffAccounting/FETCH_STAFF_REQUEST',
	FetchStaffSuccess = '@staffAccounting/FETCH_STAFF_SUCCESS',
	FetchStaffFailure = '@staffAccounting/FETCH_STAFF_FAILURE',
	// fetch single
	FetchStaffMemberRequest = '@staffAccounting/FETCH_STAFF_MEMBER_REQUEST',
	FetchStaffMemberSuccess = '@staffAccounting/FETCH_STAFF_MEMBER_SUCCESS',
	FetchStaffMemberFailure = '@staffAccounting/FETCH_STAFF_MEMBER_FAILURE',
	// add new staff
	AddStaffRequest = '@staffAccounting/ADD_STAFF_REQUEST',
	AddStaffSuccess = '@staffAccounting/ADD_STAFF_SUCCESS',
	AddStaffFailure = '@staffAccounting/ADD_STAFF_FAILURE',
	// update staff member - price per word, isActive
	EditStaffMemberRequest = '@staffAccounting/EDIT_STAFF_MEMBER_REQUEST',
	EditStaffMemberSuccess = '@staffAccounting/EDIT_STAFF_MEMBER_SUCCESS',
	EditStaffMemberFailure = '@staffAccounting/EDIT_STAFF_MEMBER_FAILURE',

	/// staff payments
	// fetch
	FetchPaymentsForStaffMemberRequest = '@staffAccounting/FETCH_PAYMENTS_FOR_STAFF_MEMBER_REQUEST',
	FetchPaymentsForStaffMemberSuccess = '@staffAccounting/FETCH_PAYMENTS_FOR_STAFF_MEMBER_SUCCESS',
	FetchPaymentsForStaffMemberFailure = '@staffAccounting/FETCH_PAYMENTS_FOR_STAFF_MEMBER_FAILURE',
	// add
	CreatePaymentForStaffMemberRequest = '@staffAccounting/CREATE_PAYMENT_FOR_STAFF_MEMBER_REQUEST',
	CreatePaymentForStaffMemberSuccess = '@staffAccounting/CREATE_PAYMENT_FOR_STAFF_MEMBER_SUCCESS',
	CreatePaymentForStaffMemberFailure = '@staffAccounting/CREATE_PAYMENT_FOR_STAFF_MEMBER_FAILURE'
}

export interface IStaffById {
	[id: number]: IStaffMember
}
export interface IStaff {
	byId: IStaffById
	ids: number[]
}

export interface IStaffPaymentById {
	[id: number]: IStaffPayment
}
export interface IStaffPayments {
	byId: IStaffPaymentById
	ids: number[]
}

export interface IEditStaffMember {
	isActive?: boolean
	pricePerWord?: IMoney
}

export interface ICreateStaffPayment {
	paid: IMoney
	paymentMethod: PaymentMethod
}

export interface IAddStaff {
	userIds: number[]
}
