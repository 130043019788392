import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Page from '../../../components/layout/Page'
import { Routes } from '../../../config/routes'
import { ModuleDetailsPage } from '../components/dashboard/ModuleDetailsPage'
import { useEditPageModule, usePage } from '../hooks'
import { redirectToSinglePage } from '../utils/helpers'

interface ISingleModuleParams {
	pageId: string | undefined
	moduleId: string | undefined
}

export const SingleModuleContainer: React.FC = () => {
	const params = useParams<ISingleModuleParams>()
	const pageId = parseInt(params.pageId as string, 10)
	const moduleId = parseInt(params.moduleId as string, 10)

	const { page, isLoading: isPageLoading } = usePage(pageId)

	const module = page && page.modules?.find(module => module.id === moduleId)

	const { onEditPageModule, isLoading: isEditPageModuleLoading } = useEditPageModule(moduleId)

	const history = useHistory()

	const onRedirectToSinglePage = () => {
		redirectToSinglePage(history, pageId)
	}

	return (
		<Page
			isLoading={isPageLoading}
			loadingText="Loading module data"
			breadcrumbItems={[
				{ name: 'Pages', path: Routes.Pages.route },
				{
					name: page?.name || '',
					path: Routes.Pages.singlePageRoute.replace(':pageId', pageId.toString())
				},
				{
					name: module?.fieldValues.title || '',
					path: Routes.Pages.singleModuleRoute
						.replace(':pageId', pageId.toString())
						.replace(':moduleId', moduleId.toString())
				}
			]}
		>
			{page && module ? (
				<ModuleDetailsPage
					module={module}
					onEditPageModule={onEditPageModule}
					isEditPageModuleLoading={isEditPageModuleLoading}
					onRedirectToSinglePage={onRedirectToSinglePage}
				/>
			) : (
				<></>
			)}
		</Page>
	)
}
