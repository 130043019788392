import { Input, PageHeader } from 'antd'
import { ITag } from 'brainstorming-types'
import * as React from 'react'

import Page from '../../../components/layout/Page'
import { Routes } from '../../../config/routes'
import { AddTagButton } from '../components/dashboard/shared/AddTagButton'
import { GroupTagsTable } from '../components/dashboard/group/GroupTagsTable'
import { StyledButtonDiv } from '../components/dashboard/shared/StyledButtonDiv'
import { useCreateTag, useGroupTags, useDeleteTag } from '../hooks'
import { useGroupMembersById } from '../hooks/useGroupMembersById'

export const GroupTagsContainer = () => {
	const { groupTags, isLoading: isGroupTagsLoading } = useGroupTags()

	const groupTagIds = groupTags.map(({ id }) => id)
	const { groupMembersById } = useGroupMembersById(groupTagIds)

	const { onCreateTag, isLoading: isCreateTagLoading } = useCreateTag()

	const { onDeleteTag, isLoading: isDeleteTagLoading } = useDeleteTag()

	const [query, setQuery] = React.useState('')

	const [filteredTags, setFilteredTags] = React.useState<ITag[]>([])

	const filterTags = () => {
		const filteredTags = query
			? groupTags.filter(({ name }) => {
					return name.toLowerCase().includes(query.toLowerCase())
			  })
			: groupTags

		setFilteredTags(filteredTags)
	}

	React.useEffect(filterTags, [groupTags, query])

	const onSearchChange = (event: any) => {
		setQuery(event.target.value || '')
	}

	return (
		<Page
			isLoading={isGroupTagsLoading}
			loadingText={'Loading Group tags'}
			breadcrumbItems={[{ name: 'Group tags', path: Routes.GroupTags.route }]}
		>
			<PageHeader title="Group tags" />

			<StyledButtonDiv>
				<AddTagButton
					isGroup={true}
					onCreateTag={onCreateTag}
					isCreateTagLoading={isCreateTagLoading}
				/>
				<Input value={query} onChange={onSearchChange} placeholder="Filter tags" />
			</StyledButtonDiv>

			<GroupTagsTable
				groupTags={filteredTags}
				groupMembersById={groupMembersById}
				isGroupTagsLoading={isGroupTagsLoading}
				onDeleteTag={onDeleteTag}
				isDeleteTagLoading={isDeleteTagLoading}
			/>
		</Page>
	)
}
