export enum TagsActionTypes {
	// fetch regular tags
	FetchRegularTagsRequest = '@tags/FETCH_REGULAR_TAGS_REQUEST',
	FetchRegularTagsSuccess = '@tags/FETCH_REGULAR_TAGS_SUCCESS',
	FetchRegularTagsFailure = '@tags/FETCH_REGULAR_TAGS_FAILURE',
	// fetch group tags
	FetchGroupTagsRequest = '@tags/FETCH_GROUP_TAGS_REQUEST',
	FetchGroupTagsSuccess = '@tags/FETCH_GROUP_TAGS_SUCCESS',
	FetchGroupTagsFailure = '@tags/FETCH_GROUP_TAGS_FAILURE',
	// create tag
	CreateTagRequest = '@tags/CREATE_TAG_REQUEST',
	CreateTagSuccess = '@tags/CREATE_TAG_SUCCESS',
	CreateTagFailure = '@tags/CREATE_TAG_FAILURE',
	// update tag
	UpdateTagRequest = '@tags/UPDATE_TAG_REQUEST',
	UpdateTagSuccess = '@tags/UPDATE_TAG_SUCCESS',
	UpdateTagFailure = '@tags/UPDATE_TAG_FAILURE',
	// update synonyms
	UpdateSynonymsRequest = '@tags/UPDATE_SYNONYMS_REQUEST',
	UpdateSynonymsSuccess = '@tags/UPDATE_SYNONYMS_SUCCESS',
	UpdateSynonymsFailure = '@tags/UPDATE_SYNONYMS_FAILURE',
	// fetch synonyms for tag
	FetchSynonymsRequest = '@tags/FETCH_SYNONYMS_REQUEST',
	FetchSynonymsSuccess = '@tags/FETCH_SYNONYMS_SUCCESS',
	FetchSynonymsFailure = '@tags/FETCH_SYNONYMS_FAILURE',
	// fetch synonyms batch
	FetchSynonymsBatchRequest = '@tags/FETCH_SYNONYMS_BATCH_REQUEST',
	FetchSynonymsBatchSuccess = '@tags/FETCH_SYNONYMS_BATCH_SUCCESS',
	FetchSynonymsBatchFailure = '@tags/FETCH_SYNONYMS_BATCH_FAILURE',
	// add tags to group
	AddTagsToGroupRequest = '@tags/ADD_TAGS_TO_GROUP_REQUEST',
	AddTagsToGroupSuccess = '@tags/ADD_TAGS_TO_GROUP_SUCCESS',
	AddTagsToGroupFailure = '@tags/ADD_TAGS_TO_GROUP_FAILURE',
	// remove tags from group
	RemoveTagsFromGroupRequest = '@tags/REMOVE_TAGS_FROM_GROUP_REQUEST',
	RemoveTagsFromGroupSuccess = '@tags/REMOVE_TAGS_FROM_GROUP_SUCCESS',
	RemoveTagsFromGroupFailure = '@tags/REMOVE_TAGS_FROM_GROUP_FAILURE',
	// fetch group members
	FetchGroupMembersRequest = '@tags/FETCH_GROUP_MEMBERS_REQUEST',
	FetchGroupMembersSuccess = '@tags/FETCH_GROUP_MEMBERS_SUCCESS',
	FetchGroupMembersFailure = '@tags/FETCH_GROUP_MEMBERS_FAILURE',
	// fetch group members batch
	FetchGroupMembersBatchRequest = '@tags/FETCH_GROUP_MEMBERS_BATCH_REQUEST',
	FetchGroupMembersBatchSuccess = '@tags/FETCH_GROUP_MEMBERS_BATCH_SUCCESS',
	FetchGroupMembersBatchFailure = '@tags/FETCH_GROUP_MEMBERS_BATCH_FAILURE',
	// fetch groups
	FetchGroupsRequest = '@tags/FETCH_GROUPS_REQUEST',
	FetchGroupsSuccess = '@tags/FETCH_GROUPS_SUCCESS',
	FetchGroupsFailure = '@tags/FETCH_GROUPS_FAILURE',
	// fetch groups batch
	FetchGroupsBatchRequest = '@tags/FETCH_GROUPS_BATCH_REQUEST',
	FetchGroupsBatchSuccess = '@tags/FETCH_GROUPS_BATCH_SUCCESS',
	FetchGroupsBatchFailure = '@tags/FETCH_GROUPS_BATCH_FAILURE',
	// update groups
	UpdateGroupsRequest = '@tags/UPDATE_GROUPS_REQUEST',
	UpdateGroupsSuccess = '@tags/UPDATE_GROUPS_SUCCESS',
	UpdateGroupsFailure = '@tags/UPDATE_GROUPS_FAILURE',
	// update groups
	FetchSingleTagRequest = '@tags/FETCH_SINGLE_TAG_REQUEST',
	FetchSingleTagSuccess = '@tags/FETCH_SINGLE_TAG_SUCCESS',
	FetchSingleTagFailure = '@tags/FETCH_SINGLE_TAG_FAILURE',
	// delete tag
	DeleteTagRequest = '@tags/DELETE_TAG_REQUEST',
	DeleteTagSuccess = '@tags/DELETE_TAG_SUCCESS',
	DeleteTagFailure = '@tags/DELETE_TAG_FAILURE'
}
