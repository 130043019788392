import { IPage, IPageModuleFieldValues, PageModuleLayout, PageType } from 'brainstorming-types'

export enum PagesActionType {
	/// pages
	// fetch all
	FetchPagesRequest = '@pages/FETCH_PAGES_REQUEST',
	FetchPagesSuccess = '@pages/FETCH_PAGES_SUCCESS',
	FetchPagesFailure = '@pages/FETCH_PAGES_FAILURE',
	// fetch single
	FetchPageRequest = '@pages/FETCH_PAGE_REQUEST',
	FetchPageSuccess = '@pages/FETCH_PAGE_SUCCESS',
	FetchPageFailure = '@pages/FETCH_PAGE_FAILURE',
	// add new page
	CreatePageRequest = '@pages/CREATE_PAGE_REQUEST',
	CreatePageSuccess = '@pages/CREATE_PAGE_SUCCESS',
	CreatePageFailure = '@pages/CREATE_PAGE_FAILURE',
	// delete page
	DeletePageRequest = '@pages/DELETE_PAGE_REQUEST',
	DeletePageSuccess = '@pages/DELETE_PAGE_SUCCESS',
	DeletePageFailure = '@pages/DELETE_PAGE_FAILURE',
	// update page
	EditPageRequest = '@pages/EDIT_PAGE_REQUEST',
	EditPageSuccess = '@pages/EDIT_PAGE_SUCCESS',
	EditPageFailure = '@pages/EDIT_PAGE_FAILURE',
	// update pages
	EditPagesRequest = '@pages/EDIT_PAGES_REQUEST',
	EditPagesSuccess = '@pages/EDIT_PAGES_SUCCESS',
	EditPagesFailure = '@pages/EDIT_PAGES_FAILURE',
	// update page module order
	EditPageModuleOrderRequest = '@pages/EDIT_PAGE_MODULE_ORDER_REQUEST',
	EditPageModuleOrderSuccess = '@pages/EDIT_PAGE_MODULE_ORDER_SUCCESS',
	EditPageModuleOrderFailure = '@pages/EDIT_PAGE_MODULE_ORDER_FAILURE',

	/// page modules
	// create
	CreateModuleForPageRequest = '@pages/CREATE_MODULE_FOR_PAGE_REQUEST',
	CreateModuleForPageSuccess = '@pages/CREATE_MODULE_FOR_PAGE_SUCCESS',
	CreateModuleForPageFailure = '@pages/CREATE_MODULE_FOR_PAGE_FAILURE',
	// edit
	EditPageModuleRequest = '@pages/EDIT_PAGE_MODULE_REQUEST',
	EditPageModuleSuccess = '@pages/EDIT_PAGE_MODULE_SUCCESS',
	EditPageModuleFailure = '@pages/EDIT_PAGE_MODULE_FAILURE',
	// delete
	DeletePageModuleRequest = '@pages/DELETE_PAGE_MODULE_REQUEST',
	DeletePageModuleSuccess = '@pages/DELETE_PAGE_MODULE_SUCCESS',
	DeletePageModuleFailure = '@pages/DELETE_PAGE_MODULE_FAILURE'
}

export interface IPagesById {
	[id: number]: IPage
}
export interface IPages {
	byId: IPagesById
	ids: number[]
}

export interface ICreatePage {
	name: string
	slug: string
}

export interface IEditPage {
	name?: string
	slug?: string
	type?: PageType
	repeatLayout?: boolean
}

export interface IEditSinglePageBatch extends IEditPage {
	id: number
}

export interface IEditPagesBatch {
	data: IEditSinglePageBatch[]
}

export interface IEditPageModuleOrder {
	// ordered array of module ids
	moduleOrder: number[]
}

export interface ICreatePageModule {
	layoutName: PageModuleLayout
	fieldValues: IPageModuleFieldValues
}

export interface IEditPageModule {
	// TODO: maybe layoutName can be optional????
	layoutName?: PageModuleLayout
	fieldValues: IPageModuleFieldValues
}
