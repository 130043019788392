import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { addTagsToGroupRequest, removeTagsFromGroupRequest } from '../store/actions'
import { TagsActionTypes } from '../store/action.types'

interface IUseAddAndRemoveGroupMembersResult {
	onAddGroupMembers: (groupId: number, idsToAdd: number[]) => any
	onRemoveGroupMembers: (groupId: number, idsToRemove: number[]) => any
	isAddGroupMembersLoading: boolean
	isRemoveGroupMembersLoading: boolean
}

export const useAddAndRemoveGroupMembers = (): IUseAddAndRemoveGroupMembersResult => {
	const dispatch = useDispatch()

	const onAddGroupMembers = useCallback(
		(groupId: number, idsToAdd: number[]) => dispatch(addTagsToGroupRequest(groupId, idsToAdd)),
		[dispatch]
	)

	const onRemoveGroupMembers = useCallback(
		(groupId: number, idsToRemove: number[]) =>
			dispatch(removeTagsFromGroupRequest(groupId, idsToRemove)),
		[dispatch]
	)

	const loaders = useSelector(getLoadersState)
	const isAddGroupMembersLoading = isLoading(loaders, [TagsActionTypes.AddTagsToGroupRequest], {
		default: false
	})
	const isRemoveGroupMembersLoading = isLoading(
		loaders,
		[TagsActionTypes.RemoveTagsFromGroupRequest],
		{
			default: false
		}
	)

	return {
		onAddGroupMembers,
		onRemoveGroupMembers,
		isAddGroupMembersLoading,
		isRemoveGroupMembersLoading
	}
}
