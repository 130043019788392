import axios from 'axios'
import { SESSION_MANAGEMENT } from '../../../config/urls'
import {
	ICreatePage,
	IEditPage,
	ICreatePageModule,
	IEditPageModule,
	IEditPageModuleOrder,
	IEditPagesBatch
} from './types'

export const fetchPages = async () => {
	return axios
		.get(`${SESSION_MANAGEMENT}/pages`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const fetchPage = async (pageId: number) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/pages/${pageId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const createPage = async (data: ICreatePage) => {
	return axios
		.post(`${SESSION_MANAGEMENT}/pages`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const editPage = async (pageId: number, data: IEditPage) => {
	return axios
		.patch(`${SESSION_MANAGEMENT}/pages/${pageId}`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const editPages = async (data: IEditPagesBatch) => {
	return axios
		.patch(`${SESSION_MANAGEMENT}/pages/update-batch`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const editPageModuleOrder = async (pageId: number, data: IEditPageModuleOrder) => {
	return axios
		.patch(`${SESSION_MANAGEMENT}/pages/${pageId}/module-order`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const deletePage = async (pageId: number) => {
	return axios
		.delete(`${SESSION_MANAGEMENT}/pages/${pageId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const createModuleForPage = async (pageId: number, data: ICreatePageModule) => {
	return axios
		.post(`${SESSION_MANAGEMENT}/page-modules`, { pageId, ...data })
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const editPageModule = async (moduleId: number, data: IEditPageModule) => {
	return axios
		.patch(`${SESSION_MANAGEMENT}/page-modules/${moduleId}`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const deletePageModule = async (moduleId: number) => {
	return axios
		.delete(`${SESSION_MANAGEMENT}/page-modules/${moduleId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}
