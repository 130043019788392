import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { IStaffMember, IStaffPayment } from 'brainstorming-types'

import { getLoadersState, isLoading } from '../../../store/common/loaders/selector'
import { fetchPaymentsForStaffMemberRequest, fetchStaffMemberRequest } from '../store/actions'
import { getStaffMember, getStaffMemberPayments } from '../store/selectors'
import { StaffActionType } from '../store/types'

interface IUseStaffMemberDataResult {
	staffMember: IStaffMember
	payments: IStaffPayment[]
	isStaffMemberLoading: boolean
	isPaymentsLoading: boolean
}

export const useStaffMember = (staffMemberId: number): IUseStaffMemberDataResult => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchStaffMemberRequest(staffMemberId))
	}, [dispatch, staffMemberId])

	useEffect(() => {
		dispatch(fetchPaymentsForStaffMemberRequest(staffMemberId))
	}, [dispatch, staffMemberId])

	const getStaffMemberSelector = useCallback(getStaffMember(staffMemberId), [staffMemberId])
	const getStaffMemberPaymentsSelector = useCallback(getStaffMemberPayments(staffMemberId), [
		staffMemberId
	])

	const staffMember = useSelector(getStaffMemberSelector)
	const payments = useSelector(getStaffMemberPaymentsSelector)
	const loaders = useSelector(getLoadersState)
	const isStaffMemberLoading = isLoading(loaders, [StaffActionType.FetchStaffMemberRequest])
	const isPaymentsLoading = isLoading(loaders, [StaffActionType.FetchPaymentsForStaffMemberRequest])

	return {
		staffMember,
		payments,
		isStaffMemberLoading,
		isPaymentsLoading
	}
}
