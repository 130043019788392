import { SESSION_MANAGEMENT } from '../../../config/urls'
import axios from 'axios'
import { stringify } from 'querystring'
import { IEditComment } from './types'

export const fetchComments = async (params: any) => {
	return axios
		.get(`${SESSION_MANAGEMENT}/admin/comments/`, {
			params,
			paramsSerializer: stringify
		})
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const deleteComment = async (commentId: number) => {
	return axios
		.delete(`${SESSION_MANAGEMENT}/admin/comments/${commentId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const editComment = async (commentId: number, data: IEditComment) => {
	return axios
		.put(`${SESSION_MANAGEMENT}/admin/comments/${commentId}`, data)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}

export const restoreComment = async (commentId: number) => {
	return axios
		.put(`${SESSION_MANAGEMENT}/admin/comments/restore/${commentId}`)
		.then(response => ({ response: response.data }))
		.catch(error => ({ error: error.response.data }))
}
